import React, { Component } from 'react';
import classnames from 'classnames';

import ImageLoader from '../Shared/ImageLoader/ImageLoader';
import { getImageLink } from '../../helpers/imageHelper';
import { imageSizes } from '../../config/constants';
import ZoomPanPinch from "../Shared/ZoomPanPinch";

export default class TableImageItem extends Component {
  static defaultProps = {
    isExpandable: true,
    large: false,
  };

  state = {
    isLightboxOpen: false,
  };

  render() {
    const { currentAccount, isExpandable, large } = this.props;
    const firstLetter = currentAccount ? currentAccount.name[0] : '';
    const { isLightboxOpen } = this.state;
    const imageSize = large ? imageSizes.HISTORY_ITEM : imageSizes.SEARCH_ITEM;
    let smallImageLink = this.getLink(imageSize);
    let fullImageLink = this.getLink();

    return (
      <div className="body-cell-image">
        {smallImageLink && (
          <ImageLoader
            url={smallImageLink}
            spinnerWidth={imageSizes.SEARCH_ITEM.width / 2}
            containerStyle={{
              borderRadius: 5,
              height: imageSize.height,
              width: imageSize.width,
            }}
            imageStyle={{
              borderRadius: 5,
              height: imageSize.height,
              width: imageSize.width,
            }}
            onClick={
              isExpandable
                ? (e) => {
                    if (e) {
                      e.stopPropagation();
                    }
                    this.setState({ isLightboxOpen: true });
                  }
                : undefined
            }
          />
        )}
        {!smallImageLink && (
          <div
            className={classnames('first-letter-image', {
              'image-preview-large': large,
            })}
          >
            {firstLetter}
          </div>
        )}
        {isExpandable && fullImageLink && (
          <ZoomPanPinch
              imageSrc={fullImageLink}
              isOpen={isLightboxOpen}
              onClose={() => {
                this.setState({isLightboxOpen: false})
              }}
          />
        )}
      </div>
    );
  }

  getLink = (imageSize) => {
    const { imageUUID, imagesLinks } = this.props;
    return getImageLink(imageUUID, imagesLinks, imageSize);
  };
}
