import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import { withTranslation } from 'react-i18next';

import './style.css';
import palette from '../../../theme/palette';

class FormEditFilters extends Component {
  render() {
    const { column, filter, onChange, onSubmit, t } = this.props;

    return (
      <div className="table-filter" onKeyPress={this.handleKeyPress}>
        <div className="table-filter-input">
          <TextField
            hintText={column.name}
            fullWidth={true}
            type="text"
            name={column.name}
            value={filter ? filter.filter : ''}
            onChange={(e) => onChange(e, column.index)}
            autoFocus
            onFocus={this.moveCaretAtEnd}
          />
        </div>

        <div className="table-filter-buttons">
          <RaisedButton
            label={t('btn:clear')}
            className="btn"
            backgroundColor={palette.error}
            labelColor={palette.white}
            onClick={(e) => onSubmit(e, true)}
          />
          <RaisedButton
            label={t('btn:apply')}
            className="btn"
            primary
            onClick={onSubmit}
          />
        </div>
      </div>
    );
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onSubmit(e);
    }
  };

  moveCaretAtEnd(e) {
    var temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  }
}

export default withTranslation()(FormEditFilters);
