import axios from 'axios';
import i18next from 'i18next';
import Notifications from 'react-notification-system-redux';
import { push } from 'connected-react-router';

import {
  api,
  entityNames,
  resourceTypes,
  route,
  jobsTabs,
} from 'config/constants';
import { options, INCLUDE_RESOURCE } from 'helpers/cookieHelper';
import { adoptSettingsColumns } from 'helpers/fieldsHelper';
import { handleVersion } from 'helpers/versionsHelper';
import { getValidFormRulesList } from "../../helpers/patchHelper";
import * as type from './types';

export function fetchJobs(jobsTab) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.GET_JOBS,
    });

    const accountUUID = getState().accounts.currentAccount.uuid;
    if (accountUUID) {
      return axios
        .get(
          `${api.GET_SLATE_VIEWS_LIST}/${accountUUID}/export/jobs?query-type=${jobsTab}`,
          options,
        )
        .then((res) => {
          handleVersion(res);
          const jobs = res.data.data;

          if (jobs) {
            dispatch({
              type: type.SET_JOBS,
              payload: {
                jobs,
                jobsTab,
              },
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_JOBS,
          });
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_JOBS,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_JOBS,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function clearJobs() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_JOBS });
  };
}

export const updateJob = (data, jobUUID, name, shouldAddTimeZone = false) => (dispatch, getState) => {
  const { patches: _patches } = data;
  const patches = getValidFormRulesList(_patches);
  if (patches.length) {
    const accountUUID = getState().accounts.currentAccount.uuid;
    const currentTimeZone = getState().app.currentTimeZone;
    const patch = shouldAddTimeZone ?  [
        {
          op: 'add',
          path: 'ianaTimezone',
          value: currentTimeZone
        },
        ...patches] : patches;

    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.UPDATE_EXPORT_JOB,
    });

    return axios
      .patch(
        `${api.UPDATE_RESOURCE}/${resourceTypes.JOB}/${jobUUID}?account-uuid=${accountUUID}&${INCLUDE_RESOURCE}`,
        patch,
        options,
      )
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.ENTITY_UPDATED,
          entityName: entityNames.UPDATE_EXPORT_JOB,
        });

        dispatch(push(route.EXPORT));

        if (name) {
          dispatch(
            Notifications.success({
              message: `${name} ${i18next.t(
                'notifications:successfullySavedJob',
              )}`,
              position: 'tr',
              autoDismiss: 3,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityNames.UPDATE_EXPORT_JOB,
                hasErrors: { error400: i18next.t('messages:userNotFound') },
              });
              break;

            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.UPDATE_EXPORT_JOB,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityNames.UPDATE_EXPORT_JOB,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  }
};

// TODO: Create and clone job functions are almost equal, need to refactor it without a lot of copypast.
export function createJob(viewUUID) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.CREATE_JOB,
    });

    const accountUUID = getState().accounts.currentAccount.uuid;
    if (accountUUID && viewUUID) {
      return axios
        .get(
          `${api.CREATE_JOB}/${accountUUID}/views/${viewUUID}/export`,
          options,
        )
        .then((res) => {
          handleVersion(res);
          const settings = adoptSettingsColumns(res.data.data);

          if (settings) {
            dispatch({
              type: type.SET_JOB_SETTINGS,
              payload: {
                settings: Object.assign(settings, { fromView: true }),
              },
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.CREATE_JOB,
          });

          dispatch(push(route.EXPORT_NEW_JOB));
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.CREATE_JOB,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.CREATE_JOB,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function cloneJob(jobUUID) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.CLONE_JOB,
    });

    const accountUUID = getState().accounts.currentAccount.uuid;
    if (accountUUID && jobUUID) {
      return axios
        .get(
          `${api.CREATE_JOB}/${accountUUID}/export/jobs/${jobUUID}/clone`,
          options,
        )
        .then((res) => {
          handleVersion(res);
          const settings = adoptSettingsColumns(res.data.data);

          if (settings) {
            dispatch({
              type: type.SET_JOB_SETTINGS,
              payload: {
                settings,
              },
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.CLONE_JOB,
          });

          dispatch(push(route.EXPORT_NEW_JOB));

          const newTimeZone = settings.ianaTimezone;
          const currentTimeZone = getState().app.currentTimeZone;

          if (newTimeZone && newTimeZone !== currentTimeZone) {
            dispatch({
              type: type.SET_CURRENT_TIME_ZONE,
              payload: newTimeZone,
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.CLONE_JOB,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.CLONE_JOB,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function rerunJob(jobUUID, name) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.RERUN_JOB,
    });

    const accountUUID = getState().accounts.currentAccount.uuid;
    if (accountUUID && jobUUID) {
      const currentTimeZone = getState().app.currentTimeZone;
      return axios
        .post(
          `${api.CREATE_JOB}/${accountUUID}/export/jobs/${jobUUID}/rerun`,
          {
            ianaTimezone: currentTimeZone
          },
          options,
        )
        .then((res) => {
          handleVersion(res);
          const isRerun = res.data.data;

          if (isRerun) {
            dispatch({
              type: type.SET_JOBS_TAB_UPDATE,
              payload: jobsTabs.ACTIVE,
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.RERUN_JOB,
          });

          if (isRerun && name) {
            dispatch(
              Notifications.success({
                message: `${name} ${i18next.t(
                  'notifications:rerunHasBeenStarted',
                )}`,
                position: 'tr',
                autoDismiss: 3,
              }),
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.RERUN_JOB,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.RERUN_JOB,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function removeJob(jobUUID, name) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.REMOVE_JOB,
    });

    const accountUUID = getState().accounts.currentAccount.uuid;
    if (accountUUID && jobUUID) {
      return axios
        .delete(
          `${api.CREATE_JOB}/${accountUUID}/export/jobs/${jobUUID}`,
          options,
        )
        .then((res) => {
          handleVersion(res);
          dispatch({
            type: type.SET_JOBS_TAB_UPDATE,
            payload: jobsTabs.ARCHIVED,
          });

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.REMOVE_JOB,
          });

          if (name) {
            dispatch(
              Notifications.success({
                message: `${name} ${i18next.t(
                  'notifications:successfullyRemoveExportJob',
                )}`,
                position: 'tr',
                autoDismiss: 3,
              }),
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.REMOVE_JOB,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.REMOVE_JOB,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function clearJobSettings() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_JOB_SETTINGS });
  };
}

// if tab need to be updated
export function setJobsTabUpdate(tab) {
  return (dispatch) => {
    dispatch({
      type: type.SET_JOBS_TAB_UPDATE,
      payload: tab,
    });
  };
}
