import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import routes from 'config/routes';
import AppRoute from './AppRoute';
import { CaptureRouteNotFound, RouteNotFound } from './NotFound';

const RenderRoutes = ({ currentUser, isContentFixed }) => (
  <CaptureRouteNotFound user={currentUser}>
    <div
      className={classnames(`content`, {
        'content-fixed': isContentFixed,
      })}
    >
      <Switch>
        {routes.map((route, index) => {
          return (
            <AppRoute
              key={index}
              exact={route.exact || false}
              path={route.path}
              component={route.component}
              layout={route.layout}
              routes={route.routes}
              user={currentUser}
              noPaddingContainer={route.noPaddingContainer}
            />
          );
        })}
        <RouteNotFound />
      </Switch>
    </div>
  </CaptureRouteNotFound>
);

RenderRoutes.propTypes = {
  currentUser: PropTypes.object,
  isContentFixed: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default withRouter(connect(mapStateToProps)(RenderRoutes));
