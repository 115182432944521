import p from './palette';

const muiColors = {
  primary1Color: p.primary,
  primary2Color: p.primaryH,
  primary3Color: p.primaryH,
  accent1Color: p.secondary,
  accent2Color: p.secondaryH,
  accent3Color: p.secondaryH,
  textColor: p.defaultText,
  secondaryTextColor: p.grey600,
  alternateTextColor: p.white,
  canvasColor: p.white,
  borderColor: p.border,
  disabledColor: p.grey300,
  shadowColor: p.grey800,
};

export default muiColors;
