import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import MarkerInfo from '../../../containers/Map/MarkerInfo';
import { validateLat, validateLng } from '../../../helpers/validationHelper';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapChangeTrigger: false,
    };
    this.googleMaps = React.createRef();
  }

  componentDidMount = () => {
    const { slates } = this.props;
    if (slates) {
      this.fitBounds(this.checkCoordinates(slates));
    }
  };

  render() {
    const {
      zoom,
      center,
      slates,
      isView,
      isSlate,
      isClusterer,
      isMarkerShown,
      onMarkerClick,
      onMarkerMove,
      mapType,
    } = this.props;
    const { mapChangeTrigger } = this.state;

    return (
      <GoogleMap
        defaultZoom={zoom || 4}
        center={center} // need for marker re-rendering in slate editor
        ref={this.googleMaps}
        onIdle={this.handleIdle}
        onMapTypeIdChanged={this.handleMapTypeChange}
        mapTypeId={mapType}
      >
        {isView && isClusterer && (
          <MarkerClusterer
            onClick={() => {}}
            averageCenter
            enableRetinaIcons
            gridSize={50}
          >
            {slates &&
              slates.map((slate) => (
                <MarkerInfo
                  key={slate.uuid}
                  slate={slate}
                  googleMaps={this.googleMaps.current}
                  mapChangeTrigger={mapChangeTrigger}
                  onMarkerClick={onMarkerClick}
                />
              ))}
          </MarkerClusterer>
        )}

        {isView &&
          !isClusterer &&
          slates.map((slate) => (
            <MarkerInfo
              key={slate.uuid}
              slate={slate}
              googleMaps={this.googleMaps.current}
              mapChangeTrigger={mapChangeTrigger}
              onMarkerClick={onMarkerClick}
            />
          ))}

        {isSlate && isMarkerShown && (
          <Marker position={center} draggable={true} onDragEnd={onMarkerMove} />
        )}
      </GoogleMap>
    );
  }

  checkCoordinates = (slates) =>
    slates.filter((slate) => validateLat(slate.lat) && validateLng(slate.lng));

  fitBounds = (slates) => {
    if (slates && slates.length && this.googleMaps.current) {
      const bounds = new window.google.maps.LatLngBounds();
      slates.forEach((slate) => {
        bounds.extend(new window.google.maps.LatLng(slate.lat, slate.lng));
      });
      this.googleMaps.current.fitBounds(bounds);

      this.setState({
        mapChangeTrigger: !this.state.mapChangeTrigger,
      });
    }
  };

  handleIdle = () => {
    this.setState({
      mapChangeTrigger: !this.state.mapChangeTrigger,
    });
  };

  handleMapTypeChange = () => {
    const mapType = this.googleMaps.current.getMapTypeId();
    const { updateMapType } = this.props;
    if (mapType && updateMapType) {
      updateMapType(mapType);
    }
  };
}

export default withGoogleMap(Map);
