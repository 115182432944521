import find from 'lodash/find';

import * as type from 'store/actions/types';

const initialState = [{ type: 'errorDialog', isOpen: false }];

const updateState = (state, type, isOpen, uuid) => {
  if (state.length > 0) {
    let item = find(state, { type: type });
    if (item) {
      return state.map((obj, index) => {
        if (obj.type === type) {
          return { ...obj, isOpen: isOpen, uuid: uuid };
        }
        return obj;
      });
    } else {
      return [
        ...state,
        {
          type: type,
          isOpen: isOpen,
          uuid: uuid,
        },
      ];
    }
  } else {
    return [
      ...state,
      {
        type: type,
        isOpen: isOpen,
        uuid: uuid,
      },
    ];
  }
};

const dialogs = (state = initialState, action) => {
  switch (action.type) {
    case type.ERROR_DIALOG_OPEN:
      return updateState(state, 'errorDialog', true, action.uuid);
    case type.ERROR_DIALOG_CLOSE:
      return updateState(state, 'errorDialog', false, null);
    default:
      return state;
  }
};

export default dialogs;
