import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { route } from '../../config/constants';
import Content from '../../containers/Content';
import { isLoggedOut } from './../../helpers/accountHelper';

const AppRoute = ({
  component: Component,
  layout,
  user,
  noPaddingContainer,
  exact,
  ...rest
}) => {
  const content = (
    <Content
      component={
        <Route {...rest} render={(props) => <Component {...props} />}/>
      }
      layout={layout}
      noPaddingContainer={noPaddingContainer}
      currentUser={user}
    />
  );
  if (exact && isLoggedOut(user)) {
    return <Redirect to={route.START}/>;
  }
  return content;
};

export default AppRoute;
