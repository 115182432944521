import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import cx from 'classnames';

import { theme } from './theme.js';

class SlatePaneBar extends Component {
  render() {
    const { onDock, onHide, onPrev, onNext } = this.props;

    return (
      <div className="slate-pane-bar" style={theme.slatePaneHeader}>
        <div className="slate-pane-bar-actions">
          <div className="slate-pane-icon slate-pane-pin" onClick={onDock}>
            <FontAwesome name="thumb-tack" />
          </div>
          <div className="slate-pane-icon slate-pane-close" onClick={onHide}>
            <FontAwesome name="times" />
          </div>
        </div>
        <div className="slate-pane-bar-actions-right">
          <div
            className={cx('slate-pane-icon', 'slate-pane-next', {
              'slate-pane-icon-disabled': !onPrev,
            })}
            onClick={onPrev}
          >
            <FontAwesome name="angle-left" />
          </div>
          <div
            className={cx('slate-pane-icon', 'slate-pane-next', {
              'slate-pane-icon-disabled': !onNext,
            })}
            onClick={onNext}
          >
            <FontAwesome name="angle-right" />
          </div>
        </div>
      </div>
    );
  }
}

export default SlatePaneBar;
