import React from 'react';
import tryMe from '../../helpers/tryMe';
import TextField from 'material-ui/TextField';
import p from '../../theme/palette';
import styles from './styles';

const FormTelField = (props) => (
  <span>
    <TextField
      value={props.value}
      name={props.name}
      onChange={(e) => props.actions.handlePhoneChange(e)}
      hintText={props.hintText}
      floatingLabelText={props.floatingLabelText}
      fullWidth={props.fullWidth}
      type="tel"
      autoComplete={props.autocomplete}
      errorText={tryMe(props.state.dataErrors)[props.name] || ''}
      onBlur={(e) => props.actions.handleBlur(e)}
      errorStyle={{ bottom: 10, fontSize: 11, color: p.error }}
      floatingLabelFocusStyle={{
        transform: 'scale(0.75) translate(0px, -20px)',
        color: p.defaultText,
      }}
      floatingLabelShrinkStyle={{
        transform: 'scale(0.75) translate(0px, -20px)',
      }}
      floatingLabelStyle={{ top: 26 }}
      hintStyle={{ bottom: 8 }}
      inputStyle={{ marginTop: 8 }}
      style={{ fontSize: 14,
//---- global font setting ----
        fontWeight: 400,
        height: 56 }}
      underlineFocusStyle={{ borderColor: p.defaultText }}
      underlineDisabledStyle={{}}
      underlineStyle={{ borderWidth: 1 }}
    />
    <div style={styles.formFieldHint}>{props.hint}</div>
  </span>
);

export default FormTelField;
