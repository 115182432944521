import React, { Component } from 'react';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import { Grid, AutoSizer, ScrollSync, InfiniteLoader } from 'react-virtualized';
import { arrayMove } from 'react-sortable-hoc';

import BasicDialog from 'components/Dialogs/BasicDialog';
import FormEditFilters from './Forms/FormEditFilters';
import { SortableList } from './Header/Sortable';
import ScrollableTableRow from './ScrollableTableRow';
import { loadingStatuses } from './loadingStatuses';
import Expander from '../Shared/Expander/Expander';
import { getColumnMaxWidth } from './helpers';
import {
  sortTypes,
  fieldsTypes,
  dateTimeDisplayTypes,
  dateTimeFormats,
} from 'config/constants';
import { getTextWidth } from 'helpers/canvasHelper';
import { renderDateTime } from 'helpers/dateTimeHelper';
// import 'react-resizable-ext/css/styles.css';
import './styles.css';

const ScrollTablePreset = {
  rowHeight: {
    small: 36,
    medium: 70,
    large: 140,
  },
};

const columnsSet = (columns) =>
  columns.map((column) => column.name).sort((a, b) => a.localeCompare(b));

class ScrollableTable extends Component {
  static nextId = 1;

  static defaultProps = {
    isExpandable: true,
    isExpanded: false,
    onExpand: () => {},
    onCompress: () => {},
    updateFilters: () => {},
    rowsSelected: [],
    widthStretch: false,
    onRowClickParentHandler: () => {},
    columns: [],
    isRowClickable: true,
  };

  state = {
    columns: [], // column info for reordering
    data: null,
    dataIDs: [], // for proper scrolling purposes
    rowCount: 0, // data rows total count - provided by server

    loadedRowsMap: {}, // for InfiniteLoading
    // loadedRowCount: 0, // for InfiniteLoading
    // loadingRowCount: 0, // for InfiniteLoading

    rowWidth: 0, // is calculated on render and update

    isFilterDialogOpen: false, // is Filter Dialog open?
    filterColumn: null, // Current Column to show in the dialog
    currentFilter: null,

    isEditCellDialogOpen: false, // is Edit Value Dialog open?

    rowsSelected: [], // Array of selected data ids (not rowIndexes)
    isAllRowsSelected: false, // are all rows selected?
    rangeStart: null, // rowIndex to start rangle selection
    rangeEnd: null, // rowIndex to end range selection
  };

  constructor(props) {
    super(props);

    this.scrollableTable = React.createRef();
    this.scrollSync = React.createRef();
    this.gridHeader = React.createRef();
    this.gridBody = React.createRef();

    this.fixedCellWidth = 0; // width of the first 'fixed' column
    this.fixedRowEnd = 14;
    this.uniqueId = ScrollableTable.nextId++;
  }

  UNSAFE_componentWillMount() {
    const { data, dataIDs, columns, rowsSelected } = this.props;
    const rowCount = this.props.rowCount || data.length;

    this.fixedCellWidth = this.calcFixedCellWidth(rowCount);

    let loadedRowsMap = {};
    for (var i = 0; i < data.length; i++) {
      loadedRowsMap[i] = loadingStatuses.STATUS_LOADED;
    }

    let newRowsSelected = [];
    if (rowsSelected) {
      newRowsSelected = rowsSelected;
    }

    if (this.props.isStaticRows) {
      this.setState({
        columns,
        rowWidth: 500,
        data,
        dataIDs,
        rowCount,
        loadedRowsMap,
        rowsSelected: newRowsSelected,
      });
    } else {
      let colRow = this.setColumnsRowWidth(data, columns);

      this.setState({
        columns: colRow.newColumns,
        rowWidth: colRow.rowWidth,
        data,
        dataIDs,
        rowCount,
        loadedRowsMap,
        rowsSelected: newRowsSelected,
      });
    }
  }

  componentDidMount() {
    this.scrollableTable.current.addEventListener('copy', this.handleCopyEvent);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { columns, data, isStaticRows, rowsSelected } = this.props;
    if (
      !isEqual(columns, nextProps.columns) ||
      !isEqual(data, nextProps.data)
    ) {
      if (
        this.scrollSync.current &&
        !isEqual(columnsSet(columns), columnsSet(nextProps.columns))
      ) {
        this.scrollSync.current._onScroll({ scrollLeft: 0 });
      }
      this.fixedCellWidth = this.calcFixedCellWidth(nextProps.data.length);
      let colRow = { newColumns: nextProps.columns, rowWidth: 500 };

      if (!isStaticRows) {
        colRow = this.setColumnsRowWidth(nextProps.data, nextProps.columns);
      }

      const loadedRowsMap = {};
      for (var j = 0; j < nextProps.data.length; j++) {
        loadedRowsMap[j] = loadingStatuses.STATUS_LOADED;
      }
      const newItemsIndex = data.length;
      this.setState(
        {
          columns: colRow.newColumns,
          rowWidth: colRow.rowWidth,
          data: nextProps.data,
          dataIDs: nextProps.dataIDs,
          rowCount: nextProps.rowCount || nextProps.data.length,
          loadedRowsMap,
        },
        () => {
          this.gridBody.current.recomputeGridSize({
            columnIndex: 0,
            rowIndex: newItemsIndex,
          });
          this.scrollSync.current.forceUpdate();
          this.tableForceUpdate();
        },
      );
    }

    if (!isEqual(rowsSelected, nextProps.rowsSelected)) {
      this.setState(
        {
          rowsSelected: nextProps.rowsSelected,
        },
        () => {
          const { rowsSelected, dataIDs } = this.state;
          this.tableForceUpdate();

          // scroll to selected row (keyboard or arrows navigation)
          if (rowsSelected.length === 1) {
            this.gridBody.current.scrollToCell({
              rowIndex: dataIDs.indexOf(rowsSelected[0]),
            });
          }
        },
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual(nextProps, this.props) && isEqual(nextState, this.state));
  }

  componentDidUpdate() {
    let gridBody = document.getElementById(`bodyGrid${this.uniqueId}`);
    if (gridBody) {
      this.scrollBarWidth = gridBody.offsetWidth - gridBody.clientWidth;
    }
  }

  componentWillUnmount() {
    document.getElementById('pages').classList.remove('slate-pane-open');
    this.scrollableTable.current.removeEventListener(
      'copy',
      this.handleCopyEvent,
    );
  }

  render() {
    const {
      isExpanded,
      onExpand,
      onCompress,
      width,
      height,
      isExpandable,
    } = this.props;
    return (
      <div
        className="scrollable-table"
        ref={this.scrollableTable}
        style={{ width, height }}
      >
        <BasicDialog
          title="Filter"
          open={this.state.isFilterDialogOpen}
          onClose={this.handleCloseFilterDialog}
          style={{ maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }}
        >
          <FormEditFilters
            column={this.state.filterColumn}
            filter={this.state.currentFilter}
            onChange={this.handleFilterValueChange}
            onClose={this.handleCloseFilterDialog}
            onSubmit={this.handleSubmitFilterDialog}
          />
        </BasicDialog>

        {isExpandable && (
          <Expander
            isExpanded={isExpanded}
            onExpand={onExpand}
            onCompress={onCompress}
          />
        )}

        <InfiniteLoader
          isRowLoaded={this._isRowLoaded}
          loadMoreRows={this._loadMoreRows}
          rowCount={this.state.rowCount}
          minimumBatchSize={1}
          threshold={1}
        >
          {this._infiniteLoaderChildFunction(this)}
        </InfiniteLoader>
      </div>
    );
  }

  tableForceUpdate = () => {
    if (this.gridHeader.current) {
      this.gridHeader.current.forceUpdate();
    }
    if (this.gridBody.current) {
      this.gridBody.current.forceUpdate();
    }
  };

  // calc the width of first column from the rowCount + 20px
  calcFixedCellWidth = (count) => {
    const num = count.toString().replace(/\d/g, '0');
    return getTextWidth(num, '10px serif') + 20;
  };

  setColumnsRowWidth = (data, columns) => {
    let data25;
    if (data.length > 25) {
      data25 = data.slice(0, 25);
    } else {
      data25 = data.slice();
    }
    return this.setInitialColumnWidth(data25, columns);
  };

  setInitialColumnWidth = (data, columns) => {
    const columnPadding = 100;
    const dataPadding = 10;
    const font = '18px serif';
    const newColumns = [];

    // calc header cell width
    columns.forEach((column) => {
      const maxWidth = getColumnMaxWidth(column);
      const canvasWidth = getTextWidth(column.name, font);

      const columnWidth = canvasWidth < maxWidth ? canvasWidth : maxWidth;
      newColumns.push({
        ...column,
        width: column.width || columnWidth + columnPadding,
      });
    });

    // calc column width
    data.forEach((dataItem) => {
      dataItem.columns.forEach((item) => {
        const column = find(newColumns, (col) => col.index === item.index);
        let value = item.value;

        const maxWidth = getColumnMaxWidth(column);
        // change a value depends on column type
        if (column && column.type === fieldsTypes.DATETIME) {
          value =
            column.displayType === dateTimeDisplayTypes.DATE
              ? renderDateTime(item.value, dateTimeFormats.DATE)
              : renderDateTime(item.value);
        }
        if (
          column &&
          (column.type === fieldsTypes.IMAGE ||
            column.type === fieldsTypes.PHOTO)
        ) {
          value = '';
        }
        const canvasWidth = getTextWidth(value, font) + dataPadding;

        if (column && canvasWidth > column.width) {
          column.width = canvasWidth < maxWidth ? canvasWidth : maxWidth;
        }
      });
    });

    // calc row width
    let rowWidth = 0;
    newColumns.forEach((column) => {
      rowWidth += column.width;
      return rowWidth;
    });

    return {
      newColumns: newColumns,
      rowWidth: rowWidth + this.fixedCellWidth + this.fixedRowEnd,
    };
  };

  setColumnWidth = (colIndex, width) => {
    let newCols = this.state.columns.map((col) => {
      if (col.index.toString() === colIndex) {
        return { ...col, width: width };
      } else {
        return col;
      }
    });
    let newRowWidth = 0;
    newCols.map((column) => (newRowWidth += column.width));
    this.setState(
      {
        columns: newCols,
        rowWidth: newRowWidth + this.fixedCellWidth + this.fixedRowEnd,
      },
      () => {
        this.scrollSync.current.forceUpdate();
        this.tableForceUpdate();
      },
    );
  };

  // ----- fetch data
  fetchNewData = (filters, sortDirection, sortColumnIndex, isNewSet) => {
    const { fetchData } = this.props;

    let sort = '';
    if (sortDirection) {
      sort = `${sortColumnIndex},${sortDirection}`;
    }

    if (!fetchData) {
      return;
    }
    fetchData(isNewSet, filters, sort);
  };

  // ----- sorting data
  handleSortData = (index) => {
    const {
      filters,
      sortDirection,
      sortColumnIndex,
      updateFilters,
    } = this.props;
    let newSortDirection;

    if (index === sortColumnIndex) {
      switch (sortDirection) {
        case '': {
          newSortDirection = sortTypes.ASC;
          break;
        }
        case sortTypes.ASC: {
          newSortDirection = sortTypes.DESC;
          break;
        }
        case sortTypes.DESC: {
          newSortDirection = '';
          index = '';
          break;
        }
        default:
          break;
      }
    } else {
      newSortDirection = sortTypes.ASC;
    }

    this.fetchNewData(filters, newSortDirection, index, true);
    updateFilters({
      sortDirection: newSortDirection,
      sortColumnIndex: index,
    });
  };

  // ----- filtering data
  handleOpenFilterDialog = (column) => {
    const { filters } = this.props;
    let currentFilter = find(filters, (item) => item.index === column.index);

    if (!currentFilter) {
      currentFilter = { filter: '', index: column.index };
    }

    this.setState(
      {
        isFilterDialogOpen: true,
        filterColumn: column,
        currentFilter,
      },
      () => document.body.classList.add('dialog-open'),
    );
  };

  handleFilterValueChange = (e) => {
    let currentFilter = cloneDeep(this.state.currentFilter);
    currentFilter.filter = e.target.value;
    this.setState({ currentFilter });
  };

  handleCloseFilterDialog = () => {
    this.setState(
      {
        isFilterDialogOpen: false,
        currentFilter: null,
      },
      () => {
        document.body.classList.remove('dialog-open');
      },
    );
  };

  handleSubmitFilterDialog = (e, isClear) => {
    let { currentFilter } = this.state;
    if (isClear) {
      currentFilter = {
        ...currentFilter,
        filter: '',
      };
    }

    const {
      filters,
      sortDirection,
      sortColumnIndex,
      updateFilters,
    } = this.props;

    let newFilters = cloneDeep(filters);
    if (!newFilters) {
      newFilters = [];
    }
    const filterIndex = findIndex(newFilters, { index: currentFilter.index });

    if (filterIndex === -1) {
      newFilters.push(currentFilter); // new filter
    } else {
      newFilters.splice(filterIndex, 1, currentFilter); // replace filter
    }

    newFilters = newFilters.filter((item) => item.filter.length);

    if (isEqual(filters, newFilters)) {
      this.handleCloseFilterDialog();
    } else {
      this.setState(
        {
          isFilterDialogOpen: false,
          currentFilter: null,
        },
        () => {
          document.body.classList.remove('dialog-open');
          this.fetchNewData(newFilters, sortDirection, sortColumnIndex, true);
          updateFilters({ filters: newFilters });
        },
      );
    }
  };

  // -----

  handleCloseEditDialog = () => {
    this.setState(
      {
        isEditCellDialogOpen: false,
      },
      () => document.body.classList.remove('dialog-open'),
    );
  };

  handleResizeStart = (e) => {
    if (e) {
      e.preventDefault();
    }
  };

  handleResizeColumn = (e, data) => {
    const colIndex = data.node.parentElement.getAttribute('id');
    this.setColumnWidth(colIndex, data.size.width);
  };

  // HANDLE ROW DIFFERENT CLICKS
  handleRowClicks = (e, rowUUID, index) => {
    if (!rowUUID) {
      return;
    }
    if (!this._delayedClick) {
      this._delayedClick = debounce(
        (e, rowUUID) => this.handleRowSingleClick(e, rowUUID),
        200,
      );
    }
    if (this.clickedOnce) {
      this.handleRowDoubleClick(e, rowUUID, index);
    } else {
      this._delayedClick(e, rowUUID, index);
      this.clickedOnce = true;
    }
  };

  handleRowSingleClick = (e, rowUUID) => {
    if (rowUUID) {
      let newRowsSelected = [];
      newRowsSelected.push(rowUUID);
      this.clickedOnce = undefined;
      this.setState(
        {
          rowsSelected: newRowsSelected,
        },
        () => {
          if (this.gridBody.current) {
            this.gridBody.current.forceUpdate();
          }
          this.props.onRowClickParentHandler(e, rowUUID);
        },
      );
    }
  };

  handleRowDoubleClick = (e, rowUUID, index) => {
    this.clearSelection();

    this._delayedClick.cancel();
    this.clickedOnce = false;
  };

  // ----- select rows
  handleChangeCheckbox = (e, rowIndex) => {
    this.clearSelection();
    const { data, rowsSelected, rangeStart, rangeEnd } = this.state;
    const { uniqueField } = this.props;

    let newRowsSelected = cloneDeep(rowsSelected);
    const row = data[rowIndex];
    let selectedUUID;

    if (row) {
      selectedUUID = row[uniqueField];
    } else {
      return;
    }

    let newRangeStart = rangeStart;
    let newRangeEnd = rangeEnd;

    if (e.shiftKey) {
      if (newRangeStart === null) {
        return;
      }

      if (rowIndex < newRangeStart) {
        newRangeEnd = newRangeStart;
        newRangeStart = rowIndex;
      } else {
        newRangeEnd = rowIndex;
      }

      for (let i = newRangeStart; i <= newRangeEnd; i++) {
        let selectedUUID = data[i][uniqueField];
        let row = find(newRowsSelected, (uuid) => uuid === selectedUUID);
        if (!row) {
          newRowsSelected.push(selectedUUID);
        }
      }
    } else {
      newRangeStart = rowIndex;
      newRangeEnd = null;

      let row = find(newRowsSelected, (uuid) => uuid === selectedUUID);
      if (row) {
        newRowsSelected = remove(newRowsSelected, (uuid) => {
          return uuid !== selectedUUID;
        });
      } else {
        newRowsSelected.push(selectedUUID);
      }
    }

    this.setState(
      {
        isAllRowsSelected: false,
        rangeStart: newRangeStart,
        rangeEnd: newRangeEnd,
        rowsSelected: newRowsSelected,
      },
      () => {
        this.tableForceUpdate();
      },
    );
  };

  handleSelectAllRows = () => {
    const { data, isAllRowsSelected } = this.state;
    const { uniqueField } = this.props;
    let newRowsSelected = [];

    if (!isAllRowsSelected) {
      data.forEach((item) => {
        newRowsSelected.push(item[uniqueField]);
      });
    }

    this.setState(
      {
        isAllRowsSelected: !this.state.isAllRowsSelected,
        rowsSelected: newRowsSelected,
      },
      () => {
        this.tableForceUpdate();
      },
    );
  };

  isRowSelected = (rowIndex) => {
    const { data, rowsSelected } = this.state;
    const { uniqueField } = this.props;

    let row = data[rowIndex];
    if (!row) {
      return false;
    }
    return !!find(rowsSelected, (uuid) => uuid === row[uniqueField]);
  };

  isFirstRowSelected = (rowIndex) => {
    return (
      !(rowIndex > 0 && this.isRowSelected(rowIndex - 1)) &&
      this.isRowSelected(rowIndex)
    );
  };

  calcHeaderRightOffset = (scrollWidth, clientWidth, scrollLeft) => {
    if (scrollLeft === 0) this.headerRightOffset = 0;
    else {
      const diff = scrollWidth - (clientWidth - this.scrollBarWidth) - 2;
      if (diff > 0 && diff < this.scrollBarWidth) {
        this.headerRightOffset = scrollLeft;
      } else if (diff > 0 && diff < this.scrollBarWidth + scrollLeft) {
        this.headerRightOffset = this.scrollBarWidth - 2 - (diff - scrollLeft);
      } else this.headerRightOffset = 0;

      if (scrollLeft === diff) this.headerRightOffset += 2;
    }
  };

  getPhotoColumnsIndexesByColumns = (columns) => {
    return columns
      .filter((column) => column.type === fieldsTypes.PHOTO)
      .map((column) => column.index);
  };

  _infiniteLoaderChildFunction = (componentContext) => {
    const photoColumnsIndexes = componentContext.getPhotoColumnsIndexesByColumns(
      componentContext.props.columns,
    );
    const rowHeightFunction = (params) => {
      const rowIndex = params.index;
      if (photoColumnsIndexes.length) {
        const { data, loadedRowsMap } = componentContext.state;
        if (loadedRowsMap[rowIndex] !== loadingStatuses.STATUS_LOADED) {
          return ScrollTablePreset.rowHeight.small;
        }
        const notEmptyImageIndex = photoColumnsIndexes.findIndex(
          (columnIndex) => {
            if (!data[rowIndex]) {
              return false;
            }
            const column = data[rowIndex].columns[columnIndex];
            const value = column && column.value;
            return value && typeof value === 'string' && value !== '';
          },
        );
        if (notEmptyImageIndex !== -1) {
          return ScrollTablePreset.rowHeight.medium;
        }
      }
      return ScrollTablePreset.rowHeight.small;
    };
    const isItemsExist =
      componentContext.state &&
      componentContext.state.data &&
      componentContext.state.data.length;
    const useRowHeightFunction = !!photoColumnsIndexes.length && isItemsExist;
    return ({ onRowsRendered }) => {
      this._onRowsRendered = onRowsRendered;

      return (
        <ScrollSync ref={this.scrollSync}>
          {({ clientWidth, onScroll, scrollLeft, scrollWidth }) => {
            this.calcHeaderRightOffset(scrollWidth, clientWidth, scrollLeft);

            return (
              <AutoSizer>
                {({ width, height }) => {
                  const {
                    fixedHeaderHeight,
                    widthStretch,
                    fixedHeader,
                    rowHeightPreset,
                  } = this.props;
                  const { rowCount, rowWidth } = this.state;
                  const lineWidth = widthStretch
                    ? Math.max(width - 20, rowWidth)
                    : rowWidth;

                  let rowHeight = useRowHeightFunction
                    ? rowHeightFunction
                    : {
                        medium: ScrollTablePreset.rowHeight.medium,
                        large: ScrollTablePreset.rowHeight.large,
                      }
                      [rowHeightPreset] || ScrollTablePreset.rowHeight.small;
                  if(typeof rowHeightPreset === 'function') {
                    rowHeight = rowHeightPreset;
                  }
                  return (
                    <div>
                      {fixedHeader && (
                        <Grid
                          id={`headerGrid${this.uniqueId}`}
                          className="header-grid"
                          cellRenderer={this._headerCellRenderer}
                          columnCount={1}
                          columnWidth={lineWidth}
                          height={fixedHeaderHeight}
                          rowCount={1}
                          rowHeight={fixedHeaderHeight}
                          width={width}
                          scrollLeft={scrollLeft}
                          ref={this.gridHeader}
                        />
                      )}

                      <Grid
                        id={`bodyGrid${this.uniqueId}`}
                        className="body-grid"
                        cellRenderer={this._cellRenderer}
                        columnCount={1}
                        columnWidth={lineWidth}
                        height={
                          fixedHeader ? height - fixedHeaderHeight : height
                        }
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        width={width}
                        onSectionRendered={this._onSectionRendered}
                        ref={this.gridBody}
                        scrollLeft={scrollLeft}
                        onScroll={(scrollInfo) => {
                          if (this.props.data.length > 0) {
                            return onScroll(scrollInfo);
                          }
                        }}
                      />
                    </div>
                  );
                }}
              </AutoSizer>
            );
          }}
        </ScrollSync>
      );
    };
  };

  _onSortStart = (obj, e) => {
    if (e) {
      e.preventDefault();
    }
    document
      .getElementById(`headerGrid${this.uniqueId}`)
      .classList.add('header-drag');
  };

  _onSortEnd = ({ oldIndex, newIndex }) => {
    document
      .getElementById(`headerGrid${this.uniqueId}`)
      .classList.remove('header-drag');
    const { columns } = this.state;
    const { updateColumns } = this.props;

    let sortedColumns = arrayMove(columns, oldIndex, newIndex);
    updateColumns(sortedColumns);
  };

  _headerCellRenderer = ({ key, style }) => {
    const { columns, isAllRowsSelected } = this.state;
    const {
      filters,
      sortDirection,
      sortColumnIndex,
      useDragHandle,
      withOrdering,
      withFiltration,
      withSorting,
    } = this.props;

    let sortIcon;

    if (sortDirection === sortTypes.ASC) {
      sortIcon = 'arrow-down';
    } else if (sortDirection === sortTypes.DESC) {
      sortIcon = 'arrow-up';
    }

    return (
      <SortableList
        key={key}
        columns={columns}
        axis={'x'}
        useDragHandle={useDragHandle}
        withOrdering={withOrdering}
        withFiltration={withFiltration}
        withSorting={withSorting}
        lockAxis={'x'}
        onSortStart={this._onSortStart}
        onSortEnd={this._onSortEnd}
        style={style}
        filters={filters}
        sortIcon={sortIcon}
        sortColumnIndex={sortColumnIndex}
        isAllRowsSelected={isAllRowsSelected}
        fixedCellWidth={this.fixedCellWidth}
        headerRightOffset={this.headerRightOffset}
        handleResizeStart={this.handleResizeStart}
        handleResizeColumn={this.handleResizeColumn}
        handleSelectAllRows={this.handleSelectAllRows}
        handleOpenFilterDialog={this.handleOpenFilterDialog}
        handleSortData={this.handleSortData}
      />
    );
  };

  _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const { data, columns } = this.state;
    const {
      rowHeightPreset,
      uniqueField,
      isImageExpandable,
      showEmptyImages,
      isRowClickable,
    } = this.props;
    if (data.length) {
      return (
        <ScrollableTableRow
          loadedRowsMap={this.state.loadedRowsMap}
          key={key}
          style={style}
          rowIndex={rowIndex}
          columns={columns}
          data={data}
          fixedCellWidth={this.fixedCellWidth}
          isRowSelected={this.isRowSelected}
          isFirstRowSelected={this.isFirstRowSelected}
          onChangeCheckbox={this.handleChangeCheckbox}
          onRowClick={this.handleRowClicks}
          rowHeightPreset={rowHeightPreset}
          uniqueField={uniqueField}
          isImageExpandable={isImageExpandable}
          showEmptyImages={showEmptyImages}
          isRowClickable={isRowClickable}
        />
      );
    }
  };

  // STATUS_LOADING or STATUS_LOADED
  _isRowLoaded = ({ index }) => !!this.state.loadedRowsMap[index];

  _loadMoreRows = ({ startIndex, stopIndex }) => {
    return;
  };

  _onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }) => {
    const {
      dataGetEntity,
      data,
      dataToken,
      filters,
      sortDirection,
      sortColumnIndex,
    } = this.props;
    if (dataToken && rowStopIndex > data.length - 20) {
      if (dataGetEntity && !dataGetEntity.isFetching) {
        this.fetchNewData(filters, sortDirection, sortColumnIndex, false);
      }
    }
  };

  handleCopyEvent = (e) => {
    // We need to prevent the default copy functionality,
    // otherwise it would just copy the selection as usual.
    if (e) {
      e.preventDefault();
    }

    // The copy event doesn't give us access to the clipboard data,
    // so we need to get the user selection via the Selection API.
    const selectionText = window.getSelection().toString();
    let escaped = escape(selectionText);

    // remove draggable icon "::" from header
    escaped = escaped.replace(/%3A%3A%0A/g, '');

    if (escaped.startsWith('%0A')) {
      escaped = escaped.slice(3);
    }

    // replace all "line feed" with "horizontal tab"
    escaped = escaped.replace(/%0A/g, '%09');

    // replace special signs "@#$" with "line feed"
    escaped = escaped.replace(/%09@%23%24%09/g, '%0A');
    escaped = escaped.replace(/@%23%24%09/g, '');

    // Place the transformed text in the clipboard.
    e.clipboardData.setData('text/plain', unescape(escaped));
  };

  clearSelection = () => {
    const selection = window.getSelection();
    selection.removeAllRanges();
  };
}

export default ScrollableTable;
