import palette from '../../theme/palette';

export const styles = {
  header: {
    background: palette.defaultText,
    color: palette.white,
  },
  checkboxLabel: {
    color: palette.white,
  },
  checkboxIcon: {
    fill: palette.white,
    marginRight: 7,
  },
};
