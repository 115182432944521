import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  updateAccount,
  getApiKeys,
  removeApiKey,
  setCurrentApiKey,
  clearCurrentApiKey,
} from 'store/actions/account';
import AccountSettings from 'components/AccountSettings/AccountSettings';

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    currentAccount: state.accounts.currentAccount,
    apiKeys: state.accounts.apiKeys,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    updateAccount,
    getApiKeys,
    removeApiKey,
    setCurrentApiKey,
    clearCurrentApiKey,
  })(AccountSettings),
);
