import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Content from '../components/Content/Content';

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}
export default withRouter(withTranslation()(connect(mapStateToProps)(Content)));
