import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import TextField from 'material-ui/TextField/TextField';
import IconButton from 'material-ui/IconButton';
import NavigationArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import { useTranslation } from 'react-i18next';

import { fieldsTypes } from 'config/constants';
import RuleFields from 'components/SlateViewsSettings/RuleFields/RuleFields';
import { styles } from './styles';
import NavigationClose from "material-ui/svg-icons/navigation/close";

const RecordFieldSelector = ({ currentSUUID, fields, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const textFieldRef = useRef();
  const { t } = useTranslation();

  const recordFields = useMemo(
    () =>
      fields
        .filter((field) => field.type === fieldsTypes.RECORDS)
        .map((field) => ({ ...field, parent: undefined, children: undefined })),
    [fields],
  );
  const currentField = recordFields.find(
    (field) => field.suuid === currentSUUID,
  );

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    setAnchorEl(textFieldRef.current.input);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFieldChange = ({suuid, title}) => {
    handlePopoverClose();
    onChange(suuid, title);
  };

  const onRemoveRule = () => {
    onChange('', '');
  };

  return (
    <div className="settings-record-selector">
      <TextField
        ref={textFieldRef}
        name="recordsFieldUniquenessIdentifier"
        value={currentField ? currentField.title : ''}
        hintText={t('settings:selectField')}
        onClick={handleClick}
        style={{ ...styles.field, ...styles.recordFieldSelector }}
      />
      <IconButton
        style={styles.selectButton}
        iconStyle={styles.selectButtonIcon}
        onClick={handleClick}
      >
        <NavigationArrowDropDown />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={handlePopoverClose}
      >
        <RuleFields fields={recordFields} onFieldClick={handleFieldChange} />
      </Popover>
      {!!currentField &&
        <div className="settings-record-btn-remove">
          <IconButton
            iconStyle={styles.removeButton}
            onClick={onRemoveRule}
          >
            <NavigationClose/>
          </IconButton>
        </div>
      }
    </div>
  );
};

RecordFieldSelector.propTypes = {
  currentSUUID: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RecordFieldSelector;
