export const styles = {
  style: {
    height: 38,
    border: '1px solid #ccc',
  },
  inputStyle: {
    paddingLeft: 15,
    marginTop: 0,
  },
  hintStyle: {
    paddingLeft: 15,
    fontSize: 14,
    bottom: 6,
  },
  underlineStyle: {
    bottom: -1,
  },
  containerStyle: {
    width: '100%',
  },
  clearButton: {
    border: '1px solid #ccc',
    height: 38,
    width: 38,
    padding: 9,
    borderLeft: 0,
  },
  iconInInputCentered: {
    top: '10px',
    left: '10px',
  },
};
