import React from 'react';
import FontAwesome from 'react-fontawesome';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';

import p from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  title: {
    color: p.grey300,
    fontSize: 18,
//---- global font setting ----
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',
  },
  content: {
    padding: 0,
    fontSize: 16,
  },
  closeButton: {
    position: 'absolute',
    width: 40,
    height: 40,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: p.grey300,
  },
  closeIcon: {
    fontSize: 16,
  },
}));

const BasicDialog = ({ open, onClose, actions, title, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="sm"
      {...rest}
    >
      <DialogTitle id="dialog-title" disableTypography>
        <Typography className={classes.title}>{title}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <FontAwesome name="times" className={classes.closeIcon} />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BasicDialog;
