import palette from '../../theme/palette';

export const theme = {
  active: {
    color: palette.brandOrange,
  },
  headerTitle: {
    color: 'white',
  },
  filter: {
    color: palette.filterIcon,
  },
  tableExpand: {
    color: palette.white,
    background: palette.filterIcon,
  },
  sortableIcon: {
    width: 22,
    textAlign: 'left',
    paddingLeft: 6,
    lineHeight: '35px',
    cursor: 'all-scroll',
    color: palette.sidebarLink,
  },
};
