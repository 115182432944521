const styles = {
  button: {
    width: 30,
    height: 30,
    padding: 0,
  },
  editIcon: {
    width: 20,
    height: 20,
    color: '#949494',
  },
  removeIcon: {
    width: 22,
    height: 22,
    color: '#87302c',
  },
};

export default styles;
