import each from 'lodash/each';

export function searchAvailableFields(fields, text) {
  each(fields, (field) => {
    if (!field.parents || !field.parents.length) {
      markAppropriateField(field, text);
    }
  });
}

export function searchSelectedFields(fields, text = '') {
  let appropriateFields = [];
  text = text.trim().toLowerCase();

  if (text.length === 0) {
    appropriateFields = fields;
  } else {
    each(fields, (field) => {
      if (isAppropriateField(field, text)) {
        appropriateFields.push(field);
      } else {
        if (field.parents.length) {
          each(field.parents, (parent) => {
            if (isAppropriateField(parent, text)) {
              appropriateFields.push(field);
              return false;
            }
          });
        }
      }
    });
  }
  return appropriateFields;
}

function markAppropriateField(field, text) {
  if (isAppropriateField(field, text)) {
    field.isShown = true;
    each(field.parents, (parent) => {
      parent.isShown = true;
      parent.isShowChildren = true;
    });
    if (field.children) {
      field.isShowChildren = false;
      markChildren(field.children);
    }
  } else {
    field.isShown = false;
    if (field.children) {
      each(field.children, (child) => {
        markAppropriateField(child, text);
      });
    }
  }
}

function isAppropriateField(field, text) {
  return (
    field.title.toLowerCase().includes(text) ||
    field.type.toLowerCase().includes(text)
  );
}

function markChildren(fields) {
  each(fields, (field) => {
    field.isShown = true;
    if (field.children) {
      markChildren(field.children);
    }
  });
}
