import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem } from 'material-ui/List';
import merge from 'lodash/merge';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import { useTranslation } from 'react-i18next';

import { fieldsDnDId } from 'config/constants';
import * as params from '../params';
import { styles } from '../styles';
import IconButton from "material-ui/IconButton";
import NavigationClose from "material-ui/svg-icons/navigation/close";

const SelectedItem = ({
  field,
  index,
  isHighlighted,
  onFieldAttributeChange,
  fieldAttributes,
  onFieldClick,
  hideAttributes,
}) => {
  const { t } = useTranslation();

  const renderRemoveButton = () => (
    <div className="settings-columns-item-btn-remove">
      <IconButton
        value={'delete'}
        iconStyle={styles.removeButton}
        onClick={(e) => onFieldClick(e, field.cuuid)}
      >
        <NavigationClose/>
      </IconButton>
    </div>
  )

  const renderListItem = () => (
    <div style={styles.listItem}>
      <ListItem
        primaryText={
          field.parent && <div>{renderFieldParents(field.parent)}</div>
        }
        secondaryText={
          <div className="settings-field-body" style={styles.fieldBody}>
            <div className="settings-field-title">{field.title}</div>
            {!hideAttributes && (
              <>
                <div className="settings-field-type">{field.type}</div>
                <div className="settings-field-display">
                  <SelectField
                    floatingLabelText=""
                    value={field.fieldAttribute}
                    style={styles.selectField.style}
                    labelStyle={styles.selectField.labelStyle}
                    iconStyle={styles.selectField.iconStyle}
                    selectedMenuItemStyle={
                      styles.selectField.selectedMenuItemStyle
                    }
                    listStyle={styles.selectField.listStyle}
                    underlineStyle={styles.selectField.underlineStyle}
                    onChange={(event, index, value) =>
                      onFieldAttributeChange(value)
                    }
                  >
                    {fieldAttributes.map((fieldAttribute) => (
                      <MenuItem
                        key={fieldAttribute}
                        value={fieldAttribute}
                        primaryText={t(
                          `settings:fieldAttribute_${fieldAttribute}`,
                        )}
                        innerDivStyle={styles.menuItem.innerDivStyle}
                      />
                    ))}
                  </SelectField>
                </div>
              </>
            )}

          </div>
        }
        style={styles.listItemStyle}
        innerDivStyle={styles.innerDivStyle}
        hoverColor="#c3e2f6"
        autoGenerateNestedIndicator={false}
        onClick={(e) => onFieldClick(e, field.cuuid)}
      />
    </div>
  );

  const renderFieldParents = (parent) => {
    return (
      <div style={styles.parentStyle}>
        {parent.parent && <div>{renderFieldParents(parent.parent)}</div>}
        {parent.title}
      </div>
    );
  };

  return (
    <MediaQuery minWidth={params.MIN_WIDTH} key={field.cuuid}>
      {(matches) => {
        if (matches) {
          return (
            <Draggable
              draggableId={`${fieldsDnDId.SELECTED}${field.cuuid}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={merge(
                    {},
                    provided.draggableProps.style,
                    snapshot.isDragging && styles.dragging,
                  )}
                  className={classnames({
                    'settings-field-selected': true,
                    'settings-field-selected-highlighted': isHighlighted,
                  })}
                >
                  {renderListItem()}
                  {renderRemoveButton()}
                </div>
              )}
            </Draggable>
          );
        } else {
          return (
            <div
              className={classnames({
                'settings-field-selected': true,
                'settings-field-selected-highlighted': isHighlighted,
              })}
            >
              {renderListItem()}
              {renderRemoveButton()}
            </div>
          );
        }
      }}
    </MediaQuery>
  );
};

export default SelectedItem;
