import React from 'react';
import SlatePane from 'components/SlatePane/SlatePane';
import './styles.css';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

function SlatePage({match,}) {
  const {slateUUID, imageUrl} = match.params;
  return (
    <div className="slate-page">
      <SlatePane isHidden={false} slateUUID={slateUUID} imageUrl={imageUrl} isSlatePane={false}/>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SlatePage),
);
