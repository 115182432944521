import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { SidebarHeader } from './SidebarHeader';
import { SidebarLinks } from './SidebarLinks';
import Legal from '../Legal/Legal';
import SidebarAccount from 'components/Sidebar/SidebarAccount';
import { route, routeTitles } from '../../config/constants';
import './styles.css';
import theme from './theme';
import {isLoggedOut} from "../../helpers/accountHelper";

const Sidebar = ({
  dashboardSettings,
  app,
  currentSlateViewUUID,
  onClose,
  isHovered,
  isCollapsed,
  onMouseEnter,
  onMouseLeave,
  onToggle,
  location,
  currentAccount,
  allAccountsList,
 currentUser,
}) => {
  const { t } = useTranslation();
  const links = [
    // {
    //   id: 4,
    //   url: route.METRICS,
    //   path: route.METRICS,
    //   title: t(`routes:${routeTitles.METRICS}`),
    //   icon: 'pie-chart',
    //   visibleBySettingKey: 'showMetricsPage',
    // },
    {
      id: 0,
      url: '/accounts/' + (currentAccount ? currentAccount.uuid : '') + '/views/' + currentSlateViewUUID,
      path: route.SLATE_VIEWS,
      title: t(`routes:${routeTitles.SLATE_VIEWS}`),
      icon: 'th-list',
    },
    {
      id: 1,
      url: route.SLATE_GENERATOR,
      path: route.SLATE_GENERATOR,
      title: t(`routes:${routeTitles.SLATE_GENERATOR}`),
      icon: 'plus-square-o',
    },
    {
      id: 2,
      url: route.EXPORT,
      path: route.EXPORT,
      title: t(`routes:${routeTitles.EXPORT}`),
      icon: 'download',
    },
  ];
  const linksWithNotifications = [
    ...links,
    {
      id: 3,
      url: route.NOTIFICATIONS,
      path: route.NOTIFICATIONS,
      title: t(`routes:${routeTitles.NOTIFICATIONS}`),
      icon: 'bell',
    },
  ];
  useEffect(() => {
    onClose();
  }, [location.pathname]);

  const _showNotificationTab = allAccountsList && currentAccount.uuid && allAccountsList.find(item => item.uuid === (currentAccount && currentAccount.uuid))
  const showNotificationTab = _showNotificationTab && _showNotificationTab.settings
  const currentLinksList =  !!showNotificationTab && showNotificationTab.showNotifications ? linksWithNotifications : links;

  const preparedLinks =
    currentLinksList.filter((link) =>
    link.visibleBySettingKey
      ? dashboardSettings[link.visibleBySettingKey]
      : true,
  );

  return (
    <nav
      className={classnames('sidebar', {
        hover: isHovered && isCollapsed,
      })}
      style={theme.sidebar}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!isHovered && isCollapsed && (
        <div className="sidebar-overlay-inner" onClick={onMouseEnter} />
      )}
      <SidebarHeader onToggle={onToggle} />
      <div className="sidebar-content" style={theme.sidebarContent}>
        <div>
          <SidebarAccount
            isHovered={isHovered}
            isCollapsed={isCollapsed}
            onMouseLeave={onMouseLeave}
            onClose={onClose}
          />
          {
            isLoggedOut(currentUser)
              ? <div style={theme.sidebarContentForLoggedOut}>
                  {'This is a public version of this view. ' +
                  'For full access, contact the administrator or owner of this Stack.'}
                </div>
              : <SidebarLinks links={preparedLinks} path={app.path} />
          }
        </div>
        <Legal />
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  currentUser: state.currentUser,
  currentAccount: state.accounts.currentAccount,
  allAccountsList: state.accounts.accountsList,
  dashboardSettings: state.currentUser.dashboardSettings,
  currentSlateViewUUID: state.slateViews.currentSlateView
    ? state.slateViews.currentSlateView.uuid
    : '',
});

export default withRouter(connect(mapStateToProps, null)(Sidebar));
