import React from 'react';

import './style.css';
import palette from '../../theme/palette';

const getEnvInfo = () => {
  let envStr = '';
  let styles = {};

  switch (process.env.REACT_APP_SL8_ENV) {
    case 'development': {
      envStr = `ENV: ${process.env.REACT_APP_SL8_ENV}`;
      styles = { color: palette.brandBlue };
      break;
    }
    case 'beta': {
      envStr = `ENV: ${process.env.REACT_APP_SL8_ENV}`;
      styles = { color: palette.brandOrange };
      break;
    }
    default:
      break;
  }

  return envStr ? (
    <span className="footer-env" style={styles}>
      {envStr}
    </span>
  ) : null;
};

const Legal = () => {
  const envInfo = getEnvInfo();

  return (
    <div className="legal-info">
      <div>
        <span>Slate Pages Dashboard v2.0</span>
      </div>

      <div>
        <span>Build: {process.env.REACT_APP_BUILD_NUMBER}</span>
        {envInfo}
      </div>

      <div>
        <span>&copy;{new Date().getFullYear()} The Slate Pages, LLC</span>
      </div>

      <div>
        <span>
          <a
            href="http://slatepages.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            slatepages.com
          </a>
        </span>
        <span>
          <a
            href="https://slatepages.com/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms
          </a>
        </span>
        <span>
          <a
            href="https://slatepages.com/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy
          </a>
        </span>
      </div>
    </div>
  );
};

export default Legal;
