import * as type from './types';
import {FETCHING_STATUS, FETCHING_TYPE} from "../reducers/notifications";
import {api} from "../../config/constants";
import {options} from "../../helpers/cookieHelper";
import axios from "axios";
import generateUUID from "../../helpers/generateUUID";
import cloneDeep from "lodash/cloneDeep";
import Notifications from "react-notification-system-redux";
import i18next from "i18next";

export const getListOfNotifications = () => {
  return (dispatch, getState) => {
    const accountUUID = getState().accounts.currentAccount.uuid;
    dispatch({
      type: type.SET_FETCHING_INFO,
      payload: {
        status: FETCHING_STATUS.inProgress,
        type: FETCHING_TYPE.gettingNotificationsList,
      },
    });

    return axios
      .get(
          `${api.GET_NOTIFICATIONS}${accountUUID}/notifications`,
          options,
        )
          .then((res) => {
            if (res.data.data) {
              dispatch({
                type: type.SET_LIST_OF_NOTIFICATIONS,
                payload: {
                  notifications: res.data.data,
                },
              });
            }

            dispatch({
              type: type.SET_FETCHING_INFO,
              payload: {
                status: FETCHING_STATUS.success,
                type: FETCHING_TYPE.gettingNotificationsList,
              },
            });
          })
          .catch((err) => {
            console.log('err', err);
            dispatch({
              type: type.SET_FETCHING_INFO,
              payload: {
                status: FETCHING_STATUS.failed,
                type: FETCHING_TYPE.gettingNotificationsList,
              },
            });
          });
  }
}

export const getNotification = notificationUUID => {
  return (dispatch, getState) => {
    const accountUUID = getState().accounts.currentAccount.uuid;
    dispatch({
      type: type.SET_FETCHING_INFO,
      payload: {
        status: FETCHING_STATUS.inProgress,
        type: FETCHING_TYPE.gettingNotification,
      },
    });

    return axios
      .get(
        `${api.GET_NOTIFICATIONS}${accountUUID}/notifications/${notificationUUID}`,
        options,
      )
      .then((res) => {
        if (res.data.data) {
          dispatch({
            type: type.SET_ACTIVE_NOTIFICATION_INFO,
            payload: {
              notification: res.data.data,
            },
          });
        }

        dispatch({
          type: type.SET_FETCHING_INFO,
          payload: {
            status: FETCHING_STATUS.success,
            type: FETCHING_TYPE.gettingNotification,
          },
        });
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({
          type: type.SET_FETCHING_INFO,
          payload: {
            status: FETCHING_STATUS.failed,
            type: FETCHING_TYPE.gettingNotification,
          },
        });
      });
  }
}

export const deleteNotificationsFromList = ({activeNotificationUUID, currentAccountUUID, value}) => {
  return (dispatch, getState) => {
    dispatch({
      type: type.SET_FETCHING_INFO,
      payload: {
        status: FETCHING_STATUS.inProgress,
        type: FETCHING_TYPE.deletingNotification,
      },
    })
    let verifyOptions = cloneDeep(options);
    verifyOptions.data = {confirmNotificationName: value};

    return axios
      .delete(
        `${api.DELETE_NOTIFICATION}${currentAccountUUID}/notifications/${activeNotificationUUID}`,
        verifyOptions,
      )
      .then(() => {
        const state = getState();
        const notifications = state.notifications_page.listOfNotifications.filter(notification => notification.uuid !== activeNotificationUUID);

        if (notifications) {
          dispatch({
            type: type.DELETE_NOTIFICATION,
            payload: {
              notifications,
            },
          });
          dispatch({
            type: type.SET_FETCHING_INFO,
            payload: {
              status: FETCHING_STATUS.success,
              type: FETCHING_TYPE.deletingNotification,
            },
          })
          dispatch(
            Notifications.success({
              message: `${i18next.t('notifications:notificationWasDeletedSuccessfully')}.`,
              position: 'tr',
              autoDismiss: 3,
            }),
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({
          type: type.SET_FETCHING_INFO,
          payload: {
            status: FETCHING_STATUS.failed,
            type: FETCHING_TYPE.deletingNotification,
          },
        });
      });
  }
}

export const setActiveNotifications = notificationUUID => {
  return (dispatch) => {
    if(!notificationUUID) {
      dispatch({
        type: type.SET_ACTIVE_NOTIFICATION_INFO,
        payload: {
          notification: '',
        },
      })
    }
    dispatch({
      type: type.SET_ACTIVE_NOTIFICATION,
      payload: { notificationUUID },
    })
  }
}

export const createNotification = (patches) => {
  return (dispatch, getState) => {
    dispatch({
      type: type.SET_FETCHING_INFO,
      payload: {
        status: FETCHING_STATUS.inProgress,
        type: FETCHING_TYPE.settingNotification,
      },
    });

    const notificationUUID = generateUUID();
    const { currentAccount } = getState().accounts;

    return axios
      .patch(
        `${api.CREATE_NOTIFICATION}${notificationUUID}?account-uuid=${currentAccount.uuid}`,
        patches,
        options,
      )
      .then(() => {
        dispatch({
          type: type.SET_FETCHING_INFO,
          payload: {
            status: FETCHING_STATUS.success,
            type: FETCHING_TYPE.settingNotification,
          },
        });
        getListOfNotifications();
      })
      .catch((error) => {
        if(error.response){
          dispatch({
          type: type.SET_FETCHING_INFO,
          payload: {
            status: FETCHING_STATUS.failed,
            type: FETCHING_TYPE.settingNotification,
            errors: error.response.data
          },
        });
        }
      });
  }
}


export const changeNotification = (patches, activeNotificationUUID) => {
  return (dispatch, getState) => {
    dispatch({
      type: type.SET_FETCHING_INFO,
      payload: {
        status: FETCHING_STATUS.inProgress,
        type: FETCHING_TYPE.settingNotification,
      },
    });

    const { currentAccount } = getState().accounts;

    return axios
      .patch(
        `${api.CREATE_NOTIFICATION}${activeNotificationUUID}?account-uuid=${currentAccount.uuid}`,
        patches,
        options,
      )
      .then(() => {
        dispatch({
          type: type.SET_FETCHING_INFO,
          payload: {
            status: FETCHING_STATUS.success,
            type: FETCHING_TYPE.settingNotification,
          },
        });
        getListOfNotifications();
      })
      .catch((error) => {
        if(error.response){
          dispatch({
            type: type.SET_FETCHING_INFO,
            payload: {
              status: FETCHING_STATUS.failed,
              type: FETCHING_TYPE.settingNotification,
              errors: error.response.data
            },
          });
        }
      });
  }
}

export const clearFetchingType = () => {
  return (dispatch) => {
    dispatch({
      type: type.CLEAR_FETCHING_TYPE,
    })
  }
}

