import React, { Component } from 'react';
import { ChromePicker, GithubPicker } from 'react-color';
import { Popover } from 'material-ui/Popover';

import './styles.css';

class ColorPicker extends Component {
  static defaultProps = {
    value: '#ff0000',
    presets: [
      'rgb(244, 67, 54)',
      'rgb(233, 30, 99)',
      'rgb(55, 64, 70)',
      'rgb(156, 39, 176)',
      'rgb(103, 58, 183)',
      'rgb(63, 81, 181)',
      'rgb(33, 150, 243)',
      'rgb(3, 169, 244)',
      'rgb(0, 188, 212)',
      'rgb(0, 150, 136)',
      'rgb(76, 175, 80)',
      'rgb(139, 195, 74)',
      'rgb(205, 220, 57)',
      'rgb(255, 235, 59)',
      'rgb(255, 193, 7)',
      'rgb(255, 152, 0)',
      'rgb(255, 87, 34)',
      'rgb(121, 85, 72)',
      'rgb(158, 158, 158)',
      'rgb(96, 125, 139)',
    ],
  };

  state = {
    displayColorPicker: false,
    anchorRef: null,
  };

  render() {
    const { value, presets } = this.props;
    const { displayColorPicker, anchorRef } = this.state;
    return (
      <div className="color-picker" ref={this.setAnchorRef}>
        <div className="color-picker-swatch" onClick={this.handleClick}>
          <div className="color-picker-color" style={{ background: value }} />
        </div>
        <Popover
          open={displayColorPicker}
          anchorEl={anchorRef}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleClose}
        >
          <GithubPicker
            color={value}
            onChange={this.onChange}
            triangle="hide"
            width={227}
            colors={presets}
            styles={{
              default: {
                card: {
                  padding: 4,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderWidth: 0,
                  boxShadow:
                    'rgba(0, 0, 0, 0.3) 0 0 2px, rgba(0, 0, 0, 0.3) 0 4px 8px',
                },
              },
            }}
          />
          <ChromePicker
            color={value}
            onChange={this.onChange}
            disableAlpha
            styles={{
              default: {
                saturation: {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },
              },
            }}
          />
        </Popover>
      </div>
    );
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  onChange = (color) => {
    this.props.onChange(color.hex);
  };

  setAnchorRef = (anchorRef) => {
    this.setState({
      anchorRef,
    });
  };
}

export default ColorPicker;
