import React, { Component } from 'react';
import map from 'lodash/map';
import { withTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import JobsItem from './JobsItem';
import { jobsTabs } from '../../config/constants';
import './style.css';

const MIN_WIDTH = 641;

class JobsList extends Component {
  render() {
    const {
      jobs,
      tab,
      onFavoriteClick,
      onRerunClick,
      onCloneClick,
      onRemoveClick,
      t,
    } = this.props;
    return (
      <div>
        {jobs.length ? (
          <div className="jobs-list">
            <MediaQuery minWidth={MIN_WIDTH}>
              {(matches) => {
                if (matches) {
                  return (
                    <div className="jobs-list-title">
                      <div className="jobs-list-title-name">
                        {t('export:name')}
                      </div>
                      <div className="jobs-list-title-date">
                        {t('export:date')}
                      </div>
                      <div className="jobs-list-title-status">
                        {t('export:status')}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              }}
            </MediaQuery>

            {map(jobs, (job) => (
              <JobsItem
                key={job.uuid}
                job={job}
                onRerunClick={onRerunClick}
                onCloneClick={onCloneClick}
                onFavoriteClick={onFavoriteClick}
                onRemoveClick={onRemoveClick}
                t={t}
              />
            ))}
          </div>
        ) : (
          this.getEmptyText(tab, t)
        )}
      </div>
    );
  }

  // TODO translate
  getEmptyText = (tab, t) => {
    switch (tab) {
      case jobsTabs.ACTIVE: {
        return (
          <div className="jobs-list-empty">
            There are no recent export jobs. To get started, choose
            <span className="tools-export-bold">
              {' '}
              {t('export:createNewExportJob')}{' '}
            </span>
            above.
          </div>
        );
      }
      case jobsTabs.FAVORITES: {
        return (
          <div className="jobs-list-empty">
            You have no favorite export jobs. To add a favorite, choose the
            favorite button (&#9734;) on an export job under
            <span className="tools-export-bold"> {t('export:active')} </span>
            or
            <span className="tools-export-bold"> {t('export:archived')} </span>
            tabs above.
          </div>
        );
      }
      case jobsTabs.ARCHIVED: {
        return;
      }
      default:
        break;
    }
  };
}

export default withTranslation()(JobsList);
