import { connect } from 'react-redux';
import MarkerInfo from '../../components/Shared/Map/MarkerInfo';

const mapStateToProps = (state) => {
  return {
    currentAccount: state.accounts.currentAccount,
    imagesLinks: state.images.links,
  };
};

export default connect(mapStateToProps, null)(MarkerInfo);
