export default {
  en: {
    routes: {
      home: 'Home',
      profileSettings: 'Profile Settings',
      accountSettings: 'Account Settings',
      apiKey: 'API Key',
      slateViews: 'Slate Views',
      slateViewSettings: 'Configuration',
      searchResults: 'Search Results',
      slateGenerator: 'Slate Generator',
      notifications: 'Notifications',
      notificationNew: 'New Notification',
      notificationEdit: 'Edit Notification',
      export: 'Export',
      newExportJob: 'New Export Job',
      slate: 'Slate',
      view: 'View',
      list: 'List',
      map: 'Map',
      metrics: 'Metrics',
    },
    common: {
      profileSettings: 'Profile Settings',
      logOut: 'Log Out',
      accountSettings: 'Account Settings',
      switchAccount: 'Switch Account',
      photo: 'Photo',
      now: 'Now',
      new: 'NEW',
      today: 'Today',
      color: 'Color',
    },
    btn: {
      contactUs: 'Contact Us',
      cancel: 'Cancel',
      acceptAndContinue: 'Accept and Continue',
      done: 'Done',
      delete: 'Delete',
      createProfile: 'Create Profile',
      logIn: 'Log In',
      addRule: 'Add Rule',
      addColor: 'Add Color',
      save: 'Save',
      saveRun: 'Save & Run',
      saveApiKey: 'Save API Key',
      addApiKey: 'Add API Key',
      updateAccount: 'Update Account',
      selectNewImage: 'Select New Image',
      clear: 'Clear',
      apply: 'Apply',
      map: 'Map',
      satellite: 'Satellite',
    },
    content: {
      needAUserProfile: 'Need a User Profile?',
      createOneHere: 'Create one here',
      alreadyHaveAUserProfile: 'Already have a User Profile?',
      logInHere: 'Log in here',
      rememberYourPassword: 'Remember your password?',
    },
    login: {
      login: 'Login',
      emailOrPhone: 'Email or Phone',
      password: 'Password',
      logIn: 'Log In',
      signIn: 'Sign In',
      register: 'Register',
      recoverPassword: 'Recover password',
      forgotPassword: 'Forgot your password?',
      verificationCode: 'Verification Code',
      sentVerificationCode: "We've Sent a Verification Code",
      receiveVerificationCode: "You will receive a verification code sent to the email address associated with your profile. Enter that code below to continue.",
    },
    resetPassword: {
      resetPassword: 'Reset Password',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      updatePassword: 'Update Password',
      emailOrPhone: 'Email or Phone',
      sendVerificationCode: 'Send Verification Code',
      sendCode: 'Send Code',
      code: 'Code',
      continue: 'Continue',
      sendCodeAgain: 'Send code again',
      or: 'or',
      callMeInstead: 'call me instead',
      callAgain: 'Call again',
      smsTextMeInstead: 'SMS text to me instead',
      verification: 'Verification',
      willSendYouCode:
        "We'll send you a verification code to make sure it's really you",
      enterVerificationCode: 'Enter Verification Code',
      setNewPassword: 'Set New Password',
    },
    search: {
      search: 'Search',
      minLengthError: 'Enter at least {{length}} character to search',
    },
    tooltips: {
      copyToClipboard: 'Copy to Clipboard',
      refreshView: 'Refresh View',
      viewConfiguration: 'View Configuration',
      sharableViewLink: 'Sharable View Link',
      export: 'Export',
      rerunExportJob: 'Re-run Export Job',
      cloneToNewExportJob: 'Clone to New Export Job',
      favoriteThisJob: 'Favorite This Job',
      archiveThisJob: 'Archive This Job',
    },
    userProfile: {
      email: 'Email',
      phone: 'Phone',
      name: 'Name',
      displayName: 'Display Name',
      displayNameHint: 'How others will see you in Slate Pages',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      newPassword: 'New Password',
      currentPassword: 'Current Password',
      confirmNewPassword: 'Confirm New Password',
      createProfile: 'Create Profile',
      profileSettings: 'Profile Settings',
      updateProfile: 'Update Profile',
      profileDetails: 'Profile Details',
      updatePassword: 'Update Password',
      createProfileDisclaimer:
        'By tapping Create Profile you agree to our Privacy Policy and Terms of Use agreements',
    },
    accountSettings: {
      accountSettings: 'Account Settings',
      basicInformation: 'Basic Information',
      accountName: 'Account Name',
      accountLogo: 'Account Logo',
      addAccount: 'Add Account',
      enterAnAccountAuthCode:
        'Enter an account auth code to add it to your profile',
      apiKeys: 'API Keys',
      apiKey: 'API Key',
      apiKeyName: 'API Key Name',
      apiKeyConfirm: 'API Key Confirmation',
      deleteApiKeyConfirm: 'Delete API Key Confirmation',
      selectTheServices:
        'Select the services this API key will be using. To minimize surface attack risk, only choose the services necessary.',
      importSlates: 'Import Slates',
      endpointsForGettingInfo:
        'Endpoints for getting template information and importing slates',
      updateSlates: 'Update Slates',
      endpointForGettingDetails:
        "Endpoint for getting a slate's template details and updating slates.",
      storeKey:
        'Your key is like a password, so we will not show it to you again. Store it somewhere safe.',
      authCode: 'Auth Code',
      searchMyAccounts: 'Search my accounts',
      enterTheNameOfApiKey: 'Enter the name of the API key',
      confirmToDeleteIt: 'to confirm and delete it',
      thisInformationIsLinked:
        'This information is linked from your profile. To change them, go to your profile settings',
      here: 'here',
    },
    errorDialog: {
      somethingWentWrong: 'Something went wrong...',
      unexpectedProblem:
        'There was unexpected problem with the last action performed.',
      giveAnotherTryIssueId:
        'Feel free to give it another try. If you still have trouble, contact support with this Issue ID below. It will give us all the information we need to get you all fixed up!',
      giveAnotherTry:
        "Feel free to give it another try. If you still have trouble, contact support we'll do our best to get you all fixed up!",
      issueID: 'Issue ID',
      issueIdToClipboard: 'Click here to copy the Issue ID to the clipboard',
    },
    fileUploader: {
      clickToUpload: 'Drop image or click here to upload your image',
      avatarRequirements: '(JPEG or PNG, minimal size 200x200px)',
      clearPhoto: 'Clear Photo',
      removePhoto: 'Remove Photo',
      setProfilePhoto: 'Set Profile Photo',
      fileTypeNotSupported: 'This file type is not supported',
      imageSizeError: 'Image size should be at least 200x200px',
      imageTypeError: 'Only JPEG and PNG images are allowed',
    },
    verification: {
      alreadyHaveCode: 'Already have a verification code?',
      enterCodeHere: 'Enter code here',
      needVerificationCode: 'Need a verification code?',
      needVerificationEmail: 'Need a verification email?',
      getOneHere: 'Get one here',
      sendVerificationCode: 'Send Verification Code',
      sendVerificationEmail: 'Send Verification Email',
      code: 'Code',
      verify: 'Verify',
      verifyAndLogin: 'Verify and Log In',
      sendCode: 'Send Code',
      codeWillSentTo : 'Code will be sent to ',
      verify2fa : 'Enter Verification Code',
      sendAnotherCode : 'Send another code',
      didntReceiveCode : "Didn't receive a code yet?",
      emailTwoFactorAuthentication: '{{enabled}} Two Factor Authentication for Email',
      youWillBeSentCode: 'You will be sent a code. Once you verify that code, 2FA will be {{enabled}}.',
      weSendYouCode:
        "We'll send you a verification code to the following phone number: ",
      weSendYouLink:
        "We'll send you a verification link to the following email: ",
      weSentYouLink:
        'We sent you a verification link to your email. When you receive it, click the link to complete the verification process.',
      sendCodeViaSms: 'Send Code via SMS',
      sendCodeViaCall: 'Send Code via Call',
      enterVerificationCode: 'Enter Verification Code',
      checkYourEmail: 'Check Your Email',
      weSentYouLConfirmationCode2fa: 'Check your email, we sent you a confirmation code. Enter it below to {{enabled}} two factor authentication for email.',
      enterCodeWeSent:
        'Enter the verification code we sent to your phone number',
    },
    notifications: {
      successfullyLoggedIn: 'You have successfully logged in.',
      successfullyLoggedOut: 'You have successfully signed out.',
      successfullyCreateUser: 'You have successfully signed up.',
      successfullyUpdateUser: 'You have successfully update your info.',
      successfullyUpdateAccount: 'You have successfully update your info.',
      successfullyUpdateProfile: 'Profile successfully updated',
      resetPasswordSentSMS:
        'Reset password code was sent in SMS. Please check your phone.',
      resetPasswordSentCall: 'You will receive a call in about 20 seconds.',
      resetPasswordSentEmail:
        'Reset password code was sent to your email. Please check your Inbox.',
      slateNotFound: 'Slate not found.',
      successfullySavedSlateViewSettings:
        'view configuration successfully saved.',

      successfullyAddAccount: 'New account has been added.',
      successfullyRemoveAccount: 'Account removed.',
      successfullyUpdateApiKey: 'Your API key has been updated.',
      successfullyRemoveApiKey: 'Your API key has been deleted.',
      successfullyRemoveSlateView: 'Your slate view has been deleted.',
      noLongerAccess: 'You no longer have access to',
      youHaveBeenSwitched: "You've been switched to",
      fieldCannotBeAdded:
        '{{name}} fields cannot be added as columns to a list view',
      notificationMustBeConfiguredBySlatePages: 'This notification must be configured by Slate Pages. Contact us to make changes.',
      successfullySavedJob: 'job configuration successfully saved.',
      successfullyUpdateExportJob: 'Export job successfully updated.',
      rerunHasBeenStarted: 'rerun has been started',
      successfullyRemoveExportJob: 'export job successfully removed.',
      emptyRecord:
        'There are no entries on this Record field. To add entries, use the Slate Pages app.',
      metricsEditingIsBlocked:
        'Customizing widgets cannot be performed on a mobile browser.',
      slatesFilteringRulesMustBeRemoved: `Slate filtering rule{{moreThenOne}}: {{ruleTitlesList}} must be removed before 
        you can switch to a Slates list view.`,
      allSlatesFilteringRulesMustBeRemoved: `All slate filtering rules must be removed before 
        you can switch to a Record Entries list view.`,
      linkIsNotCorrect: `Link is not correct.`,
      allListColumnsMustBeRemoved: `All list columns must be removed before you can switch 
        to a Record Entries list view.`,
      yourRecordEntryColumnsAreStillSelected: `Your record entry columns are still selected. 
        Use the List Columns section to configure columns for your list view based on your slates.`,
      toExportListView: `To export a List View select at least one column to display in your list.`,
      emptyRuleValue: `Make sure all your rules have values before saving.`,
      youChooseNewOptionButTheFollowingFieldsAreNotAvailable: `You choose {{newOption}}, but the following fields are not available in that list. Adjust your configuration below so that these fields are not being used: {{selectedFields}}`,
      toSaveListView: `To save a List View select at least one column to display in your list.`,
      geocoderError: 'Google Geocoder error.',
      geocoderErrorNoResults: 'Google Geocoder error: no results.',
      notificationWasChangedSuccessfully: 'Notification saved successfully.',
      notificationWasCreatedSuccessfully: 'Notification created successfully.',
      notificationWasDeletedSuccessfully: 'The notification was deleted successfully',
      copyToClipboardSuccessfully: 'The link was successfully copied to the clipboard',
      copyTableToClipboardSuccessfully: 'The table was successfully copied to the clipboard.',
      copyDataToClipboardSuccessfully: 'The data was successfully copied to the clipboard.',
      copyDataToClipboardFailed: 'The data was NOT copied to the clipboard.',
      copyTableToClipboardUnsuccessfully: 'Copying of the table is unsuccessful. Please try again.',
    },
    messages: {
      invalidAuthCode: 'Invalid Auth Code',
      userAlreadyExists: 'This user already exists',
      userNotFound: 'User not found.',
      tooMany2faCodeRequests: 'Too many login attempts have been made in too short of a time period. Wait a few moments and try again.',
      invalidCredentials: 'Login credentials were not valid. Please try again.',
      userDoesNotExist: 'User does not exist',
      unableToSendVerificationCode: 'Unable to send verification code',
      userDoesntExistCode: "User doesn't exist",
      tooManyCodesSent: "too many codes sent",
      accountAlreadyExist: 'This account is already available on your profile',
    },
    settings: {
      sourceTitle: 'Show:',
      sourceSubtitle: 'This determines what fields to show in options below',
      lossOfConfiguredOptions: 'Loss of Configured Options',
      popupMessageStart: 'You selected to show',
      popupMessageEnd: 'fields, but some of configured options contain fields not in this list. Below is a summary of those options. If you proceed, these options will be removed from your configuration. Would you like to continue?',
      dialogRecordsListTitle: 'Select a Records Field on your account: ',
      dialogRulesListTitle: 'Rule for: ',
      dialogColumnsListTitle: 'Column:  ',
      basicInformation: 'Basic Information',
      slateViewTitle: 'Slate View Title',
      autoRefreshTable: 'Auto-Refresh',
      autoRefreshListView: 'Auto-Refresh Every 30 seconds',
      slateFilteringRules: 'Slate Filtering Rules',
      defineIncludedSlates: 'Define how slates will be included in this view',
      pinColors: 'Pin Colors',
      definePinColors: 'Define how pins will be colored in this view',
      listColumns: 'List Columns',
      toDisplayInYourList: 'To display in your list',
      match: 'Match',
      matchPins: 'all pins matching',
      ofTheFollowingRules: 'of the following rules',
      selectField: 'Select field',
      selectOperator: 'Select operator',
      selectPattern: 'Select pattern',
      selectDate: 'Select date',
      selectTime: 'Select time',
      selectDateTime: 'Select Date & Time',
      available: 'Available',
      selected: 'Selected',
      availableFieldsSelect: {
        label: 'Show:',
        options: {
          all_fields: 'All fields',
          template_fields: 'Template Fields',
          slate_fields: 'Slate Fields',
        },
      },
      all: 'All',
      any: 'Any',
      additionalOptions: 'Additional Options',
      includeUrl: 'Include URL Column',
      includeUrlDescr:
        'A column containing the URL link of each slate will be included',
      includeUUID: 'Include Identifier Column',
      includeUUIDDescr:
        'A column containing the identifier of each slate will be included',
      includeHeaderRow: 'Include Header Row',
      includeHeaderRowDescr:
        'The first row will contain the names of each column',
      newView: 'New View',
      configuration: 'Configuration',
      newListViewConfiguration: 'New List View Configuration',
      newMapViewConfiguration: 'New Map View Configuration',
      // operators
      equals: 'Equals',
      doesntEqual: "Doesn't Equal",
      contains: 'Contains',
      doesntContain: "Doesn't Contain",
      lessThan: 'Less Than',
      lessThanEqualTo: 'Less Than Equal To',
      greaterThan: 'Greater Than',
      greaterThanEqualTo: 'Greater Than Equal To',

      existsOnSlate: 'Exists On Slate',
      doesntExistOnSlate: "Doesn't Exist On Slate",
      hasValue: 'Has Value',
      doesntHaveValue: "Doesn't Have Value",
      allTheLessThan: "All the less than",
      allTheGreaterThan: "All the greater than",
      configureAnExportJob:
        'Would you like to configure an export job based on this view?',
      export: 'Export',
      confirmToDeleteIt: 'to confirm and delete it',
      enterTheNameOfSlateView: 'Enter the name of the view',
      deleteConfirm: 'Delete Slate View Confirmation',
      slateViewName: 'View name',
      deleteView: 'Delete View',
      fieldAttribute_value: 'Value',
      fieldAttribute_user: 'User',
      fieldAttribute_timestamp: 'Time',
      slateDetails: 'Slate Details',
      fieldsFor: 'Fields for ',
      otherFields: 'Other Fields',
      slateFields: 'Slate Fields',
      whatDataWouldYouLike:
        'What data would you like to see in your list view?',
      slates: 'Slates',
      recordEntries: 'Record Entries',
      selectRecord: 'Select a Records Field on your account',
      thisListViewWillShow:
        'This list view will show entries for this field across all your slates',
      selectRecordField: `Select a records field in the Basic Information section, 
        then you’ll be able to select from a list of fields here`,
    },
    slateView: {
      autoRefresh: 'Auto-Refresh temporarily enabled. You can permanently enable it in the view configuration.',
      list: 'List',
      map: 'Map',
      views: '{{type}} Views',
      publicView: 'Use this link to provide public access to this view. Users will be able to view slates, ' +
        'but they will only have ‘public’ access (based on each Slate’s public permissions).',
    },
    slate: {
      slateIdentityInformation: 'Slate Identity Information',
      slateUrl: 'Slate URL',
      openLink: 'Open Link',
      qrCode: 'QR Code',
      on: 'on',
      coordinates: 'Coordinates',
      address: 'Address',
      latitude: 'Latitude',
      longitude: 'Longitude',
      convertToAddress: 'Convert to Address',
      convertToCoordinates: 'Convert to Coordinates',
      dropImageHere: 'Drop image here or press Select New Image button below',
      edit: 'Edit',
      copy: 'Copy',
      downloadCalendarEvent: 'Download Calendar Event',
      copyAddress: 'Copy Address',
      copyCoordinates: 'Copy Coordinates',
      showInMaps: 'Show In Maps',
      getDirections: 'Get Directions',
      openInBrowser: 'Open In Browser',
      sendEmail: 'Send Email',
      call: 'Call',
      message: 'Message',
      view: 'View',
      download: 'Download',
      viewAllEntries: 'View All Entries',
      viewLastEntry: 'View Last Entry',
      viewHistoryEntries: 'View History',
      addEntry: 'Add Entry',
      noSlateViews: 'There are no slate views.',
      journalEntries: 'Journal Entries',
      recordEntries: 'Record Entries: {{fieldName}}',
      recordEntry: 'Record Entry',
      lastEntry: 'Last Entry',
      enterAnAddress: 'Enter an address and/or coordinates above to begin',
      poweredBy: 'Powered by',
    },
    history: {
      title: 'History: {{fieldName}}',
      empty: 'History is empty',
      columnTimestamp: 'Timestamp',
      columnUser: 'User',
      columnValue: 'Value',
    },
    slateGenerator: {
      warning:
        'Please be aware that your account administrator may be invoiced if total number of Slates on account exceeds purchased quantity.',
      slateGenerator: 'Slate Generator',
      hereIsANewSlate: "Here's your new slate:",
      generateNewSlate: 'Generate New Slate',
      slateUUID: 'Slate UUID',
      url: 'URL',
      qrCode: 'QR Code',
    },
    slateViewSearch: {
      searchResultsHint: 'Search results for ',
      emptyResult: 'No results found for search criteria',
    },
    export: {
      exportJobs: 'Export Jobs',
      createNewExportJob: 'Create New Export Job',
      active: 'Active',
      favorites: 'Favorites',
      archived: 'Archived',
      name: 'Name',
      date: 'Date',
      status: 'Status',
      downloadCSV: 'Download CSV',
      newExportJob: 'New Export Job',
      rerunExportJob: 'Rerun Export Job?',
      rerunTheExportJob: 'Re-run the export job',
      rerunJobNow: 'Rerun Job Now',
      cancel: 'Cancel',
      copyToNewExportJob: 'Copy to New Export Job?',
      copyExportJob: 'Copy export job',
      youWillBeAbleToCustomize:
        "to a new job? You'll be able to customize the settings before running it.",
      copyToNewJob: 'Copy to New Job',
      archiveExportJob: 'Archive Export Job',
      archiveTheExportJob: 'Archive the export job',
      fromRecentAndFavorites:
        'from Recents and Favorites? Note: It will still be available under the Archived tab.',
      deleteExportJob: 'Delete Export Job',
      permanentlyDeleteExportJob: 'Permanently delete export job',
      thisCannotBeUndone: 'This cannot be undone.',
      timeZoneWarning: 'All dates and times exported will be translated into your timezone. We’ve defaulted your timezone here, but you can pick a different one for this export if you like.',
    },
    notificationsPage: {
      title: 'Notifications',
      addNotificationButton: 'Add Notification',
      triggerType: 'Trigger Type',
      slateChanges: 'Slate Changes',
      dateFieldReminder: 'Date Field Reminder',
      deliveryType: 'Delivery Type',
      webhook: 'Webhook',
      email: 'Email',
      newNotification: 'New Notification',
      editNotification: 'Edit {{name}}',
      deleteNotification: 'Delete Notification',
      deleteNotificationPopup: {
        title: 'Delete Notification',
        subtitle: 'Enter the name of the notification ({{name}}) to confirm and delete it:',
        popupInputTitle: 'Name',
      },
      basicInformationCard: {
        title: 'Basic Information',
        inputTitle: 'Name',
        selectorTitle: 'Select Date Field',
      },
      filteringRulesCard: {
        title: 'Filtering rules',

      },
      alertsCard: {
        title: 'Alerts',
        subtitle: 'Configure when alerts should be sent out',
        addRowButtonTitle: 'Add Alert',
        timeNotification: 'Time of day to deliver alerts',
        number: 'Number',
        period: 'Period',
        alertPart1: 'number of',
        alertPart2: 'before date.',
      },
      deliveryAddressesCard: {
        title: 'Delivery Addresses',
        subtitle: 'Specify a list of email addresses this alert will be sent to',
        addNewEmailButtonTitle: 'Add Email',
        newEmail: 'New Email',
        editEmail: 'Edit Email',
        inputTitle: 'Email',
      },
      tableTitles: {
        name: 'Name',
        dateField: 'Date Field',
        configuredAlerts: 'Configured Alerts',
        emailDeliversTo: 'Email Delivers To',
        actions: 'Actions',
        triggerType: 'Trigger Type',
        deliveryType: 'Delivery Type',
      },
      validationErrors: {
        uniqueValues: 'All values must be unique.',
        notEmptyList: 'This list cannot be empty. Please add at least one entry.',
        notEmptyEmailsList: 'At least one email is required',
        notEmptyAlertsList: 'At least one alert is required',
        notEmptyValues: 'Values cannot be empty',
        requiredName: 'Name is required',
        requiredDateField: 'A valid date field from this stack is required',
        requiredAlertTime: 'Time of day your alert will be delivered is required',
        validationsFailed: 'Validations failed',
        namesDontMatch: 'The names don\'t match',
      }
    },
    validation: {
      invalidLatitude: 'Invalid latitude',
      invalidLongitude: 'Invalid longitude',
    },
    fields: {
      text: 'Text',
      boolean: 'Boolean',
      selector: 'Selector',
      number: 'Number',
      location: 'Location',
      email: 'Email',
      url: 'Url',
      phone: 'Phone',
      dateTime: 'Date/Time',
      photo: 'Photo',
      journal: 'Journal',
      records: 'Records',
      image: 'Image',
      group: 'Group',
      description: 'Description',
    },
    widgets: {
      storage: 'Storage',
      total: 'Total',
      perSlate: 'per Slate',
    },
    metrics: {
      title: 'Metrics',
      filters: {
        from: {
          label: 'From',
          hint: 'Date',
        },
        to: {
          label: 'to',
          hint: 'Date',
        },
        filterByAccount: 'Filter by account',
        filterByUser: 'Filter by user',
        use_local_settings_modal: {
          title: 'Do you want to override your metrics settings?',
          text_1:
            'Your device have no required width for using your previous metrics settings.',
          text_2:
            'Do you want to override your settings or use local setup (in that case changes, your changes wouldn\t be saved)?',
          btn_local: 'Use local settings',
          btn_save: 'Override settings',
        },
      },
      gallery: {
        description: {
          title: 'Widget Gallery',
          text: 'Add widgets from here to customize your metrics grid',
        },
        width_selector: {
          label: 'Grid Width:',
        },
      },
      grid: '',
      no_data_widget_text:
        'Widget not applicable based on the filters currently used',
      widget: {
        users: {
          gallery: { title: 'Users', text: 'Number of users (total and new)' },
          grid: {
            title: 'No. of Users',
            chart_label: 'users',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },
        active_slates: {
          gallery: {
            title: 'Active Slates',
            text: 'Slates with activity (edits)',
          },
          grid: { title: 'Active Slates', chart_label: 'active slates' },
        },
        fields: {
          gallery: {
            title: 'Fields',
            text: 'Count of each field type being used',
          },
          grid: { title: 'No. of Fields', chart_label: 'fields' },
        },
        map_views: {
          gallery: { title: 'Map Views', text: 'Number of map views' },
          grid: {
            title: 'No. of Map Views',
            chart_label: 'views',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },
        templates: {
          gallery: {
            title: 'Templates',
            text: 'Number of templates (total and new)',
          },
          grid: {
            title: 'No. of Templates',
            chart_label: 'templates',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },

        active_users: {
          gallery: {
            title: 'Active Users',
            text: 'Users that have been active',
          },
          grid: { title: 'Active Users', chart_label: 'active users' },
        },
        new_slates: {
          gallery: {
            title: 'New Slates',
            text: 'Slates that have been commissioned',
          },
          grid: {
            title: 'No. of New Slates',
            chart_label: 'new slates',
            labels: {
              total: 'Total',
              from_us: 'From us',
              from_dashboard: 'From dashboard',
              us: 'Us',
              dashboard: 'Dashboard',
            },
            splitLabelsWord: 1,
          },
        },
        export_jobs: {
          gallery: { title: 'Export Jobs', text: 'Number of export jobs' },
          grid: {
            title: 'No. of Export Jobs',
            chart_label: 'export jobs',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },
        accounts: {
          gallery: {
            title: 'Accounts',
            text: 'Number of accounts (total and new)',
          },
          grid: {
            title: 'No. of Accounts',
            chart_label: 'accounts',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },
        user_sessions: {
          gallery: {
            title: 'User Sessions',
            text: 'Number of user sessions by client',
          },
          grid: { title: 'User Sessions', chart_label: 'user sessions' },
        },

        slates: {
          gallery: {
            title: 'Slates',
            text: 'Number of slates (total and new)',
          },
          grid: {
            title: 'No. of Slates',
            chart_label: 'slates',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },
        storage: {
          gallery: { title: 'Storage', text: 'Amount of storage used' },
          grid: { title: 'Storage' },
        },
        list_views: {
          gallery: {
            title: 'List Views',
            text: 'Number of list views',
          },
          grid: {
            title: 'No. of List Views',
            chart_label: 'views',
            labels: {
              total: 'Total',
              new: 'New',
              existing: 'Existing',
            },
          },
        },
        active_accounts: {
          gallery: {
            title: 'Active Accounts',
            text: 'Accounts with activity (edits)',
          },
          grid: { title: 'Active Accounts', chart_label: 'active accounts' },
        },
        field_edits: {
          gallery: {
            title: 'Field Edits',
            text: 'Number of edits made to slate fields',
          },
          grid: { title: 'No. of Field Edits', chart_label: 'field edits' },
        },
      },
    },
  },
};
