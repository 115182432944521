import React, { Component } from 'react';
import tryMe from '../../../helpers/tryMe';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';

export default class FormUserProfilePassword extends Component {
  UNSAFE_componentWillMount() {
    this.props.actions.setFormState({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      dataErrors: {},
    });
  }

  showContactInfoError = () =>
    tryMe(this.props.state.dataErrors).contactInfo &&
    !this.props.state.contactInfo.email &&
    !this.props.state.contactInfo.phone;

  render() {
    const { actions, state, t, errorAction } = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        <FormTextField
          name="currentPassword"
          type="password"
          autocomplete="current-password"
          hintText={t('userProfile:currentPassword')}
          floatingLabelText={t('userProfile:currentPassword')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).currentPassword || ''}
        />

        <FormTextField
          name="newPassword"
          type="password"
          autocomplete="new-password"
          hintText={t('userProfile:newPassword')}
          floatingLabelText={t('userProfile:newPassword')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).newPassword || ''}
        />

        <FormTextField
          name="confirmNewPassword"
          type="password"
          autocomplete="new-password"
          hintText={t('userProfile:confirmNewPassword')}
          floatingLabelText={t('userProfile:confirmNewPassword')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).confirmNewPassword || ''}
        />

        {errorAction && (
          <div className="form-error form-error-action">{errorAction}</div>
        )}

        <FormSubmit
          title={t('userProfile:updatePassword')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />
      </form>
    );
  }
}
