import React from 'react';
import { Link } from 'react-router-dom';
import find from 'lodash/find';
import drop from 'lodash/drop';
import map from 'lodash/map';
import BreadcrumbItem from '../../containers/Breadcrumbs/BreadcrumbItem';
import routes from '../../config/routes';
import { route } from '../../config/constants';
import { BreadcrumbChevron } from './BreadcrumbChevron';
import './style.css';

/**
 * It change dynamic parts in path to new values if possible
 */
const handleDynamicPathParts = (path, objectWithDynamicPartsValues) => {
  const parts = path.split('/');
  return map(parts, (part) => {
    if (part.includes(':')) {
      let newPart = part.replace(':', '').replace('?', '');
      return objectWithDynamicPartsValues[newPart] || newPart;
    }
    return part;
  }).join('/');
};

const Breadcrumbs = (props) => {
  let pathname = props.path;
  if (pathname.slice(-1) === '/') {
    pathname = pathname.slice(0, -1);
  }
  let pathways = drop(pathname.split('/'));
  let currentRoute = find(routes, { path: pathname });
  let homeUrl = route.ROOT;
  // let homeUrl = route.SLATE_VIEWS;
  // if (props.params.slateViewUUID) {
  //   homeUrl = `${homeUrl}${props.params.slateViewUUID}`;
  // }

  return (
    <div className="breadcrumbs">
      {pathways[0] !== '' && pathways.length > 0 && (
        <div className="breadcrumbs-item">
          <Link to={homeUrl}>{props.t('routes:home')}</Link>
          <BreadcrumbChevron />
        </div>
      )}

      {pathways.length === 0 && (
        <div className="breadcrumbs-item">
          <div>{props.t('routes:home')}</div>
        </div>
      )}

      {/* Breadcrumbs between last and home, always links */}
      {/* You should add "innerRoutePaths" attribute to routes for this */}
      {currentRoute &&
        currentRoute.innerRoutePaths &&
        map(currentRoute.innerRoutePaths, (innerRoutePath) => {
          let innerRoute = find(routes, { path: innerRoutePath });
          const updatedInnerRoutePath = handleDynamicPathParts(
            innerRoutePath,
            props.params,
          );
          return (
            <BreadcrumbItem
              path={updatedInnerRoutePath}
              title={innerRoute.title}
              isLink={true}
              key={`breadcrumb-key-${updatedInnerRoutePath}`}
            />
          );
        })}

      {/* Last breadcrumb is current page, always isn't link */}
      <BreadcrumbItem
        path={pathname}
        title={currentRoute ? currentRoute.title : ''}
        isLink={false}
        key={pathname}
      />
    </div>
  );
};

export default Breadcrumbs;
