import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from 'react-i18next';
import Card from "../Card";
import FormTextField from "../Forms/FormTextField";
import IntoFooter from "../Footer/IntoFooter";
import RaisedButton from "material-ui/RaisedButton";
import p from "../../theme/palette";
import './style.css';
import IconButton from "material-ui/IconButton";
import TimePicker from "material-ui/TimePicker";
import AlertsList from "./Components/AlertsList";
import {
  changeNotification,
  clearFetchingType,
  createNotification,
  deleteNotificationsFromList,
  getListOfNotifications,
  getNotification,
  setActiveNotifications
} from "../../store/actions/notifications";
import Preloader from "../Preloader/Preloader";
import {entityNames, logicOperatorTypes, notificationTypes, route, ruleParts} from "../../config/constants";
import DateFieldSelector from "./Components/DateFieldSelector";
import {DELIVERY_TYPE, FETCHING_STATUS, FETCHING_TYPE, TRIGGER_TYPE} from "../../store/reducers/notifications";
import {
  cleanSnapshotPreviousVersion,
  confirmFieldsSource,
  fetchSettingsFields,
  fetchSettingsOperators,
  openWarningSettingDialog,
  revertFieldsSource,
  updateFieldsSource
} from "../../store/actions/slateViews";
import SettingsRules from "../SlateViewsSettings/SettingsRules/SettingsRules";
import cloneDeep from "lodash/cloneDeep";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import Select from "../Shared/Select";
import {defaultFieldsSource, getFieldsSourceOptions} from "../FieldSelector/FieldsSourceOptions";
import {showNotification} from "../../store/actions/notification";
import CircularProgress from "material-ui/CircularProgress";
import SlateViewSettingPopup from "../SlateViewsSettings/SlateViewSettingPopup";
import keys from "lodash/keys";
import DeleteNotificationPopup from "./Components/DeleteNotificationPopup";
import {getJsonPatchData} from "../../helpers/patchHelper";

const styles = {
  name: {
    width: 360,
  },
  removeButton: {
    color: '#87302c',
  },
  cancelButtonLabel: {
    color: p.white,
  },
  cancelButton: {
    backgroundColor: p.sidebarLink,
  },
  deleteButton: {
    backgroundColor: p.brandOrange,
  },
  buttonDisable: {
    backgroundColor: p.white,
    color: 'transparent',
  },
  emailsListWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const defaultRuleSet = {
  rules: [],
  andOr: logicOperatorTypes.AND,
};
const defaultErrorsList = {
  name: '',
  recordsFieldUniquenessIdentifier: '',
  alertTimeOfDay: '',
  alertsList: '',
  emailsList: '',
};
const timeZone = 'UTC';
const locales = 'en-US';
const pluralEnding = 's';
const fake_date_number_format = '2014-08-18T';
const fake_date_string_format = 'Tue, 21 Apr 2020';
const GMT = 'GMT';


const NotificationSettings = ({
                                getNotification,
                                createNotification,
                                changeNotification,
                                currentAccount,
                                match,
                                history,
                                activeNotificationUUID,
                                activeNotificationInfo,
                                setActiveNotifications,
                                settingsFields,
                                isFetching,
                                fetchSettingsFields,
                                fetchSettingsOperators,
                                updateFieldsSource,
                                settingsFieldsFilters,
                                clearFetchingType,
                                showNotification,
                                lastStateBeforeUpdateSourceField,
                                openWarningSettingDialog,
                                currentFieldsSource,
                                revertFieldsSource,
                                confirmFieldsSource,
                                cleanSnapshotPreviousVersion,
                                operatorsEntity,
                                notificationsList: notificationsListProps,
                                getListOfNotifications,
                              }) => {
  const {t} = useTranslation();
  const [pageInfoIsLoading, setPageInfoIsLoading] = useState(true);
  // const values
  const {notificationUUID} = match.params;
  const currentNotificationUUID = activeNotificationUUID || notificationUUID;
  const [activeNotificationName, setActiveNotificationName] = useState('');
  const [defaultAlertTime, setDefaultAlertTime] = useState();
  const [availableFields, setAvailableFields] = useState();

  // dynamic values
  const [name, setName] = useState('');
  const [recordsFieldUniquenessIdentifier, setRecordsFieldUniquenessIdentifier] = useState('');
  const [dateFieldSelectorTitle, setDateFieldSelectorTitle] = useState('');
  const [emailsList, setEmailsList] = useState([]);
  const [alertTimeOfDay, setAlertTimeOfDay] = useState('');
  const [alertsList, setAlertsList] = useState([]);
  const [ruleSet, setRuleSet] = useState(defaultRuleSet);
  const [triggerType, setTriggerType] = useState(TRIGGER_TYPE.dateFieldReminder);
  const [deliveryType, setDeliveryType] = useState(DELIVERY_TYPE.email);
  const [openPopup, setOpenPopup] = useState(false);

  // errors
  const [dataErrors, setDataErrors] = useState(defaultErrorsList);
  // const [alertsErrorsIndexesList, setAlertsErrorsIndexesList] = useState([]);

  const closePopup = () => {
    setActiveNotifications('');
    setOpenPopup(false);
  }

  const getAvailableFieldsArray = (fields, parent, result = []) => {
    if (!fields) return [];
    fields.forEach((field) => {
      field.isShown = true; // for search

      if (parent) {
        field.parent = parent;
        field.parents = parent.parents.concat([parent]);
      } else {
        field.parents = [];
      }
      result.push(field);

      if (field.children) {
        field.isShowChildren = true; // for collapse
        getAvailableFieldsArray(field.children, field, result);
      }
    });
    return result;
  };

  const getAvailableFieldsList = () => {
    const fields = cloneDeep(settingsFields);
    return getAvailableFieldsArray(fields);
  }

  useEffect(() => {
    if (currentAccount && !settingsFields) fetchSettingsFields();

    if (settingsFields) {
      const newAvailableFields = getAvailableFieldsList();
      setAvailableFields(newAvailableFields);
    }

    if (!currentNotificationUUID) {
      pageInfoIsLoading && setPageInfoIsLoading(false);
      return;
    }

    if (currentAccount && currentNotificationUUID) getNotification(currentNotificationUUID);

    if(!notificationsListProps && currentAccount) getListOfNotifications();
    return () => {
      setActiveNotifications('');
    }
  }, []);

  useEffect(() => {
    if (lastStateBeforeUpdateSourceField.openPopup) return;
    const newAvailableFields = getAvailableFieldsList();

    // check difference rules
    const diffRules = [];
    let diffRecords = [];
    if (ruleSet.rules.length && !lastStateBeforeUpdateSourceField.openPopup) {
      ruleSet.rules.forEach(item => {
        if (
          item.fieldSUUID &&
          !newAvailableFields.some(i => {
            return i.suuid === item.fieldSUUID
          })
        ) {
          diffRules.push({title: item.title, operation: item.operation, value: item.value});
        }
      });
    }
    if (recordsFieldUniquenessIdentifier) {
      if (!newAvailableFields.some(i => {
        return i.suuid === recordsFieldUniquenessIdentifier
      })) {
        diffRecords.push(dateFieldSelectorTitle);
      }
    }

    if ((diffRecords.length || diffRules.length) && !lastStateBeforeUpdateSourceField.openPopup) {
      if (lastStateBeforeUpdateSourceField.lastData.sourceField ===
        currentFieldsSource) return;
      openWarningSettingDialog(
        {
          newOption: currentFieldsSource,
          selectedFields: {
            diffPinColors: [],
            diffColumns: [],
            diffRecords,
            diffRules,
          }
        }
      );
      return
    }

    setAvailableFields(newAvailableFields);
  }, [settingsFields]);

  useEffect(() => {
    if (currentAccount && !settingsFields) fetchSettingsFields();
    if (currentAccount && currentNotificationUUID) getNotification(currentNotificationUUID);
    return setActiveNotifications('');
  }, [currentAccount]);

  useEffect(() => {
    if (emailsList.length) {
      if (dataErrors.emailsList) {
        setDataErrors({
          ...dataErrors,
          emailsList: '',
        })
      }
    }
  }, [emailsList.length]);

  useEffect(() => {
    if(currentAccount) getListOfNotifications();
  }, [currentAccount && currentAccount.uuid]);

  useEffect(() => {
    if (operatorsEntity && operatorsEntity.isFetching) {
      pageInfoIsLoading && setPageInfoIsLoading(false);
    }
  }, [operatorsEntity]);

  useEffect(() => {
    if (
      (isFetching.status === FETCHING_STATUS.success) &&
      (isFetching.type === FETCHING_TYPE.gettingNotification)
    ) {

      const {name, dateFieldReminderTrigger, ruleSet, emailDelivery} = activeNotificationInfo;
      const {emails} = emailDelivery;
      const {alertTimeOfDay, alertOffsets, dateFieldPathHash} = dateFieldReminderTrigger;
      ruleSet.rules.length && ruleSet.rules.forEach((rule) =>
        fetchSettingsOperators(rule.fieldSUUID, true),
      );
      setActiveNotificationName(name);
      setName(name);

      setEmailsList(emails || []);
      setRecordsFieldUniquenessIdentifier(dateFieldPathHash);
      setAlertsList(alertOffsets);
      setAlertTimeOfDay(alertTimeOfDay);
      setRuleSet(!!ruleSet.rules.length ? ruleSet : defaultRuleSet);
      const date = new Date(fake_date_number_format + alertTimeOfDay);

      setDefaultAlertTime(alertTimeOfDay ? new Date(`${fake_date_string_format} ${date.toLocaleTimeString()} ${GMT}`) : alertTimeOfDay);

      !ruleSet.rules.length && pageInfoIsLoading && setPageInfoIsLoading(false);
      clearFetchingType();
    } else if (
      (isFetching.status === FETCHING_STATUS.success) &&
      (isFetching.type === FETCHING_TYPE.settingNotification)
    ) {
      showNotification(
        notificationTypes.SUCCESS,
        currentNotificationUUID ?
          t('notifications:notificationWasChangedSuccessfully') :
          t('notifications:notificationWasCreatedSuccessfully'),
      );
      clearFetchingType();
      setActiveNotifications('');
      history.push({
        pathname: route.NOTIFICATIONS,
      });
    } else if (
      (isFetching.status === FETCHING_STATUS.failed) &&
      (isFetching.type === FETCHING_TYPE.settingNotification) &&
      isFetching.errors
    ) {
      const errorsList = isFetching.errors && isFetching.errors.validationErrors && isFetching.errors.validationErrors.dataErrors || {};

      const errorsForItemsFromLists = {};
      keys(errorsList).forEach(key => {
        if (key.includes('[')) {
          errorsForItemsFromLists[key] = errorsList[key];
        }
      });

      let newErrorList = {
        ...errorsForItemsFromLists,
        name: errorsList.name || '',
        recordsFieldUniquenessIdentifier: errorsList['dateFieldReminderTrigger.dateFieldPathHash'] || '',
        alertTimeOfDay: errorsList['dateFieldReminderTrigger.alertTimeOfDay'] || '',
        alertsList: errorsList['dateFieldReminderTrigger.alertOffsets'] || '',
        emailsList: errorsList['emailDelivery.emails'] || '',
        triggerType: errorsList.triggerType || '',
        deliveryType: errorsList.deliveryType || '',
      };

      setDataErrors(newErrorList);
    }

    if (
      (isFetching.status === FETCHING_STATUS.success) &&
      (isFetching.type === FETCHING_TYPE.deletingNotification)
    ) {
      history.push({pathname: route.NOTIFICATIONS});
    }
  }, [isFetching]);

  const handleChange = (event) => {
    if (dataErrors.name) {
      setDataErrors({
        ...dataErrors,
        name: '',
      })
    }
    setName(event.currentTarget.value)
  }

  const deleteEmail = (event, index) => {
    event.stopPropagation();
    const newEmailsList = emailsList.filter((email, i) => i !== index);
    setEmailsList(newEmailsList);


    // check error list

    // do we have error with current index
    const newDataErrors = {...dataErrors};
    if (dataErrors[`emailDelivery.emails[${index}]`]) {
      delete newDataErrors[`emailDelivery.emails[${index}]`]
    }

    // do we have errors with a large index
    const hasErrors = Object.keys(dataErrors)
      .filter(item => item.includes('emailDelivery.emails['))
      .map(item =>
        item.replace('emailDelivery.emails[', '')
          .replace(']', '')
      ).filter(item => item > index);
    if (hasErrors.length) {
      hasErrors.forEach(item => {
        newDataErrors[`emailDelivery.emails[${item - 1}]`] = newDataErrors[`emailDelivery.emails[${item}]`];
        delete newDataErrors[`emailDelivery.emails[${item}]`];
      })
    }
    //set new errorslist
    setDataErrors(newDataErrors);
  }

  const addAlert = () => {
    if (dataErrors.alertsList) {
      setDataErrors({
        ...dataErrors,
        alertsList: '',
      })
    }
    setAlertsList([...alertsList, {offsetAmount: 1, offsetUnit: ''}]);
  }

  const setOffsetAmount = (val, index) => {
    if (dataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetAmount`]) {
      const newDataErrors = {...dataErrors};
      delete newDataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetAmount`]
      setDataErrors(newDataErrors)
    }

    const newAlertsList = [...alertsList];
    newAlertsList[index].offsetAmount = val;
    setAlertsList(newAlertsList);
  }

  const setOffsetAmountError = (errText, index) => {
    const newDataErrors = {...dataErrors};
    newDataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetAmount`] = errText;
    setDataErrors(newDataErrors)
  }


  const setOffsetUnit = (val, index) => {
    if (dataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetUnit`]) {
      const newDataErrors = {...dataErrors};
      delete newDataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetUnit`]
      setDataErrors(newDataErrors)
    }

    const newAlertsList = [...alertsList];
    newAlertsList[index].offsetUnit = val;
    setAlertsList(newAlertsList);
  }

  const deleteAlert = (event, index) => {
    event.stopPropagation();

    // check error list

    // do we have error with current index
    const newDataErrors = {...dataErrors};
    if (dataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetAmount`]) {
      delete newDataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetAmount`]
    }
    if (dataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetUnit`]) {
      delete newDataErrors[`dateFieldReminderTrigger.alertOffsets[${index}].offsetUnit`]
    }

    // do we have errors with a large index
    const hasErrorsOffsetAmount = Object.keys(dataErrors)
      .filter(item => item.includes('].offsetAmount'))
      .map(item =>
        item.replace('dateFieldReminderTrigger.alertOffsets[', '')
          .replace('].offsetAmount', '')
      ).filter(item => item > index);

    if (hasErrorsOffsetAmount.length) {
      hasErrorsOffsetAmount.forEach(item => {
        newDataErrors[`dateFieldReminderTrigger.alertOffsets[${item - 1}].offsetAmount`] = newDataErrors[`dateFieldReminderTrigger.alertOffsets[${item}].offsetAmount`];
        delete newDataErrors[`dateFieldReminderTrigger.alertOffsets[${item}].offsetAmount`];
      })
    }

    const hasErrorsOffsetUnit = Object.keys(dataErrors)
      .filter(item => item.includes('].offsetUnit'))
      .map(item =>
        item.replace('dateFieldReminderTrigger.alertOffsets[', '')
          .replace('].offsetUnit', '')
      ).filter(item => item > index);

    if (hasErrorsOffsetUnit.length) {
      hasErrorsOffsetUnit.forEach(item => {
        newDataErrors[`dateFieldReminderTrigger.alertOffsets[${item - 1}].offsetUnit`] = newDataErrors[`dateFieldReminderTrigger.alertOffsets[${item}].offsetUnit`];
        delete newDataErrors[`dateFieldReminderTrigger.alertOffsets[${item}].offsetUnit`];
      })
    }

    //set new errorslist
    setDataErrors(newDataErrors);

    const newAlertsList = alertsList.filter((alert, i) => i !== index);
    setAlertsList(newAlertsList);
  }

  const setNewEmailsList = () => {
    setEmailsList([...emailsList, ''])
    setDataErrors({
      ...dataErrors,
      emails: '',
    })
  }

  const handleChangeEmail = (index, e) => {
    let newEmailsList = [...emailsList];
    newEmailsList[index] = e.currentTarget.value;
    setEmailsList(newEmailsList);

    if (dataErrors[`emailDelivery.emails[${index}]`]) {
      const newDataErrors = {...dataErrors};
      delete newDataErrors[`emailDelivery.emails[${index}]`]
      setDataErrors(newDataErrors)
    }
  }

  const removeAllValueInObject = (obj) => {
    let _keys = keys(obj);

    _keys.forEach(key => {
      if (typeof obj[key] !== 'object') {
        obj[key] = undefined;
      } else {
        removeAllValueInObject(obj[key])
      }
    })

    return obj;
  }

  const generatePath = () => {
    const newAlertsList = alertsList.map(({offsetAmount, offsetUnit}) => {
      return {
        offsetAmount,
        offsetUnit: offsetUnit.length ? (offsetUnit.endsWith(pluralEnding)
          ? offsetUnit
          : offsetUnit + pluralEnding) : '',
      }
    })

    const timeZoneOnHours = new Date().getTimezoneOffset() / 60;


    let newState = {
      name,
      ruleSet: {
        rules: ruleSet.rules,
        andOr: ruleSet.andOr,
      },
      triggerType,
      deliveryType,
    }

    if (triggerType === TRIGGER_TYPE.dateFieldReminder) {
      newState = {
        ...newState,
        dateFieldReminderTrigger: {
          dateFieldPathHash: recordsFieldUniquenessIdentifier,
          alertOffsets: newAlertsList,
          alertTimeOfDay: alertTimeOfDay,
          alertTimeZoneOffset: -timeZoneOnHours,
        }
      }
    }

    if (deliveryType === DELIVERY_TYPE.email) {
      newState = {
        ...newState,
        emailDelivery: {emails: emailsList},
      }
    }

    let patches = getJsonPatchData([
      'dateFieldReminderTrigger',
      'deliveryType',
      'emailDelivery',
      'name',
      'ruleSet',
      'triggerType',
    ], newState, activeNotificationInfo);

    return patches;
  }

  const saveChanges = () => {
    const hasNotErrors = !Object.keys(dataErrors).find(key => !!dataErrors[key]);
    hasNotErrors && changeNotification(generatePath(), currentNotificationUUID);
  }

  const saveNewNotifications = () => {
    const hasNotErrors = !Object.keys(dataErrors).find(key => !!dataErrors[key]);
    hasNotErrors && createNotification(generatePath());
  }

  const setAlertTimeOfDayAction = (e, value) => {
    if (dataErrors.alertTimeOfDay) {
      setDataErrors({
        ...dataErrors,
        alertTimeOfDay: '',
      })
    }

    const moonLanding = new Date(value);
    const options = {timeZone, hour12: false};
    setAlertTimeOfDay(moonLanding.toLocaleTimeString(locales, options))
  }

  const agreeAndContinueAction = () => {
    const newAvailableFields = getAvailableFieldsList();
    let _ruleSet = [];
    if (ruleSet.rules.length) {
      _ruleSet = ruleSet.rules.filter(item => {
        if (item[ruleParts.RECORD_ENTRY_ATTRIBUTE]) return true
        return (item.fieldSUUID && newAvailableFields.some(i => {
          return i.suuid === item.fieldSUUID
        }))
      })
    }
    setRuleSet({...ruleSet, rules: _ruleSet});

    if (!newAvailableFields.some(i => {
      return i.suuid === recordsFieldUniquenessIdentifier
    })) {
      setDateFieldSelectorTitle('');
      setRecordsFieldUniquenessIdentifier('');
    }

    cleanSnapshotPreviousVersion();
    setAvailableFields(newAvailableFields);
    confirmFieldsSource();
  }

  return (
    <div className="inner-page slate-view-settings">
      {
        pageInfoIsLoading ?
          <Preloader/>
          :
          <>
            <div className="inner-page-header">
              <SlateViewSettingPopup
                closePopup={revertFieldsSource}
                agreeAndContinueAction={agreeAndContinueAction}
                {...lastStateBeforeUpdateSourceField}
                t={t}
              />
              <h1 className="settings-header">
                {activeNotificationName
                  ? t('notificationsPage:editNotification', {
                    name: activeNotificationName,
                  })
                  : t('notificationsPage:newNotification')}
              </h1>
              <div className="settings-source-selector settings-header">
                <div className="settings-source-title">
                  {t('settings:sourceTitle')}
                  <Select
                    defaultValue={defaultFieldsSource}
                    options={getFieldsSourceOptions(t)}
                    onChange={updateFieldsSource}
                    stylesPresetName="blackBordered"
                    settingsFieldsFilters={settingsFieldsFilters}
                  />
                </div>
                <div className="settings-source-title settings-source-subtitle">
                  {t('settings:sourceSubtitle')}
                </div>
              </div>
            </div>
            <Card
              title={t('notificationsPage:basicInformationCard:title')}
              content={
                <div className="basic-info-container">
                  <FormTextField
                    name="name"
                    value={name}
                    className="settings-name-wrapper"
                    type="text"
                    floatingLabelText={t('notificationsPage:basicInformationCard:inputTitle')}
                    state={{
                      dataErrors: {name: dataErrors.name}
                    }}
                    underlineStyle={{borderWidth: dataErrors.name ? 2 : 1}}
                    actions={{
                      handleChange,
                      handleBlur: (() => {
                      }),
                    }}
                  />
                  {
                    availableFields &&
                    <DateFieldSelector
                      fields={availableFields}
                      currentSUUID={recordsFieldUniquenessIdentifier}
                      onChange={(uuid, title) => {
                        if (dataErrors.recordsFieldUniquenessIdentifier) {
                          setDataErrors({
                            ...dataErrors,
                            recordsFieldUniquenessIdentifier: '',
                          })
                        }
                        setDateFieldSelectorTitle(title);
                        setRecordsFieldUniquenessIdentifier(uuid);
                      }}
                      errorText={dataErrors.recordsFieldUniquenessIdentifier}
                    />
                  }
                </div>
              }
            />
            <Card
              title={t('notificationsPage:filteringRulesCard:title')}
              content={
                <SettingsRules
                  isRecordListView={false}
                  value={ruleSet}
                  onChange={setRuleSet}
                  fields={availableFields}
                  fetchSettingsOperators={fetchSettingsOperators}
                />
              }
            />
            {/*<Card*/}
            {/*  title={t('notificationsPage:triggerType')}*/}
            {/*  content={*/}
            {/*    <>*/}
            {/*      <SelectField*/}
            {/*        floatingLabelText=""*/}
            {/*        value={triggerType}*/}
            {/*        style={{...selectorStyles.selectField.style, width: 230}}*/}
            {/*        labelStyle={selectorStyles.selectField.labelStyle}*/}
            {/*        iconStyle={selectorStyles.selectField.iconStyle}*/}
            {/*        selectedMenuItemStyle={selectorStyles.selectField.selectedMenuItemStyle}*/}
            {/*        listStyle={selectorStyles.selectField.listStyle}*/}
            {/*        underlineStyle={selectorStyles.selectField.underlineStyle}*/}
            {/*        onChange={(event, index, type) => {*/}
            {/*          setTriggerType(type);*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <MenuItem*/}
            {/*          value={TRIGGER_TYPE.dateFieldReminder}*/}
            {/*          primaryText={t(`notificationsPage:${TRIGGER_TYPE.dateFieldReminder}`)}*/}
            {/*          innerDivStyle={selectorStyles.menuItem.innerDivStyle}*/}
            {/*        />*/}
            {/*        <MenuItem*/}
            {/*          value={TRIGGER_TYPE.slateChanges}*/}
            {/*          primaryText={t(`notificationsPage:${TRIGGER_TYPE.slateChanges}`)}*/}
            {/*          innerDivStyle={selectorStyles.menuItem.innerDivStyle}*/}
            {/*        />*/}
            {/*      </SelectField>*/}
            {/*      {*/}
            {/*        dataErrors.triggerType &&*/}
            {/*        <div className="alert-error-title">*/}
            {/*          {dataErrors.triggerType}*/}
            {/*        </div>*/}
            {/*      }*/}
            {/*    </>*/}
            {/*  }*/}
            {/*/>*/}
            {
              triggerType === TRIGGER_TYPE.dateFieldReminder &&
              <Card
                title={t('notificationsPage:alertsCard:title')}
                subtitle={t('notificationsPage:alertsCard:subtitle')}
                content={
                  <div className={styles.emailsListWrapper}>
                    <RaisedButton
                      className="btn settings-action-cancel-btn"
                      buttonStyle={styles.cancelButton}
                      labelStyle={styles.cancelButtonLabel}
                      label={t('notificationsPage:alertsCard:addRowButtonTitle')}
                      disabled={false}
                      onClick={addAlert}
                    />
                    {
                      dataErrors.alertsList &&
                      <div className="alert-error-title">
                        {dataErrors.alertsList}
                      </div>
                    }
                    {alertsList && !!alertsList.length &&
                    <>
                      <AlertsList
                        alertsList={alertsList}
                        deleteAlert={deleteAlert}
                        setOffsetAmount={setOffsetAmount}
                        setOffsetAmountError={setOffsetAmountError}
                        setOffsetUnit={setOffsetUnit}
                        dataErrors={dataErrors}
                      />
                    </>
                    }
                    <div className="alert-time-title">
                      {t('notificationsPage:alertsCard:timeNotification')}
                    </div>
                    <TimePicker
                      errorStyle={{color: p.error}}
                      errorText={dataErrors.alertTimeOfDay}
                      name="timePicker"
                      format="ampm"
                      value={defaultAlertTime}
                      onChange={setAlertTimeOfDayAction}
                      inputStyle={styles.inputStyle}
                    />
                  </div>
                }
              />
            }

            {/*<Card*/}
            {/*  title={t('notificationsPage:deliveryType')}*/}
            {/*  content={*/}
            {/*    <>*/}
            {/*      <SelectField*/}
            {/*        floatingLabelText=""*/}
            {/*        value={deliveryType}*/}
            {/*        style={{...selectorStyles.selectField.style, width: 130}}*/}
            {/*        labelStyle={selectorStyles.selectField.labelStyle}*/}
            {/*        iconStyle={selectorStyles.selectField.iconStyle}*/}
            {/*        selectedMenuItemStyle={selectorStyles.selectField.selectedMenuItemStyle}*/}
            {/*        listStyle={selectorStyles.selectField.listStyle}*/}
            {/*        underlineStyle={selectorStyles.selectField.underlineStyle}*/}
            {/*        onChange={(event, index, type) => {*/}
            {/*          setDeliveryType(type);*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <MenuItem*/}
            {/*          value={DELIVERY_TYPE.email}*/}
            {/*          primaryText={t(`notificationsPage:${DELIVERY_TYPE.email}`)}*/}
            {/*          innerDivStyle={selectorStyles.menuItem.innerDivStyle}*/}
            {/*        />*/}
            {/*        <MenuItem*/}
            {/*          value={DELIVERY_TYPE.webhook}*/}
            {/*          primaryText={t(`notificationsPage:${DELIVERY_TYPE.webhook}`)}*/}
            {/*          innerDivStyle={selectorStyles.menuItem.innerDivStyle}*/}
            {/*        />*/}
            {/*      </SelectField>*/}
            {/*      {*/}
            {/*        dataErrors.deliveryType &&*/}
            {/*        <div className="alert-error-title">*/}
            {/*          {dataErrors.deliveryType}*/}
            {/*        </div>*/}
            {/*      }*/}
            {/*    </>*/}
            {/*  }*/}
            {/*/>*/}

            {
              deliveryType === DELIVERY_TYPE.email &&
              <Card
                title={t('notificationsPage:deliveryAddressesCard:title')}
                subtitle={t('notificationsPage:deliveryAddressesCard:subtitle')}
                content={
                  <div className={styles.emailsListWrapper}>
                    <RaisedButton
                      className="btn settings-action-cancel-btn"
                      buttonStyle={styles.cancelButton}
                      labelStyle={styles.cancelButtonLabel}
                      label={t('notificationsPage:deliveryAddressesCard:addNewEmailButtonTitle')}
                      disabled={false}
                      onClick={setNewEmailsList}
                    />
                    {
                      dataErrors.emailsList &&
                      <div className="alert-error-title">
                        {dataErrors.emailsList}
                      </div>
                    }
                    {!!emailsList.length &&
                    <div className="email-list">
                      {emailsList.map((email, index) => (
                        <div className="email-list-item"
                             key={index}
                             data-index={index}
                        >
                          <div className="popup-email-input-wrapper">
                            <FormTextField
                              name="email"
                              value={email}
                              type="email"
                              className="settings-name-wrapper"
                              hintText={t('notificationsPage:deliveryAddressesCard:inputTitle')}
                              floatingLabelText={t('notificationsPage:deliveryAddressesCard:inputTitle')}
                              state={{
                                dataErrors: {email: dataErrors[`emailDelivery.emails[${index}]`]}
                              }}
                              underlineStyle={{borderWidth: dataErrors[`emailDelivery.emails[${index}]`] ? 2 : 1}}
                              actions={{
                                handleChange: e => handleChangeEmail(index, e),
                                handleBlur: (() => {
                                }),
                              }}
                            />
                          </div>
                          <IconButton
                            iconStyle={styles.removeButton}
                            onClick={(e) => {
                              deleteEmail(e, index)
                            }}
                          >
                            <NavigationClose/>
                          </IconButton>
                        </div>
                      ))}
                    </div>
                    }
                  </div>
                }
              />
            }

            <IntoFooter>
              <div className="settings-actions-wrapper">
                <div className="settings-actions">
                  <RaisedButton
                    className="btn settings-action-cancel-btn"
                    buttonStyle={styles.buttonDisable}
                    label={t('btn:cancel')}
                    disabled={false}
                    onClick={() => history.push({pathname: route.NOTIFICATIONS})}
                  />
                  <RaisedButton
                    icon={(isFetching.status === FETCHING_STATUS.inProgress) &&
                    (isFetching.type === FETCHING_TYPE.settingNotification) ? <CircularProgress size={24}/> : null}
                    disabled={(isFetching.status === FETCHING_STATUS.inProgress) &&
                    (isFetching.type === FETCHING_TYPE.settingNotification)}
                    onClick={currentNotificationUUID ? saveChanges : saveNewNotifications}
                    primary={true}
                    className="btn btn-action"
                    label={t('btn:save')}
                  />
                </div>

                {
                  currentNotificationUUID &&
                  <RaisedButton
                    onClick={() => {
                      setActiveNotifications(notificationUUID);
                      setOpenPopup(true);
                    }}
                    primary={false}
                    className="btn btn-action"
                    label={t('notificationsPage:deleteNotification')}
                    buttonStyle={styles.deleteButton}
                    labelColor={p.white}
                  />
                }
              </div>
            </IntoFooter>
          </>
      }
      {
        activeNotificationUUID &&
        <DeleteNotificationPopup
          open={openPopup}
          closePopup={closePopup}
          currentAccountUUID={currentAccount ? currentAccount.uuid : ''}
        />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeNotificationUUID: state.notifications_page.activeNotificationUUID,
  activeNotificationInfo: state.notifications_page.activeNotificationInfo,
  isFetching: state.notifications_page.isFetching,
  currentAccount: state.accounts.currentAccount,
  settingsFields: state.slateViews.settingsFields,
  settingsFieldsFilters: state.slateViews.settingsFieldsFilters.include,
  lastStateBeforeUpdateSourceField: state.slateViews.snapshotOfTheCurrentStateForBeAbleToRevert,
  currentFieldsSource: state.slateViews.settingsFieldsFilters.include,
  operatorsEntity: state.entities[entityNames.GET_SETTINGS_OPERATORS],
  notificationsList: state.notifications_page.listOfNotifications,
});

const mapDispatchToProps = {
  setActiveNotifications,
  deleteNotificationsFromList,
  clearFetchingType,
  createNotification,
  changeNotification,
  getNotification,
  fetchSettingsFields,
  fetchSettingsOperators,
  updateFieldsSource,
  showNotification,
  openWarningSettingDialog,
  revertFieldsSource,
  confirmFieldsSource,
  cleanSnapshotPreviousVersion,
  getListOfNotifications,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NotificationSettings),
);
