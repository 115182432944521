import React from 'react';
import { infoPanelTypes } from '../../../config/constants';
import { style } from './style';

const InfoPanel = (props) => {
  let panelStyle = {};
  switch (props.type) {
    case infoPanelTypes.WARNING:
      panelStyle = style.warningPanel;
      break;
    default:
      break;
  }

  return <div style={panelStyle}>{props.children}</div>;
};

export default InfoPanel;
