import React from 'react';

import AccountAdd from 'components/Account/AccountAdd';
import AccountList from 'components/Account/AccountList';
import './style.css';
import theme from './theme';

const AccountDialog = ({ onDialogClose, onSwitchAccount }) => (
  <div className="account-dialog">
    <div className="account-dialog-left">
      <AccountAdd
        onSwitchAccount={onSwitchAccount}
        onDialogClose={onDialogClose}
      />
    </div>
    <div className="account-dialog-right" style={theme.accountDialogRight}>
      <AccountList onSwitchAccount={onSwitchAccount} />
    </div>
  </div>
);

export default AccountDialog;
