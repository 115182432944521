import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ImagePreview from '../../components/ImagePreview/ImagePreview';

function mapStateToProps(state) {
  return {
    imagesLinks: state.images.links,
  };
}

export default withTranslation()(connect(mapStateToProps)(ImagePreview));
