import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormWrapper from 'components/Forms/FormWrapper';
import { fetchReset } from 'store/actions/entities';

FormWrapper.propTypes = {
  entityName: PropTypes.string.isRequired,
  entity: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  noValidation: PropTypes.bool,
  errors: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    currentAccount: state.accounts.currentAccount,
    currentApiKey: state.accounts.currentApiKey,
    slateViewSettings: state.slateViews.settings,
    entities: state.entities,
  };
};

export default connect(mapStateToProps, { fetchReset })(
  FormWrapper,
);
