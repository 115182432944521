import p from '../../theme/palette';

const style = {
  previewButton: {
    boxShadow: 'none',
    display: 'block',
    width: 100,
    height: 100,
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  uploadButton: {
    boxShadow: 'none',
    backgroundColor: p.imagePreviewBg,
    width: 100,
    height: 100,
    display: 'block',
    cursor: 'pointer',
    backgroundSize: '100px 100px',
  },
  uploadButtonDisabled: { cursor: 'auto' },
  photoLabel: {
    //---- global font setting ----
    fontWeight: 400,
    fontSize: 16,
    color: p.imagePreviewLabel,
    margin: 0,
    lineHeight: '98px',
    textAlign: 'center',
  },
  circle: {
    borderRadius: '50%',
  },
  roundedCorners: {
    borderRadius: '5px',
  },
  border: {
    border: `2px solid ${p.imagePreviewBorder}`,
  },
};

export default style;
