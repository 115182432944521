import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  setCurrentAccountByUUID,
  updateAccountTimestamp,
} from 'store/actions/account';
import { updateUser } from 'store/actions/user';
import BasicDialog from 'components/Dialogs/BasicDialog';
import AccountDialog from 'components/Account/AccountDialog';
import ImageLoader from '../Shared/ImageLoader/ImageLoader';
import { getImageLink } from 'helpers/imageHelper';
import { route, imageSizes, entityNames } from 'config/constants';
import { history } from 'store/createStore';
import { createReplacePatch } from 'helpers/patchHelper';
import theme from './theme';
import {isLoggedOut} from "../../helpers/accountHelper";

class SidebarAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { location, onMouseLeave } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      if (onMouseLeave) {
        onMouseLeave();
      }
    }
  }

  render() {
    const { currentAccount, imagesLinks, isCollapsed, isHovered, currentUser, accountsList } = this.props;
    const { isDialogOpen } = this.state;
    let link;
    let firstLetter;

    if (currentAccount) {
      link = getImageLink(
        currentAccount.imageUUID,
        imagesLinks,
        imageSizes.ACCOUNT_SIDEBAR,
      );
      firstLetter = currentAccount.name[0].toUpperCase();
    }

    const preloaderDivider = isCollapsed && !isHovered ? 5 : 2;

    const currentAccountUuid = currentAccount && currentAccount.uuid;
    let shouldShowSettingsButton = false;

    accountsList && accountsList.forEach(account => {
      if(currentAccountUuid && (currentAccountUuid === (account && account.uuid))) {
        shouldShowSettingsButton = true;
      }
    })

    return (
      <div className="sidebar-account">
        {currentAccount && (
          <div
            className="sidebar-account-inner"
            onClick={this.handleDialogOpen}
          >
            <div
              className="sidebar-account-image"
              style={link ? {} : theme.sidebarAccountImage}
            >
              {link && (
                <ImageLoader
                  url={link}
                  spinnerWidth={
                    imageSizes.ACCOUNT_SIDEBAR.width / preloaderDivider
                  }
                  isBorder
                />
              )}
              {!link && firstLetter}
            </div>
            <div className="sidebar-account-name">{currentAccount.name}</div>

            { shouldShowSettingsButton && !isLoggedOut(currentUser) &&
              <div
                className="sidebar-account-icon"
                onClick={(e) => this.handleAccountClick(e)}
              >
                <FontAwesome name="gear" style={theme.sidebarAccountCog} />
              </div>
            }

            <BasicDialog
              title="Profile Accounts"
              open={isDialogOpen && !isLoggedOut(currentUser)}
              onClose={this.handleDialogClose}
            >
              <AccountDialog
                onSwitchAccount={this.switchAccount}
                onDialogClose={this.handleDialogClose}
              />
            </BasicDialog>
          </div>
        )}
      </div>
    );
  }

  handleDialogOpen = () => {
    this.props.onClose();
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      isDialogOpen: false,
    });
  };

  handleAccountClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    history.push({
      pathname: route.ACCOUNT,
    });
  };

  switchAccount = (uuid) => {
    if (uuid) {
      const {
        setCurrentAccountByUUID,
        updateUser,
        updateAccountTimestamp,
      } = this.props;
      let data = {};
      data.patches = [createReplacePatch(`/lastUsedAccount`, uuid)];

      setCurrentAccountByUUID(uuid);
      updateUser(data, entityNames.UPDATE_ACCOUNT_TIMESTAMP);
      updateAccountTimestamp(uuid);
      this.handleDialogClose();
      history.push({
        pathname: route.ROOT,
      });
    }
  };
}

const mapStateToProps = (state) => ({
  imagesLinks: state.images.links,
  currentAccount: state.accounts.currentAccount,
  accountsList: state.accounts.accountsList,
  currentUser: state.currentUser,
});

export default connect(mapStateToProps, {
  setCurrentAccountByUUID,
  updateAccountTimestamp,
  updateUser,
})(withRouter(SidebarAccount));
