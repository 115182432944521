import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import stylesPresets from './styles-presets';

export default class Select extends Component {
  state = {
    value: null,
    stylesPreset: {},
  };

  handleChange = (event, index, value) =>
    this.setState({ value }, () => {
      this.props.onChange(value);
    });

  getStylesPreset = (presetName) => {
    return presetName ? stylesPresets[presetName] : {};
  };

  UNSAFE_componentWillMount = () => {
    const { options, defaultValue, stylesPresetName } = this.props;
    const value = defaultValue || options[0].value;
    this.setState({
      value,
      stylesPreset: this.getStylesPreset(stylesPresetName),
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.settingsFieldsFilters !== this.props.settingsFieldsFilters) {
      this.setState({ value: nextProps.settingsFieldsFilters });
    }
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: nextProps.defaultValue });
    }
  };

  render() {
    const { options, floatingLabelText } = this.props;
    const { stylesPreset, value } = this.state;
    return (
      <SelectField
        floatingLabelText={floatingLabelText}
        value={value}
        onChange={this.handleChange}
        {...stylesPreset}
      >
        {options.map((option) => (
          <MenuItem
            value={option.value}
            primaryText={option.text}
            disabled={option.disabled}
            key={`menu-item-key-${option.value}`}
          />
        ))}
      </SelectField>
    );
  }
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.text,
    }),
  ).isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  floatingLabelText: PropTypes.string,
  stylesPresetName: PropTypes.string,
};
