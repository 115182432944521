import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormSlateViewDelete from '../../components/Forms/FormSlateViewSettings/FormSlateViewDelete';

function mapStateToProps(state) {
  return {
    currentSlateView: state.slateViews.currentSlateView,
  };
}
export default withTranslation()(
  connect(mapStateToProps, {})(FormSlateViewDelete),
);
