export const saveInLocalStorage = (userUUID, prop, value) => {
  const localUser = JSON.parse(localStorage.getItem(userUUID)) || {};
  localUser[prop] = value;
  localStorage.setItem(userUUID, JSON.stringify(localUser));
};

export const getFromLocalStorage = (userUUID, prop) => {
  const localUser = JSON.parse(localStorage.getItem(userUUID)) || {};
  return localUser[prop];
};
