import axios from 'axios';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import * as type from './types';
import {api} from 'config/constants';
import {
  cookiesTypes,
  setCookies,
  removeCookies,
  options,
} from 'helpers/cookieHelper';
import {handleVersion} from 'helpers/versionsHelper';

export function setVerificationMethod(verificationMethod) {
  return {
    type: type.SET_VERIFICATION_METHOD,
    verificationMethod: verificationMethod,
  };
}

export function sendEmailVerificationLink(entityName) {
  return (dispatch) => {
    removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);

    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .post(api.SEND_EMAIL_VERIFICATION_LINK, null, options)
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.INCREMENT_WIZARD_STEP,
          steps: 2,
        });

        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  error400: i18next.t('messages:unableToSendVerificationCode'),
                },
              });
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });

            // dispatch({
            //   type: type.ERROR_DIALOG_OPEN,
            //   uuid: err.response.data.correlationUUID || null
            // });
          }
        }
      });
  };
}

export function sendPhoneVerificationCode(smsOrCall, entityName) {
  return (dispatch) => {
    removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);

    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .post(api.SEND_PHONE_VERIFICATION_CODE, {smsOrCall: smsOrCall}, options)
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.INCREMENT_WIZARD_STEP,
          steps: 2,
        });
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  error400: i18next.t('messages:unableToSendVerificationCode'),
                },
              });
              break;

            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });

            // dispatch({
            //   type: type.ERROR_DIALOG_OPEN,
            //   uuid: err.response.data.correlationUUID || null
            // });
          }
        }
      });
  };
}

export function send2faVerificationCode(entityName, isDisable) {
  return (dispatch) => {
    removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .post(isDisable ? api.SEND_DISABLED_2FA_VERIFICATION_CODE : api.SEND_2FA_VERIFICATION_CODE, {}, options)
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.INCREMENT_WIZARD_STEP,
          steps: 2,
        });
        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              switch (err.response.data && err.response.data.errorCode) {
                case 201:
                  dispatch({
                    type: type.FETCH_ERROR,
                    entityName: entityName,
                    hasErrors: err.response.data.errorDebugDescription || i18next.t('messages:userDoesntExistCode'),
                  });
                  return;
                case 203:
                  dispatch({
                    type: type.FETCH_ERROR,
                    entityName: entityName,
                    hasErrors: err.response.data.errorDebugDescription || i18next.t('messages:tooManyCodesSent'),
                  });
                  return;
                default:
                  dispatch({
                    type: type.FETCH_ERROR,
                    entityName: entityName,
                    hasErrors: {
                      error400: i18next.t('messages:unableToSendVerificationCode'),
                    },
                  });
                  return;
              }
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              return;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}


export function verify2faCode(data, entityName, isDisable) {
  return (dispatch) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .post(isDisable ? api.VERIFY_DISABLED_2FA_CODE : api.VERIFY_2FA_CODE, {code: data.code}, options)
      .then((res) => {
        handleVersion(res);
        let user = res.data.data;
        setCookies(cookiesTypes.USER_COOKIE, user);

        dispatch({
          type: type.SET_USER,
          payload: {
            user,
          },
        });

        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });

        dispatch({
          type: type.ENTITY_UPDATED,
          entityName: entityName,
        });
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });

            // dispatch({
            //   type: type.ERROR_DIALOG_OPEN,
            //   uuid: err.response.data.correlationUUID || null
            // });
          }
        }
      });
  };
}


export function verifyPhoneEmailCode(data, entityName) {
  return (dispatch) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });
    let verifyOptions = cloneDeep(options);
    verifyOptions.data = data;

    return axios
      .delete(api.VERIFY_CODE, verifyOptions)
      .then((res) => {
        handleVersion(res);
        let user = res.data.data;
        setCookies(cookiesTypes.USER_COOKIE, user);

        dispatch({
          type: type.SET_USER,
          payload: {
            user,
          },
        });

        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });

        dispatch({
          type: type.ENTITY_UPDATED,
          entityName: entityName,
        });
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });

            // dispatch({
            //   type: type.ERROR_DIALOG_OPEN,
            //   uuid: err.response.data.correlationUUID || null
            // });
          }
        }
      });
  };
}
