import CircularProgress from "material-ui/CircularProgress";
import React from "react";

const VerificationFooter = ({
                              wizardStep,
                              type,
                              t,
                              incrementWizardStep,
                              onResend2faCode,
                              decrementWizardStep,
                              timer,
                            }) => {
  const is2fa = type === '2FA' || type === 'disable2FA';
  return <div className="verification-dialog-footer">
    {type === 'phone' && wizardStep === 1 && (
      <div className="left">
        <p>{t('verification:alreadyHaveCode')}</p>
        <a href="#" onClick={() => incrementWizardStep(2)}>
          {t('verification:enterCodeHere')}
        </a>
      </div>
    )}
    {wizardStep === 2 && (
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
      }} className="left">
        <p>
          {
            type === 'email'
              ? t('verification:needVerificationEmail')
              : is2fa
              ? t('verification:didntReceiveCode')
              : t('verification:needVerificationCode')
          }
        </p>
        <div style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          height: 24,
        }}>
          {
            timer ?
              <div style={{
                color: 'gray',
                cursor: 'initial',
                textDecoration: 'none',
              }}>
                {
                  is2fa
                    ? t('verification:sendAnotherCode')
                    : t('verification:getOneHere')
                }
              </div>
              : <a href="#" onClick={() => {
                if (is2fa) {
                  onResend2faCode();
                } else {
                  decrementWizardStep(2)
                }
              }}>
                {
                  is2fa
                    ? t('verification:sendAnotherCode')
                    : t('verification:getOneHere')
                }
              </a>
          }
          {
            timer ? <div className="verification-code-timer" >
              <CircularProgress
                size={20}
                value={Math.round(100 * timer / 30)}
                mode={'determinate'}
              />
            </div> : null
          }
        </div>
      </div>
    )}
  </div>
}

export default VerificationFooter;
