import p from '../../theme/palette';
import palette from "../../theme/palette";

const styles = {
  multiField: {
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: 4,
    padding: '0 15px 0px 15px',
    margin: '10px -15px',
    position: 'relative',
    transition: 'all .2s',
  },
  multiFieldError: {
    borderColor: p.error,
    transition: 'all .2s',
  },
  multiFieldErrorText: {
    color: p.error,
    fontSize: 11,
    position: 'absolute',
    bottom: -20,
    left: 15,
  },
  formError: {
    borderLeft: '2px solid',
    borderColor: p.error,
    color: p.error,
    padding: '8px 12px',
    background: p.errorBg,
  },
  formFieldHint: {
    fontSize: 10,
    position: 'relative',
    top: -3,
    color: p.fieldHint,
  },
  accountLogoTitle: {
    color: p.grey300,
    margin: '15px 0 5px 0',
  },
  fieldMaxWidth: {
    maxWidth: 350,
  },
  apikeyCheckbox: {
    fontSize: 18,
  },
  apikeyOptions: {
    marginBottom: 10,
    marginLeft: 40,
  },
  bottomButton: {
    marginLeft: 10,
  },
  bottomButtonRight: {
    right: 0,
    position: 'absolute',
  },
  actions: {
    position: 'absolute',
    right: 30,
  },
  dialogButton: {
    position: 'absolute',
    right: 120,
    bottom: 8,
  },
  dialogButtonInline: {
    backgroundColor: p.brandOrange,
    textTransform: 'uppercase',
  },
  iconInInput: {
    position: 'absolute',
    top: '-3px',
    left: '-1px',
    fontSize: '19px',
  },
  searchHintStyle: {
    width: 226,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  warmingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    marginLeft: 24,
  },
  warmingRowContainer: {
    display: 'flex',
    width: 'calc(100% - 243px)',
    marginLeft: 24,
    justifyContent: 'flex-end',
    height: 34,
  },
  warmingTitle: {
    color: palette.dialogErrorHeaderBg,
    fontSize: 10,
    opacity: '.7',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 500,
    minWidth: 300,
    marginRight: 24,
  },
  cancelButton: {
    backgroundColor: p.sidebarLink,
  },
};

export default styles;
