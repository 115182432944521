import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {login, loginWith2faCode} from 'store/actions/user';
import {Login} from 'components/Login';

function mapStateToProps(state) {
  return {
    isOpenLogin2faDialog: state.currentUser.login2faDialogData && state.currentUser.login2faDialogData.isOpen,
  };
}

export default withTranslation()(connect(
  mapStateToProps, {
    login,
    loginWith2faCode,
  }
)(Login));
