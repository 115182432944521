const coreStyles = {
  listStyle: { marginTop: '20px' },
  fullWidth: true,
  underlineStyle: {
    border: 'none',
  },
  menuStyle: {
    border: '1px solid rgb(204, 204, 204)',
    height: '30px',
    fontSize: '15px',
    lineHeight: '35px',
    paddingLeft: '10px',
  },
  style: {
    height: '30px',
    fontSize: '15px',
    lineHeight: '35px',
    width: '170px',
  },
  hintStyle: { height: '30px', fontSize: '15px', lineHeight: '35px' },
  labelStyle: {
    height: '30px',
    fontSize: '15px',
    lineHeight: '35px',
    paddingRight: '35px',
  },
  iconStyle: {
    height: '30px',
    fontSize: '15px',
    lineHeight: '35px',
    paddingTop: '2px',
    paddingRight: '5px',
  },
};

export default {
  smallList: {
    ...coreStyles,
    listStyle: { width: '50px' },
    menuStyle: {
      ...coreStyles.menuStyle,
      width: '64px',
      // overflowX: 'hidden'
    },
    labelStyle: {
      ...coreStyles.labelStyle,
      color: 'white',
    },
    style: {
      ...coreStyles.labelStyle,
      width: '60px',
      marginLeft: '10px',
    },
  },
  blackBordered: {
    ...coreStyles,
    listStyle: {
      listStyle: { width: '170px' },
      // ---- global font setting ----
      // maxWidth: '168px',
      // overflowX: 'hidden'

    },
  },
  wideBlackBordered: {
    ...coreStyles,
    menuStyle: {
      ...coreStyles.menuStyle,
      width: 250,
    },
    style: {
      ...coreStyles,
      width: 250,
    },
  }
};
