const DASHBOARD_MIN_VERSION_HEADER = 'dashboard-min-ver';

let currentVersion = null;
let isNewVersionExist = false;

// This section needed for fast testing
window.addEventListener('reduce-dashboard-min-version', () => {
  if (currentVersion) {
    currentVersion--;
    console.log('Dashboard version was reduced. New version: ', currentVersion);
  }
});

export const handleVersion = (res) => {
  const newVersion = res.headers[DASHBOARD_MIN_VERSION_HEADER];
  if (currentVersion === null) {
    currentVersion = newVersion;
  } else if (newVersion > currentVersion) {
    isNewVersionExist = true;
  }
};

export const isVersionObsolete = () => {
  return isNewVersionExist;
};

export const checkVersion = () => {
  if (isVersionObsolete()) {
    this.updatePage();
  }
};

export const updatePage = () => {
  console.log('Clearing cache and hard reloading...');
  if (window.caches) {
    // Service worker cache should be cleared with window.caches.delete()
    window.caches.keys().then((names) => {
      names.forEach((name) => window.caches.delete(name));
    });
  }
  localStorage.clear();
  window.location.reload(true);
};

