import React from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbChevron } from './BreadcrumbChevron';
import { routeTitles } from '../../config/constants';
import palette from '../../theme/palette';

export const BreadcrumbItem = ({
  t,
  path,
  title,
  isLink,
  currentSlateView,
  newSlateViewType,
}) => {
  let name = t(`routes:${title}`);

  // render current slate view name
  if (
    title === routeTitles.SLATE_VIEWS &&
    currentSlateView &&
    !newSlateViewType
  ) {
    name = currentSlateView.name;
  }

  // render new slate view creating type
  if (title === routeTitles.SLATE_VIEW_NEW && newSlateViewType) {
    name = `${t('routes:new')} ${t(`routes:${newSlateViewType}`)} ${t(
      'routes:view',
    )}`;
  }

  // TODO handle properly
  if (path.includes('slateViewUUID')) {
    if (currentSlateView) {
      path = path.replace('slateViewUUID', currentSlateView.uuid);
    } else {
      path = path.replace('slateViewUUID', '');
    }
  }

  if (isLink) {
    return (
      <div className="breadcrumbs-item">
        <Link to={path}>{name}</Link>
        {<BreadcrumbChevron />}
      </div>
    );
  } else {
    return (
      <div
        className="breadcrumbs-item"
        style={{ color: palette.breadcrumbLastItem }}
      >
        {name}
      </div>
    );
  }
};
