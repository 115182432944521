import React, {Component} from 'react';
import Notifications from 'react-notification-system-redux';
import classnames from 'classnames';
import find from 'lodash/find';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {updateUser, getUser, handleLoginDialogClose} from 'store/actions/user';
import RenderRoutes from 'components/Routes/RenderRoutes';
import Sidebar from 'components/Sidebar/Sidebar';
import Topbar from 'containers/Topbar';
import ErrorDialog from 'containers/Dialogs/ErrorDialog';
import {LeftPane} from './SignLayout/LeftPane';
import {Footer, FooterProvider} from './Footer/Footer';
import {createReplacePatch} from 'helpers/patchHelper';
import {cookiesTypes, getCookies} from 'helpers/cookieHelper';
import {isLoggedOut} from 'helpers/accountHelper';
import tryMe from 'helpers/tryMe';
import {layouts, entityNames} from 'config/constants';
import routes from 'config/routes';
import palette from 'theme/palette';
import {fetchTimeZones} from "../store/actions/account";
import LoginModal from "./SlateViews/LoginModal";

const notificationStyle = {
  Containers: {
    DefaultStyle: {
      width: 380,
    },
  },
  NotificationItem: {
    DefaultStyle: {
      color: palette.white,
      border: 0,
      borderRadius: 4,
      padding: '10px 40px 10px 60px',
      lineHeight: '1.3em',
      boxShadow: 'rgba(65, 64, 66,.3) 0px 2px 4px',
    },
    success: {
      backgroundColor: palette.notificationSuccessBg,
    },
    warning: {
      backgroundColor: palette.notificationWarningBg,
    },
    info: {
      backgroundColor: palette.notificationInfoBg,
    },
    error: {
      backgroundColor: palette.notificationErrorBg,
    },
  },
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: {
        sidebarMobile: false,
      },
      isCollapsed: null,
      isHovered: false,
      isContentFixed: false,
      footerRef: null,
    };
  }

  UNSAFE_componentWillMount() {
    if (getCookies(cookiesTypes.USER_COOKIE)) {
      this.props.getUser();
    }
    this.props.fetchTimeZones();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {currentUser} = nextProps;

    if (currentUser && currentUser.etagChanged) {
      this.props.getUser();
    }

    if (!isLoggedOut(currentUser)) {
      if (currentUser.dashboardSettings) {
        let collapsed =
          currentUser.dashboardSettings.navigationPaneCollapsed;
        this.setState({isCollapsed: collapsed});
      }
    }

    // check app path change
    const oldPath = this.props.app.path;
    const newPath = nextProps.app.path;

    if (oldPath !== newPath) {
      const newRoute = find(routes, (item) => item.path === newPath);

      this.setState({
        isHovered: false,
        isContentFixed: newRoute.isContentFixed,
        noPaddingContainer: newRoute.noPaddingContainer,
        noScrollContainer: newRoute.noScrollContainer,
      });
    }
  }

  render() {
    const {app, notifications, currentAccount, accountsList, currentUser, handleLoginDialogClose} = this.props;
    const {notFoundError} = tryMe(this.props.location.state);
    const {footerRef, noPaddingContainer, noScrollContainer} = this.state;

    return (
      <div
        // tabIndex={0} // ToDo find out why it is necessary
        className={classnames(`app`, {
          'sidebar-collapsed': this.state.isCollapsed,
          'sidebar-opened': this.state.isOpen.sidebarMobile,
          'layout-404': notFoundError,
          [`layout-${app.layout}`]: !notFoundError,
        })}
      >
        {this.renderEnvTopbar()}

        <LoginModal
          open={currentUser.isOpenLoginDialog}
          onClose={handleLoginDialogClose}
        />

        {app.layout === layouts.DASHBOARD && !notFoundError && (
          <Sidebar
            isCollapsed={this.state.isCollapsed}
            isHovered={this.state.isHovered}
            onClose={this.closeSidebarMobile}
            onToggle={this.toggleSidebarDesktop}
            onMouseEnter={
              this.state.isCollapsed ? this.handleMouseEnter : undefined
            }
            onMouseLeave={
              this.state.isCollapsed ? this.handleMouseLeave : undefined
            }
          />
        )}
        <section className="views">
          <div className="view" onClick={this.handleMouseLeave}>
            {this.state.isOpen.sidebarMobile && (
              <div
                className="sidebar-overlay"
                onClick={this.closeSidebarMobile}
              />
            )}
            <div className="pages" id="pages">
              <div className="page" id="page">
                <FooterProvider value={{ ref: footerRef }}>
                  <div
                    className={classnames('container', {
                      'no-padding-container': noPaddingContainer,
                      'no-scroll-container': noScrollContainer,
                      'with-empty-footer':
                        !footerRef || footerRef.childNodes.length < 1,
                    })}
                  >
                    <Notifications
                      notifications={notifications}
                      style={notificationStyle}
                    />

                    <ErrorDialog />
                    {app.layout === layouts.SIGN && !notFoundError && (
                      <LeftPane />
                    )}
                    <RenderRoutes isContentFixed={this.state.isContentFixed} />
                    <Footer
                      onSetRef={(footerRef) => this.setState({ footerRef })}
                      onChildrenChange={() => this.forceUpdate()}
                    />
                  </div>
                </FooterProvider>
              </div>
            </div>
            {app.layout === layouts.DASHBOARD && !notFoundError && (
              <Topbar
                handleSidebarToggle={this.toggleSidebarMobile}
                isOpenSidebarMobile={this.state.isOpen.sidebarMobile}
                accountsList={accountsList}
                currentAccountUuid={currentAccount && currentAccount.uuid}
              />
            )}
          </div>
        </section>
      </div>
    );
  }

  toggleSidebarDesktop = () => {
    let collapse = !this.state.isCollapsed;
    let { isHovered } = this.state;

    if (collapse) {
      isHovered = false;
    }

    this.setState({ isCollapsed: collapse, isHovered });

    let data = {};
    data.patches = [
      createReplacePatch(
        `/dashboardSettings/navigationPaneCollapsed`,
        collapse,
      ),
    ];

    this.props.updateUser(data, entityNames.DASHBOARD_SETTINGS);
  };

  toggleSidebarMobile = () => {
    this.setState({
      isOpen: { sidebarMobile: !this.state.isOpen.sidebarMobile },
    });
  };

  closeSidebarMobile = () => {
    this.setState({ isOpen: { sidebarMobile: false } });
  };

  handleMouseEnter = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  renderEnvTopbar = () => {
    switch (process.env.REACT_APP_SL8_ENV) {
      case 'development': {
        return (
          <div
            className="app-env-topbar"
            style={{ backgroundColor: palette.brandBlue }}
          />
        );
      }
      case 'beta': {
        return (
          <div
            className="app-env-topbar"
            style={{ backgroundColor: palette.brandOrange }}
          />
        );
      }
      default:
        return null;
    }
  };
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  notifications: state.notifications,
  app: state.app,
  accountsList: state.accounts.accountsList,
  currentAccount: state.accounts.currentAccount,
});

export default withRouter(
  connect(mapStateToProps, {
    updateUser,
    getUser,
    fetchTimeZones,
    handleLoginDialogClose,
  })(App),
);
