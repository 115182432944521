import palette from '../../../theme/palette';

const theme = {
  dialogErrorHeader: {
    backgroundColor: palette.dialogErrorHeaderBg,
    color: palette.white,
  },
  dialogErrorBody: {
    color: palette.defaultText,
  },
  dialogErrorIssue: {
    backgroundColor: palette.brandOrange,
    color: palette.white,
  },
  dialogErrorIssueTitle: {
    color: palette.brandOrange,
    textAlign: 'center',
    fontSize: 18,
  },
  dialogErrorIssueHint: {
    color: palette.brandOrange,
  },
};

export default theme;
