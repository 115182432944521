const reA = /[^a-zA-Z$-/:-?{-~!"^_`[\]]/g;
const reN = /[^0-9]/g;

export const HandleObjectSort = {
  alphaNumericTitle(a, b) {
    let aA = a.title.replace(reA, '');
    let bA = b.title.replace(reA, '');
    if (aA === bA) {
      let aN = parseInt(a.title.replace(reN, ''), 10);
      let bN = parseInt(b.title.replace(reN, ''), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  },
};
