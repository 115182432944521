import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import Search from '../../Shared/Search/Search';
import { searchAvailableFields } from 'helpers/searchHelper';
import '../style.css';
import CollapsibleField from "../../SlateViewsSettings/CollapsibleField/CollapsibleField";


const DateFieldSelectorOption = ({ fields, onFieldClick }) => {

  const [availableLocalList, setAvailableLocalList] = useState(fields);

  useEffect(()=>{
    setAvailableLocalList(fields.map(item => ({...item, isShown: true})))
  }, [fields])


  const handleSearch = (text = '') => {
    const available = cloneDeep(fields);
    text = text.trim().toLowerCase();

    if (text.length) {
      searchAvailableFields(available, text);
    }

    setAvailableLocalList(available.map(item => (item)))
  };

  return (
    <div>
      <Search onSearch={handleSearch} autoFocus />
      <div className="available-rule-fields">
        {availableLocalList.map((field) =>
          field.parent ? null : (
            <CollapsibleField
              field={field}
              key={field.suuid || field.recordEntryAttribute}
              onFieldClick={onFieldClick}
            />
          ),
        )}
      </div>
    </div>
  );
};

DateFieldSelectorOption.propTypes = {
  fields: PropTypes.array.isRequired,
  onFieldClick: PropTypes.func.isRequired,
};

export default DateFieldSelectorOption;
