import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Verification from 'components/Verification/Verification';
import {
  incrementWizardStep,
  decrementWizardStep,
  resetWizardStep,
} from 'store/actions/wizard';
import {
  setVerificationMethod,
  sendEmailVerificationLink,
  sendPhoneVerificationCode,
  send2faVerificationCode,
  verifyPhoneEmailCode,
  verify2faCode,
} from 'store/actions/verification';
import {fetchReset} from "../../store/actions/entities";

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    app: state.app,
  };
}

export default withTranslation()(
  connect(mapStateToProps, {
    sendEmailVerificationLink,
    sendPhoneVerificationCode,
    send2faVerificationCode,
    incrementWizardStep,
    decrementWizardStep,
    resetWizardStep,
    verifyPhoneEmailCode,
    verify2faCode,
    fetchReset,
    setVerificationMethod,
  })(Verification),
);
