import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import FontAwesome from 'react-fontawesome';
import palette from '../../theme/palette';

const theme = {
  color: palette.brandOrange,
  border: '2px solid',
  borderColor: palette.brandOrange,
  height: 42,
  lineHeight: '38px',
};

const BtnContactUs = (props) => (
  <a href="mailto:support@slatepages.com">
    <RaisedButton
      label={props.t('btn:contactUs')}
      className="btn"
      labelColor={palette.brandOrange}
      labelStyle={{ fontWeight: 100 }}
      buttonStyle={theme}
      overlayStyle={{ paddingLeft: 5, paddingRight: 5 }}
      icon={
        <FontAwesome
          name="envelope-o"
          style={{
            fontSize: 24,
            lineHeight: '38px',
            position: 'relative',
            top: '-2px',
          }}
        />
      }
    />
  </a>
);

export default BtnContactUs;
