import palette from 'theme/palette';

export const styles = {
  slateViewCounter: {
    background: palette.defaultText,
    color: palette.white,
  },
  slateViewAction: {
    background: palette.slateViewActionBg,
    color: palette.white,
  },
  name: {
    width: '100%',
    maxWidth: 360,
  },
  removeButton: {
    color: '#87302c',
  },
  selectButton: {
    position: 'absolute',
    top: 2,
    right: -10,
  },
  selectButtonIcon: {
    fill: '#c0c0c0',
  },
  field: {
    width: '100%',
    maxWidth: 220,
    cursor: 'pointer',
    paddingRight: 25,
  },
  operator: {
    style: {
      width: '100%',
      maxWidth: 220,
    },
    iconStyle: {
      top: 3,
      right: -10,
    },
    labelStyle: {
      paddingRight: 38,
    },
  },
  pattern: {
    width: '100%',
    maxWidth: 220,
  },
  dateTypeGroup: {
    display: 'inline-block',
    lineHeight: '64px',
    height: '60px',
    whiteSpace: 'nowrap',
  },
  dateTypeItem: {
    display: 'inline-block',
    width: 'auto',
    whiteSpace: 'nowrap',
    margin: '0 10px',
  },
  dateTypeItemLabel: {
    display: 'none',
  },
  recordFieldSelector: {
    maxWidth: 360,
  },
};
