import React from 'react';

import Stage1 from '../../containers/ResetPasswordStages/Stage1';
import Stage2 from '../../containers/ResetPasswordStages/Stage2';
import Stage3 from '../../containers/ResetPasswordStages/Stage3';

import './styles.css';

const ResetPasswordStages = (props) => {
  const { wizardStep } = props.app;

  let stageComponent;

  if (wizardStep === 1) {
    stageComponent = <Stage1 />;
  } else if (wizardStep === 2) {
    stageComponent = <Stage2 />;
  } else {
    stageComponent = <Stage3 />;
  }

  return <div className="reset-password">{stageComponent}</div>;
};

export default ResetPasswordStages;
