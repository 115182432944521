import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import FontAwesome from 'react-fontawesome';
import { useTranslation } from 'react-i18next';

import SlateViewChip from 'components/Shared/SlateViewChip';
import { slateViewsTypes } from 'config/constants';
import './style.css';
import theme from './theme';

const SlateViewsList = ({ type, views, onSlateViewClick }) => {
  const { t } = useTranslation();
  const isListType = type === slateViewsTypes.LIST;

  return (
    <div className="slate-view-list">
      <div className="slate-view-list-title">
        <FontAwesome name={isListType ? 'list-ul' : 'map-marker'} />
        {t('slateView:views', {
          type: isListType ? 'List' : 'Map',
        })}
      </div>

      <div className="slate-view-list-container">
        {views.map((item) => (
          <div className="slate-view-list-item" key={item.uuid}>
            <FlatButton onClick={() => onSlateViewClick(item.uuid)} fullWidth>
              <div className="slate-view-list-item-button">
                <div className="slate-view-list-item-name">{item.name}</div>
                {item.listViewType && (
                  <SlateViewChip type={item.listViewType} />
                )}
                <div
                  className="slate-view-list-count"
                  style={isListType ? theme.counterList : theme.counterMap}
                >
                  {item.count}
                </div>
              </div>
            </FlatButton>
          </div>
        ))}
      </div>
    </div>
  );
};

SlateViewsList.propTypes = {
  type: PropTypes.string.isRequired,
  views: PropTypes.array.isRequired,
  onSlateViewClick: PropTypes.func.isRequired,
};

export default SlateViewsList;
