import React from 'react';
import { createPortal } from 'react-dom';
import './style.css';

import { FooterConsumer } from './Footer';

const IntoFooter = ({ children }) => (
  <FooterConsumer>
    {({ ref }) => ref && <div>{createPortal(children, ref)}</div>}
  </FooterConsumer>
);

export default IntoFooter;
