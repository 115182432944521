import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash/debounce';
import find from 'lodash/find';

import SelectedItem from './SelectedItem';
import { fieldsDnDId } from 'config/constants';
import { fieldAttributeOptions } from 'config/constants';
import * as params from '../params';
import { styles } from '../styles';
import '../style.css';

class SelectedFields extends Component {
  static propTypes = {
    selected: PropTypes.array.isRequired,
    highlightedFields: PropTypes.array.isRequired,
    onFieldAttributeChange: PropTypes.func.isRequired,
    hideAttributes: PropTypes.bool.isRequired,
    onSingleClick: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._delayedClick = debounce(
      this.handleFieldSingleClick,
      params.DEBOUNCE_TIME,
    );
  }

  render() {
    const {
      selected,
      highlightedFields,
      onFieldAttributeChange,
      hideAttributes,
    } = this.props;

    return (
      <Droppable droppableId={fieldsDnDId.SELECTED}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={styles.selectedDroppable}
          >
            {selected.map((field, index) => {
              const busyAttributes = selected
                .filter(
                  (f) => f.suuid === field.suuid && f.cuuid !== field.cuuid,
                )
                .map((f) => f.fieldAttribute);
              return (
                <SelectedItem
                  key={field.cuuid}
                  field={field}
                  index={index}
                  isHighlighted={
                    !!find(
                      highlightedFields,
                      (item) => item.cuuid === field.cuuid,
                    )
                  }
                  onFieldClick={this.handleFieldClick}
                  onFieldAttributeChange={(fieldAttribute) =>
                    onFieldAttributeChange(field.cuuid, fieldAttribute)
                  }
                  fieldAttributes={fieldAttributeOptions.filter(
                    (fieldAttribute) =>
                      !busyAttributes.includes(fieldAttribute),
                  )}
                  hideAttributes={field.meta || hideAttributes}
                />
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  handleFieldClick = (e, cuuid) => {
    const eventParams = {
      ctrlKey: e.nativeEvent.ctrlKey,
      shiftKey: e.nativeEvent.shiftKey,
    };

    if (
      this.clickedOnce ||
      (e.currentTarget.value === 'delete')
    ) {
      // Handle Double Click
      this._delayedClick.cancel();
      this.clickedOnce = false;
      this.props.onDoubleClick(cuuid);
      (e.currentTarget.value === 'delete') && e.stopPropagation();
    } else {
      // Handle single click
      this._delayedClick(cuuid, eventParams);
      this.clickedOnce = true;
    }
  };

  handleFieldSingleClick = (cuuid, eventParams) => {
    this.clickedOnce = undefined;
    this.props.onSingleClick(cuuid, eventParams);
  };
}

export default SelectedFields;
