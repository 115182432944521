import React, { Component } from 'react';
import Cropper from 'react-cropper';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import '@babel/register';
import 'babel-polyfill';
import FileDrop from 'react-file-drop';

import ImagePreview from '../../containers/ImagePreview/ImagePreview';
import { avatarShapes } from '../../config/constants';
import {
  isFileImage,
  processImageConsideringOrientation,
  b64toBlob,
} from '../../helpers/imageHelper';
import style from './style';
import './styles.css';
import 'cropperjs/dist/cropper.css';

class AvatarUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      image: null,
      imageTmp: null,
      imagePreviewUrl: null,
      extension: null,
      error: null,
    };
    this.cropper = React.createRef();
  }

  render() {
    let { imagePreviewUrl, image, error } = this.state;
    const { t, type, shape, imageUUID, disabled } = this.props;
    let $imageUploader;

    let open = disabled ? false : this.state.open;

    if (image) {
      $imageUploader = (
        <div className={shape === avatarShapes.CIRCLE ? 'cropper-circle' : ''}>
          <Cropper
            src={image}
            ref={this.cropper}
            style={style.cropper}
            aspectRatio={1 / 1}
            guides={false}
          />
        </div>
      );
    } else {
      $imageUploader = (
        <div>
          <label>
            <div className="avatar-uploader-dialog">
              {error && <span style={style.uploadLabelError}>{error}</span>}
              <p>{t('fileUploader:clickToUpload')}</p>
              <p className="text-small">
                {t('fileUploader:avatarRequirements')}
              </p>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={this.handleImageChange}
              hidden
            />
          </label>
        </div>
      );
    }

    const actions = [
      <RaisedButton
        className="btn btn-action"
        label={t('fileUploader:clearPhoto')}
        disabled={!image}
        onClick={this.handleClearPhoto}
      />,
      <RaisedButton
        className="btn btn-action"
        label={t('btn:cancel')}
        secondary={true}
        onClick={this.handleDialogClose}
      />,
      <RaisedButton
        className="btn btn-action"
        label={t('btn:done')}
        primary={true}
        keyboardFocused={true}
        onClick={this.handleDone}
      />,
    ];

    return (
      <div className="avatar-preview">
        <ImagePreview
          image={imagePreviewUrl}
          handleDialogOpen={this.handleDialogOpen}
          imageUUID={imageUUID}
          type={type}
          shape={shape}
          disabled={disabled}
        />

        <Dialog
          className="dialog"
          repositionOnUpdate={false}
          title={t('fileUploader:setProfilePhoto')}
          actions={actions}
          modal={false}
          open={open}
          onRequestClose={this.handleDialogClose}
          titleStyle={style.dialogTitle}
          bodyStyle={style.dialogUploadBody}
          autoDetectWindowHeight={false}
          contentStyle={{ marginBottom: 64 }}
          style={{
            overflowY: 'auto',
            paddingTop: 0,
            WebkitOverflowScrolling: 'touch',
          }}
        >
          <FileDrop onDrop={this.handleDrop}>{$imageUploader}</FileDrop>
        </Dialog>

        {imageUUID && (
          <RaisedButton
            className="btn btn-action"
            label={t('fileUploader:removePhoto')}
            onClick={this.handleRemoveProfileImage}
            disabled={disabled}
          />
        )}
      </div>
    );
  }

  handleDrop = (files) => {
    const file = files[0];
    processImageConsideringOrientation(file, this.updateImage);
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];

    if (!isFileImage(file)) {
      return;
    }
    processImageConsideringOrientation(file, this.updateImage);
  };

  updateImage = (file) => {
    const url = window.URL.createObjectURL(file);
    this.setState({ image: url });
  };

  crop = () => {
    const dataUrl = this.cropper.current.getCroppedCanvas().toDataURL();
    const base64 = dataUrl.split(',')[1];
    const contentType = dataUrl.substring(
      dataUrl.indexOf(':') + 1,
      dataUrl.indexOf(';'),
    );
    const blob = b64toBlob(base64, contentType);
    const url = window.URL.createObjectURL(blob);

    this.setState(
      {
        imagePreviewUrl: url,
        open: false,
        imageTmp: null,
        currentImageUUID: this.props.imageUUID,
      },
      this.addAttachment(base64, url),
    );
  };

  addAttachment = (base64, url) => {
    this.props.onAddAttachment('base64:' + base64, url);
  };

  handleDialogOpen = () =>
    this.setState({ open: true }, () =>
      document.body.classList.add('dialog-open'),
    );

  handleDialogClose = () => {
    let data;
    if (this.state.imageTmp) {
      let image = this.state.imageTmp;
      if (this.state.imagePreviewUrl) {
        data = { open: false, image: image, imageTmp: null, error: null };
      } else {
        data = { open: false, image: null, imageTmp: null, error: null };
      }
    } else {
      if (this.state.image && !this.state.imagePreviewUrl) {
        data = { open: false, image: null, error: null };
      } else {
        data = { open: false, error: null };
      }
    }
    this.setState(data, () => document.body.classList.remove('dialog-open'));
  };

  handleClearPhoto = () => {
    let imageTmp = this.state.image;
    this.setState({ imageTmp: imageTmp, image: '' });
    this.props.onRestoreAttachment(this.state.currentImageUUID);
  };

  handleRemoveProfileImage = () => {
    this.setState({ image: '', imagePreviewUrl: '' });
    this.props.onRemoveAttachment();
  };

  handleDone = () => {
    if (this.state.image) {
      this.crop();
      document.body.classList.remove('dialog-open');
    } else {
      this.setState(
        {
          open: false,
          image: null,
          imageTmp: null,
          imagePreviewUrl: null,
        },
        () => document.body.classList.remove('dialog-open'),
      );
    }
  };
}

export default AvatarUploader;
