import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import FormSlateViewTimeZone from "../../components/Forms/FormSlateViewSettings/FormSlateViewTimeZone";
import {handleChangeTimeZone} from "../../store/actions/account";

function mapStateToProps(state) {
  return {
    currentTimeZone: state.app.currentTimeZone,
    timeZones: state.app.timeZones,
    state: state,
  };
}

export default withTranslation()(
  connect(mapStateToProps, {handleChangeTimeZone})(FormSlateViewTimeZone),
);

