import * as type from './types';

export function incrementWizardStep(steps) {
  return (dispatch) => {
    dispatch({
      type: type.INCREMENT_WIZARD_STEP,
      steps,
    });
  };
}

export function decrementWizardStep(steps) {
  return (dispatch) => {
    dispatch({
      type: type.DECREMENT_WIZARD_STEP,
      steps,
    });
  };
}

export function resetWizardStep() {
  return (dispatch) => {
    dispatch({
      type: type.RESET_WIZARD_STEP,
    });
  };
}
