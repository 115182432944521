import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import tryMe from '../../helpers/tryMe';

export class FormSubmit extends Component {
  render() {
    const { handleSubmit, handleMouseDown, entities, entityName } = this.props;
    let item = entities[entityName];

    const {
      title,
      primary,
      style,
      buttonStyle,
      disabled,
      labelColor,
    } = this.props;
    return (
      <RaisedButton
        label={title}
        className="btn"
        primary={primary === undefined ? true : primary}
        style={style}
        buttonStyle={buttonStyle}
        labelColor={labelColor}
        onClick={(e) => {
          handleSubmit(e);
        }}
        onMouseDown={
          handleMouseDown
            ? (e) => {
                handleMouseDown(e);
              }
            : () => {}
        }
        icon={tryMe(item).isFetching ? <CircularProgress size={24} /> : null}
        disabled={!!(tryMe(item).isFetching || disabled)}
      />
    );
  }
}
