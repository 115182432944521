import React, {Component} from 'react';
import formStyles from "../styles";
import Select from "../../Shared/Select";

export default class FormSlateViewTimeZone extends Component {
  render() {
    const {t, handleChangeTimeZone, currentTimeZone, timeZones} = this.props;
    return (
      <>
        <div style={formStyles.warmingTitle}>
          {t('export:timeZoneWarning')}
        </div>

        <Select
          defaultValue={currentTimeZone}
          options={timeZones}
          onChange={handleChangeTimeZone}
          stylesPresetName="wideBlackBordered"
        />
      </>
    );
  }
}
