import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import Breadcrumbs from 'containers/Breadcrumbs/Breadcrumbs';
import * as type from 'store/actions/types';
import { layouts, route } from 'config/constants';
import {isLoggedOut} from "../../helpers/accountHelper";

export default class Content extends Component {
  UNSAFE_componentWillMount() {
    this.detectLayout(this.props);
    this.props.dispatch({
      type: type.SET_APP_PATH,
      payload: this.props.component.props.path,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.detectLayout(nextProps);
  }

  detectLayout = (props) => {
    let { layout } = props.app;
    let { dispatch } = props;
    if (layout !== props.layout) {
      dispatch({ type: type.SET_APP_LAYOUT, payload: props.layout });
    }
  };

  getSwitchPage = () => {
    let switchPage;
    const { path } = this.props.component.props;
    const { t } = this.props;

    switch (path) {
      case route.LOGIN: {
        switchPage = (
          <div className="layout-sign-switch">
            {t('content:needAUserProfile')}{' '}
            <Link to={route.CREATE_PROFILE}>{t('content:createOneHere')}</Link>.
          </div>
        );
        break;
      }
      case route.CREATE_PROFILE: {
        switchPage = (
          <div className="layout-sign-switch layout-sign-switch-create-profile">
            {t('content:alreadyHaveAUserProfile')}{' '}
            <Link to={route.LOGIN}>{t('content:logInHere')}</Link>.
          </div>
        );
        break;
      }
      case route.RESET_PASSWORD: {
        switchPage = (
          <div className="layout-sign-switch">
            {t('content:rememberYourPassword')}{' '}
            <Link to={route.LOGIN}>{t('content:logInHere')}</Link>.
          </div>
        );
        break;
      }
      default:
        break;
    }
    return switchPage;
  };

  render() {
    const { component, app, noPaddingContainer, currentUser } = this.props;
    switch (app.layout) {
      case layouts.SIGN: {
        return (
          <div className="sign-card-wrapper">
            <Card content={component} />
            {this.getSwitchPage()}
          </div>
        );
      }
      case layouts.DASHBOARD: {
        const style = {};
        if (noPaddingContainer) {
          style.padding = '0 30px';
        }
        return (
          <div style={{ height: '100%' }}>
            {
              isLoggedOut(currentUser) ? <></> :
                <div style={style}>
                  <Breadcrumbs
                    path={component.props.computedMatch.path}
                    params={component.props.computedMatch.params}
                  />
                </div>
            }
            {component}
          </div>
        );
      }
      default:
        return component;
    }
  }
}
