import React from 'react';
import merge from 'lodash/merge';

import './styles.css';

// TODO add documentation, fix structure, add proptypes
// spinner original size 103x88px, ratio = width/height
const RATIO = 1.17;

export default function Preloader(props) {
  const { isGray, spinnerWidth, ...restProps } = props;

  let url = `url(${process.env.PUBLIC_URL}/images/loading-logo.png)`;
  if (isGray) {
    url = `url(${process.env.PUBLIC_URL}/images/loading-logo-gray.png)`;
  }

  let params = {};
  if (spinnerWidth) {
    const height = Math.round(spinnerWidth / RATIO);
    params = {
      width: spinnerWidth,
      height,
      backgroundSize: `${spinnerWidth}px ${height}px`,
      marginTop: -height / 2,
      marginLeft: -spinnerWidth / 2,
    };
  }

  return (
    <div className="preloader" {...restProps}>
      <div
        className="preloader-inner"
        style={merge(
          {},
          {
            backgroundImage: url,
          },
          params,
        )}
      />
    </div>
  );
}
