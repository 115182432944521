import React, {Component} from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import classnames from 'classnames';

import SlateTable from '../../containers/SlateTable/SlateTable';
import SlatePane from '../../components/SlatePane/SlatePane';
import {fieldsTypes} from '../../config/constants';
import './style.css';
import theme from './../SlateViews/theme';

class SlateViewSearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSlateViewExpanded: false,
      searchFinished: true,
    };

    this.active = null;
    this.columns = [
      {
        name: '',
        type: fieldsTypes.IMAGE,
        key: 'imageUUID',
        width: 77,
        index: 0,
      },
      {
        name: 'Title',
        type: fieldsTypes.TEXT,
        key: 'title',
        width: 384,
        index: 1,
      },
    ];
  }

  componentDidMount() {
    document.addEventListener(
      'scroll',
      (e) => this._handleScrollEvent(e),
      true,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const searchStringFromUrl = this.props.match.params.searchString;

    // case - user reload page, it can define searchString only from url
    if (
      !this.props.searchString &&
      nextProps.currentAccount &&
      this.state.searchFinished
    ) {
      this.props.fetchSlatesForSearch({search: searchStringFromUrl || ''});
      this.setState({searchFinished: false});
    }
    if (
      this.props.searchString === nextProps.searchString &&
      !this.state.searchFinished
    ) {
      this.setState({searchFinished: true});
    }
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    !(isEqual(nextProps, this.props) && isEqual(nextState, this.state));

  render() {
    const {isSlateViewExpanded} = this.state;

    const {slates, searchResultRowCount, t} = this.props;
    const searchString = this.props.match.params.searchString;

    if (isEmpty(slates)) {
      return (
        <div>
          <div className="slate-view-empty">
            {`${t('slateViewSearch:emptyResult')} '${searchString}'`}
          </div>
        </div>
      );
    } else {
      const currentSlateIndex = (slates || [])
        .map((slate) => slate.uuid)
        .indexOf(this.props.slateInSlatePaneUUID);
      const currentSlate = currentSlateIndex && slates ? slates[currentSlateIndex] : null;
      const prevSlateIndex = currentSlateIndex - 1;
      const nextSlateIndex = currentSlateIndex + 1;
      return (
        <div className="inner-page slate-view">
          <div className="inner-page-header">
            <div className="inner-page-title">
              <span>
                {t('slateViewSearch:searchResultsHint')}
                {`'${searchString}'`}
              </span>
              <div
                className="slate-view-counter"
                style={theme.slateViewCounter}
              >
                {searchResultRowCount}
              </div>
            </div>
          </div>

          <div className="slate-view-container">
            {/* this handle expanded or not */}
            <div
              className={classnames({
                'slate-view-inner': true,
                'slate-view-inner-expanded': isSlateViewExpanded,
              })}
            >
              {/* this handle position near slate pane */}
              <div
                className={classnames({
                  'slate-view-data': true,
                  'slate-view-data-expanded': isSlateViewExpanded,
                  'slate-view-data-with-pane':
                    this.props.isPaneOpen || this.props.isPaneHidden,
                  'slate-view-data-docked':
                    this.props.slateInSlatePaneUUID && this.props.isPaneDocked,
                })}
              >
                {slates && (
                  <SlateTable
                    onRowClickParentHandler={(e, uuid) =>
                      this.openSlatePane(uuid)
                    }
                    columns={this.columns}
                    slates={slates.map((slate) => ({
                      slateUUID: slate.uuid,
                      columns: this.columns.map((column) => ({
                        index: column.index,
                        value: slate[column.key],
                      })),
                    }))}
                    fixedHeader={true}
                    fixedHeaderHeight={36}
                    isExpanded={isSlateViewExpanded}
                    onExpand={this.handleExpandListView}
                    onCompress={this.handleCompressSlateView}
                    isStaticRows={true}
                    withOrdering={false}
                    withFiltration={false}
                    withSorting={false}
                    useDragHandle={true}
                    rowHeightPreset="medium"
                    isImageExpandable={false}
                    rowCount={searchResultRowCount}
                    showEmptyImages={true}
                  />
                )}
              </div>

              {this.props.isPaneOpen && !this.props.isPaneDocked && (
                <div
                  className="slate-pane-overlay"
                  onClick={this.handleHideSlatePane}
                />
              )}

              <SlatePane
                slateUUID={this.props.slateInSlatePaneUUID}
                imageUrl={currentSlate && currentSlate.imageUrl}
                isOpen={this.props.isPaneOpen}
                isHidden={this.props.isPaneHidden}
                isDocked={
                  this.props.slateInSlatePaneUUID && this.props.isPaneDocked
                }
                onShow={this.handleShowSlatePane}
                onHide={this.handleCloseSlatePane}
                onDock={this.toggleDockSlatePane}
                onPrev={
                  currentSlateIndex >= 0 && prevSlateIndex >= 0
                    ? () => this.openSlatePane(slates[prevSlateIndex].uuid)
                    : undefined
                }
                onNext={
                  currentSlateIndex >= 0 && nextSlateIndex < slates.length
                    ? () => this.openSlatePane(slates[nextSlateIndex].uuid)
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }

  // TABLE SIZE HANDLING

  handleExpandListView = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        isSlateViewExpanded: true,
      },
      () => document.querySelector('.body-grid').focus(),
    );
  };

  handleExpandMapView = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isSlateViewExpanded: true,
    });
  };

  handleCompressSlateView = () => {
    this.setState({
      isSlateViewExpanded: false,
    });
  };

  // SCROLL HANDLING

  _handleScrollEvent = (e) => {
    let elem = e.target.classList;
    if (elem && elem.contains('body-grid') && this.active !== 'bodyGrid') {
      this.active = 'bodyGrid';
      e.target.focus();
    } else if (
      elem &&
      elem.contains('slate-pane-inner') &&
      this.active !== 'slatePane'
    ) {
      this.active = 'slatePane';
      e.target.focus();
    }
  };

  // SLATE PANE ACTIONS

  openSlatePane = (slateUUID) => {
    if (slateUUID) {
      let updatedAttribtues = {
        isPaneHidden: false,
        slateInSlatePaneUUID: slateUUID,
      };
      if (!this.props.isPaneDocked) {
        updatedAttribtues.isPaneOpen = true;
      }
      this.props.updateSlatePane(updatedAttribtues);
      if (this.gridBody) {
        this.gridBody.forceUpdate();
      }
      if (!this.props.isPaneDocked) {
        document.getElementById('pages').classList.add('slate-pane-open');
      }
    }
  };

  handleShowSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: true,
      isPaneHidden: false,
    });
    if (!this.props.isPaneDocked) {
      document.getElementById('pages').classList.add('slate-pane-open');
    }
  };

  handleHideSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: false,
      isPaneHidden: true,
    });
    document.getElementById('pages').classList.remove('slate-pane-open');
  };

  handleCloseSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: false,
    });
    document.getElementById('pages').classList.remove('slate-pane-open');
    if (this.gridBody) {
      this.gridBody.forceUpdate();
    }
  };

  toggleDockSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: !this.props.isPaneOpen,
      isPaneHidden: this.props.isPaneHidden,
      isPaneDocked: !this.props.isPaneDocked,
    });
    document.getElementById('pages').classList.remove('slate-pane-open');
  };
}

export default SlateViewSearchResult;
