import React from 'react';
import FontAwesome from 'react-fontawesome';
import palette from '../../theme/palette';

export const BreadcrumbChevron = () => (
  <div className="breadcrumbs-chevron">
    <FontAwesome
      name="angle-right"
      style={{ color: palette.breadcrumbLastItem }}
    />
  </div>
);
