import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import { withTranslation } from 'react-i18next';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';

import { getDateFromTimestamp } from 'helpers/dateTimeHelper';
import { dynamicDate } from 'config/constants';
import { styles } from './styles';
import './style.css';

const dateTypeDynamic = 'dynamic';
const dateTypeFixed = 'fixed';

class DateTimePicker extends Component {
  state = {
    lastDatetime: undefined,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      lastDatetime: this.props.value === dynamicDate ? null : this.props.value,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== dynamicDate &&
      nextProps.value !== this.props.lastDatetime
    ) {
      this.setState({
        lastDatetime: nextProps.value,
      });
    }
  }

  render() {
    const { value, onChange, showTime, t } = this.props;
    const { lastDatetime } = this.state;
    const isDynamic = value === dynamicDate;
    const datetime = lastDatetime ? getDateFromTimestamp(lastDatetime) : null;
    return [
      <RadioButtonGroup
        key="date-type"
        name="Date type"
        style={styles.dateTypeGroup}
        onChange={(e, newValue) => {
          onChange(e, newValue === dateTypeDynamic ? dynamicDate : datetime);
        }}
        valueSelected={isDynamic ? dateTypeDynamic : dateTypeFixed}
      >
        <RadioButton
          style={styles.dateTypeItem}
          value={dateTypeDynamic}
          label={showTime ? t('common:now') : t('common:today')}
        />
        <RadioButton
          style={styles.dateTypeItem}
          className="settings-rule-date-type-fixed"
          labelStyle={styles.dateTypeItemLabel}
          value={dateTypeFixed}
          label={
            showTime ? t('settings:selectDateTime') : t('settings:selectDate')
          }
        />
      </RadioButtonGroup>,
      <div className="date-picker" key="date-picker">
        <DatePicker
          value={datetime}
          hintText={t('settings:selectDate')}
          textFieldStyle={styles.operator.style}
          onChange={onChange}
          disabled={isDynamic}
        />
        {showTime && (
          <TimePicker
            hintText={t('settings:selectTime')}
            value={datetime}
            textFieldStyle={styles.operator.style}
            onChange={onChange}
            disabled={isDynamic}
          />
        )}
      </div>,
    ];
  }
}

export default withTranslation()(DateTimePicker);
