import React from 'react';
import classnames from 'classnames';
import {
  booleanDisplayTypes,
  selectorOperators,
  columnTypes,
  fieldAttributeOptions,
} from '../config/constants';
import generateUUID from './generateUUID';
import '../components/Shared/Fields/styles.css';

const MIN_DESKTOP_WIDTH = 991;

export const getBooleanText = (type, value) => {
  let result = '';

  switch (type) {
    case booleanDisplayTypes.RED_GREEN: {
      result = value ? 'Green' : 'Red';
      break;
    }
    case booleanDisplayTypes.TRUE_FALSE: {
      result = value ? 'True' : 'False';
      break;
    }
    case booleanDisplayTypes.YES_NO: {
      result = value ? 'Yes' : 'No';
      break;
    }
    case booleanDisplayTypes.ON_OFF: {
      result = value ? 'On' : 'Off';
      break;
    }
    default:
      result = value ? 'True' : 'False';
  }
  return result;
};

export const renderBooleanValue = (type, value) => {
  switch (type) {
    case booleanDisplayTypes.RED_GREEN: {
      return (
        <div
          className={classnames({
            'field-data-boolean': true,
            'field-data-boolean-true': value,
          })}
        />
      );
    }

    default: {
      return getBooleanText(type, value);
    }
  }
};

export const getDialogStyle = () => {
  const { clientWidth } = document.documentElement;
  return {
    width: clientWidth >= MIN_DESKTOP_WIDTH ? 400 : '85%',
    maxWidth: 728,
  };
};

export const checkPatternExist = (operator) =>
  !(
    operator === selectorOperators.EXIST_ON_SLATE ||
    operator === selectorOperators.DOESNT_EXIST_ON_SLATE ||
    operator === selectorOperators.HAS_VALUE ||
    operator === selectorOperators.DOESNT_HAVE_VALUE
  );

export const adoptSettingsColumns = (settings) => {
  let newSettings;
  if (settings.columns) {
    newSettings = {
      ...settings,
      columns: settings.columns.map((column) => ({
        ...column,
        fieldSUUID:
          column.columnType === columnTypes.METADATA
            ? column.attribute
            : column.fieldSUUID,
        fieldAttribute:
          column.columnType === columnTypes.METADATA
            ? fieldAttributeOptions[0]
            : column.fieldAttribute,
        cuuid: generateUUID(),
      })),
    }; // Generate unique ID
  } else {
    newSettings = settings;
  }
  return newSettings;
};
