import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormAccountSettingsBasicInfo from 'components/Forms/FormsAccountSettings/FormAccountSettingsBasicInfo';
import { getAccount } from 'store/actions/account';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    currentAccount: state.accounts.currentAccount,
  };
}
export default withTranslation()(
  connect(mapStateToProps, { getAccount })(FormAccountSettingsBasicInfo),
);
