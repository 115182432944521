import { connect } from 'react-redux';

import TableImageItem from 'components/ScrollableTable/TableImageItem';

function mapStateToProps(state) {
  return {
    currentAccount: state.accounts.currentAccount,
    imagesLinks: state.images.links,
  };
}

export default connect(mapStateToProps, null)(TableImageItem);
