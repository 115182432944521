import React from 'react';
import { Link } from 'react-router-dom';

import RaisedButton from 'material-ui/RaisedButton';

import p from '../../theme/palette';
import { theme } from './theme';
import { route } from '../../config/constants';
import './styles.css';

export const Start = (props) => {
  return (
    <div className="login-start">
      <div className="login-start-logo" />
      <RaisedButton
        className="btn"
        containerElement={<Link to={route.CREATE_PROFILE} />}
        label={props.t('btn:createProfile')}
        primary={true}
      />
      <RaisedButton
        className="btn"
        containerElement={<Link to={route.LOGIN} />}
        label={props.t('btn:logIn')}
        buttonStyle={theme.loginButton}
        style={{ width: 180, margin: '0px auto' }}
        labelColor={p.brandBlue}
        overlayStyle={{ height: 32 }}
      />
    </div>
  );
};
