import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import theme from './theme';

export const SidebarLinks = ({ links, path }) => {
  return (
    <ul className="sidebar-nav">
      {links.map((link) => {
        return (
          <span key={link.id}>
            <li className="sidebar-nav-item">
              <Link
                to={link.url}
                className="sidebar-nav-link"
                style={
                  path === link.path
                    ? theme.sidebarActiveLink
                    : theme.sidebarLink
                }
              >
                <div className="sidebar-nav-cont">
                  <div className="sidebar-nav-icon">
                    <FontAwesome name={link.icon} />
                  </div>

                  <div className="sidebar-nav-title">{link.title}</div>
                </div>
              </Link>
            </li>
            {link.links && <SidebarLinks links={link.links} path={path} />}
          </span>
        );
      })}
    </ul>
  );
};
