import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import tryMe from '../../../helpers/tryMe';

import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';

import {route} from '../../../config/constants';

export default class FormLogin extends Component {
  UNSAFE_componentWillMount() {
    let previousSessionData = this.props.previousSessionData;
    let emailOrPhone = '';
    if (previousSessionData) {
      if (!!previousSessionData.phone) {
        emailOrPhone = previousSessionData.phone;
      }
      if (!!previousSessionData.email) {
        emailOrPhone = previousSessionData.email;
      }
    }
    this.props.actions.setFormState({
      emailOrPhone: emailOrPhone,
      password: '',
      dataErrors: {
        emailOrPhone: '',
        password: '',
      },
    });
  }

  render() {
    const {actions, state, t, errorAction, isOpenLoginDialog} = this.props;
    return (
      <form onKeyPress={actions.handleKeyPress}>
        <FormTextField
          name="emailOrPhone"
          type="email"
          hintText={t('login:emailOrPhone')}
          floatingLabelText={t('login:emailOrPhone')}
          fullWidth={true}
          actions={actions}
          state={state}
          autocomplete="email"
          value={tryMe(state).emailOrPhone || ''}
        />

        <FormTextField
          name="password"
          type="password"
          hintText={t('login:password')}
          floatingLabelText={t('login:password')}
          fullWidth={true}
          actions={actions}
          state={state}
          autocomplete="current-password"
          value={tryMe(state).password || ''}
        />

        {errorAction && (
          <div
            className="form-error form-error-action"
            style={{marginLeft: 'auto', marginRight: 'auto'}}
          >
            {errorAction}
          </div>
        )}

        <FormSubmit
          title={t('login:logIn')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />

        {
          !isOpenLoginDialog && <Link
            to={route.RESET_PASSWORD}
            style={{display: 'block', textAlign: 'center'}}
          >
            {t('login:forgotPassword')}
          </Link>
        }
      </form>
    );
  }
}
