import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  fetchSlateViewSettings,
  updateSlateViewSettings,
  setNewSlateViewType,
  fetchSettingsFields,
  fetchSlateViewsList,
  setCurrentSlateView,
  updateFieldsSource,
} from 'store/actions/slateViews';
import FormWrapper from 'containers/Forms/FormWrapper';
import FormSlateViewSettings from 'components/Forms/FormSlateViewSettings/FormSlateViewSettings';
import { history } from 'store/createStore';
import generateUUID from 'helpers/generateUUID';
import {
  route,
  entityNames,
  slateViewsTypes,
  resourceTypes,
} from 'config/constants';
import { patchOptions } from 'config/constants';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import {defaultFieldsSource, getFieldsSourceOptions} from "../FieldSelector/FieldsSourceOptions";
import Select from "../Shared/Select";
import PropTypes from "prop-types";
import SlateViewSettingPopup from "./SlateViewSettingPopup";
import {cleanFieldsSource, confirmFieldsSource, revertFieldsSource} from "../../store/actions/slateViews";

class SlateViewSettings extends Component {
  static propTypes = {
    updateFieldsSource: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.viewType = props.slateViewType;
    this.isNewView = false;
  }

  UNSAFE_componentWillMount = () => {
    const {
      match,
      fetchSlateViewSettings,
      newSlateViewType,
      updateFieldsSource,
      cleanFieldsSource,
    } = this.props;
    const { views: slateViewUUID } = match.params;
    cleanFieldsSource();
    updateFieldsSource('all')

    // edit current slate view
    if (slateViewUUID) {
      fetchSlateViewSettings(slateViewUUID);
    } else {
      // create new slate view
      this.isNewView = true;
      this.viewType = newSlateViewType;
    }

    this.entityName =
      this.viewType === slateViewsTypes.LIST
        ? entityNames.UPDATE_LIST_VIEW
        : entityNames.UPDATE_MAP_VIEW;

    this.resourceType =
      this.viewType === slateViewsTypes.LIST
        ? resourceTypes.LIST_VIEW
        : resourceTypes.MAP_VIEW;
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      currentAccount,
      fetchSettingsFields,
      fetchSlateViewSettings,
      fetchSlateViewsList,
      slateViewType,
      setCurrentSlateView,
    } = this.props;

    // handle account changing (after reloading the page)
    if (nextProps.currentAccount) {
      if (
        (currentAccount &&
          currentAccount.uuid !== nextProps.currentAccount.uuid) ||
        !currentAccount
      ) {
        fetchSlateViewsList(nextProps.currentAccount.uuid);
        fetchSettingsFields();
        const { views: slateViewUUID } = nextProps.match.params;

        // if existing slate view
        if (slateViewUUID) {
          setCurrentSlateView(slateViewUUID);
          fetchSlateViewSettings(slateViewUUID);
        } else {
          // redirect if new slate view, because the type is unknown
          history.push({
            pathname: route.SLATE_VIEWS,
          });
        }
      }
    }

    // handle slate view type changing (after reloading the page)
    if (nextProps.slateViewType && (nextProps.slateViewType !== slateViewType)) {
      this.viewType = nextProps.slateViewType;

      this.entityName =
        this.viewType === slateViewsTypes.LIST
          ? entityNames.UPDATE_LIST_VIEW
          : entityNames.UPDATE_MAP_VIEW;

      this.resourceType =
        this.viewType === slateViewsTypes.LIST
          ? resourceTypes.LIST_VIEW
          : resourceTypes.MAP_VIEW;
    }
  };

  componentWillUnmount = () => {
    this.props.setNewSlateViewType();
  };

  handleChangeFieldsSource = (value) => {
      this.props.updateFieldsSource(value)
  };

  render() {
    const {
      t,
      settingsFieldsFilters,
    } = this.props;

    return (
      <div className="inner-page slate-view-settings">
        <div className="inner-page-header">
          <h1 className="settings-header">{this.getTitle()}</h1>
          <div className="settings-source-selector settings-header">
            <div className="settings-source-title">
              {t('settings:sourceTitle')}
            <Select
              defaultValue={defaultFieldsSource}
              options={getFieldsSourceOptions(t)}
              onChange={this.handleChangeFieldsSource}
              stylesPresetName="blackBordered"
              settingsFieldsFilters={settingsFieldsFilters}
            />
            </div>
            <div className="settings-source-title settings-source-subtitle">
              {t('settings:sourceSubtitle')}
            </div>
          </div>
        </div>
        <FormWrapper
          noValidation={true}
          entityName={this.entityName}
          entity={
            this.viewType === slateViewsTypes.LIST
              ? [
                  'name',
                  'columns',
                  'ruleSet',
                  'listViewType',
                  'recordsFieldUniquenessIdentifier',
                  'autoRefresh',
                ] // for list view
              : ['name', 'ruleSet', 'pinColors', 'autoRefresh'] // for map view
          }
          jsonPatchResource={this.resourceType}
          onSubmit={this.onSave}
        >
          <FormSlateViewSettings
            resource={this.resourceType}
            isNewView={this.isNewView}
          />
        </FormWrapper>

        <SlateViewSettingPopup
          closePopup={this.props.revertFieldsSource}
          agreeAndContinueAction={this.props.confirmFieldsSource}
          {...this.props.lastStateBeforeUpdateSourceField}
          t={t}
        />
      </div>
    );
  }

  getTitle = () => {
    const { settings, t } = this.props;
    let title = '';
    if (this.isNewView) {
      title =
        this.viewType === slateViewsTypes.LIST
          ? t('settings:newListViewConfiguration')
          : t('settings:newMapViewConfiguration');
    } else {
      if (settings) {
        title = `${settings.name} ${t('settings:configuration')}`;
      }
    }
    return title;
  };

  onSave = (data) => {
    const { settings, match, updateSlateViewSettings } = this.props;
    let name = settings ? settings.name : '';

    // find new name
    if (data.patches) {
      data.patches.forEach((patch) => {
        if (patch.path === '/name') {
          name = patch.value;
        }
      });
    }
    let slateViewUUID = this.isNewView
      ? generateUUID()
      : match.params.views;

    let newSortAndFilters;
    if (!this.isNewView) {
      newSortAndFilters = this.makeNewSortAndFiltersOnEditViewSettingSubmit(
        data.patches,
      );
    }

    updateSlateViewSettings(
      data,
      this.entityName,
      slateViewUUID,
      this.resourceType,
      name,
      newSortAndFilters,
    );
  };

  makeNewSortAndFiltersOnEditViewSettingSubmit = (patches) => {
    const { settings, sortColumnIndex, filters } = this.props;

    let currentSortCuuid,
      currentFiltersCuuids,
      newSortIndex,
      newSortDirection,
      newFilters = [];

    const columns = settings.columns;
    if (sortColumnIndex !== undefined && sortColumnIndex !== '') {
      currentSortCuuid = columns[sortColumnIndex].cuuid;
    }
    if (filters) {
      currentFiltersCuuids = filters.map(
        (filter) => settings.columns[filter.index].cuuid,
      );
    }

    if (patches) {
      const replaceColumnPatch = patches.find(
        (patch) =>
          patch.op === patchOptions.REPLACE &&
          patch.path === '/columns' &&
          patch.value,
      );
      if (replaceColumnPatch) {
        if (currentSortCuuid) {
          newSortIndex = replaceColumnPatch.value.findIndex(
            (column) => column.sourceCuuid === currentSortCuuid,
          );
          if (newSortIndex === -1) {
            newSortDirection = -1;
          }
        }
        if (currentFiltersCuuids && currentFiltersCuuids.length) {
          newFilters = cloneDeep(filters);
          each(newFilters, (newFilter, index) => {
            newFilter.index = replaceColumnPatch.value.findIndex(
              (column) => column.sourceCuuid === currentFiltersCuuids[index],
            );
          });
          newFilters = newFilters.filter((newFilter) => newFilter.index !== -1);
        }
        each(replaceColumnPatch.value, (patch) => {
          delete patch.sourceCuuid;
        });
      }
    }
    return {
      filters: newFilters,
      sortColumnIndex: newSortIndex,
      sortDirection: newSortDirection,
    };
  };
}

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  settings: state.slateViews.settings,
  settingsFields: state.slateViews.settingsFields,
  settingsFieldsFilters: state.slateViews.settingsFieldsFilters.include,
  currentSlateView: state.slateViews.currentSlateView,
  slateViewType: state.slateViews.slateViewType,
  newSlateViewType: state.slateViews.newSlateViewType,
  lastStateBeforeUpdateSourceField: state.slateViews.snapshotOfTheCurrentStateForBeAbleToRevert,
  // settingsFields: state.slateViews.settingsFields
  sortColumnIndex: state.slateViews.sortColumnIndex,
  filters: state.slateViews.filters,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchSlateViewSettings,
    updateSlateViewSettings,
    setNewSlateViewType,
    fetchSettingsFields,
    fetchSlateViewsList,
    setCurrentSlateView,
    updateFieldsSource,
    revertFieldsSource,
    confirmFieldsSource,
    cleanFieldsSource,
  })(SlateViewSettings),
);
