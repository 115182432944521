import React, { Component } from 'react';
import { Marker } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';

import ImageLoader from '../ImageLoader/ImageLoader';
import { getImageLink } from '../../../helpers/imageHelper';
import { imageSizes } from '../../../config/constants';
import { styles } from './styles';
import './styles.css';

const imageBelongingTypes = {
  SLATE: 'slate',
  ACCOUNT: 'account',
};
const TOP_OFFSET = 140; // marker height + infoBox height + small margin
const MARKER_SVG_PATH =
  'M 3.5989198,-23.170294 A 3.5989069,3.5988819 0 0 ' +
  '1 1.326307e-5,-19.571412 3.5989069,3.5988819 0 0 1 -3.5988941,-23.170294 3.5989069,3.5988819 0 0 ' +
  '1 1.326307e-5,-26.769176 3.5989069,3.5988819 0 0 1 3.5989198,-23.170294 Z ' +
  'm -10.6155009,-6.261853 ' +
  'c -1.491682,1.591985 -2.4260225,3.911062 -2.3897429,6.092395 0.07576,4.558339 2.1304509,6.283905 ' +
  '5.4162748,12.557602 1.1836141,2.7606771 2.4189562,5.6818239 3.59386809,10.51986891 ' +
  'C -0.23289948,0.45136491 -0.07366071,1.1142449 1.2628617e-6,1.1716759 0.07362254,1.2291759 ' +
  '0.2329013,0.56391392 0.39618313,-0.14973309 1.5710946,-4.9877771 2.806437,-7.9066007 3.9900516,-10.667276 ' +
  '7.2758756,-16.940974 9.3305422,-18.666552 9.4063265,-23.224891 9.4426051,-25.406225 8.5059529,-27.727613 ' +
  '7.014271,-29.319598 5.3102705,-31.138174 2.7401139,-32.484053 1.2628617e-6,-32.540333 -2.7401123,-32.596649 ' +
  '-5.3125808,-31.250723 -7.0165811,-29.432147 Z';

export default class MarkerInfo extends Component {
  static propTypes = {
    //
  };

  state = {
    isInfoBoxOpen: false,
    isInfoBoxPositionDefault: true,
    link: '',
    imageBelonging: '',
  };

  UNSAFE_componentWillMount = () => {
    this.getLink();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.mapChangeTrigger !== this.props.mapChangeTrigger) {
      this.checkInfoBoxPosition();
    }
  };

  render() {
    const { slate, currentAccount, onMarkerClick } = this.props;
    const { isInfoBoxOpen, isInfoBoxPositionDefault, link } = this.state;
    let firstLetter = currentAccount.name[0].toUpperCase();

    const infoBoxClass = isInfoBoxPositionDefault
      ? 'map-info-top'
      : 'map-info-bottom';
    const arrowClass = isInfoBoxPositionDefault
      ? 'map-info-window-arrow-top'
      : 'map-info-window-arrow-bottom';

    return (
      <Marker
        icon={{
          path: MARKER_SVG_PATH,
          fillColor: slate.color || '#ea4335',
          strokeColor: '#000',
          strokeWeight: 1,
          fillOpacity: 1,
          scale: 1.2,
        }}
        clickable
        position={{ lat: slate.lat, lng: slate.lng }}
        onMouseOver={this.handleMarkerMouseOver}
        onMouseOut={this.handleMarkerMouseOut}
        onClick={(e) => onMarkerClick(e, slate.uuid)}
      >
        {isInfoBoxOpen && (
          <InfoBox
            options={{
              boxClass: infoBoxClass,
              closeBoxURL: ``,
              enableEventPropagation: true,
              maxWidth: '300',
              disableAutoPan: true,
              boxStyle: {
                overflow: 'unset',
              },
            }}
          >
            <div>
              <div className="map-info-window">
                <div className="map-info-window-image" style={styles.mapImage}>
                  {link && (
                    <ImageLoader
                      url={link}
                      onError={this.onErrorImageLoading}
                      spinnerWidth={imageSizes.MAP_VIEW.width / 2}
                      isBorder
                    />
                  )}
                  {!link && firstLetter}
                </div>

                <div className="map-info-window-title">{slate.title}</div>
              </div>
              <div className={arrowClass} />
            </div>
          </InfoBox>
        )}
      </Marker>
    );
  }

  handleMarkerMouseOver = () => {
    this.setState({ isInfoBoxOpen: true });
  };

  handleMarkerMouseOut = () => {
    this.setState({ isInfoBoxOpen: false });
  };

  getLink = () => {
    const { slate, currentAccount, imagesLinks } = this.props;
    let imageBelonging;
    let link = getImageLink(slate.imageUUID, imagesLinks, imageSizes.MAP_VIEW);

    if (link) {
      imageBelonging = imageBelongingTypes.SLATE;
    } else {
      link = getImageLink(
        currentAccount.imageUUID,
        imagesLinks,
        imageSizes.MAP_VIEW,
      );

      if (link) {
        imageBelonging = imageBelongingTypes.ACCOUNT;
      }
    }
    this.setState({ link, imageBelonging });
  };

  onErrorImageLoading = () => {
    const { currentAccount, imagesLinks } = this.props;
    const { imageBelonging } = this.state;
    let newLink;
    let newImageBelonging;

    // load account image if slate image is broken
    if (imageBelonging === imageBelongingTypes.SLATE) {
      newLink = getImageLink(
        currentAccount.imageUUID,
        imagesLinks,
        imageSizes.MAP_VIEW,
      );
      newImageBelonging = newLink ? imageBelongingTypes.ACCOUNT : '';
    }

    this.setState({
      link: newLink,
      imageBelonging: newImageBelonging,
    });
  };

  checkInfoBoxPosition = () => {
    const { slate, googleMaps } = this.props;

    if (googleMaps) {
      const latLng = new window.google.maps.LatLng(slate.lat, slate.lng);
      const projection = googleMaps.getProjection();
      const bounds = googleMaps.getBounds();
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
      // const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
      const scale = Math.pow(2, googleMaps.getZoom());
      const worldPoint = projection.fromLatLngToPoint(latLng);

      // const xPixelsOffset = Math.floor((worldPoint.x - bottomLeft.x) * scale);
      const yPixelsOffset = Math.floor((worldPoint.y - topRight.y) * scale);

      let defaultPosition = true;

      if (yPixelsOffset < TOP_OFFSET) {
        defaultPosition = false;
      }
      this.setState({
        isInfoBoxPositionDefault: defaultPosition,
      });
    }
  };
}
