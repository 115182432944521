import React, { Component } from 'react';
import pick from 'lodash/pick';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AsYouTypeFormatter } from 'google-libphonenumber';

import { validate } from '../../helpers/validationHelper';
import { entityNames, resourceTypes } from '../../config/constants';
import {createReplacePatch, getJsonPatchData} from '../../helpers/patchHelper';
import styles from './styles';

window.SL8ValidationEngine.validatePhoneDelegate = function (phoneNumber) {
  try {
    let phoneUtil = PhoneNumberUtil.getInstance();
    let number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
    return phoneUtil.isPossibleNumber(number);
  } catch (e) {
    return false;
  }
};

export default class FormWrapper extends Component {
  constructor() {
    super();
    this.state = {};
    this.isNeedDelay = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entityName } = this.props;
    let currentEntity = nextProps.entities[entityName];

    if (
      currentEntity &&
      currentEntity.isUpdated &&
      entityName === entityNames.UPDATE_PASSWORD
    ) {
      this.setState(
        Object.assign({}, this.baseState, {
          entityName: entityName,
          dataErrors: {},
        }),
      );
    }
    if (currentEntity && currentEntity.hasErrors) {
      if (currentEntity.hasErrors.dataErrors) {
        this.setState({
          dataErrors: currentEntity.hasErrors.dataErrors,
        });
      } else if (currentEntity.hasErrors.error400) {
        this.setState({
          error400: currentEntity.hasErrors.error400,
        });
      }
    }
  }

  render() {
    const { entityName, type, children } = this.props;
    const { error400, errorAction } = this.state;

    let actions = pick(this, [
      'isValid',
      'handleKeyPress',
      'handleBlur',
      'handleMouseDown',
      'handleChange',
      'handleSwitch',
      'handlePhoneChange',
      'handleSubmit',
      'setFormState',
      'handleAddAttachment',
      'handleRemoveProfileImage',
      'handleRestoreProfileImage',
      'fetchReset',
      'handleClear',
    ]);
    let state = pick(this, ['state']);

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        errorAction,
        actions,
        state: state.state,
        entityName,
        type: type || null,
        errors: this.props.errors,
      }),
    );

    return (
      <div>
        {error400 && (
          <div style={styles.formError} className="form-error">
            {error400}
          </div>
        )}
        {childrenWithProps}
      </div>
    );
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  handleMouseDown = () => {
    this.isNeedDelay = true;
  };

  handleBlur = (e) => {
    const name = e.target.name;
    if (this.isNeedDelay) {
      setTimeout(
        () => {
          this.isValid(name);
        },
        200,
        name,
      );
      this.isNeedDelay = false;
    } else {
      this.isValid(name);
      this.setState({ error400: null, errorAction: null });
    }
  };

  handleChange = (e) => {
    let attrs = e.target.name.split('.');
    let value = e.target.value;
    let key = attrs.pop();

    let objectKey = '';
    let topObject,
      object = {};

    if (attrs.length > 0) {
      objectKey = attrs.shift();
      topObject = object = this.state[objectKey];
      attrs.forEach((attr) => (object = object[attr]));
      object[key] = value;
      this.setState({ [objectKey]: topObject });
    } else {
      this.setState({ [key]: value });
    }
  };

  handleSwitch = (e) => {
    const { name } = e.target;
    this.setState({
      [name]: !this.state[name],
    });
  };

  handlePhoneChange = (e) => {
    let phoneNumber = e.target.value.replace(/[^0-9+]+/g, '');
    try {
      let formatter = new AsYouTypeFormatter('US');
      let phoneNumberLength = phoneNumber.length;
      let num;
      for (var i = 0; i < phoneNumberLength; ++i) {
        var inputChar = phoneNumber.charAt(i);
        num = formatter.inputDigit(inputChar);
      }
      this.setState({
        contactInfo: { ...this.state.contactInfo, phone: num.trim() },
      });
    } catch (exception) {
      this.setState({
        contactInfo: { ...this.state.contactInfo, phone: phoneNumber },
      });
    }
  };

  isValid = (attributeToValidate) => {
    if (this.props.noValidation) {
      return true;
    }
    return validate(
      this.props.entityName,
      pick(this.state, this.props.entity),
      attributeToValidate,
      this.setState.bind(this),
      this.state.dataErrors,
    );
  };

  handleSubmit = () => {
    const {
      currentUser,
      currentAccount,
      currentApiKey,
      entity,
      slateViewSettings,
      onSubmit,
      jsonPatchResource,
    } = this.props;
    const { entityName, imageUUID } = this.state;

    if (!this.isValid()) {
      this.setState({ errorAction: 'Please fix field errors above' });
      return;
    }

    this.setState({ error400: null, errorAction: null });
    let data = {};
    if (jsonPatchResource) {
      let objToCompare;

      switch (jsonPatchResource) {
        case resourceTypes.USER: {
          objToCompare = currentUser;
          break;
        }
        case resourceTypes.ACCOUNT: {
          objToCompare = currentAccount;
          break;
        }
        case resourceTypes.API_KEY: {
          objToCompare = currentApiKey;
          break;
        }
        case resourceTypes.LIST_VIEW:
        case resourceTypes.MAP_VIEW: {
          objToCompare = slateViewSettings || { ruleSet: {} };
          break;
        }
        case resourceTypes.JOB: {
          objToCompare = { ruleSet: {} };
          break;
        }
        default: {
          objToCompare = {};
          break;
        }
      }
      data.patches = getJsonPatchData(entity, this.state, objToCompare);

      if (imageUUID) {
        data.patches.push(
          createReplacePatch(`/imageUUID`, imageUUID),
        );
      }
    } else {
      data = pick(this.state, entity);
      if (imageUUID) {
        data.patches.push(
          createReplacePatch(`/imageUUID`, imageUUID),
        );
      }
    }
    onSubmit(data, entityName);
  };

  setFormState = (initialState) => {
    this.setState(
      Object.assign({}, initialState, { entityName: this.props.entityName }),
    );
    this.baseState = cloneDeep(initialState);
  };

  handleAddAttachment = (value, url) => {
    this.setState({
      imageUUID: value,
      temporaryURL: url,
    });
  };

  handleRestoreProfileImage = (uuid) => {
    this.setState({
      imageUUID: uuid,
    });
  };

  handleRemoveProfileImage = () => {
    this.setState({
      imageUUID: '',
      temporaryURL: '',
    });
  };

  handleClear = () => {
    let newState = cloneDeep(this.state);
    each(newState, (value, key) => {
      if (key !== 'entityName' && key !== 'error400' && key !== 'errorAction') {
        newState[key] = '';
      }
    });
    this.setState(newState);
  };

  fetchReset = () => {
    this.props.fetchReset(this.props.entityName);
  };
}
