import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BreadcrumbItem } from '../../components/Breadcrumbs/BreadcrumbItem';

function mapStateToProps(state) {
  return {
    currentSlateView: state.slateViews.currentSlateView,
    newSlateViewType: state.slateViews.newSlateViewType,
  };
}

export default withTranslation()(connect(mapStateToProps)(BreadcrumbItem));
