import React from 'react';
import FormWrapper from '../../../containers/Forms/FormWrapper';
import FormResetPassword from '../../../containers/Forms/FormResetPassword';
import { entityNames } from '../../../config/constants';

const Stage3 = (props) => (
  <div>
    <h1>{props.t('resetPassword:setNewPassword')}</h1>
    <FormWrapper
      entityName={entityNames.RESET_PASSWORD}
      entity={['password', 'confirmPassword']}
      onSubmit={props.resetPassword}
    >
      <FormResetPassword />
    </FormWrapper>
  </div>
);

export default Stage3;
