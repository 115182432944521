import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import TextField from 'material-ui/TextField/TextField';
import IconButton from 'material-ui/IconButton';
import NavigationArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import { useTranslation } from 'react-i18next';
import { fieldsTypes } from 'config/constants';
import NavigationClose from "material-ui/svg-icons/navigation/close";
import palette from "../../../theme/palette";
import DateFieldSelectorOption from "./DateFieldSelektorOption";
import p from "../../../theme/palette";
export const styles = {
  slateViewCounter: {
    background: palette.defaultText,
    color: palette.white,
  },
  slateViewAction: {
    background: palette.slateViewActionBg,
    color: palette.white,
  },
  name: {
    width: '100%',
    maxWidth: 360,
  },
  removeButton: {
    color: '#87302c',
  },
  selectButton: {
    position: 'absolute',
    top: 2,
    right: -10,
  },
  selectButtonIcon: {
    fill: '#c0c0c0',
  },
  field: {
    width: '100%',
    maxWidth: 220,
    cursor: 'pointer',
    paddingRight: 25,
  },
  operator: {
    style: {
      width: '100%',
      maxWidth: 220,
    },
    iconStyle: {
      top: 3,
      right: -10,
    },
    labelStyle: {
      paddingRight: 38,
    },
  },
  pattern: {
    width: '100%',
    maxWidth: 220,
  },
  dateTypeGroup: {
    display: 'inline-block',
    lineHeight: '64px',
    height: '60px',
    whiteSpace: 'nowrap',
  },
  dateTypeItem: {
    display: 'inline-block',
    width: 'auto',
    whiteSpace: 'nowrap',
    margin: '0 10px',
  },
  dateTypeItemLabel: {
    display: 'none',
  },
  recordFieldSelector: {
    maxWidth: 360,
  },
};

const DateFieldSelector = ({ currentSUUID, fields, onChange, errorText }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const textFieldRef = useRef();
  const { t } = useTranslation();

  const recordFields = useMemo(
    () =>
      fields
        .filter((field) => field.type === fieldsTypes.DATETIME)
        .map((field) => ({ ...field, parent: undefined, children: undefined })),
    [fields],
  );
  const currentField = recordFields.find(
    (field) => field.suuid === currentSUUID,
  );

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    setAnchorEl(textFieldRef.current.input);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFieldChange = ({suuid, title}) => {
    handlePopoverClose();
    onChange(suuid, title);
  };

  const onRemoveRule = () => {
    onChange('', '');
  };

  return (
    <div className="basic-info-settings-record-selector">
      <TextField
        errorText={errorText || ''}
        ref={textFieldRef}
        name="recordsFieldUniquenessIdentifier"
        value={currentField ? currentField.title : ''}
        hintText={t('settings:selectField')}
        onClick={handleClick}
        style={{ ...styles.field, ...styles.recordFieldSelector }}
        errorStyle={{ color: p.error }}
      />
      <IconButton
        style={styles.selectButton}
        iconStyle={styles.selectButtonIcon}
        onClick={handleClick}
      >
        <NavigationArrowDropDown />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={handlePopoverClose}
      >
        <DateFieldSelectorOption fields={recordFields} onFieldClick={handleFieldChange} />
      </Popover>
      {!!currentField &&
      <div className="settings-record-btn-remove">
        <IconButton
          iconStyle={styles.removeButton}
          onClick={onRemoveRule}
        >
          <NavigationClose/>
        </IconButton>
      </div>
      }
    </div>
  );
};

DateFieldSelector.propTypes = {
  currentSUUID: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateFieldSelector;
