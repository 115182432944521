import * as type from 'store/actions/types';
import { layouts, localStorageProps, mapTypes } from 'config/constants';
import { saveInLocalStorage } from 'helpers/localStorageHelper';

const initialState = {
  layout: layouts.SIGN,
  path: '/',
  sidebarCollapsed: false,
  lang: 'en',
  currentTimeZone: 'America/New_York',
  timeZones: [],
  wizardStep: 1,
  mapType: mapTypes.ROADMAP,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_APP_LAYOUT:
      return { ...state, layout: action.payload };
    case type.SET_APP_PATH:
      return { ...state, path: action.payload };
    case type.TOGGLE_SIDEBAR:
      let sidebarCollapsed = !state.sidebarCollapsed;
      return { ...state, sidebarCollapsed: sidebarCollapsed };
    case type.CHANGE_LANGUAGE:
      return { ...state, lang: action.lang };
    case type.SET_CURRENT_TIME_ZONE:
      return { ...state, currentTimeZone: action.payload };
    case type.SET_TIME_ZONES:
      return { ...state, timeZones: action.payload };
    case type.INCREMENT_WIZARD_STEP:
      if (state.wizardStep < action.steps) {
        return { ...state, wizardStep: state.wizardStep + 1 };
      } else {
        return state;
      }
    case type.DECREMENT_WIZARD_STEP:
      if (state.wizardStep <= action.steps && state.wizardStep > 1) {
        return { ...state, wizardStep: state.wizardStep - 1 };
      } else {
        return state;
      }
    case type.RESET_WIZARD_STEP:
      return { ...state, wizardStep: 1 };
    case type.UPDATE_MAP_TYPE: {
      const { userUUID, mapType } = action.payload;
      saveInLocalStorage(userUUID, localStorageProps.MAP_TYPE, mapType);
      return { ...state, mapType };
    }
    default:
      return state;
  }
};

export default app;
