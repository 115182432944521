import Exif from '@fengyuanchen/exif';
import { api } from '../config/constants';

const CORRECT_IMAGE_ORIENTATION = 1;
const MAX_WIDTH = 3200;
const MAX_HEIGHT = 3200;
const UUID_LENGTH = 22;

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getImageLink = (imageUUID, imagesLinks, size) => {
  let link;
  if (imageUUID) {
    if (typeof imageUUID === 'object') {
      imageUUID = imageUUID.UUID;
    }
    if (imageUUID.includes('refuuid')) {
      link = imagesLinks[imageUUID.slice(-UUID_LENGTH)]; // from store
    } else {
      link = `${api.GET_IMAGE}/${imageUUID}`;
      if (size) {
        const ratio = window.devicePixelRatio;
        link += `?width=${Math.round(size.width * ratio)}&height=${Math.round(
          size.height * ratio,
        )}`;
      }
    }
  }
  return link;
};

export const isFileImage = (file) => {
  return (
    file &&
    (file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif')
  );
};

export const processImageConsideringOrientation = (file, callback) => {
  if (file.type === 'image/jpeg') {
    new Exif(file, {
      interoperability: false,
      gps: false,
      exif: true,
      done: (tags) => {
        if (tags.Orientation !== CORRECT_IMAGE_ORIENTATION) {
          fixImageOrientation(file, tags, callback);
        } else {
          callback(file);
        }
      },
      fail: () => {
        callback(file);
      },
    });
  } else {
    callback(file);
  }
};

export const fixImageOrientation = (file, tags, callback) => {
  const updateResults = (img, data) => {
    img.toBlob(
      (blob) => {
        callback(blob);
      },
      file.type,
      1.0,
    );
  };
  window.loadImage(file, updateResults, {
    canvas: true,
    pixelRatio: window.devicePixelRatio,
    downsamplingRatio: 0.5,
    orientation: true,
  });
};

export const getImageDataUrl = (file, callback) => {
  let url = window.URL.createObjectURL(file);
  var img = new Image();

  img.onload = () => {
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    const canvas = document.getElementById('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    const dataUrl = canvas.toDataURL('image/jpeg');
    callback(dataUrl, url);
  };
  img.src = url;
};
