import p from '../../theme/palette';

export const theme = {
  loginButton: {
    border: '1px solid',
    borderColor: p.brandBlue,
    height: 32,
    lineHeight: '32px',
  },
};
