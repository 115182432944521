import React, { Component } from 'react';
import tryMe from '../../../helpers/tryMe';

import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';

export default class FormAddAccount extends Component {
  constructor(props) {
    super();
    this.state = {
      disabled: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.actions.setFormState({
      authCode: '',
      dataErrors: {
        authCode: '',
      },
    });
  }

  componentWillUnmount() {
    this.props.actions.fetchReset();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        disabled: false,
      });
    }, 100);
  }

  render() {
    const { actions, state, t } = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        <FormTextField
          name="authCode"
          type="text"
          hintText={t('accountSettings:authCode')}
          floatingLabelText={t('accountSettings:authCode')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).authCode ? state.authCode.toUpperCase() : ''}
          center={true}
          disabled={this.state.disabled}
        />
        <FormSubmit
          title={t('accountSettings:addAccount')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />
      </form>
    );
  }
}
