import palette from '../../theme/palette';

export const styles = {
  iconButton: {
    minWidth: 40,
  },
  progressBar: {
    backgroundColor: '#fff',
  },
  preloader: {
    backgroundColor: 'transparent',
  },
  tabsItemsStyle: {
    backgroundColor: palette.grey800,
  },
  tabsInkStyle: {
    backgroundColor: palette.brandOrange,
  },
};
