import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import currentUser from './currentUser';
import accounts from './accounts';
import entities from './entities';
import dialogs from './dialogs';
import verification from './verification';
import slateViews from './slateViews';
import jobs from './jobs';
import app from './app';
import images from './images';
import metrics from './metrics';
import notifications_page from './notifications';

import { reducer as notifications } from 'react-notification-system-redux';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    currentUser,
    accounts,
    entities,
    dialogs,
    verification,
    slateViews,
    jobs,
    app,
    images,
    metrics,
    notifications_page,
  });

export default createRootReducer;
