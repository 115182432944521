import React, {Component} from 'react';
import pick from 'lodash/pick';
import FormTextField from '../FormTextField';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import tryMe from '../../../helpers/tryMe';
import {cookiesTypes, getCookies} from '../../../helpers/cookieHelper';
import {entityNames} from "../../../config/constants";

export default class FormVerficationCode extends Component {
  constructor() {
    super();
    this.state = {
      codeSentVia: '',
    };
  }

  UNSAFE_componentWillMount() {
    let code, phone, emailOrPhone, codeSentVia;

    if (this.props.location.search) {
      if (this.props.location.search.includes('?code=')) {
        code = this.props.location.search.substring(6);
      }
    }

    const resetCookies = getCookies(cookiesTypes.RESET_PASSWORD_COOKIE);
    if (resetCookies) {
      let reset = JSON.parse(resetCookies);
      codeSentVia = reset.codeSentVia;

      this.setState({codeSentVia: codeSentVia});

      emailOrPhone = reset.user;
      if (reset.codeSentVia === 'sms') {
        phone = reset.user;
      }
    }

    this.props.actions.setFormState({
      emailOrPhone: emailOrPhone || '',
      phone: phone || '',
      code: code || '',
      codeSentVia: codeSentVia || '',
      verificationMethod: this.props.verification.verificationMethod || null,
      dataErrors: {
        code: '',
      },
    });
  }

  componentDidMount() {
    let {search} = this.props.location;
    if (search) {
      if (search.includes('?code=')) {
        this.props.verifyPasswordResetCode(
          {
            phone: '',
            code: search.substring(6),
          },
          this.props.entityName,
        );
      }
    }
  }

  resendCodeViaEmail = () => {
    let data = pick(this.props.state, 'emailOrPhone');
    this.props.sendPasswordResetCode(
      data,
      this.props.state.entityName,
      this.props.app.wizardStep,
      'email',
    );
    this.setState({codeSentVia: 'email'});
  };

  resendCodeViaSMS = () => {
    let data = pick(this.props.state, 'emailOrPhone');
    this.props.sendPasswordResetCode(
      data,
      this.props.state.entityName,
      this.props.app.wizardStep,
      'sms',
    );
    this.setState({codeSentVia: 'sms'});
  };

  resendCodeViaCall = () => {
    let data = {
      smsOrCall: 'call',
      emailOrPhone: this.props.state.emailOrPhone,
    };
    this.props.sendPasswordResetCode(
      data,
      this.props.state.entityName,
      this.props.app.wizardStep,
      'call',
    );
    this.setState({codeSentVia: 'call'});
  };

  componentDidUpdate(prevProps) {
    if (this.props.verification.verificationMethod === '2FA' && this.props.state.code !== prevProps.state.code) {
      this.props.fetchReset(entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK);
      this.props.actions.setFormState({
        dataErrors: {
          code: '',
        },
      });
    }
  }

  render() {
    const {actions, state, t, verification} = this.props;
    const is2fa = verification.verificationMethod === '2FA'
    return (
      <form onKeyPress={actions.handleKeyPress}>
        <input
          name="phone"
          type="text"
          value={tryMe(state).phone || ''}
          hidden
        />
        <FormTextField
          name="code"
          autoFocus
          type="text"
          hintText={t('resetPassword:code')}
          floatingLabelText={t('resetPassword:code')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).code || ''}
        />
        <div
        style={{
          width:'100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 20,
        }}
        >
          <FormSubmit
            title={is2fa ? t('verification:verify') : t('resetPassword:continue')}
            handleSubmit={actions.handleSubmit}
            handleMouseDown={actions.handleMouseDown}
            entityName={state.entityName}
            disabled={is2fa && (!state || !state.code)}
          />
        </div>
        {this.state.codeSentVia === 'email' && (
          <div className="link text-center" onClick={this.resendCodeViaEmail}>
            {t('resetPassword:sendCodeAgain')}
          </div>
        )}
        {this.state.codeSentVia === 'sms' && (
          <div className="text-center">
            <span className="link" onClick={this.resendCodeViaSMS}>
              {t('resetPassword:sendCodeAgain')}
            </span>{' '}
            {t('resetPassword:or')}{' '}
            <span onClick={this.resendCodeViaCall} className="link">
              {t('resetPassword:callMeInstead')}
            </span>
          </div>
        )}
        {this.state.codeSentVia === 'call' && (
          <div className="text-center">
            <span className="link" onClick={this.resendCodeViaCall}>
              {t('resetPassword:callAgain')}
            </span>{' '}
            {t('resetPassword:or')}{' '}
            <span onClick={this.resendCodeViaSMS} className="link">
              {t('resetPassword:smsTextMeInstead')}
            </span>
          </div>
        )}
      </form>
    );
  }
}
