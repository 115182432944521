import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ResizableBox } from 'react-resizable-ext';
import merge from 'lodash/merge';
import Checkbox from 'material-ui/Checkbox';

import HeaderCell from './HeaderCell';
import { styles } from './styles';

export const SortableList = SortableContainer(
  ({
    columns,
    style,
    sortIcon,
    filters,
    sortColumnIndex,
    headerRightOffset,
    isAllRowsSelected,
    fixedCellWidth,
    handleResizeStart,
    handleResizeColumn,
    handleSelectAllRows,
    handleOpenFilterDialog,
    handleSortData,
    withOrdering,
    withFiltration,
    withSorting,
  }) => {
    return (
      <div
        style={merge({}, style, {
          right: headerRightOffset,
          left: 'none',
        })}
        className="header-row"
        role="row"
      >
        <div className="header-cell-fixed" style={{ width: fixedCellWidth }}>
          <Checkbox
            checked={isAllRowsSelected}
            onCheck={handleSelectAllRows}
            style={merge(
              {},
              styles.checkboxStyle,
              withOrdering ? null : { cursor: 'default' },
            )}
            iconStyle={styles.checkboxIconStyle}
            inputStyle={styles.checkboxInputStyle}
          />
          -
        </div>

        {columns.map((value, colIndex) => {
          return (
            <SortableItem
              key={`item-${colIndex}`}
              index={colIndex}
              colIndex={colIndex}
              value={value}
              width={value.width}
              filters={filters}
              sortColumnIndex={sortColumnIndex}
              sortIcon={sortIcon}
              handleResizeStart={handleResizeStart}
              handleResizeColumn={handleResizeColumn}
              handleOpenFilterDialog={handleOpenFilterDialog}
              handleSortData={handleSortData}
              withFiltration={value.type === "photo" ? false : withFiltration}
              withOrdering={withOrdering}
              withSorting={withSorting}
            />
          );
        })}
      </div>
    );
  },
);

const SortableItem = SortableElement(
  ({
    value,
    colIndex,
    width,
    sortColumnIndex,
    filters,
    sortIcon,
    handleResizeStart,
    handleResizeColumn,
    handleOpenFilterDialog,
    handleSortData,
    withFiltration,
    withOrdering,
    withSorting,
  }) => {
    return (
      <ResizableBox
        width={width}
        axis={withOrdering ? 'x' : 'none'}
        height={36}
        onResizeStart={withOrdering ? handleResizeStart : null}
        onResizeStop={withOrdering ? handleResizeColumn : null}
        id={value.index}
      >
        <HeaderCell
          height={36}
          column={value}
          width={width}
          filters={filters}
          sortColumnIndex={sortColumnIndex}
          onSortData={handleSortData}
          onFilterDialogOpen={handleOpenFilterDialog}
          sortIcon={sortIcon}
          colIndex={colIndex}
          withFiltration={withFiltration}
          withOrdering={withOrdering}
          withSorting={withSorting}
        />
      </ResizableBox>
    );
  },
);
