import React from 'react';
import classnames from 'classnames';
import FontAwesome from 'react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';
import merge from 'lodash/merge';
import MediaQuery from 'react-responsive';

import { fieldsDnDId } from 'config/constants';
import * as params from '../params';
import { styles } from '../styles';

const PADDING = 15;

const AvailableItem = ({
  field,
  index,
  onFieldClick,
  isHighlighted,
  onToggleOpen,
}) => {
  const paddingStyle = {
    paddingLeft: `${field.parents.length * PADDING + PADDING}px`,
  };
  const fieldBody = (
    <>
      {field.children && (
        <div
          style={styles.leftIcon}
          onClick={(e) => {
            e.stopPropagation();
            onToggleOpen(field.suuid);
          }}
        >
          <FontAwesome
            name={field.isShowChildren ? 'minus-square-o' : 'plus-square-o'}
            style={styles.openIcon}
          />
        </div>
      )}
      <div
        className={'settings-field-body'}
        style={{ width: `calc(100% - ${field.children ? '24' : '0'}px)` }}
      >
        <div className="settings-field-title">{field.title}</div>
        {!field.meta && <div className="settings-field-type">{field.type}</div>}
      </div>
    </>
  );

  return (
    <MediaQuery minWidth={params.MIN_WIDTH}>
      {(matches) => {
        if (matches) {
          return (
            <Draggable
              draggableId={`${fieldsDnDId.AVAILABLE}${field.suuid}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={classnames({
                    'settings-field-available': true,
                    'settings-field-available-highlighted': isHighlighted,
                  })}
                  onClick={(e) => onFieldClick(e, field.suuid)}
                  style={merge(
                    {},
                    paddingStyle,
                    provided.draggableProps.style,
                    snapshot.isDragging && styles.isDragging,
                  )}
                >
                  {fieldBody}
                </div>
              )}
            </Draggable>
          );
        } else {
          return (
            <div
              className={classnames({
                'settings-field-available': true,
                'settings-field-available-highlighted': isHighlighted,
              })}
              onClick={(e) => onFieldClick(e, field.suuid)}
              style={paddingStyle}
            >
              {fieldBody}
            </div>
          );
        }
      }}
    </MediaQuery>
  );
};

export default AvailableItem;
