import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';

import { saveInLocalStorage } from 'helpers/localStorageHelper';
import * as type from 'store/actions/types';
import { localStorageProps } from 'config/constants';

const initialState = {};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_IS_SHOWING_NOTIFICATION: {
      const newState = cloneDeep(state);
      const { uuid, currentAccount } = action;
      newState.accountsList = newState.accountsList.map(item => {
        if(item.uuid === uuid) {
          return {...item, ...currentAccount};
        }
        return item;
      });
      return newState;
    }

    case type.SET_ACCOUNTS: {
      const newState = cloneDeep(state);
      const { accounts, userUUID, currentAccount } = action.payload;

      // check is accountsList was updated
      const isAccountsListNotUpdated = newState.accountsList &&
        newState.accountsList.length === accounts.length &&
        newState.accountsList.every(account => accounts.find(newAccount => newAccount.uuid === account.uuid));

      newState.accountsList = isAccountsListNotUpdated ? (newState.accountsList || accounts) : accounts;
      newState.currentAccount = currentAccount;
      saveInLocalStorage(
        userUUID,
        localStorageProps.CURRENT_ACCOUNT,
        currentAccount,
      );
      return newState;
    }

    case type.SET_CURRENT_ACCOUNT: {
      const newState = cloneDeep(state);
      const { userUUID, currentAccount } = action.payload;
      newState.currentAccount = currentAccount;
      saveInLocalStorage(
        userUUID,
        localStorageProps.CURRENT_ACCOUNT,
        currentAccount,
      );
      return newState;
    }

    case type.UPDATE_ACCOUNT_TIMESTAMP: {
      const newState = cloneDeep(state);
      const { uuid } = action.payload;
      forEach(newState.accountsList, (item) => {
        if (item.uuid === uuid) {
          item.lastUsedTimestamp = Math.round(Date.now() / 1000);
        }
      });

      newState.accountsList = orderBy(
        newState.accountsList,
        ['lastUsedTimestamp'],
        ['desc'],
      );
      return newState;
    }

    case type.SET_API_KEYS: {
      const newState = cloneDeep(state);
      newState.apiKeys = action.payload.apiKeys;
      return newState;
    }

    case type.SET_CURRENT_API_KEY: {
      const newState = cloneDeep(state);
      newState.currentApiKey = action.payload.currentApiKey;
      return newState;
    }

    case type.CLEAR_CURRENT_API_KEY: {
      const newState = cloneDeep(state);
      newState.currentApiKey = undefined;
      return newState;
    }

    case type.SET_CREATED_API_KEY: {
      const newState = cloneDeep(state);
      newState.createdApiKey = action.payload.createdApiKey;
      return newState;
    }

    case type.CLEAR_CREATED_API_KEY: {
      const newState = cloneDeep(state);
      newState.createdApiKey = undefined;
      return newState;
    }

    case type.SET_EXISTING_ACCOUNT: {
      const newState = cloneDeep(state);
      newState.existingAccountUUID = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default accounts;
