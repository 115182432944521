import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField/TextField';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import Popover from 'material-ui/Popover';
import NavigationArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormTextField from '../Forms/FormTextField';
import RuleFields from './RuleFields/RuleFields';
import DateTimePicker from './DateTimePicker';
import {
  ruleParts,
  fieldsTypes,
  dynamicDate,
  entityNames,
  entryDetailsTypes,
  entryDetails,
} from 'config/constants';
import { getBooleanText } from 'helpers/fieldsHelper';
import { getTimestampFromDate } from 'helpers/dateTimeHelper';
import { checkPatternExist } from 'helpers/fieldsHelper';
import tryMe from 'helpers/tryMe';
import { styles } from './styles';
import './style.css';
import CircularIndeterminate from "./Loader";
import cx from "classnames";

class Rule extends Component {
  static propTypes = {
    rule: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    onRuleChange: PropTypes.func.isRequired,
    onRemoveRule: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isFieldsPopoverOpen: false,
      currentField: {title: ''},
    };
    this.fieldSelector = React.createRef();
  }
  UNSAFE_componentWillMount() {
    const {
      rule,
      fields,
      allFields,
    } = this.props;
    const fieldSUUID = rule[ruleParts.FIELD_SUUID] || rule[ruleParts.RECORD_ENTRY_ATTRIBUTE];
    if (fieldSUUID && fields.length) {
      this.findCurrentField(rule, allFields);
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      rule,
      fields
    } = this.props;
    const {
      rule: ruleNextProps,
      fields: fieldsNextProps,
      allFields: allFieldsNextProps
    } = nextProps;

    const fieldSUUID = rule && (
      rule[ruleParts.FIELD_SUUID] ||
      rule[ruleParts.RECORD_ENTRY_ATTRIBUTE]
    );
    const fieldSUUIDNextProps = ruleNextProps && (
      ruleNextProps[ruleParts.FIELD_SUUID] ||
      ruleNextProps[ruleParts.RECORD_ENTRY_ATTRIBUTE]
    );


    if (
      ((fieldSUUIDNextProps !== fieldSUUID) && fieldsNextProps && fieldsNextProps.length) ||
      ((fieldsNextProps !== fields) && fieldSUUIDNextProps)
    ) {
      this.findCurrentField(ruleNextProps ,allFieldsNextProps);
    }
  };


  findCurrentField(rule, fields) {
    let currentField;
    if(rule[ruleParts.RECORD_ENTRY_ATTRIBUTE]){
      currentField = {...rule, title: entryDetails[rule[ruleParts.RECORD_ENTRY_ATTRIBUTE]].title}
    } else {
      currentField = find(fields, (item) => item.suuid === rule[ruleParts.FIELD_SUUID]) || ''
    }
    this.setState({currentField})
  }

  render() {
    const { rule, fields, settingsOperators, t, onRemoveRule, activeRule, isRecordListView, allFields, recordsFieldUniquenessIdentifier } = this.props;
    const { isFieldsPopoverOpen, anchorEl, currentField } = this.state;
    const fieldSUUID = rule[ruleParts.RECORD_ENTRY_ATTRIBUTE] || rule[ruleParts.FIELD_SUUID];
    const operator = rule[ruleParts.OPERATOR];
    const pattern = rule[ruleParts.PATTERN];

    // check if pattern in necessary
    const isPatternExist = checkPatternExist(operator);
    const loading = this.props.operatorsEntity && this.props.operatorsEntity.isFetching

    return (
      <div className="settings-rule-content-container">
        {loading && this.props.rule.uuid === activeRule &&
          <CircularIndeterminate />
        }
        <div className={cx('settings-rule', {
          'blur': loading && this.props.rule.uuid === activeRule,
        })}>
          <div className="settings-rule-items-container">
            <div className="settings-rule-field" id={`rule-${rule.uuid}`}>
              <TextField
                ref={this.fieldSelector}
                name={ruleParts.FIELD_SUUID}
                value={currentField && currentField.title}
                hintText={t('settings:selectField')}
                onClick={this.handleFieldClick}
                style={styles.field}
              />

              <IconButton
                style={styles.selectButton}
                iconStyle={styles.selectButtonIcon}
                onClick={this.handleFieldClick}
              >
                <NavigationArrowDropDown />
              </IconButton>

              <Popover
                open={isFieldsPopoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                onRequestClose={this.handleFieldClose}
              >
                <RuleFields
                  allFields={allFields}
                  recordsFieldUniquenessIdentifier={recordsFieldUniquenessIdentifier}
                  isRecordListView={isRecordListView}
                  fields={fields}
                  onFieldClick={this.handleFieldChange}
                />
              </Popover>
            </div>

            {/* render operations selector if field is selected and operators are fetched */}
            {fieldSUUID && settingsOperators && settingsOperators[fieldSUUID] && (
              <div className="settings-rule-operator">
                <SelectField
                  value={rule[ruleParts.OPERATOR]}
                  onChange={this.handleOperatorChange}
                  style={styles.operator.style}
                  iconStyle={styles.operator.iconStyle}
                  labelStyle={styles.operator.labelStyle}
                  hintText={t('settings:selectOperator')}
                >
                  {this.renderOperators(settingsOperators, fieldSUUID)}
                </SelectField>
              </div>
            )}

            {/* render pattern selector or pattern text field if suitable operator exist */}
            {fieldSUUID &&
              settingsOperators &&
              settingsOperators[fieldSUUID] &&
              operator &&
              isPatternExist &&
              this.renderPatternComponent(
                pattern,
                currentField,
                operator,
                tryMe(tryMe(settingsOperators)[fieldSUUID]).showTime,
              )}
          </div>

          <div className="settings-rule-btn-remove">
            <IconButton
              iconStyle={styles.removeButton}
              onClick={() => onRemoveRule(rule.uuid)}
            >
              <NavigationClose />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  handleFieldClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isFieldsPopoverOpen: true,
      anchorEl: this.fieldSelector.current.input,
    });
  };

  handleFieldClose = () => {
    this.setState({
      isFieldsPopoverOpen: false,
    });
  };

  handleFieldChange = field => {
    if(field.disable) return;
    this.props.onRuleChange({
      uuid: this.props.rule.uuid,
      name: field[ruleParts.RECORD_ENTRY_ATTRIBUTE] ? ruleParts.RECORD_ENTRY_ATTRIBUTE : ruleParts.FIELD_SUUID,
      value: field[ruleParts.RECORD_ENTRY_ATTRIBUTE] ? field[ruleParts.RECORD_ENTRY_ATTRIBUTE] :field.suuid,
      title: field.title
    });
    this.setState({
      isFieldsPopoverOpen: false,
      currentField: field
    });
  };

  handleOperatorChange = (event, index, value) => {
    this.props.onRuleChange({
      uuid: this.props.rule.uuid,
      name: ruleParts.OPERATOR,
      value,
      title: this.state.currentField.title
    });
  };

  handlePatternSelectorChange = (event, index, value) => {
    this.props.onRuleChange({
      uuid: this.props.rule.uuid,
      name: ruleParts.PATTERN,
      value,
      title: this.state.currentField.title
    });
  };

  handlePatternTextFieldChange = (e) => {
    this.props.onRuleChange({
      uuid: this.props.rule.uuid,
      name: ruleParts.PATTERN,
      value: e.target.value,
      title: this.state.currentField.title
    });
  };

  handlePatternDateChange = (e, date) => {
    this.props.onRuleChange({
      uuid: this.props.rule.uuid,
      name: ruleParts.PATTERN,
      value: date === dynamicDate ? date : (date == null ? '' : getTimestampFromDate(date)),
      title: this.state.currentField.title
    });
  };

  renderOperators = (settingsOperators, fieldSUUID) => {
    const { t } = this.props;
    const allowedOperators = settingsOperators[fieldSUUID].allowedOperators || [];

    const length = allowedOperators.length;
    const items = [];
    allowedOperators.forEach(
      (operator, index) => {
        const item = (
          <MenuItem
            key={operator}
            value={operator}
            primaryText={t(`settings:${operator}`)}
          />
        );

        if (length > 4 && index === length - 4) {
          items.push(<Divider key={index} />);
        }
        items.push(item);
      },
    );
    return items;
  };

  renderPatternComponent = (pattern, currentField, operator, showTime) => {
    const { t } = this.props;

    // options for pattern selector
    let selectorPatternOptions = this.getSelectorPatternOptions(currentField);

    if(currentField.recordEntryAttribute === entryDetailsTypes.TIMESTAMP){
      return (
        <DateTimePicker
          value={pattern}
          onChange={this.handlePatternDateChange}
          showTime={showTime}
        />
      );
    }

    switch (currentField.type) {
      case fieldsTypes.DATETIME: {
        return (
          <DateTimePicker
            value={pattern}
            onChange={this.handlePatternDateChange}
            showTime={showTime}
          />
        );
      }
      case fieldsTypes.BOOLEAN: {
        if (typeof pattern === 'string' && pattern) {
          pattern = JSON.parse(pattern);
        }
      }
      // eslint-disable-next-line no-fallthrough
      case fieldsTypes.SELECTOR: {
        return (
          <div className="settings-rule-pattern-selector">
            <SelectField
              value={pattern}
              onChange={this.handlePatternSelectorChange}
              style={styles.operator.style}
              iconStyle={styles.operator.iconStyle}
              labelStyle={styles.operator.labelStyle}
              hintText={t('settings:selectPattern')}
            >
              {selectorPatternOptions &&
                this.renderSelectorPatternOptions(
                  selectorPatternOptions,
                  currentField,
                )}
            </SelectField>
          </div>
        );
      }
      default: {
        return (
          <div className="settings-rule-pattern-text">
            <FormTextField
              name={ruleParts.PATTERN}
              type="text"
              value={pattern}
              style={styles.pattern}
              state={{}}
              inputStyle={{ marginTop: 4 }}
              actions={{
                handleChange: this.handlePatternTextFieldChange,
                handleBlur: () => {},
              }}
            />
          </div>
        );
      }
    }
  };

  getSelectorPatternOptions = (currentField) => {
    const { settingsOperators } = this.props;
    let result;

    if (currentField.type === fieldsTypes.BOOLEAN) {
      result = [true, false];
    }
    if (currentField.type === fieldsTypes.SELECTOR) {
      result = settingsOperators[currentField.suuid].options;
    }

    return result;
  };

  renderSelectorPatternOptions = (selectorPatternOptions, currentField) => {
    const { settingsOperators } = this.props;

    const options = [];
    selectorPatternOptions.forEach((item) => {
      let text = item;
      if (currentField.type === fieldsTypes.BOOLEAN) {
        text = getBooleanText(
          settingsOperators[currentField.suuid].displayType,
          item,
        );
      }

      const option = <MenuItem key={item} value={item} primaryText={text} />;
      options.push(option);
    });
    return options;
  };
}

const mapStateToProps = (state) => ({
  settingsOperators: state.slateViews.settingsOperators,
  operatorsEntity: state.entities[entityNames.GET_SETTINGS_OPERATORS],
});

export default withTranslation()(connect(mapStateToProps, null)(Rule));
