import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import LinearProgress from 'material-ui/LinearProgress';
import FontAwesome from 'react-fontawesome';
import MediaQuery from 'react-responsive';

import { jobsStatuses } from '../../config/constants';
import { renderDateTime } from '../../helpers/dateTimeHelper';
import { styles } from './styles';
import './style.css';
import {Tooltip} from "@material-ui/core";

const MIN_WIDTH = 641;

export default class JobsItem extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
  };

  render() {
    const {
      job,
      onRerunClick,
      onCloneClick,
      onFavoriteClick,
      onRemoveClick,
      t,
    } = this.props;

    const isFinished = job.status === jobsStatuses.FINISHED;

    return (
      <div className="jobs-item">
        <MediaQuery minWidth={MIN_WIDTH}>
          {(matches) => {
            if (matches) {
              return (
                <React.Fragment>
                  <div className="jobs-item-name">{job.name}</div>
                  <div className="jobs-item-date">
                    {job.runStarted ? renderDateTime(job.runStarted) : ''}
                  </div>

                  <div className="jobs-item-status">
                    <div className="jobs-item-status-name">{job.status}</div>
                    <div className="jobs-item-status-progress">
                      {!isFinished && (
                        <LinearProgress
                          mode="determinate"
                          max={1}
                          value={job.progress}
                          style={styles.progressBar}
                        />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <div className="jobs-item-name">
                    <div className="jobs-item-title">{t('export:name')}:</div>
                    {job.name}
                  </div>
                  <div className="jobs-item-date">
                    <div className="jobs-item-title">{t('export:date')}:</div>
                    {job.runStarted ? renderDateTime(job.runStarted) : ''}
                  </div>

                  <div className="jobs-item-status">
                    <div className="jobs-item-title">{t('export:status')}:</div>
                    <div className="jobs-item-status-name">{job.status}</div>
                    <div className="jobs-item-status-progress">
                      {!isFinished && (
                        <LinearProgress
                          mode="determinate"
                          max={1}
                          value={job.progress}
                          style={styles.progressBar}
                        />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          }}
        </MediaQuery>

        <div className="jobs-item-buttons">
          <a
            href={job.downloadUrl}
            className={isFinished ? '' : 'disabled-link'}
          >
            <RaisedButton
              className="jobs-item-btn-text"
              label={t('export:downloadCSV')}
              icon={<FontAwesome name="cloud-download" />}
              disabled={!isFinished}
            />
          </a>
          <div className="jobs-item-buttons-actions">
            <Tooltip placement={'top'} title={t('tooltips:rerunExportJob')}>
              <RaisedButton
                className="jobs-item-btn-icon"
                icon={<FontAwesome name="repeat" />}
                style={styles.iconButton}
                onClick={() => onRerunClick(job)}
              />
            </Tooltip>
            <Tooltip placement={'top'} title={t('tooltips:cloneToNewExportJob')}>
              <RaisedButton
                className="jobs-item-btn-icon"
                icon={<FontAwesome name="clone" />}
                style={styles.iconButton}
                onClick={() => onCloneClick(job)}
              />
            </Tooltip>
            <Tooltip placement={'top'} title={t('tooltips:favoriteThisJob')}>
              <RaisedButton
                className="jobs-item-btn-icon"
                icon={<FontAwesome name={job.isFavorite ? 'star' : 'star-o'} />}
                style={styles.iconButton}
                onClick={() => onFavoriteClick(job)}
              />
            </Tooltip>
            <Tooltip placement={'top'} title={t('tooltips:archiveThisJob')}>
              <RaisedButton
                disabled={!isFinished}
                className="jobs-item-btn-icon"
                icon={<FontAwesome name="trash" />}
                style={styles.iconButton}
                onClick={() => onRemoveClick(job)}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}
