import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addAccount } from 'store/actions/account';

import FormAddAccount from 'containers/Forms/FormAddAccount';
import FormWrapper from 'containers/Forms/FormWrapper';
import { entityNames } from 'config/constants';
import theme from './theme';
import { withTranslation } from 'react-i18next';

class AccountAdd extends Component {
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      currentAccount,
      existingAccountUUID,
      onDialogClose,
      onSwitchAccount,
    } = this.props;
    if (currentAccount.uuid !== nextProps.currentAccount.uuid) {
      onDialogClose();
    }

    // switch account if existingAccountUUID exist
    if (
      nextProps.existingAccountUUID &&
      existingAccountUUID !== nextProps.existingAccountUUID
    ) {
      onSwitchAccount(nextProps.existingAccountUUID);
    }
  };

  render() {
    const { t, addAccount } = this.props;
    return (
      <div className="account-add">
        <div className="account-add-title" style={theme.addAccountText}>
          {t('accountSettings:addAccount')}
        </div>
        <div className="account-add-desc" style={theme.addAccountText}>
          {t('accountSettings:enterAnAccountAuthCode')}
        </div>
        <FormWrapper
          entityName={entityNames.ADD_ACCOUNT}
          entity={['authCode']}
          onSubmit={addAccount}
        >
          <FormAddAccount />
        </FormWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  existingAccountUUID: state.accounts.existingAccountUUID,
});

export default withTranslation()(
  connect(mapStateToProps, { addAccount })(AccountAdd),
);
