import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormUserProfileDetails from 'components/Forms/FormUserProfile/FormUserProfileDetails';
import { getUserEtag } from 'store/actions/user';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    entities: state.entities,
    app: state.app,
  };
}
export default withTranslation()(
  connect(mapStateToProps, {
    getUserEtag,
  })(FormUserProfileDetails),
);
