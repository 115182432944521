import React from 'react';
import FormWrapper from '../containers/Forms/FormWrapper';
import FormLogin from '../containers/Forms/FormLogin';
import {entityNames} from '../config/constants';
import Verification2faCodeForm from "./Verification2faCode/Verification2faCodeForm";

export const Login = ({
                        t,
                        isOpenLogin2faDialog,
                        login,
                        loginWith2faCode,
                        isModal,
                      }) => (
  <div>
    {
      !isOpenLogin2faDialog || !isModal &&
      <h1>{!isOpenLogin2faDialog ? t('login:login') : t('login:verificationCode')}</h1>
    }
    <FormWrapper
      entityName={isOpenLogin2faDialog ? entityNames.LOGIN_2FA_CODE_VERIFICATION : entityNames.CREATE_AUTH_TOKEN}
      entity={['emailOrPhone', 'password', 'code']}
      onSubmit={isOpenLogin2faDialog ? loginWith2faCode : login}
      noValidation={isOpenLogin2faDialog}
    >
      {isOpenLogin2faDialog ? <Verification2faCodeForm/> : <FormLogin/>}
    </FormWrapper>
  </div>
);
