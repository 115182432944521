import React, {Component} from 'react';
import classnames from 'classnames';
import SlatePaneBar from './SlatePaneBar';
import './styles.css';
import {isLoggedOut} from "../../helpers/accountHelper";
import {updateSlatePane} from "../../store/actions/slateViews";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getImageLink} from "../../helpers/imageHelper";
import {api, listViewTypes, notificationTypes} from "../../config/constants";
import {showNotification} from "../../store/actions/notification";
import * as copy from "copy-to-clipboard";
import {ColorExtractor} from 'react-color-extractor'
import {updatePage} from "../../helpers/versionsHelper";
import ZoomPanPinch from "../Shared/ZoomPanPinch";

class SlatePane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevSlateUUID: props.slateUUID,
      imageUrl: props.imageUrl,
      fullScreenImageUuid: null,
      colors: [],
    };
  }

  pageProcessing = () => {
    const login = !isLoggedOut(this.props.currentUser);
    document.getElementById('flutter_widget').contentWindow.postMessage(
      {
        sender: "sendIsUserLogin",
        message: login
      },
      "*"
    );
  }

  sentCurrentStackUuidToFlutter = () => {
    document.getElementById('flutter_widget').contentWindow.postMessage(
      {
        sender: "currentStackUuid",
        message: this.props.currentAccount.uuid,
      },
      "*"
    );
  }

  handler = async (ev) => {
    if (ev.data.type === 'openFullScreenImage') {
      this.setState({fullScreenImageUuid: ev.data.message});
    }
    if (ev.data.type === 'setSlateImageUrl') {
      if (ev.data.message) {
        this.setState({imageUrl: ev.data.message});
      } else {
        this.setState({
          imageUrl: ev.data.message,
          colors: [],
        });
      }
    }
    if (ev.data.type === 'copyTextToClipboard') {
      const text = ev.data.message;
      var textField = document.createElement('textarea')
      textField.innerText = text

      // made table invisible
      textField.style.position = 'absolute';
      textField.style.top = '100%';
      textField.style.fontFamily = 'initial';
      textField.style.fontSize = 'initial';
      textField.style.backgroundColor = 'transparent';

      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      this.props.showNotification(
        notificationTypes.SUCCESS,
        `${this.props.t('notifications:copyDataToClipboardSuccessfully')}`,
      );
    }
    if (ev.data.type === 'copyImageToClipboard') {
      await this.copyImg(ev.data.message);
    }
    if (ev.data.type === 'copyQRToClipboard') {
      await this.copyImg(`${api.GET_SLATE}/${ev.data.message}/qr?size=1000`);
    }
    if (ev.data.type === 'sendEmail') {
      window && window.open(`mailto:${ev.data.message}`, '_self')
    }
    if (ev.data.type === 'makeCall') {
      window && window.open(`tel:${ev.data.message}`, '_self')
    }
    if (ev.data.type === 'getIsUserLogin') {
      this.pageProcessing();
    }
    if (ev.data.type === 'rejectOpeningNextSlate') {
      this.props.updateSlatePane({
        slateInSlatePaneUUID: this.state.prevSlateUUID,
      });
    }
    if (ev.data.type === 'setPrevSlateUuid') {
      this.setState({
        prevSlateUUID: ev.data.message,
      })
    }
    if (this.props.currentAccount) {
      this.sentCurrentStackUuidToFlutter();
    }
    if (ev.data.type === 'forceUpdatePage') {
      this.props.updatePage();
    }
  }

  componentDidMount = () => {
    window.addEventListener('message', this.handler);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps
      && !isLoggedOut(this.props.currentUser)
      && isLoggedOut(prevProps.currentUser)
    ) {
      this.pageProcessing()
    }
    if (
      prevProps
      && this.props.currentAccount
      && (prevProps.currentAccount !== this.props.currentAccount)
    ) {
      this.sentCurrentStackUuidToFlutter()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handler);
  }

  onloadSlate = () => {
    this.pageProcessing();
  };

  openFullScreenImage = () => {
    const {fullScreenImageUuid} = this.state;
    return getImageLink(fullScreenImageUuid);
  };

  getLink = () => {
    const {fullScreenImageUuid} = this.state;
    if (this.state.fullScreenImageUuid) {

      return fullScreenImageUuid.startsWith("base64:") ? `data:image/jpeg;base64,${fullScreenImageUuid.replace("base64:", '')}` : fullScreenImageUuid;
    }
    return '';
  };

  getColors = colors =>
    this.setState(state => ({colors: [...state.colors, ...colors]}))


  render() {
    const {
      isOpen,
      isHidden,
      isDocked,
      onShow,
      onHide,
      onDock,
      onPrev,
      onNext,
      slateUUID,
      imageUrl,
      isSlatePane,
      currentAccount,
      currentSlateView,
    } = this.props;
    const isRecordListView = currentSlateView ? currentSlateView.listViewType === listViewTypes.records : false;
    const productionUrl = 'https://slate.slatepages.com/#/';
    const developmentUrl = 'https://slate.dev.slatepages.com/#/'
    const localUrl = 'http://localhost:5555/#/';
    const iframeInitSrc = process.env.REACT_APP_ENV === 'production'
      ? productionUrl
      : process.env.REACT_APP_ENV === "development"
        ? developmentUrl
        : localUrl;
    const iframeSlateSrc = `${iframeInitSrc}slate_details?stackUuid=${currentAccount && currentAccount.uuid}&slateType=${isRecordListView ? 'recordEntry' : 'slate'}&uuid=${slateUUID}&shouldClearSlateStack=true&imageUrl=${imageUrl}&isDisableEditingAllField=${isSlatePane === false}`;
    return (
      <div
        className={classnames({
          'slate-pane': isSlatePane !== false,
          'slate-full-page-container': isSlatePane === false,
          'slate-pane-active': isOpen,
          'slate-pane-hidden': isHidden,
          'slate-pane-docked': isDocked,
        })}
        style={{
          backgroundColor: this.state.colors.length > 0 ? this.state.colors[this.state.colors.length - 1] : '#001b32c7',
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        {isHidden && (
          <div className="slate-pane-inner-overlay" onClick={onShow}/>
        )}
        {
          isSlatePane !== false &&
          <SlatePaneBar
            onDock={onDock}
            onHide={onHide}
            onPrev={onPrev}
            onNext={onNext}
            slateUUID={slateUUID}
          />
        }
        <div className={classnames("slate-full-page-wrap", {
          'slate-full-page-for-not-pane-wrap': isSlatePane === false,
        })}>
          <div className="spinner">
            <iframe id="flutter_widget"
                    className={classnames("slate-full-page", {
                      'slate-full-page-for-not-pane': isSlatePane === false,
                    })}
                    src={slateUUID ? iframeSlateSrc : iframeInitSrc}
                    frameBorder="0"
                    allow="geolocation; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    onLoad={this.onloadSlate}
            />
          </div>
        </div>
        <ZoomPanPinch
          imageSrc={this.state.fullScreenImageUuid ? this.getLink() : null}
          isOpen={this.state.fullScreenImageUuid}
          onClose={() => this.setState({fullScreenImageUuid: null})}
        />
        <ColorExtractor getColors={this.getColors}>
          <img
            src={this.state.imageUrl}
            style={{width: 0, height: 0,}}
          />
        </ColorExtractor>
      </div>
    );
  }


  createImage = (options) => {
    options = options || {};
    const img = (Image) ? new Image() : document.createElement("img");
    if (options.src) {
      img.src = options.src;
    }
    return img;
  }

  convertToPng = (imgBlob) => {
    const imageUrl = window.URL.createObjectURL(imgBlob);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageEl = this.createImage({src: imageUrl});
    imageEl.onload = (e) => {
      canvas.width = e.target.width;
      canvas.height = e.target.height;
      ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(this.copyToClipboard, "image/png", 1);
    };
  }

  copyImg = async (src) => {
    const img = await fetch(src);
    const imgBlob = await img.blob();
    if (imgBlob.type.endsWith("jpg") || imgBlob.type.endsWith("jpeg")) {
      this.convertToPng(imgBlob);
    } else if (src.endsWith(".png")) {
      await this.copyToClipboard(imgBlob);
    } else {
      console.error("Format unsupported");
    }
  }

  copyToClipboard = async (pngBlob) => {
    try {
      await window.navigator.clipboard.write([
        new window.ClipboardItem({
          [pngBlob.type]: pngBlob
        })
      ]);

      this.props.showNotification(
        notificationTypes.SUCCESS,
        `${this.props.t('notifications:copyDataToClipboardSuccessfully')}`,
      );
      console.log("Image copied");
    } catch (error) {
      this.props.showNotification(
        notificationTypes.ERROR,
        `${this.props.t('notifications:copyDataToClipboardFailed')}`,
      );
      console.error(error);
    }
  }
}

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  currentUser: state.currentUser,
  currentSlateView: state.slateViews.currentSlateView,
});

const mapDispatchToProps = {
  updateSlatePane,
  showNotification,
  updatePage,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SlatePane)),
);
