export function validate(
  entityName,
  entity,
  attributeToValidate,
  setState,
  dataErrors,
) {
  let validation = window.SL8ValidationEngine.validateEntity(
    entityName,
    entity,
    attributeToValidate,
  );

  if (validation.hasDataErrors || validation.hasSchemaErrors) {
    if (validation.hasDataErrors) {
      setState({
        dataErrors: Object.assign(dataErrors, validation.dataErrors),
      });
    }
    return false;
  } else {
    setState({ dataErrors: { ...dataErrors, [attributeToValidate]: '' } });
    return true;
  }
}

export const validation = {
  isEmailAddress:function(str) {
    const pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(str);  // returns a boolean
  },
  isNotEmpty:function (str) {
    const pattern =/\S+/;
    return pattern.test(str);  // returns a boolean
  },
  isNumber:function(str) {
    const pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  },
  isSame:function(str1,str2){
    return str1 === str2;
  }
};


export const validateLat = (latStr) => {
  const lat = parseFloat(latStr);
  return Boolean(-90 < lat && lat < 90);
};

export const validateLng = (lngStr) => {
  const lng = parseFloat(lngStr);
  return Boolean(-180 < lng && lng < 180);
};
