import cloneDeep from 'lodash/cloneDeep';

import * as type from 'store/actions/types';

export const FETCHING_STATUS = {
  inProgress: 'inProgress',
  success: 'success',
  failed: 'failed',
};

export const FETCHING_TYPE = {
  null: 'null',
  deletingNotification: 'deletingNotification',
  gettingNotificationsList: 'gettingNotificationsList',
  settingNotification: 'settingNotification',
  gettingNotification: 'gettingNotification',
};

export const TRIGGER_TYPE = {
  slateChanges: 'slateChanges',
  dateFieldReminder: 'dateFieldReminder',
};

export const DELIVERY_TYPE = {
  webhook: 'webhook',
  email: 'email',
};

const initialState = {
  isFetching: {
    status: FETCHING_STATUS.success,
    type: FETCHING_TYPE.null,
  },
  activeNotificationUUID: null,
  activeNotificationInfo: {},
  listOfNotifications: null,
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_FETCHING_INFO: {
      const newState = cloneDeep(state);
      newState.isFetching = action.payload;
      return newState;
    }

    case type.CLEAR_FETCHING_TYPE: {
      const newState = cloneDeep(state);
      newState.isFetching = {
        ...newState.isFetching,
        type: FETCHING_TYPE.null
      };
      return newState;
    }

    case type.SET_ACTIVE_NOTIFICATION: {
      const newState = cloneDeep(state);
      newState.activeNotificationUUID = action.payload.notificationUUID;
      return newState;
    }

    case type.SET_ACTIVE_NOTIFICATION_INFO: {
      const newState = cloneDeep(state);
      newState.activeNotificationInfo = action.payload.notification;
      return newState;
    }

    case type.SET_LIST_OF_NOTIFICATIONS: {
      const newState = cloneDeep(state);
      newState.listOfNotifications = action.payload.notifications.map(({
         name,
         triggerType,
         deliveryType,
         uuid,
       }) => {
        const columns = [{
          index: 0,
          value: name || '-',
        },
          {
            index: 1,
            value: triggerType || '-',
          },
          {
            index: 2,
            value: deliveryType || '-',
          },
        ];

        return {
          uuid,
          columns,
        }
      });
      return newState;
    }

    case type.CREATE_NOTIFICATION: {
      const newState = cloneDeep(state);
      newState.listOfNotifications = [...newState.listOfNotifications, action.payload];
      return newState;
    }


    case type.DELETE_NOTIFICATION: {
      const newState = cloneDeep(state);
      newState.listOfNotifications = action.payload.notifications;
      return newState;
    }



    default:
      return state;
  }
};

export default notifications;
