import palette from '../../../theme/palette';

export const style = {
  warningPanel: {
    padding: 20,
    backgroundColor: palette.infoWarningBg,
    color: palette.infoWarningColor,
    fontWeight: 500,
    borderRadius: 5,
  },
};
