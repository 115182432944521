import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import './style.css';
import cx from "classnames";

const CollapsibleField = ({ field, onFieldClick, counter, recordsFieldUniquenessIdentifier }) => {
  const [open, setOpen] = useState(true);
  const fieldCounter = counter || 1;

  const isShow = () => {
    if(field.suuid === recordsFieldUniquenessIdentifier) return false;
    if (field.isShown) return true;
    if(!field.children) return false;
    let isShow = false;
    field.children.forEach( item => {
      if(item.isShown) {isShow = true;}
    })
    return isShow
  };

  return isShow() ? (
    <div className={cx('field-collapsible', {
      'field-collapsible-disable': field.disable,
    })}>
      <div
        className="field-collapsible-container"
        style={{ paddingLeft: 15 * fieldCounter }}
      >
        {field.children && (
          <FontAwesome
            name={open ? 'minus-square-o' : 'plus-square-o'}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
            className="field-collapsible-btn"
          />
        )}
        <div
          className="field-collapsible-data"
          onClick={() => onFieldClick(field)}
        >
          <div className="field-collapsible-title">{field.title}</div>
          <div className="field-collapsible-type">{field.type}</div>
        </div>
      </div>

      {open &&
        field.children &&
        field.children.map((field) => (
          <CollapsibleField
            field={field}
            key={field.suuid || field.recordEntryAttribute}
            onFieldClick={onFieldClick}
            counter={fieldCounter + 1}
            recordsFieldUniquenessIdentifier={recordsFieldUniquenessIdentifier}
          />
        ))}
    </div>
  ) : null;
};

CollapsibleField.propTypes = {
  field: PropTypes.object.isRequired,
  onFieldClick: PropTypes.func.isRequired,
  counter: PropTypes.number,
};

export default CollapsibleField;
