import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormAccountSettingsRemoveApiKey from 'components/Forms/FormsAccountSettings/FormAccountSettingsRemoveApiKey';
import { clearCurrentApiKey } from 'store/actions/account';

function mapStateToProps(state) {
  return {
    currentApiKey: state.accounts.currentApiKey,
  };
}
export default withTranslation()(
  connect(mapStateToProps, { clearCurrentApiKey })(
    FormAccountSettingsRemoveApiKey,
  ),
);
