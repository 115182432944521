import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    display: 'flex',
    zIndex: 2,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: "center",
  },
  loader: {
    color: 'rgb(4, 134, 220)',
  }
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress   classes={{
        colorPrimary: classes.loader,
      }}/>
    </div>
  );
}