import React from 'react';
import Checkbox from 'material-ui/Checkbox';

const SettingsOptions = ({ includeHeaderRow, onChange, t }) => {
  return (
    <div>
      <Checkbox
        label={t('settings:includeHeaderRow')}
        checked={includeHeaderRow}
        onCheck={onChange}
      />
      <div className="settings-options">
        {t('settings:includeHeaderRowDescr')}
      </div>
    </div>
  );
};

export default SettingsOptions;
