import moment from 'moment';
import { dateTimeFormats } from '../config/constants';

export const getDateFromTimestamp = (timestamp) => new Date(timestamp * 1000);

export const getTimestampFromDate = (date) =>
  Math.round(new Date(date).getTime() / 1000);

export const formatDate = (date, format = dateTimeFormats.DATE) =>
  moment(new Date(date)).format(format);

export const renderDateTime = (timestamp, format = dateTimeFormats.DATE_TIME) =>
  timestamp ? moment(new Date(timestamp * 1000)).format(format) : '';
