import { connect } from 'react-redux';
import WizardSteps from '../components/WizardSteps/WizardSteps';

function mapStateToProps(state) {
  return {
    resetPassword: state.resetPassword,
  };
}

export default connect(mapStateToProps)(WizardSteps);
