import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FontAwesome from "react-fontawesome";
import RaisedButton from "material-ui/RaisedButton";
import './style.css';
import theme from "../SlateViews/theme";
import CircularProgress from "material-ui/CircularProgress";
import {renderDateTime} from "../../helpers/dateTimeHelper";
import {
  dateTimeDisplayTypes,
  dateTimeFormats,
  fieldsTypes,
  notificationTypes,
} from "../../config/constants";
import {getImageLink} from "../../helpers/imageHelper";
import {getBooleanText} from "../../helpers/fieldsHelper";
import {Tooltip} from "@material-ui/core";

export const CopyTableButton = ({
                                  currentSlateView,
                                  slates,
                                  showNotification,
                                  fetchMoreSlates,
                                  className,
                                  rowCount
                                }) => {
  const {t} = useTranslation();
  const [isStartFetchingSlates, setIsStartFetchingSlates] = useState(false);
  const [isTableCopied, setIsTableCopied] = useState(true);

  useEffect(() => {
    if (slates && currentSlateView && isStartFetchingSlates) {
      const totalSlatesCount = rowCount;
      const currentSlatesCount = slates.length;
      if (totalSlatesCount > currentSlatesCount) {
        fetchMoreSlates();
      }
      if (totalSlatesCount <= currentSlatesCount) {
        setIsStartFetchingSlates(false);
      }
    }
  }, [slates && slates.length]);

  useEffect(() => {
    if (!isStartFetchingSlates && !isTableCopied) {
      createTable();
    }
  }, [isStartFetchingSlates]);

  const createTable = async () => {
    // create table for coping
    const body = document.body;
    const table = document.createElement('table');
    table.id = 'copyTable';

    // made table invisible
    table.style.position = 'absolute';
    table.style.top = '100%';
    table.style.fontFamily = 'initial';
    table.style.fontSize = 'initial';
    table.style.backgroundColor = 'transparent';

    appendTableHeader(table);
    appendTableData(table);
    body.appendChild(table);

    // Waiting for the table to complete
    let coppingResult = 'unsuccessful';
    let i = 0;

    while (coppingResult === 'unsuccessful' && i < 5) {
      coppingResult = await copyTableData();
      i += 1;
    }

    table.remove();

    if (coppingResult === 'unsuccessful') {
      showNotification(
        notificationTypes.ERROR,
        `${t('notifications:copyTableToClipboardUnsuccessfully')}`,
      );
    } else {
      showNotification(
        notificationTypes.SUCCESS,
        `${t('notifications:copyTableToClipboardSuccessfully')}`,
      );
    }

    setIsTableCopied(true);
  }

  const appendTableHeader = (table) => {
    const columns = currentSlateView.columns;
    const row = table.insertRow();
    for (let t = 0; t < columns.length; t++) {
      const sell = row.insertCell();
      sell.appendChild(document.createTextNode(`${columns[t].name}`));
    }
  }

  const appendTableData = (table) => {
    const columns = currentSlateView.columns;
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      const row = table.insertRow();
      for (let cellIndex = 0; cellIndex < slates[rowIndex].columns.length; cellIndex++) {
        const sell = row.insertCell();
        const child = getTableFormattedDataByType(slates[rowIndex].columns[cellIndex].value, columns[cellIndex].type, columns[cellIndex].displayType);
        sell.appendChild(child);
      }
    }
  }

  const getTableFormattedDataByType = (value, type, displayType) => {
    if (value === '') {
      return document.createTextNode(`-`);
    }
    switch (type) {
      case fieldsTypes.DATETIME: {
        const formattedValue = renderDateTime(
          value,
          displayType === dateTimeDisplayTypes.DATE
            ? dateTimeFormats.DATE
            : dateTimeFormats.DATE_TIME
        );
        return document.createTextNode(formattedValue);
      }
      case fieldsTypes.BOOLEAN: {
        const formattedValue = getBooleanText(displayType, value);
        return document.createTextNode(formattedValue);
      }
      case fieldsTypes.IMAGE:
      case fieldsTypes.PHOTO: {
        const src = getImageLink(value, []);
        return document.createTextNode(src);
      }
      default: {
        return document.createTextNode(value);
      }
    }
  }

  const checkIsFullDataLoaded = async () => {
    setIsTableCopied(false);
    if (rowCount <= slates.length) {
      createTable();
    } else {
      setIsStartFetchingSlates(true);
      fetchMoreSlates();
    }
  }

  const copyTableData = async (hasDelay) => {
    if(hasDelay) {
      await new Promise(r => setTimeout(r, 100));
    }
    const table = document.getElementById('copyTable');
    let body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(table);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(table);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(table);
      range.select();
    }

    // Waiting for the table to complete

    if(hasDelay) {
      await new Promise(r => setTimeout(r, 100));
    }
    const successful = document.execCommand('copy');
    return successful ? 'successful' : 'unsuccessful';
  }


  const totalSlatesCount = rowCount;
  const currentSlatesCount = slates.length;

  return <Tooltip placement={'top'} title={t('tooltips:copyToClipboard')}>
    <RaisedButton
      icon={isStartFetchingSlates || !isTableCopied
        ? <CircularProgress
          size={24}
          value={Math.round(100 * currentSlatesCount/totalSlatesCount)}
          mode={'determinate'}
        />
        : <FontAwesome name="copy"
          style={theme.slateViewActionIconStyle}
        />
      }
      disabled={isStartFetchingSlates || !isTableCopied}
      style={theme.slateViewActionStyle}
      className={className}
      onClick={checkIsFullDataLoaded}
    />
  </Tooltip>;
};
