import * as type from './types';

export function updateMapType(mapType) {
  return (dispatch, getState) => {
    const userUUID = getState().currentUser.uuid;
    dispatch({
      type: type.UPDATE_MAP_TYPE,
      payload: {
        userUUID,
        mapType,
      },
    });
  };
}
