import cloneDeep from 'lodash/cloneDeep';

import * as type from 'store/actions/types';

const initialState = {
  links: {},
};

const images = (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_IMAGE_LINK: {
      const { link, uuid } = action.payload;
      const newState = cloneDeep(state);

      if (!newState.links) {
        newState.links = {};
      }
      newState.links[uuid] = link;
      return newState;
    }

    case type.SET_ATTACHMENT: {
      const newState = cloneDeep(state);
      newState.attachment = action.payload.attachment;
      return newState;
    }

    case type.CLEAR_ATTACHMENT: {
      const newState = cloneDeep(state);
      newState.attachment = undefined;
      return newState;
    }

    default:
      return state;
  }
};

export default images;
