import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';
import { useTranslation } from 'react-i18next';

const ListViewTypeSelector = ({ types, listViewType, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className="settings-text">
      <span>{t('settings:whatDataWouldYouLike')}</span>
      <RadioButtonGroup
        key="slate-view-type"
        name="Slate view type"
        className="settings-radio"
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        valueSelected={listViewType}
      >
        {Object.values(types).map((type) => (
          <RadioButton
            key={type}
            className="settings-radio"
            value={type}
            label={t(`settings:${type}`)}
          />
        ))}
      </RadioButtonGroup>
    </div>
  );
};

ListViewTypeSelector.propTypes = {
  types: PropTypes.object.isRequired,
  listViewType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ListViewTypeSelector;
