import React from 'react';
import styles from './styles';

const WizardSteps = (props) => {
  const stepWidth = 100 / props.steps.length;

  return (
    <div style={styles.steps} className="wizard-steps">
      <div
        style={Object.assign(
          {
            width: `${100 - stepWidth}%`,
            left: `${stepWidth / 2}%`,
          },
          styles.border,
        )}
      />
      {props.steps.map((i) => {
        return (
          <div key={i} style={{ width: `${stepWidth}%` }}>
            <div
              style={
                i === props.currentStep
                  ? Object.assign({}, styles.item, styles.itemActive)
                  : styles.item
              }
            >
              {i}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WizardSteps;
