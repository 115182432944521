import React, { Component } from 'react';
import FormTextField from '../FormTextField';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import tryMe from '../../../helpers/tryMe';

export default class FormResetPassword extends Component {
  UNSAFE_componentWillMount() {
    this.props.actions.setFormState({
      password: '',
      confirmPassword: '',
      dataErrors: {
        password: '',
        confirmPassword: '',
      },
    });
  }
  render() {
    const { actions, state, t } = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        <FormTextField
          name="password"
          type="password"
          autocomplete="new-password"
          hintText={t('resetPassword:password')}
          floatingLabelText={t('resetPassword:password')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).password || ''}
        />
        <FormTextField
          name="confirmPassword"
          type="password"
          autocomplete="new-password"
          hintText={t('resetPassword:confirmPassword')}
          floatingLabelText={t('resetPassword:confirmPassword')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).confirmPassword || ''}
        />
        <FormSubmit
          title={t('resetPassword:updatePassword')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />
      </form>
    );
  }
}
