import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FormTextField from "../../Forms/FormTextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import {validation} from "../../../helpers/validationHelper";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import cx from "classnames";

const styles = {
  select: {
    width: 130,
  },
  removeButton: {
    color: '#87302c',
  },
  errorStyle: {
    position: 'absolute',
    bottom: -10,
    width: 1000,
    color: '#f15a29',
  },
};

const listOfUnits = ['days', 'weeks', 'months', 'years'];
const listOfUnitsForOne = ['day', 'week', 'month', 'year'];

const AlertItem = ({offsetAmount, offsetUnit, index, deleteAlert, setOffsetAmount, setOffsetAmountError, setOffsetUnit, hasError, dataErrors}) => {
  const [arrOfUnits, setArrOfUnits] = useState(offsetAmount === '1' ? listOfUnitsForOne : listOfUnits);
  const [unit, setUnit] = useState(offsetAmount === '1' ? offsetUnit.slice(0, -1) : offsetUnit);
  const { t } = useTranslation();

  const checkIsItSingularVersion = () => {
    const newUnit = offsetAmount.toString() !== '1'
      ? (offsetUnit.endsWith('s')
        ? offsetUnit
        : offsetUnit + 's')
      : (offsetUnit.endsWith('s')
        ? offsetUnit.slice(0, -1)
        : offsetUnit);
    if(newUnit !== unit){
      setUnit(newUnit);
    }
  }

  useEffect(() => {
    const newArr = offsetAmount.toString() === '1' ? listOfUnitsForOne : listOfUnits;
    setArrOfUnits(newArr);
    checkIsItSingularVersion();
  }, [offsetAmount]);

  useEffect(() => {
    checkIsItSingularVersion();
  }, [offsetUnit]);

  const handleChange = event => {
    if(
      validation.isNumber(event.currentTarget.value) ||
      (event.currentTarget.value === '')
    ){
      setOffsetAmount(event.currentTarget.value, index)
    }
  }

  const handleBlur = event => {

    if(event.currentTarget.value === ''){
      setOffsetAmountError(
        'Alert number must be greater than or equal to 0',
        index
      )
    }
  }

  return (
    <div className={cx('alerts-list-item', {
           'alerts-list-item-with-error': hasError,
         })}
         key={index}
         data-index={index}
    >
      <div className="alert-list-input-wrapper">
        <div className="alert-text-field-container">
          <FormTextField
            name="offsetAmount"
            inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
            autocomplete="email"
            hintText={t('notificationsPage:alertsCard:number')}
            errorStyle={styles.errorStyle}
            floatingLabelText={t('notificationsPage:alertsCard:number')}
            fullWidth={true}
            value={offsetAmount}
            underlineStyle={{borderWidth: dataErrors[`alertOffsets[${index}].offsetAmount`] ? 2 : 1}}
            state={{
              dataErrors: {offsetAmount: !dataErrors[`alertOffsets[${index}].offsetUnit`] && dataErrors[`alertOffsets[${index}].offsetAmount`]}
            }}
            actions={{
              handleChange,
              handleBlur,
            }}
          />
        </div>
        <div className="alert-pattern-selector">
          <SelectField
            value={unit}
            onChange={(event, i, value) =>
              setOffsetUnit(value, index)
            }
            style={styles.select}
            iconStyle={styles.iconStyle}
            labelStyle={styles.labelStyle}
            hintText={t('notificationsPage:alertsCard:period')}
            errorStyle={{...styles.errorStyle, left: -135}}
            errorText={dataErrors[`alertOffsets[${index}].offsetUnit`]}
          >
            {arrOfUnits.map(item => (
              <MenuItem key={item} value={item} primaryText={item} />
            ))}
          </SelectField>
        </div>
        {t('notificationsPage:alertsCard:alertPart2')}
      </div>
      <IconButton
        iconStyle={styles.removeButton}
        onClick={(e) => {
          deleteAlert(e, index)
        }}
      >
        <NavigationClose />
      </IconButton>
    </div>
  )
}

const AlertsList = ({deleteAlert, alertsList, setOffsetAmount, setOffsetAmountError, setOffsetUnit, dataErrors}) => {
  return (
    <div className="email-list">
      {
        alertsList.map(({offsetAmount, offsetUnit}, index) => (
          <AlertItem
            hasError={dataErrors[`alertOffsets[${index}]`]}
            dataErrors={dataErrors}
            key={index}
            offsetAmount={offsetAmount}
            offsetUnit={offsetUnit}
            index={index}
            alertsList={alertsList}
            deleteAlert={deleteAlert}
            setOffsetAmount={setOffsetAmount}
            setOffsetUnit={setOffsetUnit}
            setOffsetAmountError={setOffsetAmountError}
          />
        ))
      }
    </div>
  )
}

export default AlertsList;
