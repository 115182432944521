import React, {Component} from 'react';
import FormWrapper from '../../containers/Forms/FormWrapper';
import FormVerficationCode from '../../containers/Forms/FormVerficationCode';
import {entityNames} from '../../config/constants';
import styles from "../Forms/styles";
import {VERIFICATION_DIALOG_TYPE} from "../Forms/FormUserProfile/FormUserProfileDetails";

class VerificationStep2 extends Component {
  UNSAFE_componentWillMount() {
    this.props.setVerificationMethod(this.props.type);
  }

  render() {
    const isEmail = this.props.type === VERIFICATION_DIALOG_TYPE.email;
    const isEnable2fa = this.props.type === VERIFICATION_DIALOG_TYPE.enable2FA;
    const isDisable2fa = this.props.type === VERIFICATION_DIALOG_TYPE.disable2FA;

    return isEmail ? (
      <div className="verification-dialog-body">
        <h3>{this.props.t('verification:checkYourEmail')}</h3>
        <div style={{marginBottom: 80}}>
          {this.props.t('verification:weSentYouLink')}
        </div>
      </div>
    ) : (isEnable2fa || isDisable2fa) ? (
      <div className="verification-dialog-body">
        <h3>{this.props.t('verification:verify2fa')}</h3>
        <div style={{marginBottom: 20}}>
          {this.props.t('verification:weSentYouLConfirmationCode2fa', {
            enabled: isEnable2fa ? 'enabled' : 'disabled'
          })}
        </div>
        {this.props.errors && (
          <div style={{marginTop: 12}}>
            <div style={styles.formError} className="form-error">
              {this.props.errors}
            </div>
          </div>
        )}

        <FormWrapper
          entityName={
            isEnable2fa
              ? entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK
              : entityNames.VERIFICATION_DISABLE_2FA_CODE_BACK
          }
          entity={['verificationMethod', 'code']}
          noValidation={true}
          onSubmit={(data, entityName) =>
            this.props.onVerifyCode(
              data,
              entityName,
              isDisable2fa,
            )
          }
        >
          <FormVerficationCode/>
        </FormWrapper>
      </div>
    ) : (
      <div className="verification-dialog-body">
        <h3>{this.props.t('verification:enterVerificationCode')}</h3>
        <div style={{marginBottom: 20}}>
          {this.props.t('verification:enterCodeWeSent')}
        </div>
        <FormWrapper
          entityName={entityNames.VERIFY_PHONE_VERIFICATION_CODE}
          entity={['verificationMethod', 'code']}
          onSubmit={this.props.onVerifyCode}
        >
          <FormVerficationCode/>
        </FormWrapper>
      </div>
    );
  }
}

export default VerificationStep2;
