import axios from 'axios';
import { push } from 'connected-react-router';
import * as type from './types';
import i18next from 'i18next';
import Notifications from 'react-notification-system-redux';
import find from 'lodash/find';
import differenceBy from 'lodash/differenceBy';
import cloneDeep from 'lodash/cloneDeep';

import { options, INCLUDE_RESOURCE } from 'helpers/cookieHelper';
import { api, entityNames, resourceTypes, route } from 'config/constants';
import {
  getCurrentAccount,
  getLocalAccountName,
  getAccountByUUID,
} from 'helpers/accountHelper';
import {checkVersion, handleVersion} from 'helpers/versionsHelper';
import {getNotificationIsShowing} from "./user";
import jstz from "jstz";

export function fetchTimeZones() {
  return (dispatch) => {
    const currentTimeZone = jstz.determine().name();

    dispatch({
      type: type.SET_CURRENT_TIME_ZONE,
      payload: currentTimeZone,
    });

    let timeZonesOptions = cloneDeep(options);
    return axios
      .get(api.GET_TIME_ZONES, timeZonesOptions)
      .then((res) => {
        handleVersion(res);
        checkVersion();
        const options = res.data.data.map((item, index) => {
          return {
            text: item,
            value: item,
          };
        });
        dispatch({
          type: type.SET_TIME_ZONES,
          payload: options,
        });
      });
  };
}

export function handleChangeTimeZone(newTimeZone) {
  return (dispatch) => {
    dispatch({
      type: type.SET_CURRENT_TIME_ZONE,
      payload: newTimeZone,
    });
  };
}

const handleAccountResponse = (res, dispatch, getState, entityName) => {
  const accounts = res.data.data;
  const userUUID = getState().currentUser.uuid;
  const localAccountName = getLocalAccountName(userUUID, accounts);
  let currentAccount;

  if (entityName === entityNames.ADD_ACCOUNT) {
    const diffArray = differenceBy(
      accounts,
      getState().accounts.accountsList,
      'uuid',
    );
    currentAccount = diffArray.length
      ? diffArray[0]
      : getCurrentAccount(userUUID, accounts);
  } else {
    currentAccount = getCurrentAccount(userUUID, accounts);
  }

  if (accounts) {
    if(currentAccount && currentAccount.uuid) {
      getNotificationIsShowing({uuid: currentAccount.uuid, dispatch}).then(() => {})
    }

    dispatch({
      type: type.SET_ACCOUNTS,
      payload: {
        userUUID,
        accounts,
        currentAccount,
      },
    });
  }

  if (entityName) {
    dispatch({
      type: type.FETCH_SUCCESS,
      entityName: entityName,
    });

    switch (entityName) {
      case entityNames.ADD_ACCOUNT:
        dispatch(
          Notifications.success({
            message: i18next.t('notifications:successfullyAddAccount'),
            position: 'tr',
            autoDismiss: 3,
          }),
        );

        dispatch(
          Notifications.success({
            message: `${i18next.t('notifications:youHaveBeenSwitched')} ${
              currentAccount.name
            }.`,
            position: 'tr',
            autoDismiss: 3,
          }),
        );
        break;

      case entityNames.REMOVE_ACCOUNT:
        dispatch(
          Notifications.success({
            message: i18next.t('notifications:successfullyRemoveAccount'),
            position: 'tr',
            autoDismiss: 3,
          }),
        );
        break;

      default:
        break;
    }
  }

  if (localAccountName) {
    dispatch(
      Notifications.warning({
        message: `${i18next.t(
          'notifications:noLongerAccess',
        )} ${localAccountName}. ${i18next.t(
          'notifications:youHaveBeenSwitched',
        )} ${currentAccount.name}.`,
        position: 'tr',
        autoDismiss: 3,
      }),
    );
  }
};

export const getAccount = (uuid) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityNames.GET_ACCOUNT,
  });

  return axios
    .get(`${api.GET_ACCOUNT}/${uuid}`, options)
    .then((res) => {
      handleVersion(res);
      const currentAccount = res.data.data;
      if (currentAccount) {
        dispatch({
          type: type.SET_CURRENT_ACCOUNT,
          payload: {
            currentAccount,
            userUUID: getState().currentUser.uuid,
          },
        });
      }

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName: entityNames.GET_ACCOUNT,
      });
    })
    .catch((err) => {});
};

export const setCurrentAccountByUUID = (uuid) => {
  return (dispatch, getState) => {
    // clear existing account UUID
    dispatch({
      type: type.SET_EXISTING_ACCOUNT,
    });

    const { currentAccount } = getState().accounts;
    if (currentAccount && currentAccount.uuid === uuid) {
      return;
    }

    const newAccount = getAccountByUUID(uuid, getState().accounts.accountsList);

    if (newAccount) {
      dispatch({
        type: type.SET_CURRENT_ACCOUNT,
        payload: {
          userUUID: getState().currentUser.uuid,
          currentAccount: newAccount,
        },
      });

      dispatch({
        type: type.CLEAR_SLATE_VIEWS,
      });

      dispatch(
        Notifications.success({
          message: `${i18next.t('notifications:youHaveBeenSwitched')} ${
            newAccount.name
          }.`,
          position: 'tr',
          autoDismiss: 3,
        }),
      );
    }
  };
};

export function addAccount(data, entityName) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });
    return axios
      .put(api.ADD_ACCOUNT, data, options)
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.CLEAR_SLATE_VIEWS,
        });
        handleAccountResponse(res, dispatch, getState, entityName);
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              switch (err.response.data.errorCode) {
                case 204:
                  dispatch({
                    type: type.FETCH_ERROR,
                    entityName: entityName,
                    hasErrors: {
                      error400: i18next.t('messages:invalidAuthCode'),
                    },
                  });
                  break;

                case 205:
                  const accountUUID = err.response.data.data;
                  if (accountUUID)
                    dispatch({
                      type: type.SET_EXISTING_ACCOUNT,
                      payload: accountUUID,
                    });
                  break;

                default:
                  dispatch({
                    type: type.FETCH_ERROR,
                    entityName: entityName,
                    hasErrors: {
                      error400: i18next.t('messages:invalidAuthCode'),
                    },
                  });
              }
              break;

            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export const removeAccount = (uuid, entityName) => (dispatch, getState) => {
  return axios
    .delete(`${api.REMOVE_ACCOUNT}/${uuid}`, options)
    .then((res) => {
      handleVersion(res);
      handleAccountResponse(res, dispatch, getState, entityName);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: type.FETCH_ERROR,
          entityName: entityName,
          hasErrors: err.response.data.correlationUUID || null,
        });
      }
    });
};

export const updateAccount = (data, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityName,
  });

  return axios
    .patch(
      `${api.UPDATE_RESOURCE}/${resourceTypes.ACCOUNT}/${
        getState().accounts.currentAccount.uuid
      }?${INCLUDE_RESOURCE}`,
      data.patches,
      options,
    )
    .then((res) => {
      handleVersion(res);
      let account = res.data.data.result;

      if (account) {
        dispatch({
          type: type.SET_CURRENT_ACCOUNT,
          payload: {
            currentAccount: account,
            userUUID: getState().currentUser.uuid,
          },
        });
      }

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName: entityName,
      });

      dispatch(
        Notifications.success({
          message: i18next.t('notifications:successfullyUpdateAccount'),
          position: 'tr',
          autoDismiss: 3,
        }),
      );
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: { error400: i18next.t('messages:userNotFound') },
            });
            break;

          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;

          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
        }
      }
    });
};

export const updateAccountTimestamp = (uuid) => (dispatch, getState) => {
  dispatch({
    type: type.UPDATE_ACCOUNT_TIMESTAMP,
    payload: {
      uuid,
    },
  });
};

export const getApiKeys = (uuid) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityNames.GET_API_KEYS,
  });

  return axios
    .get(`${api.GET_ACCOUNT}/${uuid}/apikeys`, options)
    .then((res) => {
      handleVersion(res);
      const apiKeys = res.data.data;
      if (apiKeys) {
        dispatch({
          type: type.SET_API_KEYS,
          payload: {
            apiKeys,
          },
        });
      }

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName: entityNames.GET_API_KEYS,
      });
    })
    .catch((err) => {});
};

export const removeApiKey = (data, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName,
  });

  const accountUUID = getState().accounts.currentAccount.uuid;
  const apiKeyUUID = getState().accounts.currentApiKey.uuid;
  let verifyOptions = cloneDeep(options);
  verifyOptions.data = data;

  return axios
    .delete(
      `${api.GET_ACCOUNT}/${accountUUID}/apikeys/${apiKeyUUID}`,
      verifyOptions,
    )
    .then((res) => {
      handleVersion(res);
      const apiKeys = res.data.data;
      if (apiKeys) {
        dispatch({
          type: type.SET_API_KEYS,
          payload: {
            apiKeys,
          },
        });
      }

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName,
      });

      dispatch(
        Notifications.success({
          message: i18next.t('notifications:successfullyRemoveApiKey'),
          position: 'tr',
          autoDismiss: 3,
        }),
      );
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityNames.REMOVE_API_KEY,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;
          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
            break;
        }
      }
    });
};

export const updateApiKey = (data, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityName,
  });

  const { currentAccount, currentApiKey } = getState().accounts;

  return axios
    .patch(
      `${api.UPDATE_RESOURCE}/${resourceTypes.API_KEY}/${currentApiKey.uuid}?account-uuid=${currentAccount.uuid}&${INCLUDE_RESOURCE}`,
      data.patches,
      options,
    )
    .then((res) => {
      handleVersion(res);
      let currentApiKey = res.data.data;

      if (currentApiKey) {
        dispatch({
          type: type.SET_CURRENT_API_KEY,
          payload: {
            currentApiKey,
          },
        });
      }

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName: entityName,
      });

      dispatch(push(route.ACCOUNT));

      dispatch(
        Notifications.success({
          message: i18next.t('notifications:successfullyUpdateApiKey'),
          position: 'tr',
          autoDismiss: 3,
        }),
      );
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;

          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
        }
      }
    });
};

export const createApiKey = (data, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityName,
  });

  const { currentAccount } = getState().accounts;

  return axios
    .put(`${api.CREATE_ACCOUNT}/${currentAccount.uuid}/apikeys`, data, options)
    .then((res) => {
      handleVersion(res);
      const createdApiKey = res.data.data;

      if (createApiKey) {
        dispatch({
          type: type.SET_CREATED_API_KEY,
          payload: {
            createdApiKey,
          },
        });
      }

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName: entityName,
      });
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;

          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
        }
      }
    });
};

export const setCurrentApiKey = (uuid) => (dispatch, getState) => {
  const currentApiKey = find(
    getState().accounts.apiKeys,
    (item) => item.uuid === uuid,
  );
  if (currentApiKey) {
    dispatch({
      type: type.SET_CURRENT_API_KEY,
      payload: {
        currentApiKey,
      },
    });
  }
};

export const clearCurrentApiKey = () => (dispatch, getState) => {
  dispatch({
    type: type.CLEAR_CURRENT_API_KEY,
  });
};

export const clearCreatedApiKey = () => (dispatch, getState) => {
  dispatch({
    type: type.CLEAR_CREATED_API_KEY,
  });
};
