import React, { Component } from 'react';
import AutoComplete from 'material-ui/AutoComplete';
import FlatButton from 'material-ui/FlatButton';
import { connect } from 'react-redux';
import { getUserEtag } from 'store/actions/user';

import ImageLoader from '../Shared/ImageLoader/ImageLoader';
import { getImageLink } from 'helpers/imageHelper';
import { imageSizes } from 'config/constants';
import theme from './theme';
import { withTranslation } from 'react-i18next';

class AccountList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: props.accountsList,
    };
  }

  componentDidMount = () => {
    this.props.getUserEtag();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.accountsList) {
      this.setState({ accounts: nextProps.accountsList });
    }
  };

  render() {
    const { accounts } = this.state;
    const { imagesLinks, onSwitchAccount, t } = this.props;
    return (
      <div className="account-list">
        <AutoComplete
          hintText={`${t('accountSettings:searchMyAccounts')} ...`}
          dataSource={accounts}
          onUpdateInput={this.handleSearch}
          fullWidth={true}
          textFieldStyle={theme.autoCompleteSearch.textField}
          underlineStyle={theme.autoCompleteSearch.underline}
          className="account-list-search"
        />

        <div className="account-list-container">
          {accounts.map((item) => {
            let link = getImageLink(
              item.imageUUID,
              imagesLinks,
              imageSizes.ACCOUNT_LIST,
            );
            let firstLetter = item.name[0].toUpperCase();

            return (
              <div
                key={item.uuid}
                className="account-item"
                style={theme.accountItem}
              >
                <FlatButton
                  label={item.name}
                  onClick={(e) => onSwitchAccount(item.uuid)}
                  icon={
                    <div
                      className="account-item-image"
                      style={link ? {} : theme.accountItemImage}
                    >
                      {link && (
                        <ImageLoader
                          url={link}
                          spinnerWidth={imageSizes.ACCOUNT_LIST.width / 2}
                          isBorder
                        />
                      )}
                      {!link && firstLetter}
                    </div>
                  }
                  fullWidth
                  style={theme.accountButton}
                  labelStyle={theme.accountItemLabel}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  handleSearch = (query) => {
    const { accountsList } = this.props;
    let results = [];

    if (query.length === 0) {
      results = accountsList;
    } else {
      accountsList.forEach((item) => {
        let tmp = item.name.toLowerCase().search(query.toLowerCase());
        if (tmp !== -1) {
          results.push(item);
        }
      });
    }
    this.setState({ accounts: results });
  };
}

const mapStateToProps = (state) => ({
  accountsList: state.accounts.accountsList,
  imagesLinks: state.images.links,
});

export default withTranslation()(
  connect(mapStateToProps, { getUserEtag })(AccountList),
);
