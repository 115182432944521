import { layouts, route, routeTitles } from './constants';

// Tools
import SlateGenerator from 'components/SlateGenerator/SlateGenerator';
import ExportJobs from 'components/ExportJobs/ExportJobs';
import ExportJobSettings from 'components/ExportJobSettings/ExportJobSettings';
import Notifications from "../components/Notifications/Notifications";
import NotificationsSettings from "../components/Notifications/NotificationsSettings";

// Public
import Start from 'containers/Start';
import Login from 'containers/Login';
import CreateProfile from 'containers/CreateProfile';
import ResetPassword from 'containers/ResetPassword';

// Private
import Home from 'components/Home/Home';
import ProfileSettings from 'containers/ProfileSettings';
import AccountSettings from 'containers/AccountSettings';
import ApiKeySettings from 'containers/ApiKey/ApiKeySettings';

// Private // slate views
import SlateView from 'components/SlateViews/SlateView';
import SlateViewSettings from 'components/SlateViewsSettings/SlateViewSettings';
import SlateViewSearchResult from 'containers/SlateViewSearchResult/SlateViewSearchResult';

// Private // Metrics
// import Metrics from 'components/Metrics/Metrics.container';

// Private // SlatePage
import SlatePage from 'components/SlatePage/SlatePage';

const routes = [
  //Public
  {
    path: route.START,
    layout: layouts.SIGN,
    component: Start,
  },
  {
    path: route.LOGIN,
    layout: layouts.SIGN,
    component: Login,
  },
  {
    path: route.CREATE_PROFILE,
    layout: layouts.SIGN,
    component: CreateProfile,
  },
  {
    path: route.RESET_PASSWORD,
    layout: layouts.SIGN,
    component: ResetPassword,
  },

  // Private
  {
    path: route.ROOT,
    exact: true,
    title: routeTitles.HOME,
    layout: layouts.DASHBOARD,
    component: Home,
  },
  {
    path: route.PROFILE,
    layout: layouts.DASHBOARD,
    title: routeTitles.PROFILE_SETTINGS,
    component: ProfileSettings,
    exact: true,
  },
  {
    path: route.ACCOUNT,
    layout: layouts.DASHBOARD,
    title: routeTitles.ACCOUNT_SETTINGS,
    component: AccountSettings,
    exact: true,
  },
  {
    path: route.API_KEY,
    layout: layouts.DASHBOARD,
    title: routeTitles.API_KEY,
    component: ApiKeySettings,
    exact: true,
  },

  // Private // Slate Views
  {
    innerRoutePaths: [route.SLATE_VIEW],
    path: route.SLATE_VIEW_NEW,
    exact: true,
    title: routeTitles.SLATE_VIEW_NEW,
    layout: layouts.DASHBOARD,
    component: SlateViewSettings,
  },
  {
    path: route.SLATE_VIEW,
    title: routeTitles.SLATE_VIEWS,
    layout: layouts.DASHBOARD,
    component: SlateView,
    isContentFixed: true,
    defaultPathPriority: 2,
  },
  {
    innerRoutePaths: [route.SLATE_VIEW],
    path: route.SLATE_VIEW_SETTINGS,
    exact: true,
    title: routeTitles.SLATE_VIEW_SETTINGS,
    layout: layouts.DASHBOARD,
    component: SlateViewSettings,
  },
  {
    path: route.SLATE_VIEW_SEARCH,
    exact: true,
    title: routeTitles.SLATE_VIEW_SEARCH,
    layout: layouts.DASHBOARD,
    component: SlateViewSearchResult,
    isContentFixed: true,
  },

  // Private // Tools
  {
    path: route.SLATE_GENERATOR,
    title: routeTitles.SLATE_GENERATOR,
    exact: true,
    layout: layouts.DASHBOARD,
    component: SlateGenerator,
  },
  {
    path: route.EXPORT,
    title: routeTitles.EXPORT,
    exact: true,
    layout: layouts.DASHBOARD,
    component: ExportJobs,
  },
  {
    innerRoutePaths: [route.EXPORT],
    path: route.EXPORT_NEW_JOB,
    title: routeTitles.EXPORT_NEW_JOB,
    exact: true,
    layout: layouts.DASHBOARD,
    component: ExportJobSettings,
  },
  {
    path: route.NOTIFICATIONS,
    title: routeTitles.NOTIFICATIONS,
    exact: true,
    layout: layouts.DASHBOARD,
    component: Notifications,
    isContentFixed: true,
    defaultPathPriority: 3,
  },
  {
    innerRoutePaths: [route.NOTIFICATIONS],
    path: route.NOTIFICATIONS_NEW,
    title: routeTitles.NOTIFICATION_NEW,
    exact: true,
    layout: layouts.DASHBOARD,
    component: NotificationsSettings,
  },
  {
    innerRoutePaths: [route.NOTIFICATIONS],
    path: route.NOTIFICATIONS_EDIT,
    exact: true,
    title: routeTitles.NOTIFICATION_EDIT,
    layout: layouts.DASHBOARD,
    component: NotificationsSettings,
  },

  // Private // Metrics
  // {
  //   path: route.METRICS,
  //   title: routeTitles.METRICS,
  //   exact: true,
  //   layout: layouts.DASHBOARD,
  //   component: Metrics,
  //   // noPaddingContainer: true,
  //   // noScrollContainer: true,
  //   isContentFixed: true,
  //   allowBySettingKey: 'showMetricsPage',
  //   defaultPathPriority: 1,
  // },

  // Private // Slate
  {
    path: route.SLATE,
    title: routeTitles.SLATE,
    exact: false,
    layout: layouts.SLATE,
    component: SlatePage,
  },
];

export default routes;
