import React, { Component } from 'react';
import tryMe from '../../../helpers/tryMe';
import AvatarUploader from '../../AvatarUploader/AvatarUploader';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';
import FormTelField from '../FormTelField';
import { avatarShapes } from '../../../config/constants';
import styles from '../styles';

export default class FormUserProfile extends Component {
  UNSAFE_componentWillMount() {
    const { currentUser } = this.props;
    let edit = this.props.type === 'edit';

    this.props.actions.setFormState({
      name: edit ? currentUser.name : '',
      displayName: edit ? currentUser.displayName : '',
      contactInfo: {
        phone: edit ? currentUser.contactInfo.phone : '',
        email: edit ? currentUser.contactInfo.email : '',
      },
      password: {
        password: '',
        confirmPassword: '',
      },
      imageUUID: edit ? currentUser.imageUUID : '',
      attachments: [],
      dataErrors: {},
    });
  }

  showContactInfoError = () =>
    tryMe(this.props.state.dataErrors).contactInfo &&
    !this.props.state.contactInfo.email &&
    !this.props.state.contactInfo.phone;

  render() {
    const { currentUser, actions, state, t, type, errorAction } = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        {currentUser.error && (
          <div style={styles.formError}>{currentUser.error}</div>
        )}

        <AvatarUploader
          onAddAttachment={actions.handleAddAttachment}
          type={type}
          t={t}
          shape={avatarShapes.CIRCLE}
        />

        <div
          style={
            this.showContactInfoError()
              ? Object.assign({}, styles.multiField, styles.multiFieldError)
              : styles.multiField
          }
        >
          <FormTextField
            name="contactInfo.email"
            type="email"
            autocomplete="email"
            hintText={t('userProfile:email')}
            floatingLabelText={t('userProfile:email')}
            fullWidth={true}
            actions={actions}
            state={state}
            value={tryMe(state.contactInfo).email || ''}
          />

          <FormTelField
            name="contactInfo.phone"
            type="tel"
            autocomplete="tel-nation"
            hintText={t('userProfile:phone')}
            floatingLabelText={t('userProfile:phone')}
            fullWidth={true}
            actions={actions}
            state={state}
            value={tryMe(state.contactInfo).phone || ''}
            hint="For non-US numbers, include country code"
          />

          {this.showContactInfoError() && (
            <div style={styles.multiFieldErrorText}>
              {state.dataErrors.contactInfo}
            </div>
          )}
        </div>

        <FormTextField
          name="name"
          type="text"
          autocomplete="name"
          hintText={t('userProfile:name')}
          floatingLabelText={t('userProfile:name')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).name || ''}
        />

        <FormTextField
          name="displayName"
          type="text"
          hintText={t('userProfile:displayName')}
          floatingLabelText={t('userProfile:displayName')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).displayName || ''}
          hint={t('userProfile:displayNameHint')}
        />

        <div style={{ marginTop: 30 }}>
          <FormTextField
            name="password.password"
            type="password"
            autocomplete="new-password"
            hintText={t('userProfile:password')}
            floatingLabelText={t('userProfile:password')}
            fullWidth={true}
            actions={actions}
            state={state}
            value={tryMe(state.password).password || ''}
          />
        </div>

        <FormTextField
          name="password.confirmPassword"
          type="password"
          autocomplete="new-password"
          hintText={t('userProfile:confirmPassword')}
          floatingLabelText={t('userProfile:confirmPassword')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state.password).confirmPassword || ''}
        />

        {errorAction && (
          <div
            className="form-error form-error-action"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            {errorAction}
          </div>
        )}

        <FormSubmit
          title={t('userProfile:createProfile')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />
      </form>
    );
  }
}
