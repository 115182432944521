export const styles = {
  checkboxStyle: {
    padding: 6,
  },
  checkboxIconStyle: {
    fill: 'none',
    margin: 0,
  },
  checkboxInputStyle: {
    top: 0,
  },
};
