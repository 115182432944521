const palette = {
  primary: '#0486dc',
  primaryH: '#0374bf',
  secondary: '#c0c0c0',
  secondaryH: '#949494',
  defaultText: '#414042',
  link: '#f15a29',
  border: '#c0c0c0',
  success: '#03bf98',
  cardTitle: '#c0c0c0',
  cardTitleBorder: '#eeeeee',
  error: '#f15a29',
  errorBg: '#fff3ef',
  fieldHint: '#c0c0c0',
  sidebarContent: '#414042',
  sidebarHeader: '#414042',
  sidebarLink: '#aaaaaa',
  sidebarLinkActive: '#ffffff',
  sidebarAccountBg: '#39383a',
  sidebarAccountImageBg: '#aaaaaa',
  appHeaderBg: '#eeeeee',
  appHeaderBorder: '#e6e6e6',
  notificationSuccessBg: '#03bf98',
  notificationWarningBg: '#f15a29',
  notificationErrorBg: '#e6160d',
  notificationInfoBg: '#414042',
  dialogErrorHeaderBg: '#f15a29',
  breadcrumbLastItem: '#949494',
  avatarDefaultBg: '#e6e6e6',
  avatarDefaultBorder: '#c0c0c0',
  avatarDefaultColor: '#c0c0c0',
  dialogBorder: '#eeeeee',
  addAccountText: '#949494',
  filterIcon: '#696869',
  slateViewActionBg: '#d6d6d6',
  companyLogoBg: '#c0c0c0',
  infoWarningBg: '#fff3cd',
  infoWarningColor: '#856405',

  switchTrue: '#72bb53',
  switchFalse: '#e6160d',

  brandOrange: '#f15a29',
  brandOrangeD: '#d14e23',
  brandBlue: '#0486dc',
  brandBlueD: '#0374bf',
  white: '#ffffff',
  grey200: '#eeeeee',
  grey300: '#c0c0c0',
  grey400: '#949494',
  grey600: '#565556',
  grey800: '#414042',
  slatePaneBg: '#f7f6f6', // #ebebeb

  imagePreviewBg: '#eeeeee',
  imagePreviewBorder: '#cccccc',
  imagePreviewLabel: '#c5c5c5',
};

export default palette;

// brandOrange: links
// brandBlue: buttons

// grey200: backgound
// grey300: placeholder text, borders, form labels, dashboard card title
// grey400: buttons
// grey600: sidebar background
// grey800: default text, sidebar background
