import React from 'react';
import FormWrapper from '../../../containers/Forms/FormWrapper';
import FormVerificationPhoneEmail from '../../../containers/Forms/FormVerificationPhoneEmail';
import { entityNames } from '../../../config/constants';

const Stage1 = (props) => (
  <div>
    <h1>{props.t('resetPassword:verification')}</h1>
    <p style={{ textAlign: 'center', maxWidth: 250, margin: '0px auto' }}>
      {props.t('resetPassword:willSendYouCode')}
    </p>
    <FormWrapper
      entityName={entityNames.SEND_PASSWORD_RESET_CODE}
      entity={['emailOrPhone', 'smsOrCall']}
      onSubmit={props.sendPasswordResetCode}
    >
      <FormVerificationPhoneEmail />
    </FormWrapper>
  </div>
);

export default Stage1;
