import React, {Component} from 'react';
import WizardSteps from '../../components/WizardSteps/WizardSteps';
import VerificationStep1 from './VerificationStep1';
import VerificationStep2 from './VerificationStep2';
import './style.css';
import {entityNames} from "../../config/constants";
import VerificationFooter from "./VerificationFooter";
import {VERIFICATION_DIALOG_TYPE} from "../Forms/FormUserProfile/FormUserProfileDetails";

const ERRORS_BY_TYPE = {
  '2FA': entityNames.VERIFICATION_ENABLE_2FA_CODE,
  'disable2FA': entityNames.VERIFICATION_DISABLE_2FA_CODE,
}

export default class Verification extends Component {
  constructor() {
    super();
    this.state = {
      timer: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.resetWizardStep();
    const {wizardStep} = this.props.app;
    if (wizardStep === 2 && this.props.type === VERIFICATION_DIALOG_TYPE.enable2FA) {
      this.props.fetchReset(entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK);
    } else if (wizardStep === 2 && this.props.type === VERIFICATION_DIALOG_TYPE.disable2FA) {
      this.props.fetchReset(entityNames.VERIFICATION_DISABLE_2FA_CODE_BACK);
    } else {
      this.props.fetchReset(ERRORS_BY_TYPE[this.props.type]);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.entities
        || !prevProps.entities[entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK]
        || !prevProps.entities[entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK].isUpdated)
      && this.props.entities
      && this.props.entities[entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK]
      && this.props.entities[entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK].isUpdated
    ) {
      this.props.onRequestClose();
    }
    if (
      (!prevProps.entities
        || !prevProps.entities[entityNames.VERIFICATION_DISABLE_2FA_CODE_BACK]
        || !prevProps.entities[entityNames.VERIFICATION_DISABLE_2FA_CODE_BACK].isUpdated)
      && this.props.entities
      && this.props.entities[entityNames.VERIFICATION_DISABLE_2FA_CODE_BACK]
      && this.props.entities[entityNames.VERIFICATION_DISABLE_2FA_CODE_BACK].isUpdated
    ) {
      this.props.onRequestClose();
    }

    if (this.props.app.wizardStep === 2 && prevProps.app.wizardStep !== 2) {
      this.startTimer()
    }
  }

  startTimer () {
    this.setState({
      timer: 30
    })
    const interval = setInterval(() => {
      if (this.state.timer - 1 >= 0) {
        this.setState({
          timer: this.state.timer - 1
        })
      } else {
        this.setState({
          timer: null
        })
        clearInterval(interval);
      }
    }, 1000);
  }

  render() {
    const {currentUser, type, t, entities} = this.props;
    const {wizardStep} = this.props.app;

    return (
      <div className="verification-dialog">
        <WizardSteps steps={[1, 2]} currentStep={wizardStep}/>

        {wizardStep === 1 && (
          <VerificationStep1
            t={t}
            type={type}
            user={currentUser}
            errors={entities && entities[ERRORS_BY_TYPE[type]] && entities[ERRORS_BY_TYPE[type]].hasErrors}
            onSendVerificationCode={
              type === VERIFICATION_DIALOG_TYPE.email
                ? this.props.sendEmailVerificationLink
                : type === VERIFICATION_DIALOG_TYPE.enable2FA
                || type === VERIFICATION_DIALOG_TYPE.disable2FA
                ? this.props.send2faVerificationCode
                : this.props.sendPhoneVerificationCode
            }
          />
        )}

        {wizardStep === 2 && (
          <VerificationStep2
            t={t}
            type={type}
            resetErrors={this.props.fetchReset}
            errors={entities && entities[ERRORS_BY_TYPE[type]] && entities[ERRORS_BY_TYPE[type]].hasErrors}
            onVerifyCode={
              type === VERIFICATION_DIALOG_TYPE.enable2FA
              || type === VERIFICATION_DIALOG_TYPE.disable2FA
                ? this.props.verify2faCode
                : this.props.verifyPhoneEmailCode
            }
            setVerificationMethod={this.props.setVerificationMethod}
          />
        )}

        <VerificationFooter
          wizardStep={wizardStep}
          type={type}
          t={t}
          incrementWizardStep={this.props.incrementWizardStep}
          decrementWizardStep={this.props.decrementWizardStep}
          timer={this.state.timer}
          onResend2faCode={() => {
            this.startTimer()
            this.props.send2faVerificationCode(
              type === VERIFICATION_DIALOG_TYPE.enable2FA
                ? entityNames.VERIFICATION_ENABLE_2FA_CODE
                : entityNames.VERIFICATION_DISABLE_2FA_CODE,
              type === VERIFICATION_DIALOG_TYPE.disable2FA,
            )
          }}
        />
      </div>
    );
  }
}
