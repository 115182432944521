import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import RaisedButton from 'material-ui/RaisedButton';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import share from './share.svg';

import {
  fetchSlateViewsList,
  setCurrentSlateView,
  fetchSlates,
  clearSlates,
  updateSlatePane,
  fetchSlateView,
  fetchCurrentSlate,
} from 'store/actions/slateViews';
import { createJob } from 'store/actions/jobs';
import {api, entityNames, notificationTypes, route} from 'config/constants';
import BasicDialog from 'components/Dialogs/BasicDialog';
import SlateViewsSelector from '../SlateViews/SlateViewsSelector';
import SlateTable from 'containers/SlateTable/SlateTable';
import CreateViewButton from 'components/SlateViews/CreateViewButton';
import MapView from 'containers/Map/MapView';
import Preloader from '../Preloader/Preloader';
import { slateViewsTypes, fieldsTypes } from 'config/constants';
import SlatePane from 'components/SlatePane/SlatePane';
import SlateViewChip from 'components/Shared/SlateViewChip';
import { presetNames } from 'config/constants';
import {checkVersion, handleVersion} from 'helpers/versionsHelper';
import './style.css';
import theme from './theme';
import copy from 'copy-to-clipboard';
import { showNotification } from 'store/actions/notification';
import i18next from "i18next";
import {getAccountByUUID, isLoggedOut} from "../../helpers/accountHelper";
import {getAccount, setCurrentAccountByUUID} from "../../store/actions/account";
import axios from "axios";
import {options} from "../../helpers/cookieHelper";
import TextInput from "./TextInput";
import {CopyTableButton} from "./CopyTable";
import {Tooltip} from "@material-ui/core";
import Checkbox from "material-ui/Checkbox";

const ARROW_UP = 'ArrowUp';
const ARROW_DOWN = 'ArrowDown';
const THROTTLE_TIME = 250;

class SlateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenSlateViewsSelector: false,
      isOpenSettingsPopover: false,
      isOpenExportDialog: false,
      isOpenShareDialog: false,
      isOpenLoginDialog: false,
      isSlateViewExpanded: false,

      isViewListFakeLoading: false,
      autoRefreshTableTimer: null,
    };

    this.active = null;
    this.isListView = false;
    this.isMapView = false;
    this.isMapSlatesFetched = false;

    this.delayedSlatePaneOpen = throttle(this.openSlatePane, THROTTLE_TIME);
  }

  static defaultProps = {
    filters: [],
  };

  UNSAFE_componentWillMount() {
    checkVersion();
  }

  setAutoRefreshTimer() {
    this.setState({
      autoRefreshTableTimer: setInterval(this.handleRefreshClick, 30000),
    });
  }

  removeAutoRefreshTimer() {
    clearTimeout(this.state.autoRefreshTableTimer);
    this.setState({
      autoRefreshTableTimer: null,
    });
  }

  componentDidMount() {
    const {
      currentSlateView,
      fetchSlateViewsList,
      setCurrentSlateView,
      slateViewType,
      currentUser,
      getAccount,
      currentAccount,
      listViews,
      mapViews,
      history,
      showNotification,
      t,
      setCurrentAccountByUUID,
      match,
    } = this.props;

    const { views: slateViewUuid, accounts: accountUuid } = this.props.match.params;

    // if link incorrect - redirect to login
    if((!slateViewUuid || !accountUuid) && !!isLoggedOut(currentUser)) {
      history.push({
        pathname: `${route.LOGIN}`,
      });
      showNotification(
        notificationTypes.WARNING,
        t('notifications:linkIsNotCorrect'),
      );
    }

    const isAccountUuidFromUrlFake = accountUuid === ':accounts';
    const isAccountUuidFromUrlMatchWithCurrent = currentAccount && (currentAccount.uuid !== accountUuid);

    // if no AccountUuid in url - we should add it
    if(isAccountUuidFromUrlFake && currentAccount) {
      this.addAccountUuidToURL(currentAccount.uuid);
      if(!isLoggedOut(currentUser)) {
        fetchSlateViewsList(currentAccount.uuid);
      }
      return;
    } else if (isAccountUuidFromUrlMatchWithCurrent && !isAccountUuidFromUrlFake) {
      this.addAccountUuidToURL(accountUuid);
      setCurrentAccountByUUID(accountUuid);
      return;
    } if (isAccountUuidFromUrlFake) {
      return;
    }

    // set View type if user login
    if(slateViewType) {
      this.checkSlateViewType(slateViewType);
    }

    // get account info (and SlateViewsList if user login)
    if (accountUuid) {
      getAccount(accountUuid);

      if(!isLoggedOut(currentUser)) {
        fetchSlateViewsList(accountUuid);
      }
    }


    // set CurrentSlateView and fetch slates
    if (slateViewUuid) {
      const isViewListEmpty = !listViews && !mapViews;

        if (
          isViewListEmpty &&
          !this.state.isViewListFakeLoading
        ) {
          this.handleFetchView(accountUuid, slateViewUuid).then(() => {});
        }

        setCurrentSlateView(slateViewUuid);
        this.handleFetchSlates(
        {
          ...this.props,
          currentSlateView: {uuid: slateViewUuid},
          _accountUUID: accountUuid,
        }, true, true);
    } else if (currentSlateView && slateViewType && accountUuid === currentAccount.uuid) {
      this.handleFetchSlates(this.props, true);
      // this.addSlateViewUUIDInURL(currentSlateView.uuid, accountUuid);
    }

    document.addEventListener('scroll', this._handleScrollEvent, true);

    const { accounts: accountUuidFromUrl } = match.params;

    if(!currentAccount && !currentSlateView) {
      setCurrentAccountByUUID(accountUuidFromUrl);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      slateViewType,
      currentSlateView,
      currentUser,
      listViews,
      mapViews,
      fetchSlateViewsList,
      currentAccount,
      slateViewsListGetEntity,
    } = this.props;
    const { currentAccount: nextPropsCurrentAccount, currentUser: nextPropsCurrentUser } = nextProps;
    const { views: slateViewUuidFromUrl, accounts: accountUuidFromUrl } = nextProps.match.params;
    const isAccountUuidFromUrlFake = accountUuidFromUrl === ':accounts';

    if(
      !slateViewUuidFromUrl &&
      currentAccount &&
      currentAccount.uuid &&
      !slateViewsListGetEntity &&
      !isLoggedOut(currentUser)
    ) {
      fetchSlateViewsList(currentAccount.uuid);
      return;
    }

    this.checkSlateViewType(nextProps.slateViewType);

    if(isAccountUuidFromUrlFake && nextPropsCurrentAccount) {
      this.addAccountUuidToURL(nextPropsCurrentAccount.uuid);
      fetchSlateViewsList(nextPropsCurrentAccount.uuid);
      return;
    } else if (
      nextPropsCurrentAccount &&
      (nextPropsCurrentAccount.uuid !== accountUuidFromUrl) &&
      !isAccountUuidFromUrlFake
    ) {
      return;
    } if (isAccountUuidFromUrlFake) {
      return;
    }
    if((nextPropsCurrentAccount && currentAccount &&
      (nextPropsCurrentAccount.uuid !== currentAccount.uuid))
      || isLoggedOut(currentUser) && !isLoggedOut(nextPropsCurrentUser)
    ) {
      fetchSlateViewsList(accountUuidFromUrl);
      return;
    }

    if (!slateViewUuidFromUrl && currentSlateView && nextPropsCurrentAccount &&
      (nextPropsCurrentAccount.uuid === accountUuidFromUrl)) {
      this.addSlateViewUUIDInURL(currentSlateView.uuid, accountUuidFromUrl);
      return;
    }

    if (nextProps.currentSlateView && nextPropsCurrentAccount) {
      if ((!slateViewType && nextProps.slateViewType) ||
        ((currentSlateView &&
          currentSlateView.uuid) !== nextProps.currentSlateView.uuid))
      {
        if (
          !listViews &&
          !mapViews &&
          isLoggedOut(currentUser) &&
          !this.state.isViewListFakeLoading
        ) {
          this.handleFetchView(nextPropsCurrentAccount.uuid, nextProps.currentSlateView.uuid).then(() => {});
        }

        this.handleFetchSlates(
          nextProps,
          true,
          currentSlateView && currentSlateView.uuid !== nextProps.currentSlateView.uuid,
          );
      }

      // fetch all slates for map view
      if (this.isMapView) {
        if (!isEqual(nextProps.slates, this.props.slates)) {
          if (nextProps.slatesToken) {
            this.handleFetchSlates(nextProps);
          } else {
            this.isMapSlatesFetched = true;
          }
        } else if (
          isEqual(nextProps.slates, this.props.slates)
          && !nextProps.slatesToken
          && this.props.slates
        ){
          this.isMapSlatesFetched = true;
        }

        if (!nextProps.slates) {
          this.isMapSlatesFetched = false;
        }
      }

      if (!nextProps.slates) {
        this.isMapSlatesFetched = false;
      }
    }

    const isSlatesAlreadyGot = nextProps.slatesGetEntity &&
      nextProps.slatesGetEntity.isFetching;

    if (!isSlatesAlreadyGot) {
      if (!this.props.autoRefresh && nextProps.autoRefresh && this.state.autoRefreshTableTimer === null) {
        this.setAutoRefreshTimer();
      } else if (this.props.autoRefresh && !nextProps.autoRefresh && this.state.autoRefreshTableTimer !== null) {
        this.removeAutoRefreshTimer();
      }
    }
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    !(isEqual(nextProps, this.props) && isEqual(nextState, this.state));

  componentWillUnmount = () => {
    this.props.clearSlates();
    document.removeEventListener('scroll', this._handleScrollEvent, true);
    this.removeAutoRefreshTimer();
  };

  render() {
    const {
      isOpenSlateViewsSelector,
      isOpenExportDialog,
      isOpenShareDialog,
      isSlateViewExpanded,
    } = this.state;

    const {
      currentSlateView,
      slates,
      rowCount,
      listViews,
      mapViews,
      filters,
      t,
      slateInSlatePaneUUID,
      isPaneOpen,
      isPaneHidden,
      isPaneDocked,
      currentUser,
      accountsList,
      currentAccount,
    } = this.props;

    const isSlateViewExist = this.checkSlateViewExist();

    const currentSlateIndex = (slates || [])
      .map((slate) => slate.slateUUID)
      .indexOf(slateInSlatePaneUUID);
    const currentSlate = currentSlateIndex && slates ? slates[currentSlateIndex] : null;
    const prevSlateIndex = currentSlateIndex - 1;
    const nextSlateIndex = currentSlateIndex + 1;
    const isPrevExist = currentSlateIndex >= 0 && prevSlateIndex >= 0;
    const isNextExist =
      currentSlateIndex >= 0 && nextSlateIndex < slates.length;

    const rowHeightPreset =
      currentSlateView &&
      currentSlateView.columns &&
      currentSlateView.columns.find(
        (column) => column.type === fieldsTypes.PHOTO,
      )
        ? presetNames.mediumIfPhoto
        : null;

    const shareUIUrl = window.location.href
      .replace('/accounts/', '\n/accounts/')
      .replace('/views/', '\n/views/');

    const shareUrl = window.location.href;
    const { views: slateViewUuidFromUrl, accounts: accountUuidFromUrl } = this.props.match.params;

    let existingAccountUUID = false;

    if(currentAccount && accountsList) {
      existingAccountUUID = !!getAccountByUUID(currentAccount.uuid, accountsList);
    }

    return (
      <div
        className="inner-page slate-view"
        tabIndex="0"
        onKeyDown={(e) =>
          this.handleKeyDown(
            e,
            slates,
            isPrevExist,
            isNextExist,
            prevSlateIndex,
            nextSlateIndex,
          )
        }
      >
        {isSlateViewExist ? (
          <>
            {this.showPreloader()}
            {(this.isListView || this.isMapView) && (
              <div className="inner-page-header">
                <div className="inner-page-title">
                      {
                        isLoggedOut(currentUser) || !existingAccountUUID ?
                          <div style={{
                            ...theme.fakeSlateViewSelector,
                            ...(this.isListView
                            ? theme.slateViewSelectorButtonList
                            : theme.slateViewSelectorButtonMap)
                          }}>
                            <span style={{...theme.labelForSelector}}>
                              {currentSlateView.name}
                            </span>
                          </div> :
                          <RaisedButton
                            label={currentSlateView.name}
                            labelPosition="before"
                            primary={true}
                            onClick={this.handleSlateViewsSelectorOpen}
                            icon={
                              <FontAwesome
                                name={
                                  isOpenSlateViewsSelector
                                    ? 'chevron-up'
                                    : 'chevron-down'
                                }
                                style={theme.slateViewSelectorIcon}
                              />
                            }
                            style={theme.slateViewSelector}
                            buttonStyle={
                              this.isListView
                                ? theme.slateViewSelectorButtonList
                                : theme.slateViewSelectorButtonMap
                            }
                            labelStyle={{...theme.slateViewSelectorLabel, ...theme.labelForSelector}}
                          />
                      }
                      {currentSlateView.listViewType && (
                        <div className="slate-view-type">
                          <SlateViewChip type={currentSlateView.listViewType} />
                        </div>
                      )}

                      <div
                        className="slate-view-counter"
                        style={theme.slateViewCounter}
                      >
                        {currentSlateView.count}
                      </div>

                      {filters.length > 0 && slates && (
                        <div
                          className="slate-view-counter"
                          style={theme.slateViewCounterFiltered}
                        >
                          <FontAwesome name="filter" />
                          &nbsp;
                          {rowCount}
                        </div>
                      )}
                    </div>

                <div className="slate-view-actions">
                  <Checkbox
                    iconStyle={{fill: this.state.autoRefreshTableTimer !== null && !this.props.slatesGetEntity.isFetching ? 'rgb(241, 90, 41)' : null}}
                    className={classnames(`slate-view-refresh-checkbox`, {
                      'settings-refresh-checkbox-animation': this.state.autoRefreshTableTimer !== null && !this.props.slatesGetEntity.isFetching,
                    })}
                    label={t('settings:autoRefreshTable')}
                    checked={this.state.autoRefreshTableTimer !== null}
                    onCheck={(e, checked) => {
                      if (checked) {
                        if (!isLoggedOut(currentUser)) {
                          this.props.showNotification(
                            notificationTypes.SUCCESS,
                            t('slateView:autoRefresh'),
                          );
                        }
                        this.setAutoRefreshTimer();
                      } else {
                        this.removeAutoRefreshTimer();
                      }
                    }}
                  />
                  <Tooltip title={t('tooltips:refreshView')} placement={'top'}>
                    <RaisedButton
                      className="slate-view-actions-refresh"
                      icon={<FontAwesome name="refresh"
                         style={theme.slateViewActionIconStyle}
                      />}
                      style={theme.slateViewActionStyle}
                      onClick={this.handleRefreshClick}
                    />
                  </Tooltip>
                  {
                    !isLoggedOut(currentUser) &&
                    <Tooltip title={t('tooltips:viewConfiguration')} placement={'top'}>
                      <Link to={`/settings/accounts/${accountUuidFromUrl}/views/${slateViewUuidFromUrl}/`}>
                        <RaisedButton
                          icon={<FontAwesome name="gear"
                            style={theme.slateViewActionIconStyle}
                          />}
                          style={theme.slateViewActionStyle}
                          buttonStyle={theme.slateViewActionButtonSettings}
                          overlayStyle={theme.slateViewActionButtonSettings}
                        />
                      </Link>
                    </Tooltip>
                  }

                  <div className="slate-view-actions-divider" />

                  <Tooltip title={t('tooltips:sharableViewLink')} placement={'top'}>
                    <RaisedButton
                      className="slate-view-actions-refresh"
                      icon={
                        <img src={share} width="14" height="14" alt={'share_icon'} />
                      }
                      style={theme.slateViewActionStyle}
                      buttonStyle={theme.slateViewActionButtonExport}
                      overlayStyle={theme.slateViewBorderRadius}
                      onClick={this.handleShareDialogOpen}
                    />
                  </Tooltip>

                  {!isLoggedOut(currentUser) && this.isListView && (
                    <Tooltip title={t('tooltips:export')} placement={'top'}>
                      <RaisedButton
                        icon={<FontAwesome name="download"
                          style={theme.slateViewActionIconStyle}
                        />}
                        style={theme.slateViewActionStyle}
                        onClick={this.handleExportDialogOpen}
                      />
                    </Tooltip>
                  )}
                  {
                    !this.isMapView && slates && slates.length !== 0 &&
                    <CopyTableButton
                      slates={slates}
                      currentSlateView={currentSlateView}
                      showNotification={this.props.showNotification}
                      className="slate-view-actions-copy-table"
                      rowCount={rowCount}
                      fetchMoreSlates={() => this.handleFetchSlates(this.props)}
                    />
                  }
                </div>
              </div>
            )}

            <div className="slate-view-container">
              {/* this handle expanded or not */}
              <div
                className={classnames({
                  'slate-view-inner': true,
                  'slate-view-inner-expanded': isSlateViewExpanded,
                })}
              >
                {/* this handle position near slate pane */}
                <div
                  className={classnames({
                    'slate-view-data': true,
                    'slate-view-data-expanded': isSlateViewExpanded,
                    'slate-view-data-with-pane': isPaneOpen || isPaneHidden,
                    'slate-view-data-docked':
                      slateInSlatePaneUUID && isPaneDocked,
                  })}
                >
                  {this.isListView && (
                    <SlateTable
                      onRowClickParentHandler={(e, uuid) =>
                        this.openSlatePane(uuid)
                      }
                      slateViewUUID={currentSlateView.uuid}
                      slates={slates}
                      columns={currentSlateView.columns}
                      rowCount={rowCount}
                      isExpanded={isSlateViewExpanded}
                      onExpand={this.handleExpandSlateView}
                      onCompress={this.handleCompressSlateView}
                      rowHeightPreset={rowHeightPreset}
                    />
                  )}

                  {this.isMapView && this.isMapSlatesFetched && (
                    <MapView
                      slates={slates}
                      onMarkerClick={(e, uuid) => this.openSlatePane(uuid)}
                      isSlateViewExpanded={isSlateViewExpanded}
                      onExpandSlateView={this.handleExpandSlateView}
                      onCompressSlateView={this.handleCompressSlateView}
                    />
                  )}
                </div>

                {isPaneOpen && !isPaneDocked && (
                  <div
                    className="slate-pane-overlay"
                    onClick={this.handleHideSlatePane}
                  />
                )}

                <SlatePane
                  slateUUID={slateInSlatePaneUUID}
                  imageUrl={currentSlate && currentSlate.imageUrl}
                  isOpen={isPaneOpen}
                  isHidden={isPaneHidden}
                  isDocked={slateInSlatePaneUUID && isPaneDocked}
                  onShow={this.handleShowSlatePane}
                  onHide={this.handleCloseSlatePane}
                  onDock={this.toggleDockSlatePane}
                  onPrev={
                    isPrevExist
                      ? () =>
                          this.openSlatePane(slates[prevSlateIndex].slateUUID)
                      : undefined
                  }
                  onNext={
                    isNextExist
                      ? () =>
                          this.openSlatePane(slates[nextSlateIndex].slateUUID)
                      : undefined
                  }
                />
              </div>
            </div>

            <BasicDialog
              title={t('settings:export')}
              open={isOpenExportDialog}
              onClose={this.handleExportDialogClose}
              actions={[
                <RaisedButton
                  key="cancel"
                  label={t('btn:cancel')}
                  className="slate-view-settings-dialog-btn"
                  onClick={this.handleExportDialogClose}
                />,
                <RaisedButton
                  key="export"
                  disabled={!currentSlateView}
                  label={t('settings:export')}
                  primary={true}
                  onClick={this.handleExportSlateView}
                />,
              ]}
            >
              <div className="slate-view-settings-dialog">
                {t('settings:configureAnExportJob')}
              </div>
            </BasicDialog>

            <BasicDialog
              title={t('settings:share')}
              open={isOpenShareDialog}
              onClose={this.handleShareDialogClose}
              actions={[
                <RaisedButton
                  key="cancel"
                  label={t('btn:close')}
                  className="slate-view-settings-dialog-btn"
                  onClick={this.handleShareDialogClose}
                />,
              ]}
            >
              <div className="slate-view-share-dialog">
                {t('slateView:publicView')}
              </div>
              <div style={theme.shareUrlButtonContainer}>
                <div className="slate-view-share-dialog-url">
                  <TextInput shareUrl={shareUIUrl} multiline={true}/>
                </div>
                <RaisedButton
                  icon={<FontAwesome name="copy" />}
                  style={theme.shareUrlButton}
                  overlayStyle={theme.slateViewActionButtonSettings}
                  onClick={() => this.copyTextToClipboard(shareUrl)}
                />
              </div>
            </BasicDialog>

            {listViews && mapViews && (
              <BasicDialog
                title="Slate Views"
                open={isOpenSlateViewsSelector}
                onClose={this.handleSlateViewsSelectorClose}
                style={theme.slateViewSelectorDialog}
                maxWidth="md"
              >
                <SlateViewsSelector
                  listViews={listViews}
                  mapViews={mapViews}
                  onSlateViewClick={this.handleClickSlateView}
                />
              </BasicDialog>
            )}
          </>
        ) : (
          <div>
            <div className="slate-view-empty">{t('slate:noSlateViews')}</div>
            <div className="slate-view-empty-create-button">
              {
                isLoggedOut(currentUser) ?
                  <div>Log Out</div> :
                  <CreateViewButton />
              }
            </div>
          </div>
        )}
      </div>
    );
  }

  checkSlateViewType = (slateViewType) => {
    this.isListView = slateViewType === slateViewsTypes.LIST;
    this.isMapView = slateViewType === slateViewsTypes.MAP;
  };

  handleFetchSlates = (
    { sortDirection, sortColumnIndex, filters, currentSlateView, _accountUUID, fetchSlates },
    isNewSet = false,
    isChangedSlateView = false,
  ) => {
    const isSlatesAlreadyFetching = this.props.slatesGetEntity &&
      this.props.slatesGetEntity.isFetching;
    if( isSlatesAlreadyFetching ) {
      return;
    }

    let sort = '';
    if (sortDirection) {
      sort = `${sortColumnIndex},${sortDirection}`;
    }
    fetchSlates(currentSlateView.uuid, {
      isNewSet,
      filters,
      sort,
      isChangedSlateView,
      _accountUUID
    });
  };

  handleFetchView = async (currentAccountUuid, currentSlateViewUuid) => {
    this.setState({
      isViewListFakeLoading: true,
    });

   axios
    .get(`${api.GET_SLATE_VIEWS_LIST}/${currentAccountUuid}/views/${currentSlateViewUuid}/info`, options)
    .then((res) => {
      handleVersion(res);
      const views = res.data.data;

      this.props.fetchSlateView({
        newSlateView: res.data.data,
      })

      if (views) {
        this.checkSlateViewType(slateViewsTypes.LIST);
      } else {
        this.checkSlateViewType(slateViewsTypes.MAP);
      }
      this.setState({
        isViewListFakeLoading: false,
      });
    });
  }

  handleAccountChanges = (nextProps) => {
    const { currentAccount, fetchSlateViewsList, currentUser } = this.props;

    if (nextProps.currentAccount) {
      if (
        currentAccount &&
        currentAccount.uuid !== nextProps.currentAccount.uuid
        && !isLoggedOut(currentUser)
      ) {
        fetchSlateViewsList(nextProps.currentAccount.uuid);
      }
      if (!currentAccount && !isLoggedOut(currentUser)) {
        fetchSlateViewsList(nextProps.currentAccount.uuid);
      }
    }
  };

  addAccountUuidToURL = (accountUuid) => {
    this.props.history.replace({
      pathname: `/accounts/${accountUuid}/views`,
    });
  };

  addSlateViewUUIDInURL = (currentSlateViewUuid, accountUuid) => {
    if (this.props.match.params.views !== currentSlateViewUuid) {
      this.props.history.replace({
        pathname: `/accounts/${accountUuid}/views/${currentSlateViewUuid}`,
      });
    }
  };

  checkSlateViewExist = () => {
    const { slateViewsListGetEntity, currentSlateView } = this.props;
    return !(
      slateViewsListGetEntity &&
      !slateViewsListGetEntity.isFetching &&
      !currentSlateView
    );
  };

  handleRefreshClick = () => {
    const {
      fetchSlateViewsList,
      currentAccount,
      currentUser,
      fetchCurrentSlate,
      slateInSlatePaneUUID,
    } = this.props;
    this.handleFetchSlates(this.props, true);

    if (!isLoggedOut(currentUser)) {
      fetchSlateViewsList(currentAccount.uuid);
    }

    if(slateInSlatePaneUUID) {
      fetchCurrentSlate(slateInSlatePaneUUID)
    }
  };

  // TABLE SIZE HANDLING

  handleExpandSlateView = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        isSlateViewExpanded: true,
      },
      () => {
        const bodyGrid = document.querySelector('.body-grid');
        if (bodyGrid) {
          bodyGrid.focus();
        }
      },
    );
  };

  handleCompressSlateView = () => {
    this.setState({
      isSlateViewExpanded: false,
    });
  };

  // SCROLL HANDLING

  _handleScrollEvent = (e) => {
    let elem = e.target.classList;
    if (elem && elem.contains('body-grid') && this.active !== 'bodyGrid') {
      this.active = 'bodyGrid';
      e.target.focus();
    } else if (
      elem &&
      elem.contains('slate-pane-inner') &&
      this.active !== 'slatePane'
    ) {
      this.active = 'slatePane';
      e.target.focus();
    }
  };

  handleShareDialogOpen = () => {
    this.setState({ isOpenShareDialog: true });
  };

  handleShareDialogClose = () => {
    this.setState({ isOpenShareDialog: false });
  };

  copyTextToClipboard = (text) => {
    const {
      showNotification,
    } = this.props;

    copy(text);
    showNotification(
      notificationTypes.SUCCESS,
      `${i18next.t('notifications:copyToClipboardSuccessfully')}`,
    );
  }

  handleExportDialogOpen = () => {
    this.setState({ isOpenExportDialog: true });
  };

  handleExportDialogClose = () => {
    this.setState({ isOpenExportDialog: false });
  };

  handleSlateViewsSelectorOpen = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ isOpenSlateViewsSelector: true });
  };

  handleSlateViewsSelectorClose = () => {
    this.setState({ isOpenSlateViewsSelector: false });
  };

  handleClickSlateView = (uuid) => {
    checkVersion();
    const { currentSlateView, setCurrentSlateView } = this.props;
    const { accounts: accountUuid } = this.props.match.params;
    this.setState({ isOpenSlateViewsSelector: false });

    if (currentSlateView.uuid !== uuid) {
      setCurrentSlateView(uuid);
      this.addSlateViewUUIDInURL(uuid, accountUuid);
    }
  };

  showPreloader = () => {
    const {
      slateViewsListGetEntity,
      slatesGetEntity,
      createJobEntity,
      slates,
      currentSlateView,
    } = this.props;

    // slate views list is fetching
    if (slateViewsListGetEntity && slateViewsListGetEntity.isFetching) {
      return <Preloader />;
    }

    // SlateView isn't chosen in slateViewList
    if (!currentSlateView || !currentSlateView.name) {
      return <Preloader />;
    }

    // slates are fetching for the first time
    if (
      this.isListView &&
      !slates &&
      slatesGetEntity &&
      slatesGetEntity.isFetching
    ) {
      return <Preloader />;
    }

    // slates are fetching for map view
    if (this.isMapView && !this.isMapSlatesFetched) {
      return <Preloader />;
    }

    // export slate view settings
    if (createJobEntity && createJobEntity.isFetching) {
      return <Preloader />;
    }
  };

  // SLATE PANE ACTIONS
   openSlatePane = (slateUUID) => {
    if (slateUUID) {
      if (slateUUID === this.props.slateInSlatePaneUUID) {
        const frame_id = 'flutter_widget';
        const link = window.document.getElementById(frame_id).src;
        window.document.getElementById(frame_id).src = link + `&forceUpdateFromList=${Date.now().toString()}`;
      }

      let updatedAttributes = {
        isPaneHidden: false,
        slateInSlatePaneUUID: slateUUID,
      };
      if (!this.props.isPaneDocked) {
        updatedAttributes.isPaneOpen = true;
      }
      this.props.updateSlatePane(updatedAttributes);
      if (!this.props.isPaneDocked) {
        document.getElementById('pages').classList.add('slate-pane-open');
      }
    }
  };

  handleKeyDown = (
    e,
    slates,
    isPrevExist,
    isNextExist,
    prevSlateIndex,
    nextSlateIndex,
  ) => {
    switch (e.key) {
      case ARROW_UP: {
        if (isPrevExist) {
          if (e) {
            e.preventDefault();
          }
          this.delayedSlatePaneOpen(slates[prevSlateIndex].slateUUID);
        }
        break;
      }
      case ARROW_DOWN: {
        if (isNextExist) {
          if (e) {
            e.preventDefault();
          }
          this.delayedSlatePaneOpen(slates[nextSlateIndex].slateUUID);
        }
        break;
      }
      default:
        break;
    }
  };

  handleShowSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: true,
      isPaneHidden: false,
    });
    if (!this.props.isPaneDocked) {
      document.getElementById('pages').classList.add('slate-pane-open');
    }
  };

  handleHideSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: false,
      isPaneHidden: true,
    });
    document.getElementById('pages').classList.remove('slate-pane-open');
  };

  handleCloseSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: false,
    });
    document.getElementById('pages').classList.remove('slate-pane-open');
  };

  toggleDockSlatePane = () => {
    this.props.updateSlatePane({
      isPaneOpen: !this.props.isPaneOpen,
      isPaneHidden: this.props.isPaneHidden,
      isPaneDocked: !this.props.isPaneDocked,
    });
    document.getElementById('pages').classList.remove('slate-pane-open');
  };

  handleExportSlateView = () => {
    this.handleExportDialogClose();
    const { currentSlateView, createJob } = this.props;
    createJob(currentSlateView.uuid);
  };
}

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  currentSlateView: state.slateViews.currentSlateView,
  slateViewType: state.slateViews.slateViewType,
  slates: state.slateViews.slates,
  autoRefresh: state.slateViews.autoRefresh,
  listViews: state.slateViews.listViews,
  mapViews: state.slateViews.mapViews,
  slatesToken: state.slateViews.slatesToken,
  filters: state.slateViews.filters,
  sortDirection: state.slateViews.sortDirection,
  sortColumnIndex: state.slateViews.sortColumnIndex,
  rowCount: state.slateViews.rowCount,
  slateViewsListGetEntity: state.entities[entityNames.GET_SLATE_VIEWS_LIST],
  slatesGetEntity: state.entities[entityNames.GET_SLATES],
  createJobEntity: state.entities[entityNames.CREATE_JOB],
  currentUser: state.currentUser,
  isPaneOpen: state.slateViews.isPaneOpen,
  isPaneHidden: state.slateViews.isPaneHidden,
  isPaneDocked: state.slateViews.isPaneDocked,
  slateInSlatePaneUUID: state.slateViews.slateInSlatePaneUUID,
  accountsList: state.accounts.accountsList,
});

const mapDispatchToProps = {
  fetchSlateViewsList,
  setCurrentSlateView,
  fetchSlates,
  fetchSlateView,
  clearSlates,
  updateSlatePane,
  createJob,
  showNotification,
  setCurrentAccountByUUID,
  getAccount,
  fetchCurrentSlate,
  getAccountByUUID,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SlateView)),
);
