import React from 'react';
import Checkbox from 'material-ui/Checkbox';

import Expander from '../Shared/Expander/Expander';
import { styles } from './styles';

const MapViewHeader = ({
  isSlateViewExpanded,
  onExpandSlateView,
  onCompressSlateView,
  ungroupMapPins,
  onGroupPinChange,
}) => {
  return (
    <div className="map-view-header" style={styles.header}>
      <div className="map-view-header-checkbox">
        <Checkbox
          label="Show Slates Grouped"
          checked={!ungroupMapPins}
          onCheck={onGroupPinChange}
          labelStyle={styles.checkboxLabel}
          iconStyle={styles.checkboxIcon}
        />
      </div>

      <Expander
        isExpanded={isSlateViewExpanded}
        onExpand={onExpandSlateView}
        onCompress={onCompressSlateView}
      />
    </div>
  );
};

export default MapViewHeader;
