import axios from 'axios';
import {push} from 'connected-react-router';
import Notifications from 'react-notification-system-redux';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import * as type from './types';
import {route, api} from 'config/constants';
import {
  cookiesTypes,
  removeCookies,
  setCookies,
  options,
} from 'helpers/cookieHelper';
import {getCurrentAccount, getLocalAccountName} from 'helpers/accountHelper';
import {handleVersion} from 'helpers/versionsHelper';

export function sendPasswordResetCode(data, entityName, stage, smsOrCall) {
  return (dispatch) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });
    removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);

    return axios
      .post(api.SEND_PASSWORD_RESET_CODE, data)
      .then((res) => {
        handleVersion(res);
        setCookies(cookiesTypes.RESET_PASSWORD_COOKIE, {
          user: data.emailOrPhone,
          codeSentVia: res.data.data.codeSentVia,
        });

        if (!stage) {
          dispatch({
            type: type.INCREMENT_WIZARD_STEP,
            steps: 3,
          });
        }
        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });
        if (smsOrCall === 'sms' || res.data.data.codeSentVia === 'sms') {
          dispatch(
            Notifications.success({
              message: i18next.t('notifications:resetPasswordSentSMS'),
              position: 'tr',
              autoDismiss: 5,
            }),
          );
        } else if (smsOrCall === 'call') {
          dispatch(
            Notifications.success({
              message: i18next.t('notifications:resetPasswordSentCall'),
              position: 'tr',
              autoDismiss: 5,
            }),
          );
        } else {
          dispatch(
            Notifications.success({
              message: i18next.t('notifications:resetPasswordSentEmail'),
              position: 'tr',
              autoDismiss: 5,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;
            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export function verifyPasswordResetCode(data, entityName) {
  return (dispatch) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });
    let verifyOptions = cloneDeep(options);
    verifyOptions.data = data;

    return axios
      .delete(api.SEND_PASSWORD_RESET_CODE, verifyOptions)
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.INCREMENT_WIZARD_STEP,
          steps: 3,
        });
        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              if (err.response.data.errorCode === 202) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: {
                      code: err.response.data.errorDebugDescription,
                    },
                  },
                });
              }
              break;
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;
            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export const handleSuccessResetPassword = (res, dispatch, entityName) => {
  handleVersion(res);
  const {data} = res.data;
  const {user, accounts} = data;
  const localAccountName = getLocalAccountName(user.uuid, accounts);
  const currentAccount = getCurrentAccount(user.uuid, accounts);

  dispatch({
    type: type.SET_USER,
    payload: {
      user,
    },
  });

  dispatch({
    type: type.SET_ACCOUNTS,
    payload: {
      userUUID: user.uuid,
      accounts,
      currentAccount,
    },
  });

  dispatch({
    type: type.FETCH_SUCCESS,
    entityName: entityName,
  });

  dispatch({
    type: type.RESET_WIZARD_STEP,
  });

  dispatch(push(route.ROOT));

  dispatch(
    Notifications.success({
      message: i18next.t('notifications:successfullyUpdateProfile'),
      position: 'tr',
      autoDismiss: 5,
    }),
  );

  if (localAccountName) {
    dispatch(
      Notifications.warning({
        message: `${i18next.t(
          'notifications:noLongerAccess',
        )} ${localAccountName}. ${i18next.t(
          'notifications:youHaveBeenSwitched',
        )} ${currentAccount.name}.`,
        position: 'tr',
        autoDismiss: 3,
      }),
    );
  }

  removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);
}

export function resetPassword(data, entityName) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });
    return axios
      .post(api.RESET_PASSWORD, data, options)
      .then((res) => {
        if (res.status === 202) {
          dispatch({
            type: type.FETCH_STOP,
            entityName: entityName,
          });
          dispatch({
            type: type.SET_LOGIN_2FA_CODE_DATA,
            payload: {
              isOpen: true,
              parentEntityName: entityName
            }
          });
          if (!getState().currentUser.isOpenLoginDialog) {
            dispatch(push(route.LOGIN));
          }
        } else {
          handleSuccessResetPassword(res, dispatch, entityName);
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              if (err.response && err.response.data && err.response.data.errorCode) {
                switch (err.response.data.errorCode) {
                  default:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:userNotFound')},
                    });
                    break;
                }
              } else {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {error400: i18next.t('messages:userNotFound')},
                });
                break;
              }
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
                break;
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
                break;
              }
            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}
