import React, { Component } from 'react';
import classnames from 'classnames';
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import IconButton from 'material-ui/IconButton';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import debounce from 'lodash/debounce';
import ImageLoader from '../Shared/ImageLoader/ImageLoader';
import { getImageLink } from '../../helpers/imageHelper';
import {imageSizes, route} from '../../config/constants';
import './styles.css';
import theme from './theme';
import SlatesSearch from './../../containers/SlatesSearch';
import tryMe from '../../helpers/tryMe';
import { zendeskOffsets } from '../../helpers/setupZendesk';
import {isLoggedOut} from "../../helpers/accountHelper";
import RaisedButton from "material-ui/RaisedButton";
import p from "../../theme/palette";
import { withTranslation } from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {history} from "../../store/createStore";
import {connect} from "react-redux";
import {handleLoginDialogOpen} from "../../store/actions/user";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenProfile: false,
      isOpenDialog: false,
      isOpenSearch: false,
    };
    this.userNameRef = React.createRef();
  }

  handleProfileDropdown = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isOpenProfile: true,
      anchorEl: e.currentTarget,
    });
  };

  handleDropdownClose = () => {
    this.setState({
      isOpenProfile: false,
    });
  };

  handleSearchToggle = () => {
    const isOpenSearch = !this.state.isOpenSearch;
    this.setState({ isOpenSearch }, () => this.updateZendeskWidgetPosition());
  };

  updateZendeskWidgetPosition = (nameLabel = this.userNameRef.current) => {
    const launcher = document.querySelector('iframe.zEWidget-launcher');
    if (launcher) {
      const nameWidth = nameLabel
        ? zendeskOffsets.HIDE + nameLabel.getBoundingClientRect().width
        : 0;
      const newHorizontalOffset = `${zendeskOffsets.RIGHT + nameWidth}px`;
      if (launcher.style.marginRight !== newHorizontalOffset) {
        launcher.style.marginRight = newHorizontalOffset;
      }
      const newVerticalOffset = `${
        this.state.isOpenSearch || this.props.isOpenSidebarMobile
          ? -zendeskOffsets.HIDE
          : zendeskOffsets.TOP
      }px`;
      if (launcher.style.marginTop !== newVerticalOffset) {
        launcher.style.marginTop = newVerticalOffset;
      }
    } else {
      setTimeout(this.updateZendeskWidgetPosition, 100);
    }
  };

  onWindowResize = debounce(() => this.updateZendeskWidgetPosition(), 50);

  componentDidMount() {
    this.updateZendeskWidgetPosition();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    this.updateZendeskWidgetPosition(null);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (
      tryMe(prevProps.currentUser).name !==
        tryMe(this.props.currentUser).name ||
      prevProps.isOpenSidebarMobile !== this.props.isOpenSidebarMobile
    ) {
      this.updateZendeskWidgetPosition();
    }
  }

  render() {
    const { currentUser, imagesLinks, handleSidebarToggle, accountsList, currentAccountUuid } = this.props;
    const { isOpenSearch } = this.state;
    let link;

    if (currentUser) {
      link = getImageLink(
        currentUser.imageUUID,
        imagesLinks,
        imageSizes.USER_TOPBAR,
      );
    }

    let shouldShowSearchOfSlates = false;

    accountsList && accountsList.forEach(account => {
      if(currentAccountUuid && (currentAccountUuid === (account && account.uuid))) {
        shouldShowSearchOfSlates = true;
      }
    })

    return (
      <div className="topbar" style={theme.appHeader}>
        <div className="topbar-inner">
          <div className="inline device-screen-only">
            <IconButton
              iconClassName="material-icons"
              onClick={handleSidebarToggle}
              className="topbar-toggle"
            >
              menu
            </IconButton>
          </div>

          {
            !isLoggedOut(currentUser) ?
              <>
                {
                  shouldShowSearchOfSlates &&
                    <>
                      <div className="inline device-screen-only">
                        <IconButton
                          iconClassName="material-icons"
                          onClick={this.handleSearchToggle}
                          className="topbar-toggle"
                          iconStyle={isOpenSearch ? theme.searchIcon : {}}
                        >
                          search
                        </IconButton>
                      </div>
                      <div className={`topbar-search ${isOpenSearch ? 'toggled' : ''}`}>
                        <SlatesSearch />
                      </div>
                    </>
                }
                <div className="profile-wrapper">
                  <div
                    className={classnames({
                      'topbar-user-profile': true,
                      open: this.state.isOpenProfile,
                    })}
                    onClick={this.handleProfileDropdown}
                  >
                    <div ref={this.userNameRef} className="topbar-user-name">
                      {currentUser.name}
                    </div>

                    <div
                      className="avatar-default"
                      style={link ? {} : theme.avatarDefault}
                    >
                      {link && (
                        <ImageLoader
                          url={link}
                          spinnerWidth={imageSizes.USER_TOPBAR.width / 2}
                          containerStyle={theme.imageLoaderBorder}
                          imageStyle={theme.imageLoaderBorder}
                          preloaderStyle={theme.imageLoaderBorder}
                          isBorder
                        />
                      )}
                      {!link && currentUser.profileLetter}
                    </div>

                    <FontAwesome
                      name="angle-down"
                      style={theme.appHeaderUserChevron}
                    />
                    <Popover
                      animation={PopoverAnimationVertical}
                      style={theme.popoverTransition}
                      className="popover topbar-popover"
                      open={this.state.isOpenProfile}
                      anchorEl={this.state.anchorEl}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                      onRequestClose={this.handleDropdownClose}
                    >
                      <Link
                        to="/profile"
                        className="popover-item"
                        onClick={this.handleDropdownClose}
                      >
                        {this.props.t('common:profileSettings')}
                      </Link>
                      <div className="popover-item" onClick={this.handleLogout}>
                        {this.props.t('common:logOut')}
                      </div>
                    </Popover>
                  </div>
                </div>
              </>
              :
              <div
                style={theme.loginContainer}
              >
                <RaisedButton
                  onClick={this.handleLogin}
                  className="btn"
                  label={this.props.t('btn:logIn')}
                  buttonStyle={theme.loginButton}
                  style={{ width: 180, margin: '0px 20px 0px 0px' }}
                  labelColor={p.brandBlue}
                  overlayStyle={{ height: 32 }}
                />
              </div>
          }
        </div>
      </div>
    );
  }

  handleLogout = () => {
    this.props.logout();
  };

  handleLogin = () => {
    const {pathname} = this.props.location;
    if (pathname.includes('/accounts/') && pathname.includes('/views/')) {
      this.props.handleLoginDialogOpen();
    } else {
      history.push({
        pathname: `${route.LOGIN}`,
      });
    }
  };
}

export default withTranslation()(connect(null, {handleLoginDialogOpen})(withRouter(Topbar)));
