import React, { PureComponent } from 'react';
import Checkbox from 'material-ui/Checkbox';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';
import tryMe from '../../../helpers/tryMe';
import { apiKeyRoles } from '../../../config/constants';
import styles from '../styles';

export default class FormAccountSettingsApiKey extends PureComponent {
  componentDidMount() {
    const { currentApiKey, actions } = this.props;

    actions.setFormState({
      name: currentApiKey ? currentApiKey.name : '',
      roles: currentApiKey ? currentApiKey.roles : '',
      dataErrors: {},
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.currentApiKey, this.props.currentApiKey)) {
      this.props.actions.setFormState({
        name: nextProps.currentApiKey.name,
        roles: nextProps.currentApiKey.roles,
      });
    }
  }

  render() {
    const { actions, state, t, errorAction } = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        <FormTextField
          name="name"
          type="text"
          hintText={t('accountSettings:apiKeyName')}
          floatingLabelText={t('accountSettings:apiKeyName')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).name || ''}
          style={styles.fieldMaxWidth}
        />

        <p>{t('accountSettings:selectTheServices')}</p>

        <div>
          <Checkbox
            name={apiKeyRoles.SLATE_IMPORT}
            label={t('accountSettings:importSlates')}
            checked={
              isEmpty(state)
                ? false
                : state.roles.includes(apiKeyRoles.SLATE_IMPORT)
            }
            onCheck={this.changeRoles}
            style={styles.apikeyCheckbox}
          />
          <div className="apikey-options" style={styles.apikeyOptions}>
            {t('accountSettings:endpointsForGettingInfo')}
          </div>

          <Checkbox
            name={apiKeyRoles.SLATE_UPDATE}
            label={t('accountSettings:updateSlates')}
            checked={
              isEmpty(state)
                ? false
                : state.roles.includes(apiKeyRoles.SLATE_UPDATE)
            }
            onCheck={this.changeRoles}
            style={styles.apikeyCheckbox}
          />
          <div className="apikey-options" style={styles.apikeyOptions}>
            {t('accountSettings:endpointForGettingDetails')}
          </div>
        </div>

        {errorAction && (
          <div className="form-error form-error-action">{errorAction}</div>
        )}

        <FormSubmit
          title={t('btn:saveApiKey')}
          handleSubmit={actions.handleSubmit}
          entityName={state.entityName}
        />
      </form>
    );
  }

  changeRoles = (e) => {
    const { name } = e.target;
    const { roles } = this.props.state;
    let rolesArr = [];

    if (roles) {
      rolesArr = this.props.state.roles.split(',');
    }

    const index = rolesArr.indexOf(name);

    if (index === -1) {
      rolesArr.push(name);
    } else {
      rolesArr.splice(index, 1);
    }

    this.props.actions.setFormState({
      roles: rolesArr.join(','),
    });
  };
}
