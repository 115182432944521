const defaultMaxWidth = 500;

const specificColumnMaxWidths = {
  'Name': 300,
  'Date Field': 300,
  'Email Delivers To': 300,
  'Filter Size': 250,
  Value: Infinity,
};

export const getColumnMaxWidth = (column) => {
  const maxWidth = specificColumnMaxWidths[column && column.name];
  return maxWidth || defaultMaxWidth;
};
