import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {route} from "../../config/constants";
import {isLoggedOut} from "../../helpers/accountHelper";

export const CaptureRouteNotFound = withRouter((props) => {
  const { children, location, user } = props;
  if (location && location.pathname.includes('/slate-views/')) {
    if (isLoggedOut(user)) {
      return <Redirect to={route.START}/>;
    } else {
      return <Redirect to={route.SLATE_VIEW}/>;
    }
  }

  return location && location.state && location.state.notFoundError ? (
    <NotFound />
  ) : (
    children
  );
});

export const RouteNotFound = () => (
  <Redirect to={{ state: { notFoundError: true } }} />
);

export const NotFound = () => (
  <div className="not_found">
    <h1>Not Found</h1>
  </div>
);
