import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ResetPassword from 'components/ResetPassword';
import { resetWizardStep, incrementWizardStep } from 'store/actions/wizard';

function mapStateToProps(state) {
  return {
    app: state.app,
    isOpenLogin2faDialog: state.currentUser.login2faDialogData && state.currentUser.login2faDialogData.isOpen,
  };
}

export default withTranslation()(
  connect(mapStateToProps, { resetWizardStep, incrementWizardStep })(
    ResetPassword,
  ),
);
