import React from 'react';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
  chip: {
    color: 'white',
    backgroundColor: 'rgba(241, 90, 41, 0.9)',
    fontSize: 11,
  },
}));

const NewChip = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Chip label={t('common:new')} size="small"
          className={classes.chip}
    />
  );
};

export default NewChip;
