import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import Map from '../../containers/Map/Map';
import MapViewHeader from './MapViewHeader';
import { createReplacePatch } from '../../helpers/patchHelper';
import { entityNames } from '../../config/constants';
import './styles.css';

export default class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slates: [],
      count: undefined,
      ungroupMapPins: props.dashboardSettings
        ? props.dashboardSettings.ungroupMapPins
        : true,
    };
  }

  componentDidMount = () => {
    const { slates } = this.props;
    this.checkCoordinates(slates);

    if (slates) {
      this.setState({ count: slates.length });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { slates } = nextProps;
    if (!isEqual(slates, this.props.slates)) {
      this.checkCoordinates(slates);
      this.setState({ count: slates.length });
    }
  };

  render() {
    const {
      onMarkerClick,
      isSlateViewExpanded,
      onExpandSlateView,
      onCompressSlateView,
    } = this.props;

    const { slates, count, ungroupMapPins } = this.state;

    return (
      <div className="map-view">
        <MapViewHeader
          isSlateViewExpanded={isSlateViewExpanded}
          onExpandSlateView={onExpandSlateView}
          onCompressSlateView={onCompressSlateView}
          ungroupMapPins={ungroupMapPins}
          onGroupPinChange={this.handleGroupPinChange}
        />

        {count === slates.length && (
          <Map
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `calc(100% - 36px)`,
                  border: '1px solid #e6e6e6',
                  borderTop: 'none',
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
            slates={slates}
            isView
            isClusterer={!ungroupMapPins}
            onMarkerClick={onMarkerClick}
          />
        )}
      </div>
    );
  }

  handleGroupPinChange = () => {
    const ungroupMapPins = !this.state.ungroupMapPins;
    this.setState({ ungroupMapPins });

    const data = {};
    data.patches = [
      createReplacePatch(`/dashboardSettings/ungroupMapPins`, ungroupMapPins),
    ];

    this.props.updateUser(data, entityNames.DASHBOARD_SETTINGS);
  };

  checkCoordinates = (slates) => {
    const withCoords = [];
    const withoutCoords = [];
    if (slates) {
      slates.forEach((slate) => {
        if (slate.lat && slate.lng) {
          withCoords.push(slate);
        } else {
          withoutCoords.push(slate);
        }
      });
    }
    this.setState({ slates: withCoords }, () => {
      this.getCoordsFromAddress(withoutCoords, this.updateSlates);
    });
  };

  getCoordsFromAddress = (slates, callback) => {
    var geocoder = new window.google.maps.Geocoder();

    slates.forEach((slate) => {
      const { address } = slate;
      if (address) {
        geocoder.geocode({ address }, function (results, status) {
          if (status === 'OK') {
            slate.lat = results[0].geometry.location.lat();
            slate.lng = results[0].geometry.location.lng();
            callback(slate);
          } else {
            callback();
          }
        });
      } else {
        callback();
      }
    });
  };

  updateSlates = (slate) => {
    if (slate) {
      const slates = cloneDeep(this.state.slates);
      slates.push(slate);
      this.setState({ slates });
    } else {
      let { count } = this.state;
      this.setState({ count: count - 1 });
    }
  };
}
