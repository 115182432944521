import p from '../../theme/palette';

const theme = {
  appHeader: {
    backgroundColor: p.appHeaderBg,
    borderBottom: `1px solid ${p.appHeaderBorder}`,
  },
  appHeaderUserChevron: {
    color: p.grey300,
  },
  avatarDefault: {
    color: p.avatarDefaultColor,
    backgroundColor: p.avatarDefaultBg,
    border: `1px solid ${p.avatarDefaultBorder}`,
  },
  imageLoaderBorder: {
    borderRadius: '50%',
  },
  popoverTransition: {
    transition:
      'transform 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
  searchIcon: {
    color: p.brandOrange,
  },
  loginContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
};

export default theme;
