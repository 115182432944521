import each from 'lodash/each';
import keys from 'lodash/keys';
import isEqual from 'lodash/isEqual';

import {
  patchOptions,
  ruleParts,
} from 'config/constants';

export const createReplacePatch = (path, value) => {
  return {
    op: patchOptions.REPLACE,
    path,
    value,
  };
};

export const createAddPatch = (path, value) => {
  return {
    op: patchOptions.ADD,
    path,
    value,
  };
};

export const getJsonPatchData = (
  entitiesToCompare,
  currentObj,
  initialObj,
  patches = [],
  path = '',
) => {
  each(entitiesToCompare, (item) => {
    // check array
    if (Array.isArray(currentObj[item])) {
      if (!isEqual(currentObj[item], initialObj ? initialObj[item] : undefined)) {
        patches.push(createReplacePatch(`${path}/${item}`, currentObj[item]));
      }
      // check object
    } else if (typeof currentObj[item] === 'object') {
      getJsonPatchData(
        keys(currentObj[item]),
        currentObj[item],
        initialObj ? initialObj[item] : undefined,
        patches,
        path ? `${path}/${item}` : `/${item}`,
      );
      // check simple types
    } else if (currentObj[item] !== (initialObj ? initialObj[item] : undefined)) {
      patches.push(createReplacePatch(`${path}/${item}`, currentObj[item]));
    }
  });
  return patches;
};


export const getValidFormRulesList = patches => {
  return patches.map( item => {
    if(item.path === '/ruleSet/rules'){
      const newItem = {...item};
      newItem.value = newItem.value.map((item, index)=>{
        const {fieldSUUID, operation, value, uuid} = item

        if(item[ruleParts.RECORD_ENTRY_ATTRIBUTE]) {
          return {
            recordEntryAttribute: item[ruleParts.RECORD_ENTRY_ATTRIBUTE],
            operation,
            value,
            orderIndex: index,
          }
        } else {
          return {
            fieldSUUID,
            operation,
            orderIndex: index,
            uuid,
            value,
          }
        }
      })
      return newItem
    }
    return item
  })
}