import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';

import Card from '../../components/Card';
import FormWrapper from '../../containers/Forms/FormWrapper';
import FormAccountSettingsApiKey from '../../containers/Forms/FormAccountSettingsApiKey';
import { entityNames, route, resourceTypes } from '../../config/constants';
import { history } from '../../store/createStore';

export default class ApiKeySettings extends Component {
  constructor(props) {
    super(props);

    this.isExistingApiKey = !!(
      this.props.location.state && this.props.location.state.uuid
    );
  }

  componentDidMount = () => {
    const { setCurrentApiKey, location } = this.props;
    if (this.isExistingApiKey) {
      setCurrentApiKey(location.state.uuid);
    }
  };

  componentWillUnmount = () => {
    this.props.clearCurrentApiKey();
    this.props.clearCreatedApiKey();
  };

  render() {
    const { t, updateApiKey, createApiKey, createdApiKey } = this.props;

    return (
      <div className="inner-page page_account-apikey">
        <div className="inner-page-header">
          <div className="inner-page-title">
            <h1>
              {createdApiKey
                ? t('accountSettings:apiKeyConfirm')
                : t('accountSettings:apiKey')}
            </h1>
          </div>
        </div>

        {createdApiKey ? (
          <Card
            content={
              <div className="apikey-confirm">
                <div className="apikey-confirm-name">{createdApiKey.name}</div>
                <div className="apikey-confirm-key">{createdApiKey.apiKey}</div>
                <div className="apikey-confirm-descr">
                  {t('accountSettings:storeKey')}
                </div>

                <RaisedButton
                  className="btn"
                  label={t('btn:done')}
                  secondary={true}
                  onClick={this.onConfirmAPIKey}
                />
              </div>
            }
          />
        ) : this.isExistingApiKey ? (
          <Card
            title={t('accountSettings:basicInformation')}
            content={
              <FormWrapper
                type="edit"
                entityName={entityNames.UPDATE_API_KEY}
                entity={['name', 'roles']}
                jsonPatchResource={resourceTypes.API_KEY}
                onSubmit={updateApiKey}
              >
                <FormAccountSettingsApiKey />
              </FormWrapper>
            }
          />
        ) : (
          <Card
            title={t('accountSettings:basicInformation')}
            content={
              <FormWrapper
                type="edit"
                entityName={entityNames.CREATE_API_KEY}
                entity={['name', 'roles']}
                onSubmit={createApiKey}
              >
                <FormAccountSettingsApiKey />
              </FormWrapper>
            }
          />
        )}
      </div>
    );
  }

  onConfirmAPIKey = () => {
    history.push(route.ACCOUNT);
  };
}
