import maxBy from 'lodash/maxBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { getFromLocalStorage } from './localStorageHelper';
import { localStorageProps } from '../config/constants';

const getLatestAccount = (accounts) =>
  maxBy(accounts, (item) => item.lastUsedTimestamp);

export const  getAccountByUUID = (accountUUID, accounts) => {
  const currentAccount = find(accounts, { uuid: accountUUID });

  if (currentAccount) {
    return currentAccount;
  }
};

export const getCurrentAccount = (userUUID, accounts) => {
  const localAccount = getFromLocalStorage(
    userUUID,
    localStorageProps.CURRENT_ACCOUNT,
  );
  if (localAccount) {
    const currentAccount = getAccountByUUID(localAccount.uuid, accounts);
    if (currentAccount) {
      return currentAccount;
    }
  }
  return getLatestAccount(accounts);
};

export const getLocalAccountName = (userUUID, accounts) => {
  const localAccount = getFromLocalStorage(
    userUUID,
    localStorageProps.CURRENT_ACCOUNT,
  );
  if (localAccount && !getAccountByUUID(localAccount.uuid, accounts)) {
    return localAccount.name;
  }
};

export const isLoggedOut = (currentUser) => {
  return (
    !currentUser ||
    currentUser.uuid === undefined ||
    isEmpty(currentUser) ||
    (size(currentUser) === 1 && currentUser.previousSessionData)
  );
};
