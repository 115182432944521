import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import BtnContactUs from '../../../containers/Buttons/BtnContactUs';
import { IssueID } from './IssueID';
import theme from './theme';
import './style.css';

export const ErrorDialogContent = (props) => {
  return (
    <div className="dialog-error">
      <div className="dialog-error-title" style={theme.dialogErrorHeader}>
        <div>{props.t('errorDialog:somethingWentWrong')}</div>
        <div className="dialog-error-dismiss" onClick={props.handleClose}>
          ×
        </div>
      </div>
      <div className="dialog-error-body" style={theme.dialogErrorBody}>
        <h1>{props.t('errorDialog:unexpectedProblem')}</h1>
        {props.item.uuid ? (
          <div className="dialog-error-body-text">
            {props.t('errorDialog:giveAnotherTryIssueId')}
          </div>
        ) : (
          <div className="dialog-error-body-text">
            {props.t('errorDialog:giveAnotherTry')}
          </div>
        )}
        {props.item.uuid && (
          <div style={theme.dialogErrorIssueTitle}>
            {props.t('errorDialog:issueID')}
          </div>
        )}
        <div className="dialog-error-action">
          {props.item.uuid && <IssueID uuid={props.item.uuid} t={props.t} />}
          <BtnContactUs />
        </div>
        <div className="dialog-error-done">
          <RaisedButton
            label="Done"
            secondary={true}
            onClick={props.handleClose}
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};
