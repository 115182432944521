import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import NavigationClose from 'material-ui/svg-icons/navigation/close';

import SettingsRules from './SettingsRules/SettingsRules';
import { logicOperatorTypes } from '../../config/constants';
import { styles as ruleListStyles } from './SettingsRules/styles';
import { styles as ruleStyles } from './styles';
import ColorPicker from '../Shared/ColorPicker/ColorPicker';
import './style.css';

class PinColors extends Component {
  render() {
    const { value, fields, fetchSettingsOperators, t } = this.props;
    return (
      <div>
        {value &&
          value.map((pinColor, index) => {
            return (
              <div className="pin-colors" key={index}>
                <div className="settings-rule-btn-remove">
                  <IconButton
                    iconStyle={ruleStyles.removeButton}
                    onClick={() => this.handleRemovePinColor(index)}
                  >
                    <NavigationClose />
                  </IconButton>
                </div>
                <SettingsRules
                  label={[
                    <span
                      className="settings-rules-operator-text"
                      key="color-label-field"
                    >
                      {t('common:color')}
                    </span>,
                    <ColorPicker
                      key="color-picker"
                      value={pinColor.color}
                      onChange={(color) => this.handleColorChange(index, color)}
                    />,
                    <span
                      className="settings-rules-operator-text"
                      key="color-label-match"
                    >
                      {t('settings:matchPins')}
                    </span>,
                  ]}
                  value={pinColor.ruleSet}
                  onChange={(ruleSet) =>
                    this.handleRuleSetChange(index, ruleSet)
                  }
                  fields={fields}
                  fetchSettingsOperators={fetchSettingsOperators}
                />
              </div>
            );
          })}
        <RaisedButton
          className="settings-rules-add"
          label={t('btn:addColor')}
          labelStyle={ruleListStyles.addRule}
          backgroundColor="#ccc"
          onClick={() =>
            this.handleAddPinColor({
              color: '#ea4335',
              ruleSet: {
                rules: [],
                andOr: logicOperatorTypes.AND,
              },
            })
          }
        />
      </div>
    );
  }

  handleRuleSetChange = (pinColorIndex, ruleSet) => {
    const { value, onChange } = this.props;
    const newValue = [...value];
    newValue[pinColorIndex] = { ...newValue[pinColorIndex], ruleSet };
    onChange(newValue);
  };

  handleColorChange = (pinColorIndex, color) => {
    const { value, onChange } = this.props;
    const newValue = [...value];
    newValue[pinColorIndex] = { ...newValue[pinColorIndex], color };
    onChange(newValue);
  };

  handleAddPinColor = (pinColor) => {
    const { value, onChange } = this.props;
    onChange([...value, pinColor]);
  };

  handleRemovePinColor = (pinColorIndex) => {
    const { value, onChange } = this.props;
    const newValue = [...value];
    newValue.splice(pinColorIndex, 1);
    onChange(newValue);
  };
}

export default withTranslation()(PinColors);
