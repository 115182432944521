export const styles = {
  selectField: {
    style: {
      width: 100,
      height: 36,
      margin: '0 5px',
      textAlign: 'center',
      backgroundColor: '#0486dc',
      borderRadius: 4,
    },
    labelStyle: {
      padding: 0,
      color: '#fff',
      height: 36,
      lineHeight: '36px',
      top: 0,
    },
    iconStyle: {
      width: 36,
      height: 36,
      fill: '#fff',
      padding: 0,
    },
    selectedMenuItemStyle: {
      backgroundColor: '#0486dc',
      color: '#fff',
    },
    listStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    underlineStyle: {
      display: 'none',
    },
  },
  menuItem: {
    innerDivStyle: {
      height: 36,
      lineHeight: '36px',
      paddingLeft: '10px',
    },
  },
  addRule: {
    textTransform: 'none',
    color: 'white',
  },
};
