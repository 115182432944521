import React, { Component } from 'react';
import classnames from 'classnames';
import RaisedButton from 'material-ui/RaisedButton';
import FontAwesome from 'react-fontawesome';

import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';
import { route } from '../../../config/constants';
import tryMe from '../../../helpers/tryMe';
import styles from '../styles';

export default class FormSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withFocus: false,
    };
  }

  UNSAFE_componentWillMount() {
    const pathname = this.props.location.pathname;
    const onSearchPage = pathname.includes(route.SLATE_VIEW_SEARCH_REDIRECT);

    let initialSearchString = '';
    if (onSearchPage) {
      // TODO change to this way, but now match contain wrong data
      // initialSearchString = this.props.match.params.searchString;

      const numberOfSearcStringInUrl = 2;
      initialSearchString = pathname.split('/')[numberOfSearcStringInUrl] || '';
    }

    this.props.actions.setFormState({
      search: initialSearchString,
    });
  }

  componentDidMount() {
    const form = document.getElementsByClassName('search-form')[0];
    form.addEventListener('focus', this.addFocus, true);
    form.addEventListener('blur', this.removeFocus, true);
  }

  componentWillUnmount() {
    const form = document.getElementsByClassName('search-form')[0];
    form.removeEventListener('focus', this.addFocus, true);
    form.removeEventListener('blur', this.removeFocus, true);
  }

  render() {
    const {
      actions,
      state,
      t,
      errorAction,
      currentAccount,
      errors,
    } = this.props;
    const name = currentAccount ? currentAccount.name : '';

    return (
      <form className="search-form" onKeyPress={actions.handleKeyPress}>
        <FormTextField
          autocomplete="off"
          icon="search"
          className="search-input-wrapper"
          iconOnlyMediumScreen={true}
          underlineShow={false}
          name="search"
          type="text"
          hintText={`${t('search:search')} ${name}`}
          floatingLabelText={`${t('search:search')} ${name}`}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).search || ''}
          hintStyle={styles.searchHintStyle}
          floatingLabelStyle={styles.searchHintStyle}
        />

        {errorAction && (
          <div
            className="form-error form-error-action"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            {errorAction}
          </div>
        )}

        {errors && errors.search && (
          <div
            className={classnames({
              'form-error search-error ': true,
              hidden: !this.state.withFocus,
            })}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            {errors.search}
          </div>
        )}

        <FormSubmit
          title={t('search:search')}
          style={{ display: 'none' }}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />
        {state.search && state.search.length >= 1 && (
          <RaisedButton
            backgroundColor={'#eeeeee'}
            className="btn reset-form-button"
            style={{
              minWidth: '36px',
            }}
            icon={<FontAwesome name="times" />}
            onClick={(e) => {
              this.props.actions.handleClear();
              this.props.actions.fetchReset();
            }}
          />
        )}
      </form>
    );
  }

  addFocus = () => this.setState({ withFocus: true });

  removeFocus = () => this.setState({ withFocus: false });
}
