import cloneDeep from 'lodash/cloneDeep';

import * as type from 'store/actions/types';

const initialState = {};

const entities = (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_START: {
      let newState = cloneDeep(state);
      const { entityName, isCounted } = action;
      newState[entityName] = {
        isFetching: true,
        isUpdated: false,
        hasErrors: null,
      };
      if (isCounted) {
        newState[entityName].count = newState[entityName].count
          ? newState[entityName].count + 1
          : 1;
      }
      return newState;
    }

    case type.FETCH_SUCCESS: {
      let newState = cloneDeep(state);
      const { entityName, isCounted } = action;
      newState[entityName].isFetching = false;
      newState[entityName].hasErrors = null;
      if (isCounted) {
        if (newState[entityName].count) {
          newState[entityName].count--;
          if (newState[entityName].count) {
            newState[entityName].isFetching = true;
          }
        }
      }
      return newState;
    }

    case type.FETCH_ERROR: {
      let newState = cloneDeep(state);
      const { entityName, isCounted, hasErrors } = action;
      if (!newState[entityName]) {
        newState[entityName] = {}
      }
      newState[entityName].isFetching = false;
      newState[entityName].hasErrors = hasErrors;
      if (isCounted) {
        if (newState[entityName].count) {
          newState[entityName].count--;
          if (newState[entityName].count) {
            newState[entityName].isFetching = true;
          }
        }
      }
      return newState;
    }

    case type.FETCH_RESET: {
      let newState = cloneDeep(state);
      newState[action.entityName] = {
        isFetching: false,
        isUpdated: false,
        hasErrors: null,
      };
      return newState;
    }

    case type.ENTITY_UPDATED: {
      let newState = cloneDeep(state);
      newState[action.entityName].isFetching = false;
      newState[action.entityName].isUpdated = true;
      return newState;
    }

    default:
      return state;
  }
};

export default entities;
