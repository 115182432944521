import React, {Component} from 'react';
import WizardSteps from '../containers/WizardSteps';
import ResetPasswordStages from '../containers/ResetPasswordStages/ResetPasswordStages';

export default class ResetPassword extends Component {
  UNSAFE_componentWillMount() {
    this.props.resetWizardStep();
    const {location, incrementWizardStep,} = this.props;

    if (location) {
      const {search} = location;

      if (search) {
        if (search.includes('?code=')) {
          incrementWizardStep(3);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {isOpenLogin2faDialog, onChange} = this.props;
    if (isOpenLogin2faDialog && !prevProps.isOpenLogin2faDialog && !!onChange) {
      onChange(null, 0);
    }
  }

  render() {
    const {wizardStep} = this.props.app;

    return (
      <div>
        <h1>{this.props.t('resetPassword:resetPassword')}</h1>
        <WizardSteps steps={[1, 2, 3]} currentStep={wizardStep}/>
        <ResetPasswordStages/>
      </div>
    );
  }
}
