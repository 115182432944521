import React, { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';

import tryMe from '../../../helpers/tryMe';
import AvatarUploader from '../../AvatarUploader/AvatarUploader';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';
import { avatarShapes } from '../../../config/constants';
import styles from '../styles';

export default class FormAccountSettingsBasicInfo extends PureComponent {
  componentDidMount() {
    const { currentAccount, actions, getAccount } = this.props;

    if (currentAccount) {
      getAccount(currentAccount.uuid);

      actions.setFormState({
        name: currentAccount.name,
        imageUUID: currentAccount.imageUUID,
        attachments: [],
        dataErrors: {},
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.currentAccount, this.props.currentAccount)) {
      this.props.getAccount(nextProps.currentAccount.uuid);

      this.props.actions.setFormState({
        name: nextProps.currentAccount.name,
        imageUUID: nextProps.currentAccount.imageUUID,
        attachments: [],
        dataErrors: {},
      });
    }
  }

  render() {
    const { actions, state, t, currentAccount, errorAction } = this.props;
    const isPersonalAccount = this.checkPersonalAccount();

    return (
      <form onKeyPress={actions.handleKeyPress}>
        {currentAccount && currentAccount.error && (
          <div style={styles.formError}>{currentAccount.error}</div>
        )}

        <FormTextField
          name="name"
          type="text"
          hintText={t('accountSettings:accountName')}
          floatingLabelText={t('accountSettings:accountName')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).name || ''}
          disabled={isPersonalAccount}
        />

        <div style={styles.accountLogoTitle}>
          {t('accountSettings:accountLogo')}
        </div>

        {currentAccount && (
          <AvatarUploader
            onAddAttachment={actions.handleAddAttachment}
            onRemoveAttachment={actions.handleRemoveProfileImage}
            onRestoreAttachment={actions.handleRestoreProfileImage}
            imageUUID={state.imageUUID}
            type={this.props.type}
            shape={avatarShapes.ROUNDED_CORNERS}
            t={t}
            disabled={isPersonalAccount}
          />
        )}

        {errorAction && (
          <div className="form-error form-error-action">{errorAction}</div>
        )}

        <FormSubmit
          title={t('btn:updateAccount')}
          handleSubmit={actions.handleSubmit}
          entityName={state.entityName}
          disabled={isPersonalAccount}
        />
      </form>
    );
  }

  checkPersonalAccount = () => {
    const { currentUser, currentAccount } = this.props;
    let result = false;

    if (currentUser && currentAccount) {
      result = currentUser.personalAccountUUID === currentAccount.uuid;
    }
    return result;
  };
}
