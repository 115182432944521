import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import forEachRight from 'lodash/forEachRight';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import each from 'lodash/each';
import uniqBy from 'lodash/uniqBy';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import FontAwesome from 'react-fontawesome';
import { DragDropContext } from 'react-beautiful-dnd';
import { withTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';

import { showNotification } from 'store/actions/notification';
import AvailableFields from './Available/AvailableFields';
import SelectedFields from './Selected/SelectedFields';
import Search from '../Shared/Search/Search';
import {
  searchAvailableFields,
  searchSelectedFields,
} from 'helpers/searchHelper';
import { fieldsTypes, notificationTypes, fieldsDnDId } from 'config/constants';
import generateUUID from 'helpers/generateUUID';
import { fieldAttributeOptions, listViewTypes } from 'config/constants';
import * as params from './params';
import './style.css';

class SettingsFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAvailableSearchText: '',
      fields: cloneDeep(props.fields),
      available: cloneDeep(props.fields),
      selectedFields: props.selectedFields || [],
      searchSelected: [],
      highlightedAvailable: [],
      highlightedSelected: [],
      isSearch: false,
    };
    this.selectedFieldsContainer = React.createRef();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.selectedFields &&
      nextProps.selectedFields !== this.props.selectedFields
    ) {
      this.setState({
        selectedFields: nextProps.selectedFields,
        searchSelected: [],
        isSearch: false,
      });
    }
    if (nextProps.fields && nextProps.fields !== this.props.fields) {
      this.setState(
        {
          fields: nextProps.fields,
        },
        () => {
          this.onSearchAvailable(this.state.currentAvailableSearchText);
        },
      );
    }
  };

  render() {
    const { listViewType, t } = this.props;
    const { highlightedAvailable, highlightedSelected } = this.state;
    const availableFields = this.getAvailableFields();
    const isRecordsListView = listViewType === listViewTypes.records;

    return (
      <DragDropContext
        onDragStart={() => {}}
        onDragUpdate={() => {}}
        onDragEnd={this.onDragEnd}
      >
        <div className="settings-fields">
          <div className="settings-fields-column">
            <div className="settings-fields-column-header">
              <div className="part-left">
                <h2 className="settings-fields-column-title">
                  {t('settings:available')}
                </h2>
              </div>
            </div>

            <Search onSearch={this.onSearchAvailable} />
            <div className="settings-fields-column-container">
              {availableFields === null ? (
                <div className="settings-fields-nothing">
                  {t('settings:selectRecordField')}
                </div>
              ) : (
                <AvailableFields
                  fields={availableFields}
                  highlightedFields={highlightedAvailable}
                  onSingleClick={this.handleAvailableFieldHighlight}
                  onDoubleClick={this.handleAvailableFieldSelect}
                  onToggleOpen={this.handleToggleOpenField}
                />
              )}
            </div>
          </div>

          <div className="separator">
            <div className="left-to-right" onClick={this.handleArrowAddClick}>
              <MediaQuery minWidth={params.MIN_WIDTH}>
                {(matches) => {
                  if (matches) {
                    return <FontAwesome name="arrow-right" />;
                  } else {
                    return <FontAwesome name="arrow-down" />;
                  }
                }}
              </MediaQuery>
            </div>
            <div
              className="right-to-left"
              onClick={this.handleArrowRemoveClick}
            >
              <MediaQuery minWidth={params.MIN_WIDTH}>
                {(matches) => {
                  if (matches) {
                    return <FontAwesome name="arrow-left" />;
                  } else {
                    return <FontAwesome name="arrow-up" />;
                  }
                }}
              </MediaQuery>
            </div>
          </div>

          <div className="settings-fields-column">
            <div className="settings-fields-column-header">
              <h2 className="settings-fields-column-title">
                {t('settings:selected')}
              </h2>
              <div className="settings-fields-buttons">
                <div
                  className="settings-fields-button"
                  onClick={this.handleMoveUp}
                >
                  <FontAwesome name="arrow-up" />
                </div>
                <div
                  className="settings-fields-button"
                  onClick={this.handleMoveDown}
                >
                  <FontAwesome name="arrow-down" />
                </div>
              </div>
            </div>

            <Search onSearch={this.onSearchSelected} />
            <div
              className="settings-fields-column-container"
              ref={this.selectedFieldsContainer}
            >
              <SelectedFields
                selected={this.getSelectedItems()}
                highlightedFields={highlightedSelected}
                onSingleClick={this.handleSelectedFieldHighlight}
                onDoubleClick={this.handleSelectedFieldRemove}
                onFieldAttributeChange={this.handleSelectedFieldAttributeChange}
                hideAttributes={isRecordsListView}
              />
            </div>
          </div>
        </div>
      </DragDropContext>
    );
  }

  handleToggleOpenField = (uuid) => {
    const { available } = this.state;
    const fields = cloneDeep(available);
    const currentField = find(fields, (f) => f.suuid === uuid);

    if (currentField && currentField.children) {
      currentField.isShowChildren = !currentField.isShowChildren;
      this.setState({ available: fields });
    }
  };

  prepareRecordChildren = (children, parentLengthToRemove, result = []) => {
    children.forEach((field) => {
      field.parent = undefined;
      // remove record parents to decrease a padding in the list
      field.parents.splice(0, parentLengthToRemove);
      result.push(field);
      if (field.children) {
        this.prepareRecordChildren(
          field.children,
          parentLengthToRemove,
          result,
        );
      }
    });
    return result;
  };

  getAvailableFields = () => {
    const { available } = this.state;
    const { recordSuuid, listViewType, t } = this.props;
    const isRecordListView = listViewType === listViewTypes.records;

    // prepare record field
    if (isRecordListView) {
      // record entry is not selected
      if (!recordSuuid) {
        return null;
      }
      const recordField = available.find(
        (field) => field.suuid === recordSuuid,
      );
      if (!recordField) {
        return null;
      }
      const recordChildren = cloneDeep(recordField).children;
      if (recordChildren) {
        const prepared = this.prepareRecordChildren(
          recordChildren,
          recordField.parents.length + 1,
        );
        const availableWithoutRecordsFields = available.filter(field => {
          return prepared.findIndex(_field =>
            (_field.suuid === field.suuid) || (field.suuid === recordSuuid)
          ) === -1
        });
        return [
          {
            disabled: true,
            title: t('settings:fieldsFor') + recordField.title,
            id: 'record',
          },
          ...prepared,
          {
            disabled: true,
            title: t('settings:otherFields'),
            id: 'record',
            },
          ...availableWithoutRecordsFields,
        ];
      }
    }

    // default behavior (for slates)
    const metaFields = [
      {
        disabled: true,
        title: t('settings:slateDetails'),
        id: 'details',
      },
    ];
    const otherFields = [
      {
        disabled: true,
        title: t('settings:slateFields'),
        id: 'fields',
      },
    ];
    available.forEach((field) => {
      if (field.meta) {
        metaFields.push(field);
      } else {
        otherFields.push(field);
      }
    });
    return [...metaFields, ...otherFields];
  };

  getSelectedItems = () => {
    const { selectedFields, searchSelected, isSearch } = this.state;
    return isSearch ? searchSelected : selectedFields;
  };

  getDraggableField = (id) => {
    if (id.startsWith(fieldsDnDId.AVAILABLE)) {
      return find(
        this.state.fields,
        (f) => f.suuid === id.replace(fieldsDnDId.AVAILABLE, ''),
      );
    } else if (id.startsWith(fieldsDnDId.SELECTED)) {
      return find(
        this.state.selectedFields,
        (f) => f.cuuid === id.replace(fieldsDnDId.SELECTED, ''),
      );
    }
  };

  onDragEnd = (result) => {
    if (result.destination) {
      let newSelected = cloneDeep(this.state.selectedFields);
      const { source, destination, draggableId } = result;

      // From left to right
      if (
        source.droppableId === fieldsDnDId.AVAILABLE &&
        destination.droppableId === fieldsDnDId.SELECTED
      ) {
        let draggableField = this.getDraggableField(draggableId);
        let { index } = destination;

        const newField = this.buildField(draggableField);
        if (newField && this.checkFieldType(draggableField)) {
          newSelected.splice(index, 0, newField);
        }

        this.setState(
          {
            selectedFields: newSelected,
          },
          () => {
            this.props.onSelectedFieldsChange(newSelected);
          },
        );
      }

      // Right side sort
      else if (
        source.droppableId === fieldsDnDId.SELECTED &&
        destination.droppableId === fieldsDnDId.SELECTED
      ) {
        newSelected = this.reorder(
          newSelected,
          source.index,
          destination.index,
        );

        let newSearchSelected = cloneDeep(this.state.searchSelected);
        if (newSearchSelected.length) {
          newSearchSelected = this.reorder(
            newSearchSelected,
            source.index,
            destination.index,
          );
        }

        this.setState(
          {
            selectedFields: newSelected,
            searchSelected: newSearchSelected,
          },
          () => {
            this.props.onSelectedFieldsChange(newSelected);
          },
        );
      }

      // From right to left (remove)
      else if (
        source.droppableId === fieldsDnDId.SELECTED &&
        destination.droppableId === fieldsDnDId.AVAILABLE
      ) {
        let draggableField = this.getDraggableField(draggableId);
        this.handleSelectedFieldRemove(draggableField.cuuid);
      }
    }
  };

  reorder = (array, startIndex, endIndex) => {
    const [removed] = array.splice(startIndex, 1);
    array.splice(endIndex, 0, removed);
    return array;
  };

  handleAvailableFieldHighlight = (uuid, eventParams) => {
    const currentField = find(
      this.state.fields,
      (field) => field.suuid === uuid,
    );

    let newHighlighted = this.addToHighlighted(
      eventParams,
      currentField,
      cloneDeep(this.state.highlightedAvailable),
      this.state.fields,
      true,
    );
    this.setState({
      highlightedAvailable: newHighlighted,
      highlightedSelected: [],
    });
  };

  handleSelectedFieldHighlight = (cuuid, eventParams) => {
    const {
      isSearch,
      searchSelected,
      selectedFields,
      highlightedSelected,
    } = this.state;
    const selected = isSearch ? searchSelected : selectedFields;

    const currentField = find(selectedFields, (field) => field.cuuid === cuuid);

    let newHighlighted = this.addToHighlighted(
      eventParams,
      currentField,
      cloneDeep(highlightedSelected),
      selected,
    );

    this.setState({
      highlightedSelected: newHighlighted,
      highlightedAvailable: [],
    });
  };

  addToHighlighted = (eventParams, field, currentArray, allArray, isShown) => {
    const { shiftKey, ctrlKey } = eventParams;

    // shift key pressed
    if (shiftKey && !ctrlKey && currentArray.length) {
      // find min index of the highlighted fields
      const startIndex = reduce(
        currentArray,
        (index, item) =>
          Math.min(findIndex(allArray, { cuuid: item.cuuid }), index),
        allArray.length - 1,
      );
      const endIndex = findIndex(allArray, { cuuid: field.cuuid });

      let slice = allArray.slice(
        Math.min(startIndex, endIndex),
        Math.max(startIndex, endIndex) + 1,
      );

      if (isShown) {
        slice = filter(slice, 'isShown');
      }
      currentArray = currentArray.concat(slice);
      currentArray = uniqBy(currentArray, 'cuuid');

      // ctrl key pressed
    } else if (!shiftKey && ctrlKey) {
      let index = findIndex(currentArray, { cuuid: field.cuuid });
      if (index === -1) {
        currentArray.push(field);
      } else {
        currentArray.splice(index, 1);
      }
    } else {
      currentArray = [field];
    }
    return currentArray;
  };

  // needs to add all children of a field to selected
  addChildrenToSelected = (fields, array) => {
    each(fields, (field) => {
      if (field.children) {
        this.addChildrenToSelected(field.children, array);
      } else {
        const newField = this.buildField(field);
        if (newField) {
          array.push(newField);
        }
      }
    });
  };

  // double click on available field
  handleAvailableFieldSelect = (uuid) => {
    const newSelectedFields = cloneDeep(this.state.selectedFields);
    const currentField = find(
      this.state.fields,
      (field) => field.suuid === uuid,
    );

    const newField = this.buildField(currentField);
    if (newField && this.checkFieldType(currentField)) {
      newSelectedFields.push(newField);
    }

    this.setState(
      {
        highlightedAvailable: [],
        highlightedSelected: [],
        selectedFields: newSelectedFields,
      },
      () => {
        this.props.onSelectedFieldsChange(newSelectedFields);
        this.scrollSelectedToBottom();
      },
    );
  };

  handleSelectedFieldRemove = (cuuid) => {
    let newSelected = cloneDeep(this.state.selectedFields);
    let newSearchSelected = cloneDeep(this.state.searchSelected);

    newSelected = remove(newSelected, (f) => f.cuuid !== cuuid);
    newSearchSelected = remove(newSearchSelected, (f) => f.cuuid !== cuuid);

    this.setState(
      {
        searchSelected: newSearchSelected,
        selectedFields: newSelected,
      },
      () => {
        this.props.onSelectedFieldsChange(newSelected);
      },
    );
  };

  handleSelectedFieldAttributeChange = (cuuid, fieldAttribute) => {
    let newSelected = cloneDeep(this.state.selectedFields);

    const field = find(newSelected, (f) => f.cuuid === cuuid);
    field.fieldAttribute = fieldAttribute;

    this.setState(
      {
        selectedFields: newSelected,
      },
      () => {
        this.props.onSelectedFieldsChange(newSelected);
      },
    );
  };

  buildField = (field) => {
    const { listViewType } = this.props;
    const isRecordsListView = listViewType === listViewTypes.records;

    // allow only first attribute for meta fields and record entries fields
    const busyAttributes = this.state.selectedFields
      .filter((f) => f.suuid === field.suuid && f.cuuid !== field.cuuid)
      .map((field) => field.fieldAttribute);
    const freeAttribute = find(
      field.meta || isRecordsListView
        ? fieldAttributeOptions.slice(0, 1)
        : fieldAttributeOptions,
      (fieldAttribute) => !busyAttributes.includes(fieldAttribute),
    );
    if (freeAttribute) {
      return { ...field, fieldAttribute: freeAttribute, cuuid: generateUUID() };
    }
  };

  handleArrowAddClick = () => {
    let newSelectedFields = cloneDeep(this.state.selectedFields);

    forEach(this.state.highlightedAvailable, (field) => {
      const newField = this.buildField(field);
      if (newField && this.checkFieldType(field)) {
        newSelectedFields.push(newField);
      }
    });

    this.setState(
      {
        highlightedAvailable: [],
        highlightedSelected: [],
        selectedFields: newSelectedFields,
      },
      () => {
        this.props.onSelectedFieldsChange(newSelectedFields);
        this.scrollSelectedToBottom();
      },
    );
  };

  handleArrowRemoveClick = () => {
    if (this.state.highlightedSelected.length > 0) {
      let newSelected = cloneDeep(this.state.selectedFields);
      let newSearchSelected = cloneDeep(this.state.searchSelected);

      forEach(this.state.highlightedSelected, (field) => {
        remove(newSelected, (f) => f.cuuid === field.cuuid);
        remove(newSearchSelected, (f) => f.cuuid === field.cuuid);
      });
      this.setState(
        {
          highlightedAvailable: [],
          highlightedSelected: [],
          searchSelected: newSearchSelected,
          selectedFields: newSelected,
        },
        () => {
          this.props.onSelectedFieldsChange(newSelected);
        },
      );
    }
  };

  handleMoveUp = () => {
    let { highlightedSelected } = this.state;
    let { selectedFields } = this.state;

    if (!highlightedSelected.length || !selectedFields.length) {
      return;
    }

    let shouldReplace = [];
    forEach(highlightedSelected, (item) => shouldReplace.push(item.cuuid));

    let replacedSelected = cloneDeep(selectedFields);
    let currentIndex;
    let replacedItem;

    forEach(selectedFields, (item, index) => {
      if (shouldReplace.includes(item.cuuid)) {
        if (index) {
          replacedItem = replacedSelected.splice(index, 1)[0];

          if (currentIndex) {
            replacedSelected.splice(currentIndex, 0, replacedItem);
            ++currentIndex;
          } else {
            replacedSelected.splice(index - 1, 0, replacedItem);
            currentIndex = index;
          }
        } else currentIndex = 1;
      }
    });

    this.setState(
      {
        selectedFields: replacedSelected,
      },
      () => {
        this.props.onSelectedFieldsChange(replacedSelected);
      },
    );
  };

  handleMoveDown = () => {
    let { highlightedSelected } = this.state;
    let { selectedFields } = this.state;

    if (!highlightedSelected.length || !selectedFields.length) {
      return;
    }

    let shouldReplace = [];
    forEach(highlightedSelected, (item) => shouldReplace.push(item.cuuid));

    let replacedSelected = cloneDeep(selectedFields);
    let currentIndex;
    let replacedItem;

    forEachRight(selectedFields, (item, index) => {
      if (shouldReplace.includes(item.cuuid)) {
        if (index !== selectedFields.length - 1) {
          replacedItem = replacedSelected.splice(index, 1)[0];

          if (currentIndex) {
            replacedSelected.splice(currentIndex, 0, replacedItem);
            --currentIndex;
          } else {
            replacedSelected.splice(index + 1, 0, replacedItem);
            currentIndex = index;
          }
        } else currentIndex = selectedFields.length - 2;
      }
    });

    this.setState(
      {
        selectedFields: replacedSelected,
      },
      () => {
        this.props.onSelectedFieldsChange(replacedSelected);
      },
    );
  };

  onSearchAvailable = (text = '') => {
    let available = cloneDeep(this.state.fields);
    text = text.trim().toLowerCase();

    if (text.length) {
      searchAvailableFields(available, text);
    }
    this.setState({
      currentAvailableSearchText: text,
      available,
      highlightedAvailable: [],
      highlightedSelected: [],
    });
  };

  onSearchSelected = (text = '') => {
    const selected = cloneDeep(this.state.selectedFields);
    let appropriateFields = [];
    let isSearch = false;

    if (text) {
      appropriateFields = searchSelectedFields(selected, text);
      isSearch = true;
    }
    this.setState({
      searchSelected: appropriateFields,
      isSearch,
      highlightedAvailable: [],
      highlightedSelected: [],
    });
  };

  checkFieldType = (field) => {
    // do not allow group and description fields
    const { t, showNotification } = this.props;
    if ([fieldsTypes.GROUP, fieldsTypes.DESCRIPTION].includes(field.type)) {
      showNotification(
        notificationTypes.WARNING,
        t('notifications:fieldCannotBeAdded', {
          name: t(
            field.type === fieldsTypes.GROUP
              ? 'fields:group'
              : 'fields:description',
          ),
        }),
      );
      return false;
    }
    return true;
  };

  scrollSelectedToBottom = () => {
    const scrollHeight = this.selectedFieldsContainer.current.scrollHeight;
    const height = this.selectedFieldsContainer.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.selectedFieldsContainer.current.scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  };
}

SettingsFields.propTypes = {
  fields: PropTypes.array.isRequired,
  selectedFields: PropTypes.array,
  listViewType: PropTypes.string.isRequired,
  recordSuuid: PropTypes.string.isRequired,
  onSelectedFieldsChange: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default withTranslation()(
  connect(mapStateToProps, {
    showNotification,
  })(SettingsFields),
);
