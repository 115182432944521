import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import SlateTable from 'components/SlateTable/SlateTable';
import {
  fetchSlates,
  updateSlateViewFilters,
  updateColumns,
} from 'store/actions/slateViews';
import { entityNames } from 'config/constants';

function mapStateToProps(state) {
  return {
    dataGetEntity: state.entities[entityNames.GET_SLATES],
    dataToken: state.slateViews.slatesToken,
    slateInSlatePaneUUID: state.slateViews.slateInSlatePaneUUID,
    filters: state.slateViews.filters,
    sortDirection: state.slateViews.sortDirection,
    sortColumnIndex: state.slateViews.sortColumnIndex,
  };
}

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, {
      fetchData: fetchSlates,
      updateFilters: updateSlateViewFilters,
      updateColumns,
    })(SlateTable),
  ),
);
