import React from 'react';
import { render } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { I18nextProvider } from 'react-i18next';

import { store, history } from './store/createStore';
import App from 'components/App';
import { cookiesTypes, getCookies } from './helpers/cookieHelper';
import NetworkService from './helpers/networkService';
import muiTheme from './theme/muiTheme';
import i18n from 'i18n/config';
import { setupZendesk } from 'helpers/setupZendesk';
import './index.css';

// DON'T REMOVE! Fix bug with popover rendering
// eslint-disable-next-line no-unused-vars
import PopoverPreRender from './components/Shared/PopoverPreRender';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support

// setup axios interceptors to handle special response headers and errors
NetworkService.setupInterceptors(store);

const cookiesUser = getCookies(cookiesTypes.USER_COOKIE);
if (cookiesUser) {
  let user = JSON.parse(cookiesUser);
  store.dispatch({
    type: 'SET_USER',
    payload: {
      user,
    },
  });
}

setupZendesk();

render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
