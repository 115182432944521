import React, { Component } from 'react';
import classnames from 'classnames';
import FontAwesome from 'react-fontawesome';

import { loadingStatuses } from './loadingStatuses';
import {
  fieldsTypes,
  dateTimeDisplayTypes,
  dateTimeFormats,
  presetNames,
} from 'config/constants';
import { renderDateTime } from 'helpers/dateTimeHelper';
import TableImageItem from 'containers/ScrollableTable/TableImageItem';
import { renderBooleanValue } from 'helpers/fieldsHelper';
import {Tooltip} from "@material-ui/core";

function getTextWidth(text, font) {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

class ScrollableTableRow extends Component {
  render() {
    const {
      loadedRowsMap,
      style,
      rowIndex,
      columns,
      data,
      fixedCellWidth,
      isRowSelected,
      isFirstRowSelected,
      onChangeCheckbox,
      onRowClick,
      uniqueField,
      isRowClickable,
    } = this.props;

    const isRowSelectedVal = isRowSelected(rowIndex);
    const isFirstRowSelectedVal = isFirstRowSelected(rowIndex);
    const isLoaded = loadedRowsMap[rowIndex] === loadingStatuses.STATUS_LOADED;

    const extendClass = this.defineExtendClass();

    let backStyle = {
      position: 'absolute',
      width: style.width - 22, // TODO why 22
      height: style.height ? style.height : 36,
    };

    return (
      <div
        style={{ ...style }}
        className={classnames({
          'body-row': true,
          even: rowIndex % 2 === 0,
          'body-row-selected': isRowSelectedVal,
          'body-row-selected-first': isFirstRowSelectedVal,
        })}
      >
        <div className="body-row-back" style={backStyle} />
        <div
          className={classnames('body-cell', 'body-cell-fixed', extendClass)}
          style={{ width: fixedCellWidth - 1 }}
          onClick={(e) => onChangeCheckbox(e, rowIndex)}
        >
          <div className={classnames('body-cell-fixed-newline', extendClass)}>
            @#$
          </div>
          <div>{rowIndex + 1}</div>
        </div>

        {columns.map((column, colIndex) => {
          if (isLoaded) {
            const currentCell = data[rowIndex].columns[column.index];
            if (!currentCell) {
              return null;
            }

            return (
              <div
                key={`Row${rowIndex}-Col${colIndex}`}
                style={{ width: column.width || '200px' }}
                className={classnames('body-cell', extendClass)}
                onClick={
                  isRowClickable
                    ? (e) =>
                        onRowClick(e, data[rowIndex][uniqueField], colIndex)
                    : undefined
                }
              >
                {this.renderFieldValue(currentCell.value, column)}
              </div>
            );
          } else {
            return (
              <div
                key={`Row${rowIndex}-Col${colIndex}`}
                style={{ width: column.width || '200px' }}
                className="body-cell"
              >
                <div className="body-cell-preview" />
              </div>
            );
          }
        })}
      </div>
    );
  }

  renderFieldValue = (value, { type: fieldType, displayType }) => {
    if (
      (value === '' || value === undefined) &&
      fieldType !== fieldsTypes.IMAGE
    ) {
      return '-';
    }
    switch (fieldType) {
      case fieldsTypes.DATETIME: {
        return (
          <div className="body-cell-value">
            <div className="body-cell-value-inner">
              {value
                ? displayType === dateTimeDisplayTypes.DATE
                  ? renderDateTime(value, dateTimeFormats.DATE)
                  : renderDateTime(value)
                : '-'}
            </div>
          </div>
        );
      }

      case fieldsTypes.PHOTO: {
        return value === true ? (
          <div>
            <FontAwesome name="photo" />
          </div>
        ) : (
          <TableImageItem imageUUID={value} />
        );
      }

      case fieldsTypes.EMAIL: {
        const isValueString = typeof value === 'string';
        return <div className="body-cell-value-inner-email-container">
          {
            isValueString && value ?
              <div className="body-cell-value">
                <div className="body-cell-value-inner">{value.toString()}</div>
              </div> :
              value && value.map( email => (<div className="body-cell-value-inner-email">{email}</div>))
          }
        </div>
      }

      case fieldsTypes.IMAGE: {
        const {
          rowHeightPreset,
          isImageExpandable,
          showEmptyImages,
        } = this.props;
        return !showEmptyImages && !value ? (
          '-'
        ) : (
          <TableImageItem
            large={rowHeightPreset === 'large'}
            imageUUID={value}
            isExpandable={isImageExpandable}
          />
        );
      }

      case fieldsTypes.BOOLEAN: {
        return renderBooleanValue(displayType, value);
      }

      case fieldsTypes.ACTIONS: {
        return <div className="body-cell-value">
          {value}
        </div>;
      }

      default: {
        const rowWidth = getTextWidth(value, "normal 8pt arial")
        return (
          <>
            {rowWidth > 325 || (value && value.toString().length > 35)
              ? <Tooltip title={value}>
                  <div className="body-cell-value">
                    <div className="body-cell-value-inner">{value && value.toString()}</div>
                  </div>
                </Tooltip>
              : <div className="body-cell-value">
                  <div className="body-cell-value-inner">{value && value.toString()}</div>
                </div>}
          </>
        );
      }
    }
  };

  defineExtendClass = () => {
    const { rowIndex, columns, data, rowHeightPreset } = this.props;
    let extendClass = '';
    const makeExtendClass = (preset) => `${preset}-height`;
    if (rowHeightPreset) {
      extendClass += makeExtendClass(rowHeightPreset);

      if (rowHeightPreset === presetNames.mediumIfPhoto) {
        const photoColumns = columns.filter(
          (column) => column.type === fieldsTypes.PHOTO,
        );
        if (photoColumns.length) {
          if (!data[rowIndex]) {
            // case when data not loaded yet
            extendClass = '';
          } else {
            // try to find photo with url, if it exist need medium height
            photoColumns.forEach((photoColumn) => {
              const column = data[rowIndex].columns[photoColumn.index];
              const value = column && column.value;
              if (value && typeof value === 'string' && value !== '') {
                extendClass = makeExtendClass(presetNames.medium);
              }
            });
          }
        } else {
          extendClass = '';
        }
      }
    }
    return extendClass;
  };
}

export default ScrollableTableRow;
