import p from '../../theme/palette';

const styles = {
  disclaimer: {
    fontSize: 12,
    textAlign: 'center',
    color: p.fieldHint,
    maxWidth: 280,
    margin: '0px auto',
  },
};

export default styles;
