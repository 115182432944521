import palette from '../../theme/palette';

const theme = {
  slateViewCounter: {
    background: palette.defaultText,
    color: palette.white,
  },
  slateViewCounterFiltered: {
    background: palette.brandOrange,
    color: palette.white,
  },
  slateViewActionStyle: {
    minWidth: 40,
  },
  shareUrlButton: {
    position: 'absolute',
    right: 70,
    top: 34,
    minWidth: 35,
  },
  shareUrlButtonContainer: {
    position: 'relative',
  },
  slateViewActionButtonSettings: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  slateViewActionButtonExport: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  slateViewBorderRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  slateViewSelector: {
    height: 34,
    maxWidth: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  slateViewSelectorButtonList: {
    backgroundColor: palette.success,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  slateViewSelectorButtonMap: {
    backgroundColor: palette.brandOrange,
  },
  slateViewCreateButton: {
    backgroundColor: palette.primary,
  },
  slateViewSelectorLabel: {
    textTransform: 'none',
    fontSize: 16,
  },
  slateViewSelectorIcon: {
    color: palette.white,
    paddingBottom: 3,
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 7px)',
  },
  fakeSlateViewSelector: {
    height: 34,
    borderRadius: 4,
    backgroundColor: 'rgb(3, 191, 152)',
    fontSize: 16,
    padding: '0px 16px',
    color: 'rgb(255, 255, 255)',
    display: 'flex',
    maxWidth: 300,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexDirection: 'row',
  },
  slateViewSelectorDialog: { top: 82 },
  popoverStyle: {
    transition:
      'transform 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
  autoCompleteSearch: {
    textField: {
      textIndent: 25,
    },
    underline: {
      bottom: 1,
      borderColor: palette.grey200,
    },
    input: {
      width: `calc(100% - 50px)`,
    },
  },
  listIcon: {
    top: 2,
    color: palette.success,
    fontSize: 20,
  },
  mapIcon: {
    top: 2,
    left: 8,
    color: palette.brandOrange,
    fontSize: 20,
  },
  menuItemInnerStyle: {
    padding: '0px 16px 0px 46px',
  },
  counterList: {
    backgroundColor: palette.success,
  },
  counterMap: {
    backgroundColor: palette.brandOrange,
  },
  labelForSelector: {
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 25px)',
    maxWidth: 'calc(100% - 25px)',
    marginRight: 25,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  slateViewActionIconStyle: {
    color: 'rgb(85, 85, 85)'
  }
};

export default theme;
