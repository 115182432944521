import React, { Component } from 'react';

// import 'react-resizable-ext/css/styles.css';
import '../ScrollableTable/styles.css';
import ScrollableTable from '../../components/ScrollableTable/ScrollableTable';

class SlateTable extends Component {
  static defaultProps = {
    slates: [],
  };

  render() {
    const {
      slates,
      slateViewUUID,
      slateInSlatePaneUUID,
      fetchData,
      ...restProps
    } = this.props;
    return (
      <ScrollableTable
        data={slates}
        dataIDs={slates.map((item) => item.slateUUID)}
        fixedHeader={true}
        fixedHeaderHeight={36}
        useDragHandle={true}
        withOrdering={true}
        withFiltration={true}
        withSorting={true}
        rowsSelected={slateInSlatePaneUUID ? [slateInSlatePaneUUID] : undefined}
        uniqueField="slateUUID"
        fetchData={(isNewSet, filters, sort) =>
          fetchData(slateViewUUID, { isNewSet, filters, sort })
        }
        {...restProps}
      />
    );
  }
}

export default SlateTable;
