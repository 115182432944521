import cloneDeep from 'lodash/cloneDeep';

import * as type from 'store/actions/types';
import { jobsTabs } from 'config/constants';

const initialState = {
  list: {},
};

const jobs = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_JOBS: {
      let newState = cloneDeep(state);
      const { jobs, jobsTab } = action.payload;
      newState.list[jobsTab] = jobs;
      return newState;
    }
    case type.CLEAR_JOBS: {
      let newState = cloneDeep(state);
      newState.list[jobsTabs.ACTIVE] = undefined;
      newState.list[jobsTabs.FAVORITES] = undefined;
      newState.list[jobsTabs.ARCHIVED] = undefined;
      return newState;
    }
    case type.SET_JOB_SETTINGS: {
      let newState = cloneDeep(state);
      newState.settings = action.payload.settings;
      return newState;
    }
    case type.CLEAR_JOB_SETTINGS: {
      let newState = cloneDeep(state);
      newState.settings = undefined;
      return newState;
    }
    case type.SET_JOBS_TAB_UPDATE: {
      let newState = cloneDeep(state);
      newState.needUpdateTab = action.payload;
      return newState;
    }
    default:
      break;
  }
  return state;
};

export default jobs;
