import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

import {
  slateViewsTypes,
  settingsOperatorsEntryDetails,
} from 'config/constants';
import * as type from 'store/actions/types';
import { defaultFieldsSource } from 'components/FieldSelector/FieldsSourceOptions';

const initialState = {
  isPaneOpen: false,
  isPaneHidden: false,
  isPaneDocked: false,
  journalEntries: {},
  recordEntries: {},
  historyEntries: {},
  settingsFieldsFilters: {
    include: defaultFieldsSource,
  },
  snapshotOfTheCurrentStateForBeAbleToRevert: {
    shouldCompare: false,
    lastData: {},
    openPopup: false,
    newOption: '',
    selectedFields: {},
  },
  settingsOperators: settingsOperatorsEntryDetails,
};

const clearSlateViewParams = (newState) => {
  newState.slateViewType = undefined;
  newState.slates = undefined;
  newState.autoRefresh = undefined;
  newState.slatesToken = undefined;
  newState.rowCount = undefined;
  newState.filters = undefined;
  newState.sortDirection = undefined;
  newState.sortColumnIndex = undefined;
  newState.settings = undefined;
};

const slateViews = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_SLATE_VIEWS_LIST: {
      let newState = cloneDeep(state);
      const { listViews, mapViews, currentSlateView } = action.payload;
      newState.listViews = listViews;
      newState.mapViews = mapViews;

      // set a default slate view
      if (listViews && listViews.length) {
        newState.defaultSlateView = listViews[0];
        newState.defaultSlateViewType = slateViewsTypes.LIST;
      } else if (mapViews && mapViews.length) {
        newState.defaultSlateView = mapViews[0];
        newState.defaultSlateViewType = slateViewsTypes.MAP;
      }

      // case for set slate view list without current slate-view-uuid,
      // for example on url /slate-views
      if (!currentSlateView) {
        newState.currentSlateView = newState.defaultSlateView;
        newState.slateViewType = newState.defaultSlateViewType;

        // always update current slate view if new lists are coming
      } else if (currentSlateView.name) {
        newState.currentSlateView = currentSlateView;
      } else {
        let view = find(
          listViews,
          (listView) => listView.uuid === currentSlateView.uuid,
        );
        if (view) {
          newState.slateViewType = slateViewsTypes.LIST;
        } else {
          view = find(
            mapViews,
            (mapView) => mapView.uuid === currentSlateView.uuid,
          );
          if (view) {
            newState.slateViewType = slateViewsTypes.MAP;
          } else {
            view = newState.defaultSlateView;
            newState.slateViewType = newState.defaultSlateViewType;
          }
        }
        newState.currentSlateView = view;
      }

      return newState;
    }

    case type.CLEAN_SLATE_VIEWS_AFTER_UPDATE_SETTINGS: {
      let newState = cloneDeep(state);
      newState.listViews = undefined;
      newState.currentSlateView.columns = undefined;
      return newState;
    }

    case type.SET_CURRENT_SLATE_VIEW_WITHOUT_VIEW_LIST: {
      let newState = cloneDeep(state);
      const { newSlateView } = action.payload;
      const { viewType: newSlateViewType } = newSlateView;

      if( newSlateViewType === slateViewsTypes.LIST) {
        newState.listViews = [newSlateView];
        newState.mapViews = [];
      } else {
        newState.listViews = [];
        newState.mapViews = [newSlateView];
      }

      newState.currentSlateView = newSlateView;
      newState.slateViewType = newSlateViewType;
      return newState;
    }

    case type.SET_CURRENT_SLATE_VIEW: {
      let newState = cloneDeep(state);
      const { uuid } = action.payload;

      if (!uuid) {
        newState.currentSlateView = newState.defaultSlateView;
        newState.slateViewType = newState.defaultSlateViewType;
        return newState;
      }

      let newSlateViewType;
      let newSlateView;
      // handle list view
      if (newState.listViews) {
        newSlateView = find(newState.listViews, (item) => item.uuid === uuid);
        if (newSlateView) {
          newSlateViewType = slateViewsTypes.LIST;
        }
      }
      // handle map view
      if (!newSlateView && newState.mapViews) {
        newSlateView = find(newState.mapViews, (item) => item.uuid === uuid);
        if (newSlateView) {
          newSlateViewType = slateViewsTypes.MAP;
        }
      }
      const newCurrentSlateView = newSlateView || (uuid ? { uuid } : null);
      if (
        !newState.currentSlateView ||
        !newCurrentSlateView ||
        newState.currentSlateView.uuid !== newCurrentSlateView.uuid
      ) {
        clearSlateViewParams(newState);
      }
      newState.currentSlateView = newCurrentSlateView;
      newState.slateViewType = newSlateViewType;
      return newState;
    }

    case type.SET_SLATES: {
      let newState = cloneDeep(state);
      const { slates, slatesToken, rowCount, isNewSet, autoRefresh } = action.payload;

      if (newState.slates) {
        newState.slates = newState.slates.concat(slates);
      } else {
        newState.slates = slates;
      }
      newState.slatesToken = slatesToken;

      newState.autoRefresh = autoRefresh;

      // do not change current rowCount if it's not a new set
      if (rowCount) {
        newState.rowCount = rowCount;
      } else if (isNewSet) {
        newState.rowCount = newState.slates.length;
      }
      return newState;
    }

    case type.SET_SLATES_SEARCH_RESULTS: {
      let newState = cloneDeep(state);
      const { slates, rowCount, searchString, autoRefresh } = action.payload;

      newState.searchResultSlates = slates;
      newState.searchResultRowCount = rowCount;
      newState.searchString = searchString;
      newState.autoRefresh = autoRefresh;

      return newState;
    }

    case type.CLEAR_SLATE_VIEWS: {
      return initialState;
    }

    case type.CLEAR_SLATES: {
      let newState = cloneDeep(state);
      newState.slates = undefined;
      return newState;
    }

    case type.SET_CURRENT_SLATE: {
      let newState = cloneDeep(state);
      newState.currentSlate = action.payload.currentSlate;
      return newState;
    }

    case type.SET_NEW_SLATE_VIEW_TYPE: {
      let newState = cloneDeep(state);
      newState.newSlateViewType = action.payload;
      return newState;
    }

    // ----- journal and record
    case type.SET_JOURNAL_ENTRIES: {
      let newState = cloneDeep(state);
      const { entries, continuationToken } = action.payload;

      // add new entries or create new array
      if (newState.journalEntries.entries) {
        newState.journalEntries.entries = newState.journalEntries.entries.concat(
          entries,
        );
      } else {
        newState.journalEntries.entries = entries;
      }

      newState.journalEntries.continuationToken = continuationToken;
      return newState;
    }

    case type.CLEAR_JOURNAL_ENTRIES: {
      let newState = cloneDeep(state);
      newState.journalEntries = {};
      return newState;
    }

    case type.SET_RECORD_ENTRIES: {
      let newState = cloneDeep(state);
      const { entries, continuationToken } = action.payload;

      // add new entries or create new array
      if (newState.recordEntries.entries) {
        newState.recordEntries.entries = newState.recordEntries.entries.concat(
          entries,
        );
      } else {
        newState.recordEntries.entries = entries;
      }

      newState.recordEntries.continuationToken = continuationToken;
      return newState;
    }

    case type.CLEAR_RECORD_ENTRIES: {
      let newState = cloneDeep(state);
      newState.recordEntries = {};
      return newState;
    }

    // ----- history
    case type.SET_HISTORY_ENTRIES: {
      let newState = cloneDeep(state);
      const { entries, continuationToken } = action.payload;

      // add new entries or create new array
      if (newState.historyEntries.entries) {
        newState.historyEntries.entries = newState.historyEntries.entries.concat(
          entries,
        );
      } else {
        newState.historyEntries.entries = entries;
      }

      newState.historyEntries.continuationToken = continuationToken;
      return newState;
    }

    case type.CLEAR_HISTORY_ENTRIES: {
      let newState = cloneDeep(state);
      newState.historyEntries = {};
      return newState;
    }

    // ----- settings
    case type.SET_SLATE_VIEW_SETTINGS: {
      let newState = cloneDeep(state);
      newState.settings = action.payload.settings;
      newState.snapshotOfTheCurrentStateForBeAbleToRevert.shouldCompare = true;
      return newState;
    }

    case type.CLEAR_SLATE_VIEW_SETTINGS: {
      let newState = cloneDeep(state);
      newState.settings = undefined;
      return newState;
    }

    case type.SET_SETTINGS_FIELDS: {
      let newState = cloneDeep(state);
      newState.settingsFields = action.payload;
      newState.snapshotOfTheCurrentStateForBeAbleToRevert.shouldCompare = true;
      return newState;
    }

    case type.UPDATE_SETTINGS_FIELDS_FILTERS: {
      let newState = cloneDeep(state);
      newState.snapshotOfTheCurrentStateForBeAbleToRevert.lastData = {
          sourceField: state.settingsFieldsFilters.include,
          settingsFields: state.settingsFields,
      }
      Object.assign(newState.settingsFieldsFilters, action.payload);
      return newState;
    }

    case type.CLEAN_SETTINGS_FIELDS_FILTERS: {
      let newState = cloneDeep(state);
      newState.settingsFields = null;
      return newState;
    }

    case type.REVERT_SETTINGS_OPERATORS: {
      let newState = cloneDeep(state);
      newState.settingsFieldsFilters.include = state.snapshotOfTheCurrentStateForBeAbleToRevert.lastData.sourceField;
      newState.settingsFields = state.snapshotOfTheCurrentStateForBeAbleToRevert.lastData.settingsFields;
      newState.snapshotOfTheCurrentStateForBeAbleToRevert = {
        shouldCompare: false,
        lastData: {},
        openPopup: false,
        newOption: '',
        selectedFields: {},
      };
      return newState;
    }

    case type.CONFIRM_SETTINGS_OPERATORS: {
      let newState = cloneDeep(state);
      newState.snapshotOfTheCurrentStateForBeAbleToRevert = {
        shouldCompare: true,
        lastData: {},
        openPopup: false,
        newOption: '',
        selectedFields: {},
      };
      return newState;
    }

    case type.OPEN_WARNING_SETTING_DIALOG: {
      const {selectedFields, newOption} = action.payload;
      let newState = cloneDeep(state);
      newState.snapshotOfTheCurrentStateForBeAbleToRevert = {
        ...state.snapshotOfTheCurrentStateForBeAbleToRevert,
        shouldCompare: false,
        openPopup: true,
        newOption,
        selectedFields,
      };
      return newState;
    }

    case type.CLEAN_SNAPSHOT_PREVIOUS_VERSION: {
      let newState = cloneDeep(state);
      newState.snapshotOfTheCurrentStateForBeAbleToRevert = {
        ...state.snapshotOfTheCurrentStateForBeAbleToRevert,
        shouldCompare: false,
        lastData: {},
      };
      return newState;
    }

    case type.CLEAR_SETTINGS_FIELDS: {
      let newState = cloneDeep(state);
      newState.settingsFields = [];
      return newState;
    }

    case type.SET_SETTINGS_OPERATORS: {
      let newState = cloneDeep(state);
      const { suuid, settingsOperators } = action.payload;

      if (!newState.settingsOperators) {
        newState.settingsOperators = {};
      }
      newState.settingsOperators[suuid] = settingsOperators;
      return newState;
    }

    case type.CLEAR_SETTINGS_OPERATORS: {
      let newState = cloneDeep(state);
      const { suuid } = action.payload;
      newState.settingsOperators[suuid] = undefined;
      return newState;
    }

    case type.UPDATE_SLATE_PANE_EXPANDED: {
      let newState = cloneDeep(state);
      if (action.payload.slatePaneExpanded !== undefined) {
        newState.isPaneDocked = action.payload.slatePaneExpanded;
      }
      return newState;
    }

    case type.UPDATE_SLATE_PANE_STATE: {
      let newState = cloneDeep(state);
      if (action.payload.isPaneOpen !== undefined) {
        newState.isPaneOpen = action.payload.isPaneOpen;
      }
      if (action.payload.isPaneHidden !== undefined) {
        newState.isPaneHidden = action.payload.isPaneHidden;
      }
      if (action.payload.isPaneDocked !== undefined) {
        newState.isPaneDocked = action.payload.isPaneDocked;
      }
      if (action.payload.slateInSlatePaneUUID !== undefined) {
        newState.slateInSlatePaneUUID = action.payload.slateInSlatePaneUUID;
      }
      return newState;
    }

    case type.UPDATE_SLATE_VIEW_FILTERS: {
      let newState = cloneDeep(state);
      const { filters, sortDirection, sortColumnIndex } = action.payload;

      if (filters) {
        newState.filters = filters;
      }
      if (sortDirection !== undefined) {
        if (sortDirection === -1) {
          newState.sortDirection = undefined;
        } else {
          newState.sortDirection = sortDirection;
        }
      }
      if (sortColumnIndex !== undefined) {
        if (sortColumnIndex === -1) {
          newState.sortColumnIndex = undefined;
        } else {
          newState.sortColumnIndex = sortColumnIndex;
        }
      }

      return newState;
    }

    case type.CLEAR_SLATE_VIEW_FILTERS: {
      let newState = cloneDeep(state);
      newState.filters = undefined;
      newState.sortDirection = undefined;
      newState.sortColumnIndex = undefined;
      return newState;
    }

    case type.SET_RECORD_SLATE: {
      let newState = cloneDeep(state);
      newState.recordSlate = action.payload.currentSlate;
      return newState;
    }

    case type.CLEAR_RECORD_SLATE: {
      let newState = cloneDeep(state);
      newState.recordSlate = undefined;
      return newState;
    }

    case type.UPDATE_COLUMNS: {
      let newState = cloneDeep(state);
      newState.currentSlateView = {
        ...newState.currentSlateView,
        columns: action.payload,
      };
      return newState;
    }

    default:
      return state;
  }
};

export default slateViews;
