import { route } from '../config/constants';

export const getSlateLink = (uuid) => {
  let link =
    process.env.REACT_APP_SLATES_URL +
    route.SLATE.slice(0, route.SLATE.indexOf(':')) +
    uuid;

  if (process.env.REACT_APP_SL8_ENV === 'production') {
    link = `${process.env.REACT_APP_SLATES_URL}/${uuid}`;
  }
  return link;
};
