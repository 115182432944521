import React, { Component } from 'react';
import classnames from 'classnames';
import merge from 'lodash/merge';

import Preloader from '../../Preloader/Preloader';
import { imageLoadingStatuses } from '../../../config/constants';
import { styles } from './styles';
import './styles.css';

export default class ImageLoader extends Component {
  state = {
    status: '',
  };

  componentDidMount = () => {
    const { url } = this.props;
    if (url) {
      this.loadImage(url);
    }
    this._isMounted = true;
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { url } = nextProps;
    if (url && url !== this.props.url) {
      this.loadImage(url);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const {
      url,
      containerStyle,
      imageStyle,
      preloaderStyle,
      spinnerWidth,
      isBorder,
      onClick,
    } = this.props;

    const isImageLoading = this.state.status === imageLoadingStatuses.LOADING;
    const isImageLoaded = this.state.status === imageLoadingStatuses.LOADED;
    const borderStyle = isBorder ? styles.border : {};

    return (
      <div className="image-loader" style={containerStyle}>
        {isImageLoading && (
          <Preloader
            spinnerWidth={spinnerWidth}
            isGray
            style={merge({}, borderStyle, preloaderStyle)}
          />
        )}

        <div
          className={classnames({
            'image-loader-inner': true,
            'image-loader-inner-loaded': isImageLoaded,
          })}
          style={merge(
            {},
            { backgroundImage: `url(${url})` },
            borderStyle,
            imageStyle,
          )}
          onClick={onClick}
        />
      </div>
    );
  }

  loadImage = (url) => {
    const { onLoad, onError } = this.props;
    const image = new Image();

    image.onload = () => {
      if (this._isMounted) {
        this.setState({ status: imageLoadingStatuses.LOADED });
        if (onLoad) {
          onLoad();
        }
      }
    };
    image.onerror = () => {
      if (this._isMounted) {
        this.setState({ status: imageLoadingStatuses.FAILED });
        if (onError) {
          onError();
        }
      }
    };

    image.src = url;
    this.setState({ status: imageLoadingStatuses.LOADING });
  };
}
