import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import BasicDialog from "../../Dialogs/BasicDialog";
import RaisedButton from "material-ui/RaisedButton";
import FormTextField from "../../Forms/FormTextField";
import {validation} from "../../../helpers/validationHelper";
import '../style.css'
import {
  clearFetchingType,
  deleteNotificationsFromList,
  getListOfNotifications,
  setActiveNotifications
} from "../../../store/actions/notifications";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FETCHING_STATUS, FETCHING_TYPE} from "../../../store/reducers/notifications";
import CircularProgress from "material-ui/CircularProgress";

const DeleteNotificationPopup = ({
                                   open,
                                   closePopup,
                                   deleteNotificationsFromList,
                                   activeNotificationUUID,
                                   notificationsList,
                                   isFetching,
                                   clearFetchingType,
                                   currentAccountUUID,
                                 }) => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nameOfNotification, setNameOfNotification] = useState('');
  const [dataErrors, setDataErrors] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const notification = notificationsList.find(notification => notification.uuid === activeNotificationUUID);
    notification && setNameOfNotification(notification.columns[0].value);
  }, [activeNotificationUUID]);

  useEffect(() => {
    if(
      (isFetching.status === FETCHING_STATUS.success) &&
      (isFetching.type === FETCHING_TYPE.deletingNotification)
    ){
      clearFetchingType();
      closePopup();
      setIsLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if(!open){
      setValue('');
      setActiveNotifications('');
    }
  }, [open]);

  const deleteNotification = () => {
    if(!validation.isNotEmpty(value)){
      setDataErrors(t('notificationsPage:validationErrors:requiredName'));
    } else if(value.trim() === nameOfNotification.trim()){
      deleteNotificationsFromList({activeNotificationUUID, currentAccountUUID, value: value.trim()});
      setIsLoading(true);
      setDataErrors('');
    } else {
      setDataErrors(t('notificationsPage:validationErrors:namesDontMatch'))
    }
  }

  const handleChange = event => {
    setDataErrors('');
    setValue(event.currentTarget.value)
  }

  return (
      <BasicDialog
        title={t('notificationsPage:deleteNotificationPopup:title')}
        open={open}
        onClose={closePopup}
        actions={[
           <RaisedButton
             key="delete"
             primary={true}
             label={t('btn:delete')}
             onClick={deleteNotification}
             icon={isLoading ? <CircularProgress size={24} /> : null}
             disabled={isLoading}
           />,
           <RaisedButton
             key="cancel"
             className="jobs-item-btn-text"
             label={t('btn:cancel')}
             onClick={closePopup}
           />,
        ]}
      >
          <div className="popup-email-input-wrapper">
          <div>{t('notificationsPage:deleteNotificationPopup:subtitle', {name: nameOfNotification})}</div>
          <FormTextField
            name="name"
            type="name"
            autocomplete="name"
            hintText={t('notificationsPage:deleteNotificationPopup:popupInputTitle')}
            floatingLabelText={t('notificationsPage:deleteNotificationPopup:popupInputTitle')}
            fullWidth={true}
            state={{
              dataErrors: {name: dataErrors}
            }}
            value={value}
            actions={{
              handleChange,
              handleBlur: (() => {}),
            }}
          />
        </div>
      </BasicDialog>
  )
}

const mapStateToProps = (state) => ({
  activeNotificationUUID: state.notifications_page.activeNotificationUUID,
  notificationsList: state.notifications_page.listOfNotifications,
  isFetching: state.notifications_page.isFetching,
});

const mapDispatchToProps = {
  getListOfNotifications,
  setActiveNotifications,
  deleteNotificationsFromList,
  clearFetchingType,
};

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(DeleteNotificationPopup),
  ),
);
