import React, { Component } from 'react';
import FormWrapper from '../../containers/Forms/FormWrapper';
import FormUserProfileDetails from '../../containers/Forms/FormUserProfileDetails';
import FormUserProfilePassword from '../../containers/Forms/FormUserProfilePassword';
import Card from '../../components/Card';
import { entityNames, resourceTypes } from '../../config/constants';
import './style.css';

export default class ProfileSettings extends Component {
  render() {
    const { t, updatePassword } = this.props;
    return (
      <div className="inner-page page_profile-settings">
        <div className="inner-page-header">
          <div className="inner-page-title">
            <h1>{t('userProfile:profileSettings')}</h1>
          </div>
        </div>

        <Card
          title={t('userProfile:profileDetails')}
          content={
            <FormWrapper
              type="edit"
              entityName={entityNames.UPDATE_USER}
              entity={['name', 'displayName', 'contactInfo', 'imageUUID']}
              onSubmit={this.onUpdateUser}
              jsonPatchResource={resourceTypes.USER}
            >
              <FormUserProfileDetails />
            </FormWrapper>
          }
        />

        <Card
          title={t('userProfile:password')}
          content={
            <FormWrapper
              type="edit"
              entityName={entityNames.UPDATE_PASSWORD}
              entity={['currentPassword', 'newPassword', 'confirmNewPassword']}
              onSubmit={updatePassword}
            >
              <FormUserProfilePassword />
            </FormWrapper>
          }
        />
      </div>
    );
  }

  onUpdateUser = (data, entityName) => {
    this.props.updateUser(data, entityName);
  };
}
