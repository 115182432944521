import React, { Component } from 'react';

import FormTextField from '../FormTextField';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import tryMe from '../../../helpers/tryMe';
import styles from '../styles';
import palette from '../../../theme/palette';

export default class FormAccountSettingsRemoveApiKey extends Component {
  componentDidMount() {
    const { actions } = this.props;

    actions.setFormState({
      name: '',
      dataErrors: {},
    });
  }

  componentWillUnmount = () => {
    this.props.clearCurrentApiKey();
  };

  render() {
    const { t, state, actions, currentApiKey } = this.props;
    return (
      <div>
        <div>
          {`${t('accountSettings:enterTheNameOfApiKey')} (`}
          <span style={{ fontWeight: 500 }}>
            {currentApiKey && currentApiKey.name}
          </span>
          {`) ${t('accountSettings:confirmToDeleteIt')}:`}
        </div>
        <FormTextField
          name="name"
          type="text"
          hintText={t('accountSettings:apiKeyName')}
          floatingLabelText={t('accountSettings:apiKeyName')}
          actions={actions}
          state={state}
          value={tryMe(state).name || ''}
        />

        <FormSubmit
          title={t('btn:delete')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
          primary={false}
          style={styles.dialogButton}
          buttonStyle={styles.dialogButtonInline}
          labelColor={palette.white}
        />
      </div>
    );
  }
}
