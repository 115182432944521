import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash/debounce';

import AvailableItem from './AvailableItem';
import * as params from '../params';
import { fieldsDnDId } from 'config/constants';

const AvailableFields = ({
  fields,
  highlightedFields,
  onSingleClick,
  onDoubleClick,
  onToggleOpen,
}) => {
  let clickedOnce = useRef();

  const checkClosedParents = (field) => {
    if (!field.parents.length) {
      return true;
    }

    for (let i = 0; i < field.parents.length; i++) {
      if (!field.parents[i].isShowChildren) {
        return false;
      }
    }
    return true;
  };

  const handleFieldSingleClick = (uuid, eventParams) => {
    clickedOnce.current = false;
    onSingleClick(uuid, eventParams);
  };

  const delayedClick = debounce(handleFieldSingleClick, params.DEBOUNCE_TIME);

  const handleFieldClick = (e, uuid) => {
    const eventParams = {
      ctrlKey: e.nativeEvent.ctrlKey,
      shiftKey: e.nativeEvent.shiftKey,
    };

    if (clickedOnce.current) {
      // Handle Double Click
      delayedClick.cancel();
      clickedOnce.current = false;
      onDoubleClick(uuid);
    } else {
      // Handle single click
      delayedClick(uuid, eventParams);
      clickedOnce.current = true;
    }
  };

  return (
    <Droppable droppableId={fieldsDnDId.AVAILABLE}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((field, index) => {
              if (field.disabled) {
                return (
                  <div
                    className="settings-field-available settings-field-header"
                    key={field.id}
                  >
                    {field.title}
                  </div>
                );
              }
              if (field.isShown && checkClosedParents(field)) {
                return (
                  <AvailableItem
                    key={field.suuid}
                    index={index}
                    field={field}
                    isHighlighted={Boolean(
                      highlightedFields.find(
                        (item) => item.suuid === field.suuid,
                      ),
                    )}
                    onToggleOpen={onToggleOpen}
                    onFieldClick={handleFieldClick}
                  />
                );
              }
              return null;
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

AvailableFields.propTypes = {
  fields: PropTypes.array.isRequired,
  highlightedFields: PropTypes.array.isRequired,
  onSingleClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
};

export default AvailableFields;
