import p from '../../theme/palette';

const style = {
  dialogTitle: {
    color: p.grey300,
    fontSize: 18,
    //---- global font setting ----
    fontWeight: 400,
    lineHeight: '22px',
    borderBottom: '1px solid',
    borderColor: p.grey200,
    padding: '20px 24px 16px',
  },
  dialogUploadBody: {
    padding: 0,
    height: 465,
    textAlign: 'center',
    overflow: 'visible',
  },
  uploadLabelError: {
    display: 'block',
    color: p.error,
    border: `1px dashed ${p.error}`,
    padding: '10px 15px',
    fontSize: 12,
    width: '90%',
    maxWidth: 400,
    borderRadius: 20,
    margin: '20px auto',
  },
  cropper: {
    minHeight: 200,
    maxHeight: 400,
    minWidth: 200,
  },
};

export default style;
