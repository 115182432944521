import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import find from 'lodash/find';

import * as type from 'store/actions/types';
import { ErrorDialogContent } from './ErrorDialogContent';

export class ErrorDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
      currentDialog: '',
    };
  }

  handleClose = () => {
    this.props.dispatch({ type: type.ERROR_DIALOG_CLOSE });
  };

  render() {
    const { dialogs } = this.props;
    let errorDialog = find(dialogs, { type: 'errorDialog' });

    return (
      <Dialog
        modal={false}
        repositionOnUpdate={false}
        open={errorDialog.isOpen}
        onRequestClose={this.handleClose}
        bodyStyle={{ padding: 0 }}
        autoDetectWindowHeight={false}
        contentStyle={{ width: '90%', marginBottom: 64 }}
        style={{
          zIndex: 9999,
          overflowY: 'auto',
          paddingTop: 0,
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <ErrorDialogContent
          handleClose={this.handleClose}
          item={errorDialog}
          t={this.props.t}
        />
      </Dialog>
    );
  }
}
