import React, { Component } from 'react';
import FormTextField from '../FormTextField';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import tryMe from '../../../helpers/tryMe';

export default class FormVerficationPhoneEmail extends Component {
  UNSAFE_componentWillMount() {
    this.props.actions.setFormState({
      emailOrPhone: '',
      smsOrCall: 'sms',
      dataErrors: {
        emailOrPhone: '',
        smsOrCall: '',
      },
    });
  }
  render() {
    const { actions, state, t } = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        <FormTextField
          name="emailOrPhone"
          type="email"
          hintText={t('resetPassword:emailOrPhone')}
          floatingLabelText={t('resetPassword:emailOrPhone')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).emailOrPhone || ''}
        />
        <div className="btn-verification">
          <FormSubmit
            title={t('resetPassword:sendVerificationCode')}
            handleSubmit={actions.handleSubmit}
            handleMouseDown={actions.handleMouseDown}
            entityName={state.entityName}
          />
        </div>
        <div className="btn-verification-sm">
          <FormSubmit
            title={t('resetPassword:sendCode')}
            handleSubmit={actions.handleSubmit}
            handleMouseDown={actions.handleMouseDown}
            entityName={state.entityName}
          />
        </div>
      </form>
    );
  }
}
