import React, { Component } from 'react';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';

import Card from '../../components/Card';
import FormWrapper from '../../containers/Forms/FormWrapper';
import FormAccountSettingsBasicInfo from '../../containers/Forms/FormAccountSettingsBasicInfo';
import FormAccountSettingsRemoveApiKey from '../../containers/Forms/FormAccountSettingsRemoveApiKey';
import ApiKeyItem from '../ApiKey/ApiKeyItem';
import BasicDialog from 'components/Dialogs/BasicDialog';
import {
  route,
  resourceTypes,
  entityNames,
  infoPanelTypes,
} from '../../config/constants';
import './style.css';
import InfoPanel from '../Shared/InfoPanel/InfoPanel';

export default class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoveDialogOpen: false,
    };
  }

  componentDidMount = () => {
    const { currentAccount, getApiKeys } = this.props;
    if (currentAccount) {
      getApiKeys(currentAccount.uuid);
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!isEqual(this.props.currentAccount, nextProps.currentAccount)) {
      this.props.getApiKeys(nextProps.currentAccount.uuid);
    }

    if (!isEqual(nextProps.apiKeys, this.props.apiKeys)) {
      this.setState({
        isRemoveDialogOpen: false,
      });
    }
  };

  render() {
    const { t, location, apiKeys, removeApiKey } = this.props;
    const { isRemoveDialogOpen } = this.state;
    const isPersonalAccount = this.checkPersonalAccount();

    return (
      <div className="inner-page page_account-settings">
        <div className="inner-page-header">
          <div className="inner-page-title">
            <h1>{t('accountSettings:accountSettings')}</h1>
          </div>
        </div>

        <Card
          title={t('accountSettings:basicInformation')}
          content={
            <div>
              {isPersonalAccount && (
                <div className="page_account-settings-message">
                  <InfoPanel type={infoPanelTypes.WARNING}>
                    {t('accountSettings:thisInformationIsLinked')}{' '}
                    <Link to={route.PROFILE}>{t('accountSettings:here')}</Link>.
                  </InfoPanel>
                </div>
              )}

              <FormWrapper
                type="edit"
                entityName={entityNames.UPDATE_ACCOUNT}
                entity={['name', 'imageUUID']}
                jsonPatchResource={resourceTypes.ACCOUNT}
                onSubmit={this.onUpdateAccount}
              >
                <FormAccountSettingsBasicInfo />
              </FormWrapper>
            </div>
          }
        />

        <Card
          title={t('accountSettings:apiKeys')}
          content={
            <div>
              {map(apiKeys, (item) => (
                <ApiKeyItem
                  key={item.uuid}
                  apiKey={item}
                  location={location}
                  t={t}
                  onRemoveApiKey={this.handleOpenRemoveDialog}
                />
              ))}

              {isPersonalAccount ? (
                <RaisedButton
                  className="btn"
                  label={t('btn:addApiKey')}
                  secondary={true}
                  disabled
                />
              ) : (
                <Link to={route.API_KEY}>
                  <RaisedButton
                    className="btn"
                    label={t('btn:addApiKey')}
                    secondary={true}
                  />
                </Link>
              )}
            </div>
          }
        />

        <BasicDialog
          title={t('accountSettings:deleteApiKeyConfirm')}
          open={isRemoveDialogOpen}
          onClose={this.handleCloseDialog}
          actions={[
            <RaisedButton
              key="cancel"
              className="jobs-item-btn-text"
              label={t('btn:cancel')}
              onClick={this.handleCloseDialog}
            />,
            // <RaisedButton
            //   label={t("btn:delete")}
            //   backgroundColor={palette.error}
            // />
          ]}
        >
          <div className="page_account-settings-dialog">
            <FormWrapper
              type="edit"
              entityName={entityNames.REMOVE_API_KEY}
              entity={['name']}
              onSubmit={removeApiKey}
            >
              <FormAccountSettingsRemoveApiKey />
            </FormWrapper>
          </div>
        </BasicDialog>
      </div>
    );
  }

  onUpdateAccount = (data, entityName) => {
    this.props.updateAccount(data, entityName);
  };

  handleOpenRemoveDialog = (uuid) => {
    this.props.setCurrentApiKey(uuid);
    this.setState({
      isRemoveDialogOpen: true,
    });
  };

  handleCloseDialog = () => {
    this.props.clearCurrentApiKey();
    this.setState({
      isRemoveDialogOpen: false,
    });
  };

  checkPersonalAccount = () => {
    const { currentUser, currentAccount } = this.props;
    let result = false;

    if (currentUser && currentAccount) {
      result = currentUser.personalAccountUUID === currentAccount.uuid;
    }
    return result;
  };
}
