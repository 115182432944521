import React from 'react';
import FontAwesome from 'react-fontawesome';
import palette from '../../theme/palette';
import './style.css';

export const Verified = ({customText, customClass}) => (
  <div className={customClass || "badge badge-verified"}>
    <FontAwesome
      name="check-circle"
      style={{ color: palette.success, fontSize: 24, marginRight: 10 }}
    />
    <span>{customText ? customText : 'Verified'}</span>
  </div>
);
