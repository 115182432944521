import { connect } from 'react-redux';

import ResetPasswordStages from '../../components/ResetPasswordStages/index';

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}

export default connect(mapStateToProps)(ResetPasswordStages);
