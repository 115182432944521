import React, { Component } from 'react';

import FormTextField from '../FormTextField';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import tryMe from '../../../helpers/tryMe';
import palette from '../../../theme/palette';
import styles from '../styles';

export default class FormSlateViewDelete extends Component {
  componentDidMount() {
    const { actions } = this.props;

    actions.setFormState({
      confirmViewName: '',
      dataErrors: {},
    });
  }

  render() {
    const { t, state, actions, currentSlateView } = this.props;
    return (
      <div>
        <div>
          {`${t('settings:enterTheNameOfSlateView')} (`}
          <span style={{ fontWeight: 500 }}>
            {currentSlateView && currentSlateView.name}
          </span>
          {`) ${t('settings:confirmToDeleteIt')}:`}
        </div>
        <FormTextField
          name="confirmViewName"
          type="text"
          hintText={t('settings:slateViewName')}
          floatingLabelText={t('settings:slateViewName')}
          actions={actions}
          state={state}
          value={tryMe(state).confirmViewName || ''}
        />

        <FormSubmit
          title={t('btn:delete')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
          primary={false}
          style={styles.dialogButton}
          buttonStyle={styles.dialogButtonInline}
          labelColor={palette.white}
        />
      </div>
    );
  }
}
