import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ErrorDialog } from '../../components/Dialogs/ErrorDialog/ErrorDialog';

function mapStateToProps(state) {
  return {
    dialogs: state.dialogs,
  };
}

export default withTranslation()(connect(mapStateToProps)(ErrorDialog));
