import React, { Component, createContext } from 'react';
import MediaQuery from 'react-responsive';

import './style.css';
import { getScrollBarWidth } from '../../helpers/scrollWidthHelper';

const FooterContext = createContext({});

export const FooterProvider = FooterContext.Provider;
export const FooterConsumer = FooterContext.Consumer;

const MIN_WIDTH = 992;

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.observer = new MutationObserver((mutationsList, observer) => {
      if (mutationsList.find((mutation) => mutation.type === 'childList')) {
        const { onChildrenChange } = this.props;
        if (onChildrenChange) {
          onChildrenChange();
        }
      }
    });
  }

  UNSAFE_componentWillMount() {
    this.setState({ scrollBarWidth: getScrollBarWidth() });
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  setRef = (ref) => {
    this.observer.disconnect();
    if (ref) {
      this.observer.observe(ref, { childList: true });
      this.props.onSetRef(ref);
    }
  };

  render() {
    return (
      <MediaQuery minWidth={MIN_WIDTH}>
        {(matches) => {
          if (matches) {
            return (
              <div
                className="footer"
                ref={this.setRef}
                style={{ paddingRight: this.state.scrollBarWidth + 30 }}
              />
            );
          } else {
            return <div className="footer" ref={this.setRef} />;
          }
        }}
      </MediaQuery>
    );
  }
}
