import React, {useEffect, useState} from 'react';
import {getSlateLink} from '../../helpers/linkHelper';
import {QRCode} from "react-qrcode-logo";
import {QRCodeLogoLinkMedium} from "../../helpers/qrcodeHelper";
import classnames from "classnames";

const SlateNewData = ({uuid, t}) => {
  // In order to show the loader each time, we display the elements one by one
  const [hideFirstImage, setHideFirstImage] = useState(true);

  useEffect(() => {
    setHideFirstImage(!hideFirstImage);
  }, [uuid]);

  const link = getSlateLink(uuid);
  const isItProd = process.env.REACT_APP_ENV === 'production';
  return (
    <div className="slate-generator-container">
      <div className="slate-generator-item">
        <div className="slate-generator-item-name">
          {t('slateGenerator:slateUUID')}
        </div>
        <div className="slate-generator-item-value">{uuid}</div>
      </div>
      <div className="slate-generator-item">
        <div className="slate-generator-item-name">
          {t('slateGenerator:url')}
        </div>
        <div className="slate-generator-item-value">{link}</div>
      </div>

      <div className="slate-generator-item">
        <div className="slate-generator-item-name">
          {t('slateGenerator:qrCode')}
        </div>
        <div className={classnames({
          "slate-generator-qr-dev": !isItProd,
          "slate-generator-qr": isItProd,
        })}>
          {
            !hideFirstImage && isItProd &&
            <img
              src={`https://api.dev.slatepages.com/v2.0/slates/${uuid}/qr?size=500`}
              style={{width: 200, height: 200}}
            />
          }
          {
            hideFirstImage && isItProd &&
            <img
              src={`https://api.dev.slatepages.com/v2.0/slates/${uuid}/qr?size=500`}
              style={{width: 200, height: 200}}
            />
          }
          {
            !isItProd &&
            <QRCode
              value={link}
              size={200}
              ecLevel="H"
              logoImage={QRCodeLogoLinkMedium}
              logoWidth="60"
              logoHeight="60"
              padding={0}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default SlateNewData;
