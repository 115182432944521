import React from 'react';
import FontAwesome from 'react-fontawesome';
import { styles } from './styles';
import './styles.css';

const Expander = ({ isExpanded, onExpand, onCompress }) => {
  return isExpanded ? (
    <div className="expander" style={styles.expander} onClick={onCompress}>
      <FontAwesome name="compress" />
    </div>
  ) : (
    <div className="expander" style={styles.expander} onClick={onExpand}>
      <FontAwesome name="expand" />
    </div>
  );
};

export default Expander;
