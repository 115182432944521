// app actions
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_APP_LAYOUT = 'SET_APP_LAYOUT';
export const SET_APP_PATH = 'SET_APP_PATH';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const INCREMENT_WIZARD_STEP = 'INCREMENT_WIZARD_STEP';
export const DECREMENT_WIZARD_STEP = 'DECREMENT_WIZARD_STEP';
export const RESET_WIZARD_STEP = 'RESET_WIZARD_STEP';
export const SET_CURRENT_TIME_ZONE = 'SET_CURRENT_TIME_ZONE';
export const SET_TIME_ZONES = 'SET_TIME_ZONES';

// current user actions
export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const USER_ETAG_CHANGED = 'USER_ETAG_CHANGED';
export const OPEN_LOGIN_DIALOG = 'OPEN_LOGIN_DIALOG';
export const CLOSE_LOGIN_DIALOG = 'CLOSE_LOGIN_DIALOG';
export const SET_LOGIN_2FA_CODE_DATA = 'SET_LOGIN_2FA_CODE_DATA';

// images actions
export const ADD_IMAGE_LINK = 'ADD_IMAGE_LINK';
export const SET_ATTACHMENT = 'SET_ATTACHMENT';
export const CLEAR_ATTACHMENT = 'CLEAR_ATTACHMENT';

// current account actions
export const SET_ACCOUNTS = 'SET_ACCOUNTS_LIST';
export const SET_IS_SHOWING_NOTIFICATION = 'SET_IS_SHOWING_NOTIFICATION';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const UPDATE_ACCOUNT_TIMESTAMP = 'UPDATE_ACCOUNT_TIMESTAMP';
export const SET_API_KEYS = 'SET_API_KEYS';
export const SET_CURRENT_API_KEY = 'SET_CURRENT_API_KEY';
export const CLEAR_CURRENT_API_KEY = 'CLEAR_CURRENT_API_KEY';
export const SET_CREATED_API_KEY = 'SET_CREATED_API_KEY';
export const CLEAR_CREATED_API_KEY = 'CLEAR_CREATED_API_KEY';
export const SET_EXISTING_ACCOUNT = 'SET_EXISTING_ACCOUNT';

// entities actions
export const FETCH_START = 'FETCH_START';
export const FETCH_STOP = 'FETCH_STOP';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_RESET = 'FETCH_RESET';
export const ENTITY_UPDATED = 'ENTITY_UPDATED';
export const SET_VERIFICATION_METHOD = 'SET_VERIFICATION_METHOD';

//dialogs actions
export const ERROR_DIALOG_OPEN = 'ERROR_DIALOG_OPEN';
export const ERROR_DIALOG_CLOSE = 'ERROR_DIALOG_CLOSE';

//slates views
export const SET_SLATE_VIEWS_LIST = 'SET_SLATE_VIEWS_LIST';
export const SET_CURRENT_SLATE_VIEW = 'SET_CURRENT_SLATE_VIEW';
export const SET_CURRENT_SLATE_VIEW_WITHOUT_VIEW_LIST = 'SET_CURRENT_SLATE_VIEW_WITHOUT_VIEW_LIST';
export const SET_SLATES = 'SET_SLATES';
export const SET_SLATES_SEARCH_RESULTS = 'SET_SLATES_SEARCH_RESULTS';
export const CLEAR_SLATES = 'CLEAR_SLATES';
export const CLEAR_SLATE_VIEWS = 'CLEAR_SLATE_VIEWS';
export const SET_NEW_SLATE_VIEW_TYPE = 'SET_NEW_SLATE_VIEW_TYPE';
export const SET_SLATE_VIEW_SETTINGS = 'SET_SLATE_VIEW_SETTINGS';
export const CLEAR_SLATE_VIEW_SETTINGS = 'CLEAR_SLATE_VIEW_SETTINGS';
export const SET_SETTINGS_FIELDS = 'SET_SETTINGS_FIELDS';
export const CLEAR_SETTINGS_FIELDS = 'CLEAR_SETTINGS_FIELDS';
export const UPDATE_SETTINGS_FIELDS_FILTERS = 'UPDATE_SETTINGS_FIELDS_FILTERS';
export const CLEAN_SETTINGS_FIELDS_FILTERS = 'CLEAN_SETTINGS_FIELDS_FILTERS';
export const SET_SETTINGS_OPERATORS = 'SET_SETTINGS_OPERATORS';
export const REVERT_SETTINGS_OPERATORS = 'REVERT_SETTINGS_OPERATORS';
export const CONFIRM_SETTINGS_OPERATORS = 'CONFIRM_SETTINGS_OPERATORS';
export const OPEN_WARNING_SETTING_DIALOG = 'OPEN_WARNING_SETTING_DIALOG';
export const CLEAN_SNAPSHOT_PREVIOUS_VERSION = 'CLEAN_SNAPSHOT_PREVIOUS_VERSION';
export const CLEAR_SETTINGS_OPERATORS = 'CLEAR_SETTINGS_OPERATORS';
export const UPDATE_SLATE_PANE_STATE = 'UPDATE_SLATE_PANE_STATE';
export const UPDATE_SLATE_VIEW_FILTERS = 'UPDATE_SLATE_VIEW_FILTERS';
export const CLEAR_SLATE_VIEW_FILTERS = 'CLEAR_SLATE_VIEW_FILTERS';
export const SET_CURRENT_SLATE = 'SET_CURRENT_SLATE';
export const SET_JOURNAL_ENTRIES = 'SET_JOURNAL_ENTRIES';
export const CLEAR_JOURNAL_ENTRIES = 'CLEAR_JOURNAL_ENTRIES';
export const SET_RECORD_ENTRIES = 'SET_RECORD_ENTRIES';
export const CLEAR_RECORD_ENTRIES = 'CLEAR_RECORD_ENTRIES';
export const SET_HISTORY_ENTRIES = 'SET_HISTORY_ENTRIES';
export const CLEAR_HISTORY_ENTRIES = 'CLEAR_HISTORY_ENTRIES';
export const SET_RECORD_SLATE = 'SET_RECORD_SLATE';
export const CLEAR_RECORD_SLATE = 'CLEAR_RECORD_SLATE';
export const UPDATE_COLUMNS = 'UPDATE_COLUMNS';
export const UPDATE_SLATE_PANE_EXPANDED = 'UPDATE_SLATE_PANE_EXPANDED';
export const CLEAN_SLATE_VIEWS_AFTER_UPDATE_SETTINGS =
  'CLEAN_SLATE_VIEWS_AFTER_UPDATE_SETTINGS';

//jobs
export const SET_JOBS = 'SET_JOBS';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const SET_JOB_SETTINGS = 'SET_JOB_SETTINGS';
export const CLEAR_JOB_SETTINGS = 'CLEAR_JOB_SETTINGS';
export const SET_JOBS_TAB_UPDATE = 'SET_JOBS_TAB_UPDATE';

//map
export const UPDATE_MAP_TYPE = 'UPDATE_MAP_TYPE';

//metrics
export const SET_METRICS_SETTINGS = 'SET_METRICS_SETTINGS';
export const GET_METRICS_SETTINGS_START = 'GET_METRICS_SETTINGS_START';

export const SET_METRICS_WIDGETS = 'SET_METRICS_WIDGETS';
export const ADD_WIDGET_TO_METRICS = 'ADD_WIDGET_TO_METRICS';
export const GET_METRICS_WIDGETS_START = 'GET_METRICS_WIDGETS_START';
export const DELETE_WIDGET_FROM_METRICS = 'DELETE_WIDGET_FROM_METRICS';
export const GET_WIDGET_START = 'GET_WIDGET_START';
export const UPDATE_METRICS_GRID_WIDTH = 'UPDATE_METRICS_GRID_WIDTH';
export const CHANGE_METRICS_MODE = 'CHANGE_METRICS_MODE';
export const SET_METRICS_FILTERS = 'SET_METRICS_FILTERS';

export const ATTRACT_ATTENTION_TO_WIDGET = 'ATTRACT_ATTENTION_TO_WIDGET';
export const HIDE_ATTENTION_FROM_WIDGET = 'HIDE_ATTENTION_FROM_WIDGET';
export const SET_METRICS_ACTUAL_GRID_WIDTH = 'SET_METRICS_ACTUAL_GRID_WIDTH';
export const INIT_METRICS_SETTINGS = 'INIT_METRICS_SETTINGS';
export const START_METRICS_MODE_CHANGING = 'START_METRICS_MODE_CHANGING';
export const FINISH_METRICS_MODE_CHANGING = 'FINISH_METRICS_MODE_CHANGING';


//notifications
export const SET_FETCHING_INFO = 'SET_FETCHING_INFO';
export const CLEAR_FETCHING_TYPE = 'CLEAR_FETCHING_TYPE';
export const SET_ACTIVE_NOTIFICATION = 'SET_ACTIVE_NOTIFICATION';
export const SET_ACTIVE_NOTIFICATION_INFO = 'SET_ACTIVE_NOTIFICATION_INFO';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const SET_LIST_OF_NOTIFICATIONS = 'SET_LIST_OF_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
