import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import debounce from 'lodash/debounce';

import FormTextField from '../../Forms/FormTextField';
import { styles } from './styles';
import './style.css';

const DEBOUNCE_TIME = 500;

class Search extends Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    this.delayedSearch = debounce(props.onSearch, DEBOUNCE_TIME);
  }

  onChange = (event) => {
    const text = event.target.value;
    this.setState({ text }, () => this.delayedSearch(text));
  };

  onClearClick = () => {
    this.setState(
      {
        text: '',
      },
      () => this.props.onSearch(),
    );
  };

  render() {
    const { text } = this.state;
    const { autoFocus } = this.props;
    return (
      <div className="search-container">
        <FormTextField
          autocomplete="off"
          icon="search"
          iconStyle={styles.iconInInputCentered}
          className="search-input-wrapper"
          name={'Search'}
          hintText="Filter fields"
          type="text"
          value={text}
          state={{}}
          style={styles.style}
          inputStyle={styles.inputStyle}
          hintStyle={styles.hintStyle}
          underlineStyle={styles.underlineStyle}
          containerStyle={styles.containerStyle}
          actions={{
            handleChange: this.onChange,
            handleBlur: () => {},
          }}
          fullWidth={true}
          autoFocus={autoFocus}
        />
        <IconButton
          onClick={this.onClearClick}
          style={styles.clearButton}
          iconStyle={{ width: 20, height: 20 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
}

export default Search;
