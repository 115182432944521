const fieldsSources = {
  all: 'all',
  templates: 'templates',
  slates: 'slates',
};

export const getFieldsSourceOptions = (t) => [
  {
    value: fieldsSources.all,
    text: t('settings:availableFieldsSelect:options:all_fields'),
  },
  {
    value: fieldsSources.templates,
    text: t('settings:availableFieldsSelect:options:template_fields'),
  },
  {
    value: fieldsSources.slates,
    text: t('settings:availableFieldsSelect:options:slate_fields'),
  },
];

export const defaultFieldsSource = fieldsSources.all;
