import getMuiTheme from 'material-ui/styles/getMuiTheme';
import muiColors from './muiColors';
import p from './palette';

const muiTheme = getMuiTheme({
  fontFamily: 'Montserrat',
  borderRadius: 4,
  palette: muiColors,
  button: {
    height: 34,
  },
  raisedButton: {
    color: p.white,
    textColor: p.defaultText,
    secondaryTextColor: p.defaultText,
    disabledColor: 'transparent',
//---- global font setting ----
    fontWeight: 400,
  },
//---- global font setting ----
  h2: {
    fontWeight: 400,
  }
});

export default muiTheme;
