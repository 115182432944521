import React, {Component} from 'react';
import {close2faLoginPage, resend2faLink} from "../../store/actions/user";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import FormTextField from "../Forms/FormTextField";
import {entityNames} from "../../config/constants";
import tryMe from "../../helpers/tryMe";
import FormSubmit from "../../containers/Forms/FormSubmit";
import {fetchReset} from "../../store/actions/entities";
import styles from "../Forms/styles";
import CircularProgress from "material-ui/CircularProgress";

class Verification2faCodeForm extends Component {
  constructor() {
    super();
    this.state = {
      timer: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.actions.setFormState({
      code: '',
    });
    this.startTimer()
  }

  startTimer() {
    this.setState({
      timer: 30
    })
    const interval = setInterval(() => {
      if (this.state.timer - 1 >= 0) {
        this.setState({
          timer: this.state.timer - 1
        })
      } else {
        this.setState({
          timer: null
        })
        clearInterval(interval);
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state.code !== prevProps.state.code) {
      this.props.fetchReset(entityNames.VERIFICATION_ENABLE_2FA_CODE_BACK);
      this.props.actions.setFormState({
        dataErrors: {
          code: '',
        },
      });
    }
  }

  onSentLinkAgain = () => {
    this.startTimer()
    this.props.resend2faLink();
  }

  render() {
    const {actions, state, t, errors, entities} = this.props;
    return (
      <div className={'verification-2fa-code-container'}>
        <h3>{t('login:sentVerificationCode')}</h3>
        <div style={{marginBottom: 20}}>
          {t('login:receiveVerificationCode')}
        </div>
        {errors && (
          <div style={{marginTop: 12}}>
            <div style={styles.formError} className="form-error">
              {errors}
            </div>
          </div>
        )}
        <form onKeyPress={actions.handleKeyPress}>
          <FormTextField
            name="code"
            type="text"
            hintText={t('resetPassword:code')}
            floatingLabelText={t('resetPassword:code')}
            fullWidth={true}
            actions={actions}
            state={state}
            value={tryMe(state).code || ''}
          />
          <div className={'verification-2fa-code-link-container'}>
            <div className={'verification-2fa-code-link'}>
              <p className={'verification-2fa-code-link'}
                 style={{marginRight: 4}}>{t('verification:didntReceiveCode')}</p>
              {
                this.state.timer ?
                  <div className={'verification-2fa-code-link-disabled'}>
                    {t('verification:sendAnotherCode')}
                  </div>
                  : <a className={'verification-2fa-code-link'} href="#" onClick={this.onSentLinkAgain}>
                    {t('verification:sendAnotherCode')}
                  </a>
              }
            </div>
            {
              this.state.timer ? <div className="verification-code-timer">
                <CircularProgress
                  size={16}
                  value={Math.round(100 * this.state.timer / 30)}
                  mode={'determinate'}
                />
              </div> : null
            }
          </div>
          <div className={'verification-code-button'}>
            <FormSubmit
              title={t('btn:cancel')}
              handleSubmit={this.props.close2faLoginPage}
              buttonStyle={styles.cancelButton}
            />
            <FormSubmit
              title={t('verification:verifyAndLogin')}
              handleSubmit={actions.handleSubmit}
              handleMouseDown={actions.handleMouseDown}
              entityName={state.entityName}
              disabled={!state.code}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  entities: state.entities,
});

const mapDispatchToProps = {
  fetchReset,
  resend2faLink,
  close2faLoginPage,
};


export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(Verification2faCodeForm),
  ),
);