import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import RaisedButton from 'material-ui/RaisedButton';

import {
  deleteSlateView,
  fetchSlateViewsList,
  fetchSettingsOperators,
  clearSlateViewSettings,
  updateFieldsSource,
  updateColumns,
  revertFieldsSource,
  cleanFieldsSource,
  cleanSnapshotPreviousVersion,
  openWarningSettingDialog
} from 'store/actions/slateViews';
import { clearJobSettings } from 'store/actions/jobs';
import { showNotification } from 'store/actions/notification';
import Card from '../../Card';
import ListViewTypeSelector from 'components/SlateViewsSettings/ListViewTypeSelector';
import RecordFieldSelector from 'components/SlateViewsSettings/RecordFieldSelector';
import SettingsRules from '../../SlateViewsSettings/SettingsRules/SettingsRules';
import SettingsFields from 'components/FieldSelector/SettingsFields';
import SettingsOptions from '../../SlateViewsSettings/SettingsOptions';
import FormSubmit from 'containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';
import Preloader from '../../Preloader/Preloader';
import { HandleObjectSort } from 'helpers/HandleObjectSort';
import {
  logicOperatorTypes,
  resourceTypes,
  entityNames,
  columnTypes,
  notificationTypes,
  listViewTypes,
  ruleParts,
  entryDetails,
} from 'config/constants';
import formStyles from '../styles';
import FormWrapper from 'containers/Forms/FormWrapper';
import FormSlateViewDelete from 'containers/Forms/FormSlateViewDelete';
import FormSlateViewTimeZone from 'containers/Forms/FormSlateViewTimeZone';
import BasicDialog from 'components/Dialogs/BasicDialog';
import generateUUID from 'helpers/generateUUID';
import PinColors from '../../SlateViewsSettings/PinColors';
import IntoFooter from '../../Footer/IntoFooter';
import {
  defaultFieldsSource,
} from 'components/FieldSelector/FieldsSourceOptions';
import p from 'theme/palette';
import { history } from 'store/createStore';
import Checkbox from "material-ui/Checkbox";
import NewChip from "../../Shared/NewChip";

const styles = {
  name: {
    width: 360,
  },
  cancelButtonLabel: {
    color: p.white,
  },
  cancelButton: {
    backgroundColor: p.sidebarLink,
  },
  deleteButton: {
    backgroundColor: p.brandOrange,
  },
  buttonDisable: {
    backgroundColor: p.white,
    color: 'transparent',
  },
  chip: {
    color: 'white',
    backgroundColor: 'rgba(241, 90, 41, 0.84)',
    fontSize: 11,
    paddingTop: 2,
  },
};

const initialState = {
  name: '',
  ruleSet: {
    rules: [],
    andOr: logicOperatorTypes.AND,
  },
  pinColors: [],
  columns: [],
  includeHeaderRow: true, // for jobs only
  dataErrors: {},
  listViewType: listViewTypes.slates,
  exportJobType: listViewTypes.slates, // the same as listViewType for jobs
  recordsFieldUniquenessIdentifier: '',
  autoRefresh: false,
  currentRecordsTitle: '',
};

// From is used for slate view settings and export jobs. Trigger is resource type.
class FormSlateViewSettings extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    jobSettings: PropTypes.object,
    slateViewSettings: PropTypes.object,
    currentSlateView: PropTypes.object,
    resource: PropTypes.string,
    settingsFields: PropTypes.array,
    clearSlateViewSettings: PropTypes.func.isRequired,
    updateColumns: PropTypes.func.isRequired,
    revertFieldsSource: PropTypes.func.isRequired,
    cleanSnapshotPreviousVersion: PropTypes.func.isRequired,
    clearJobSettings: PropTypes.func.isRequired,
    deleteSlateView: PropTypes.func.isRequired,
    fetchSettingsOperators: PropTypes.func.isRequired,
    fetchSlateViewsList: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    updateFieldsSource: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    state: PropTypes.shape({
      exportJobType: PropTypes.string,
      listViewType: PropTypes.string,
      columns: PropTypes.array,
      name: PropTypes.string,
      recordsFieldUniquenessIdentifier: PropTypes.string,
      autoRefresh: PropTypes.bool,
      includeHeaderRow: PropTypes.bool,
      ruleSet: PropTypes.object,
      pinColors: PropTypes.array,
      entityName: PropTypes.string,
    }),
    isNewView: PropTypes.bool,
    settingsEntity: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    fieldsEntity: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    operatorsEntity: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    listViewEntity: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    mapViewEntity: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    exportJobEntity: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    match: PropTypes.object,
  };

  state = {
    availableFields: null,
    selectedFields: [],
    isRemoveDialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.setTypesHelper(props.resource);
  }

  UNSAFE_componentWillMount() {
    const { actions, jobSettings, slateViewSettings } = this.props;
    if (this.isJob && jobSettings && this.state.availableFields) {
      this.init(jobSettings, this.state.availableFields);
    } else if (!this.isJob && slateViewSettings && this.state.availableFields) {
      this.init(slateViewSettings, this.state.availableFields);
    } else {
      actions.setFormState(initialState);
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      resource,
      settingsFields,
      slateViewSettings,
      jobSettings,
      cleanSnapshotPreviousVersion,
      actions: { setFormState },
      lastStateBeforeUpdateSourceField,
      openWarningSettingDialog,
    } = this.props;
    if (nextProps.resource !== resource) {
      this.setTypesHelper(nextProps.resource);
    }

    // init state after settingsFields loading
    if (
      ((!slateViewSettings &&
      nextProps.slateViewSettings) ||
      (!jobSettings &&
      nextProps.jobSettings)) &&
      nextProps.settingsFields
    ) {
      const fields = cloneDeep(nextProps.settingsFields);
        this.sortFields(fields);
        const newAvailableFields = this.getAvailableFieldsArray(fields);
        const newSetting = this.isJob
          ? nextProps.jobSettings: nextProps.slateViewSettings
        if(newSetting) {
          this.init(newSetting, newAvailableFields)
        } else {setFormState(initialState)}
        if (nextProps.slateViewSettings.pinColors) {
          this.fetchPinColorsOperators(nextProps.slateViewSettings.pinColors);
        }
      return
    }

    // prepare available fields (after getting first request)
    if (
      (!settingsFields &&
      nextProps.settingsFields) ||
      (nextProps.lastStateBeforeUpdateSourceField.shouldCompare &&
        !this.state.availableFields)
    ) {
      if(nextProps.lastStateBeforeUpdateSourceField.shouldCompare){
        cleanSnapshotPreviousVersion()
      }
      const fields = cloneDeep(nextProps.settingsFields);
      this.sortFields(fields);
      const newAvailableFields = this.getAvailableFieldsArray(fields);
      const newSetting = this.isJob
        ? nextProps.jobSettings: nextProps.slateViewSettings
      if(newSetting) {
        this.init(newSetting, newAvailableFields)
        return
      } else {setFormState(initialState)}
    }


    // --- update new state after close popup (if user agree to continue) ----
    if(
      (!nextProps.lastStateBeforeUpdateSourceField.openPopup) &&
      (nextProps.lastStateBeforeUpdateSourceField.shouldCompare) &&
      ((nextProps.lastStateBeforeUpdateSourceField.openPopup !==
        lastStateBeforeUpdateSourceField.openPopup))
    ) {

      // ---- if user accept to lose information (in dialog window) refresh pages info -------

      // --- refresh available && columns Fields ----
      const fields = cloneDeep(nextProps.settingsFields);
      this.sortFields(fields);
      const newAvailableFields = this.getAvailableFieldsArray(fields);
      const {selectedFields, selectedFieldsState} = this.prepareSelectedFields(
        newAvailableFields, nextProps.state  && nextProps.state.columns,
      );

      this.setState({
        availableFields: newAvailableFields,
        selectedFields: selectedFieldsState,
      });

      // --- refresh rules & pin Fields ----
      let ruleSet = [], pinColors = [], recordsField = '';

      // check available record
      if(nextProps.state.recordsFieldUniquenessIdentifier){
        recordsField = newAvailableFields.some(i => {
          return i.suuid === nextProps.state.recordsFieldUniquenessIdentifier
        })
      }

      // check available rules
      if(nextProps.state.ruleSet.rules.length){
        ruleSet = nextProps.state.ruleSet.rules.filter(item => {
          if(item[ruleParts.RECORD_ENTRY_ATTRIBUTE]) return true
          return (item.fieldSUUID && newAvailableFields.some(i => {
            return i.suuid === item.fieldSUUID
          }))
        })
      }

      // check available pinColors
      if(nextProps.state.pinColors.length) {
        pinColors = nextProps.state.pinColors.map(main => {

          let rulesArr = [];
          if (main.ruleSet.rules.length) {
            main.ruleSet.rules.forEach(item => {
              if (item.fieldSUUID && newAvailableFields.some(i => {
                return i.suuid === item.fieldSUUID
              })) {
                rulesArr.push(item)
              }
            })
          }

          main.ruleSet.rules = rulesArr
          return main
        });
      }

      let newState = cloneDeep(nextProps.state);
      newState.ruleSet = {...nextProps.state.ruleSet, rules: ruleSet};
      newState.pinColors = pinColors;
      newState.columns = selectedFields;
      newState.recordsFieldUniquenessIdentifier = recordsField || '';
      setFormState(newState);

      cleanSnapshotPreviousVersion()
      return
    }

    // --- compare previous and new state (columns, rules and records fields) for get access to switch on new source field ----
    if (
      nextProps.lastStateBeforeUpdateSourceField.shouldCompare &&
      !(nextProps.state.columns.length +
        (nextProps.state.ruleSet.rules.length ? nextProps.state.ruleSet.rules.filter(rule => rule.fieldSUUID).length : 0) +
        nextProps.state.currentRecordsTitle.length +
        nextProps.state.pinColors.length)
    ) {
      const fields = cloneDeep(nextProps.settingsFields);
      this.sortFields(fields);
      const newAvailableFields = this.getAvailableFieldsArray(fields);
      const {selectedFields, selectedFieldsState} = this.prepareSelectedFields(
        newAvailableFields, nextProps.state && nextProps.state.columns,
      );
      let newState = cloneDeep(nextProps.state);
      newState.columns = selectedFields;
      setFormState(newState);
      this.setState({
        availableFields: newAvailableFields,
        selectedFields: selectedFieldsState,
      });
      cleanSnapshotPreviousVersion();
      return
    }

    if (
      nextProps.lastStateBeforeUpdateSourceField.shouldCompare &&
      (nextProps.state.columns.length +
        (nextProps.state.ruleSet.rules.length ? nextProps.state.ruleSet.rules.filter(rule => rule.fieldSUUID).length : 0) +
        nextProps.state.currentRecordsTitle.length +
        nextProps.state.pinColors.length)
    ) {

    const fields = cloneDeep(nextProps.settingsFields);
    this.sortFields(fields);
    const newAvailableFields = this.getAvailableFieldsArray(fields);
    const {selectedFields, selectedFieldsState} = this.prepareSelectedFields(
      newAvailableFields, nextProps.state.columns
    );
    const diffColumns = [],
          diffRecords = [],
          diffRules = [],
          diffPinColors = [];


      // check difference columns
      if(selectedFields.length !== nextProps.state.columns.length){
      nextProps.state.columns.forEach(item => {
        if(!selectedFields.some(i => {
          return (
            (item.suuid && (i.suuid === item.suuid)) ||
            (item.cuuid && (i.sourceCuuid === item.cuuid)) ||
            (item.fieldSUUID && (i.suuid === item.fieldSUUID)) ||
            (item.attribute && (i.suuid === item.attribute)))
          })){
          diffColumns.push({title: item.title, fieldAttribute: item.fieldAttribute});
        }
      });
    }

      // check difference records
    if(nextProps.state.currentRecordsTitle && nextProps.state.recordsFieldUniquenessIdentifier){
      if(!newAvailableFields.some(i => (i.suuid === nextProps.state.recordsFieldUniquenessIdentifier))){
        diffRecords.push(nextProps.state.currentRecordsTitle)
      }
    }

      // check difference rules
    if(nextProps.state.ruleSet.rules.length){
      nextProps.state.ruleSet.rules.forEach(item => {
        if(
          (item[ruleParts.RECORD_ENTRY_ATTRIBUTE]) &&
          item.fieldSUUID &&
          !newAvailableFields.some(i => {
            return i.suuid === item.fieldSUUID
          })
        ){
          diffRules.push({title: item.title, operation: item.operation, value: item.value});
        }
      });
    }

      // check difference pinColors
    if(nextProps.state.pinColors.length) {
      nextProps.state.pinColors.forEach(main => {
        if (main.ruleSet.rules.length) {
          main.ruleSet.rules.forEach(item => {
            if (item.fieldSUUID && !newAvailableFields.some(i => {
              return i.suuid === item.fieldSUUID
            })) {
              diffPinColors.push({title: item.title, operation: item.operation, value: item.value, color: main.color})
            }
          });
        }
      });
    }

    if([...diffColumns, ...diffRecords, ...diffRules, ...diffPinColors].length) {
      if(lastStateBeforeUpdateSourceField.lastData.sourceField ===
        nextProps.currentFieldsSource) return ;

        openWarningSettingDialog(
          {
            newOption: nextProps.currentFieldsSource,
            selectedFields: {
              diffColumns,
              diffRecords,
              diffRules,
              diffPinColors,
            }
          }
        );
        return;
    }
    let newState = cloneDeep(nextProps.state);
    newState.columns = selectedFields;
    setFormState(newState);

    this.setState({
      availableFields: newAvailableFields,
      selectedFields: selectedFieldsState,
    });
    cleanSnapshotPreviousVersion();
    return
  }





    // check slate view settings
    if (
      nextProps.slateViewSettings &&
      !isEqual(slateViewSettings, nextProps.slateViewSettings) &&
      this.state.availableFields
    ) {
      this.init(nextProps.slateViewSettings, this.state.availableFields);
      if (nextProps.slateViewSettings.pinColors) {
        this.fetchPinColorsOperators(nextProps.slateViewSettings.pinColors);
      }
      return
    }

    // check jobs settings
    if (
      nextProps.jobSettings &&
      !isEqual(jobSettings, nextProps.jobSettings) &&
      this.state.availableFields
    ) {
      this.init(nextProps.jobSettings, this.state.availableFields);
    }
  };

  componentWillUnmount = () => {
    const { clearSlateViewSettings, clearJobSettings } = this.props;
    clearSlateViewSettings();
    clearJobSettings();
  };

  render() {
    const {
      isNewView,
      deleteSlateView,
      actions,
      state,
      state: {
        exportJobType,
        listViewType,
        name,
        recordsFieldUniquenessIdentifier,
        autoRefresh,
        ruleSet,
        pinColors,
        entityName,
        includeHeaderRow,
      },
      fetchSettingsOperators,
      t,
    } = this.props;
    const { isRemoveDialogOpen, availableFields, selectedFields } = this.state;

    const isLoading = this.checkLoading();
    const isSaving = this.checkSaving();
    const isRecordListView = this.isJob
      ? exportJobType === listViewTypes.records
      : listViewType === listViewTypes.records;
    const currentListViewType = this.isJob ? exportJobType : listViewType;
    return (
      <form>
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <Card
              title={t('settings:basicInformation')}
              content={
                <>
                  <div className="settings-name-wrapper">
                    <FormTextField
                      name="name"
                      value={name || ''}
                      style={styles.name}
                      type="text"
                      floatingLabelText={t('settings:slateViewTitle')}
                      state={state}
                      actions={actions}
                    />
                  </div>
                  {!this.isMapView && (
                    <div className="settings-type-selector">
                      <ListViewTypeSelector
                        types={listViewTypes}
                        listViewType={currentListViewType}
                        onChange={this.setListViewType}
                      />
                    </div>
                  )}
                  <div className="settings-basic-info-box">
                    <Checkbox
                      className="settings-refresh-checkbox"
                      label={t('settings:autoRefreshListView')}
                      checked={autoRefresh}
                      onCheck={this.refreshTableChange}
                    />
                    <NewChip />
                  </div>
                </>
              }
            />

            {availableFields && (
              <>
                {isRecordListView ? (
                  <>
                    <Card
                      title={t('settings:selectRecord')}
                      subtitle={t('settings:thisListViewWillShow')}
                      content={
                        <RecordFieldSelector
                          currentSUUID={recordsFieldUniquenessIdentifier}
                          fields={availableFields}
                          onChange={this.setRecordField}
                        />
                      }
                    />
                    {(recordsFieldUniquenessIdentifier || !!ruleSet.rules.length) && (
                      <Card
                        title={t('settings:slateFilteringRules')}
                        subtitle={t('settings:defineIncludedSlates')}
                        content={
                          <SettingsRules
                            isRecordListView={isRecordListView}
                            value={ruleSet}
                            onChange={this.handleRuleSetChange}
                            fields={availableFields}
                            fetchSettingsOperators={fetchSettingsOperators}
                            recordsFieldUniquenessIdentifier={
                              recordsFieldUniquenessIdentifier
                            }
                          />
                        }
                      />
                    )}
                  </>
                ) : (
                  <Card
                    title={t('settings:slateFilteringRules')}
                    subtitle={t('settings:defineIncludedSlates')}
                    content={
                      <SettingsRules
                        isRecordListView={isRecordListView}
                        value={ruleSet}
                        onChange={this.handleRuleSetChange}
                        fields={availableFields}
                        fetchSettingsOperators={fetchSettingsOperators}
                      />
                    }
                  />
                )}

                {this.isMapView && (
                  <Card
                    title={t('settings:pinColors')}
                    subtitle={t('settings:definePinColors')}
                    content={
                      <PinColors
                        value={pinColors}
                        onChange={this.handlePinColorsChange}
                        fields={availableFields}
                        fetchSettingsOperators={fetchSettingsOperators}
                      />
                    }
                  />
                )}

                {(this.isListView || this.isJob) && (
                  <Card
                    title={t('settings:listColumns')}
                    subtitle={t('settings:toDisplayInYourList')}
                    content={
                      <SettingsFields
                        fields={availableFields}
                        selectedFields={selectedFields}
                        onSelectedFieldsChange={this.handleSelectedFieldsChange}
                        listViewType={currentListViewType}
                        recordSuuid={recordsFieldUniquenessIdentifier}
                      />
                    }
                  />
                )}
              </>
            )}

            {this.isJob && (
              <Card
                title={t('settings:additionalOptions')}
                content={
                  <SettingsOptions
                    includeHeaderRow={includeHeaderRow}
                    onChange={this.handleChangeJobsOptions}
                    t={t}
                  />
                }
              />
            )}
          </>
        )}

        <IntoFooter>
          <div className="settings-actions">
            <RaisedButton
              className="btn settings-action-cancel-btn"
              labelStyle={isSaving ? null : styles.cancelButtonLabel}
              buttonStyle={isSaving ? styles.buttonDisable : styles.cancelButton}
              label={t('btn:cancel')}
              disabled={isSaving}
              onClick={isSaving ? null : this.handleCancelBtn}
            />

            <FormSubmit
              title={this.isJob ? t('btn:saveRun') : t('btn:save')}
              handleSubmit={this.handleFormSubmit}
              handleMouseDown={actions.handleMouseDown}
              entityName={entityName}
              disabled={isLoading}
            />

            {
              this.isJob &&
                <div style={formStyles.warmingRowContainer}>
                  <FormSlateViewTimeZone/>
                </div>
            }

            {!isNewView && !this.isJob && (
              <>
                <FormSubmit
                  title={t('settings:deleteView')}
                  handleSubmit={this.handleOpenRemoveDialog}
                  handleMouseDown={actions.handleMouseDown}
                  primary={false}
                  style={formStyles.bottomButtonRight}
                  buttonStyle={
                    isLoading || isSaving
                      ? styles.buttonDisable
                      : styles.deleteButton
                  }
                  labelColor={p.white}
                  disabled={isLoading || isSaving}
                />
                <BasicDialog
                  title={t('settings:deleteConfirm')}
                  open={isRemoveDialogOpen}
                  onClose={this.handleCloseDialog}
                  actions={[
                    <RaisedButton
                      key="cancel"
                      className="jobs-item-btn-text"
                      label={t('btn:cancel')}
                      onClick={this.handleCloseDialog}
                    />,
                  ]}
                >
                  <div className="page_account-settings-dialog">
                    <FormWrapper
                      type="edit"
                      entityName={entityNames.DELETE_VIEW}
                      entity={['confirmViewName']}
                      onSubmit={deleteSlateView}
                    >
                      <FormSlateViewDelete />
                    </FormWrapper>
                  </div>
                </BasicDialog>
              </>
            )}
          </div>
        </IntoFooter>
      </form>
    );
  }

  init = (settings, availableFields) => {
    const { actions } = this.props;
    const {selectedFields, selectedFieldsState} = this.prepareSelectedFields(
      availableFields,
      settings.columns,
    );

    const currentRecords = availableFields
      .find(field => settings.recordsFieldUniquenessIdentifier === field.suuid)

    const newRuleSet = {
      andOr: settings.ruleSet.andOr,
      rules: settings.ruleSet.rules && settings.ruleSet.rules.map(item => {
        const currentItem = availableFields.find(field => item.fieldSUUID === field.suuid)
        const title = currentItem ? currentItem.title : ''
        return {...item, title}
      })
    }

    const newPinColors = settings.pinColors && settings.pinColors.map( main => {
      const newRuleSet = cloneDeep(main);
      if (main.ruleSet.rules.length) {
        newRuleSet.ruleSet.rules = main.ruleSet.rules.map(item => {
          const currentItem = availableFields.find(field => item.fieldSUUID === field.suuid)
          const title = currentItem? currentItem.title : ''
          return {...item, title}
        })
      }
      return newRuleSet
    });

    actions.setFormState({
      name: settings.name || '',
      ruleSet: newRuleSet || {
        rules: [],
        andOr: logicOperatorTypes.AND,
      },
      pinColors: newPinColors || [],
      columns: selectedFields || [],
      listViewType: settings.listViewType || listViewTypes.slates,
      exportJobType: settings.exportJobType || listViewTypes.slates,
      currentRecordsTitle: currentRecords ? currentRecords.title : '',
      recordsFieldUniquenessIdentifier:
        settings.recordsFieldUniquenessIdentifier || '',
      autoRefresh:
        settings.autoRefresh,
      includeHeaderRow:
        settings.includeHeaderRow !== undefined
          ? settings.includeHeaderRow
          : true,
      dataErrors: {},
    });

    this.fetchOperators(settings.ruleSet.rules);
    this.setState({
      selectedFields: selectedFieldsState,
      availableFields,
    });
  };

  setListViewType = (type) => {
    const {
      state,
      actions: { setFormState },
      showNotification,
      updateFieldsSource,
      t,
    } = this.props;

    if (type === listViewTypes.records) {
      // do not allow switch the list view type to records if rules or columns exist
      if (state.ruleSet.rules.length) {
        showNotification(
          notificationTypes.WARNING,
          t('notifications:allSlatesFilteringRulesMustBeRemoved'),
        );
        return;
      }
      if (state.columns.length) {
        showNotification(
          notificationTypes.WARNING,
          t('notifications:allListColumnsMustBeRemoved'),
        );
        return;
      }
    }

    if (type === listViewTypes.slates) {
      if (state.ruleSet.rules.length) {
        const ruleTitlesList = state.ruleSet.rules.reduce((arr, item) => {
          if (item[ruleParts.RECORD_ENTRY_ATTRIBUTE]) {
            return [...arr, entryDetails[item[ruleParts.RECORD_ENTRY_ATTRIBUTE]].title]
          }
          return arr
        }, [])

        showNotification(
          notificationTypes.WARNING,
          t('notifications:slatesFilteringRulesMustBeRemoved',
            {
              moreThenOne: ruleTitlesList.length > 1 ? 's' : '',
              ruleTitlesList: ruleTitlesList.join(', ')
            })
        );
        return;
      }
      if (state.columns.length) {
        showNotification(
          notificationTypes.WARNING,
          t('notifications:yourRecordEntryColumnsAreStillSelected'),
        );
        return;
      }
    }

    let newState = cloneDeep(state);
    if (this.isJob) {
      newState.exportJobType = type;
    } else {
      newState.listViewType = type;
    }
    newState.recordsFieldUniquenessIdentifier = '';
    newState.currentRecordsTitle = '';
    setFormState(newState);
    this.setState({selectedFields: []})
    // set 'include' settings option to default
    updateFieldsSource(defaultFieldsSource);
  };

  setRecordField = (suuid, title) => {
    const {
      state,
      actions: { setFormState },
    } = this.props;
    let newState = cloneDeep(state);
    newState.recordsFieldUniquenessIdentifier = suuid;
    newState.currentRecordsTitle = title;

    // ---- check available rules fields ( record Entry Attribute ) ----
    const rules = state.ruleSet.rules.filter( rule => rule[ruleParts.RECORD_ENTRY_ATTRIBUTE])
    newState.ruleSet = {...state.ruleSet, rules};

    newState.columns = [];
    setFormState(newState);
    this.setState({
      selectedFields: [],
    });
  };

  handleOpenRemoveDialog = () => {
    this.setState({
      isRemoveDialogOpen: true,
    });
  };

  handleFormSubmit = () => {
    const { t, actions, state: {
      columns,
      ruleSet,
    }} = this.props;
    const noColumnsSelected = !(columns && columns.length);
    let hasEmptyRuleValue = false;
    ruleSet.rules.forEach((rule) => {
      if((rule.operation !== t('btn:existsOnSlate')
        && rule.operation !== t('btn:doesntExistOnSlate')
        && rule.operation !== t('btn:hasValue')
        && rule.operation !== t('btn:doesntHaveValue')
        && rule.value === '')
        || ((rule.fieldSUUID === undefined
        || rule.fieldSUUID === null
        || rule.fieldSUUID === "")
          && (
            rule.recordEntryAttribute !== entryDetails.userDisplayName.recordEntryAttribute
            && rule.recordEntryAttribute !== entryDetails.timestamp.recordEntryAttribute
          ))
        || rule.operation === undefined
        || rule.operation === null
        || rule.operation === ""
      ) {
        hasEmptyRuleValue = true;
      }});

    if (hasEmptyRuleValue) {
      this.showEmptyRuleValueWarning();
    } else {
      return noColumnsSelected && !this.isMapView
        ? this.showEmptyColumnsWarning()
        : actions.handleSubmit()
    }
  };

  handleCloseDialog = () => {
    this.setState({
      isRemoveDialogOpen: false,
    });
  };

  setTypesHelper = (resource) => {
    this.isListView = resource === resourceTypes.LIST_VIEW;
    this.isMapView = resource === resourceTypes.MAP_VIEW;
    this.isJob = resource === resourceTypes.JOB;
  };

  refreshTableChange = (e, checked) => {
    const {
      state,
      actions: { setFormState },
    } = this.props;

    let newState = cloneDeep(state);
    newState.autoRefresh = checked;
    setFormState(newState);
  };

  handleRuleSetChange = (ruleSet) => {
    const {
      state,
      actions: { setFormState },
    } = this.props;
    let newState = cloneDeep(state);
    newState.ruleSet = ruleSet;
    setFormState(newState);
  };

  handlePinColorsChange = (pinColors) => {
    const {
      state,
      actions: { setFormState },
    } = this.props;
    let newState = cloneDeep(state);
    newState.pinColors = pinColors;
    setFormState(newState);
  };

  handleSelectedFieldsChange = (newFields) => {
    let newState = cloneDeep(this.props.state);
    let columns = [];

    newFields.forEach((field, index) => {
      let column = {
        orderIndex: index + 1,
        cuuid: field.cuuid,
        sourceCuuid: field.sourceCuuid,
        title: field.title,
      };
      if (field.meta) {
        column = {
          ...column,
          columnType: columnTypes.METADATA,
          attribute: field.suuid,
        };
      } else {
        column = {
          ...column,
          columnType: columnTypes.FIELD,
          fieldSUUID: field.suuid,
          fieldAttribute: field.fieldAttribute,
        };
      }
      columns.push(column);
    });

    newState.columns = columns;
    this.props.actions.setFormState(newState);
    this.setState({
      selectedFields: newFields,
    });
  };

  handleChangeJobsOptions = () => {
    const {
      state,
      actions: { setFormState },
    } = this.props;
    const newState = cloneDeep(state);
    newState.includeHeaderRow = !newState.includeHeaderRow;
    setFormState(newState);
  };

  getAvailableFieldsArray = (fields, parent, result = []) => {
    if(!fields) return [];
    fields.forEach((field) => {
      field.isShown = true; // for search

      if (parent) {
        field.parent = parent;
        field.parents = parent.parents.concat([parent]);
      } else {
        field.parents = [];
      }
      result.push(field);

      if (field.children) {
        field.isShowChildren = true; // for collapse
        this.getAvailableFieldsArray(field.children, field, result);
      }
    });
    return result;
  };

  prepareSelectedFields = (availableFields, columns) => {
    if (!availableFields || !columns) {
      return {selectedFields: [], selectedFieldsState: []};
    }
    const selectedFields = [];
    const selectedFieldsState = [];
    const sortedColumns = orderBy(columns, ['orderIndex'], ['asc']);
    sortedColumns.forEach((column, index) => {
      const field = find(
        availableFields,
        (item) => (item.suuid === column.fieldSUUID) || (item.suuid === column.suuid) || (item.suuid === column.attribute));
      if (field) {
        const newField = {
          cuuid: generateUUID(),
          sourceCuuid: column.cuuid,
          fieldSUUID: column.fieldSUUID,
          columnType: field.meta ? columnTypes.METADATA : columnTypes.FIELD,
          orderIndex: index + 1,
          title: field.title
        }
        if(field.meta){
          newField.attribute = column.attribute
        } else {
          newField.fieldAttribute = column.fieldAttribute
        }
        selectedFields.push(newField);
        selectedFieldsState.push({
          ...field,
          fieldAttribute: column.fieldAttribute,
          cuuid: generateUUID(),
          sourceCuuid: column.cuuid,
        });
      }
    });

    return {selectedFields, selectedFieldsState};
  };

  sortFields = (fields) => {
    if(!fields) return [];
    fields.sort(HandleObjectSort.alphaNumericTitle);

    // !! All fields are sorted alphabetically
    // !! The task is to swap fields "Slate Photo" & "Slate Title"
    let indexSlatePhotoFields, indexSlateTitleFields;


    fields.forEach((field, index) => {

      // !! find indexes "Slate Photo" & "Slate Title" fields
      field.suuid === 'title' && (indexSlateTitleFields = index);
      field.suuid === 'photo' && (indexSlatePhotoFields = index);

      if (field.children) {
        this.sortFields(field.children);
      }
    });

    // !! We swap fields "Slate Photo" & "Slate Title"
    if(
      typeof(indexSlatePhotoFields) != 'undefined' &&
      typeof(indexSlateTitleFields) != 'undefined'
    ) {
      fields[indexSlatePhotoFields] = [
        fields[indexSlateTitleFields],
        fields[indexSlateTitleFields] = fields[indexSlatePhotoFields]
      ][0];
    }

    return fields;
  };

  fetchOperators = (rules) => {
    rules.forEach((rule) =>
      this.props.fetchSettingsOperators(rule.fieldSUUID, true),
    );
  };

  fetchPinColorsOperators = (pinColors) => {
    pinColors.forEach((pinColor) => {
      if (pinColor.ruleSet && pinColor.ruleSet.rules) {
        this.fetchOperators(pinColor.ruleSet.rules);
      }
    });
  };

  checkLoading = () => {
    const {
      settingsEntity,
      fieldsEntity,
      settingsFields,
    } = this.props;
    let settingsFieldsLoading =
      !settingsFields || (settingsFields && !settingsFields.length);

    let isLoading =
      (settingsEntity && settingsEntity.isFetching) ||
      (fieldsEntity && fieldsEntity.isFetching && settingsFieldsLoading);
    return isLoading === undefined ? true : isLoading;
  };

  checkSaving = () => {
    const { listViewEntity, mapViewEntity, exportJobEntity } = this.props;
    let isSaving = false;
    if (this.isListView) {
      if (listViewEntity && listViewEntity.isFetching) {
        isSaving = true;
      }
    } else if (this.isMapView) {
      if (mapViewEntity && mapViewEntity.isFetching) {
        isSaving = true;
      }
    } else {
      if (exportJobEntity && exportJobEntity.isFetching) {
        isSaving = true;
      }
    }
    return isSaving;
  };

  handleCancelBtn = () => {
    history.goBack();
  };

  showEmptyColumnsWarning = () => {
    const { t, showNotification } = this.props;
    showNotification(
      notificationTypes.WARNING,
      t(
        this.isJob
          ? 'notifications:toExportListView'
          : 'notifications:toSaveListView',
      ),
    );
  };

  showEmptyRuleValueWarning = () => {
    const { t, showNotification } = this.props;
    showNotification(
      notificationTypes.WARNING,
      t('notifications:emptyRuleValue'),
    );
  };

}

const mapStateToProps = (state) => ({
  slateViewSettings: state.slateViews.settings,
  lastStateBeforeUpdateSourceField: state.slateViews.snapshotOfTheCurrentStateForBeAbleToRevert,
  currentSlateView: state.slateViews.currentSlateView,
  currentFieldsSource: state.slateViews.settingsFieldsFilters.include,
  settingsFields: state.slateViews.settingsFields,
  jobSettings: state.jobs.settings,
  // for disabling action buttons on load
  settingsEntity: state.entities[entityNames.GET_SLATE_VIEW_SETTINGS],
  fieldsEntity: state.entities[entityNames.GET_SETTINGS_FIELDS],
  operatorsEntity: state.entities[entityNames.GET_SETTINGS_OPERATORS],
  // for disabling action buttons on save
  listViewEntity: state.entities[entityNames.UPDATE_LIST_VIEW],
  mapViewEntity: state.entities[entityNames.UPDATE_MAP_VIEW],
  exportJobEntity: state.entities[entityNames.UPDATE_EXPORT_JOB],
});

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, {
      fetchSettingsOperators,
      clearJobSettings,
      deleteSlateView,
      fetchSlateViewsList,
      clearSlateViewSettings,
      showNotification,
      updateFieldsSource,
      updateColumns,
      revertFieldsSource,
      cleanSnapshotPreviousVersion,
      openWarningSettingDialog,
      cleanFieldsSource,
    })(FormSlateViewSettings),
  ),
);
