import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { Tabs, Tab } from 'material-ui/Tabs';
import FontAwesome from 'react-fontawesome';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  fetchJobs,
  clearJobs,
  updateJob,
  cloneJob,
  rerunJob,
  removeJob,
  setJobsTabUpdate,
  clearJobSettings,
} from 'store/actions/jobs';
import JobsList from './JobsList';
import BasicDialog from 'components/Dialogs/BasicDialog';
import Preloader from '../Preloader/Preloader';
import { route, jobsTabs, jobsStatuses } from 'config/constants';
import { createReplacePatch } from 'helpers/patchHelper';
import palette from 'theme/palette';
import { styles } from './styles';
import './style.css';
import FormSlateViewTimeZone from "../../containers/Forms/FormSlateViewTimeZone";
import formStyles from "../Forms/styles";
import theme from "../SlateViews/theme";

const API_CALL_INTERVAL = 1000;

class ExportJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: jobsTabs.ACTIVE,
      activeJobs: props.jobs[jobsTabs.ACTIVE],
      favoritesJobs: props.jobs[jobsTabs.FAVORITES],
      archivedJobs: props.jobs[jobsTabs.ARCHIVED],
      currentJob: undefined,
      isRerunDialogOpen: false,
      isCloneDialogOpen: false,
      isRemoveDialogOpen: false,
      isArchiveDialogOpen: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    const { currentAccount, fetchJobs } = this.props;
    if (currentAccount) {
      fetchJobs(jobsTabs.ACTIVE);
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { currentAccount, jobs, fetchJobs, setJobsTabUpdate } = this.props;
    const { currentTab } = this.state;

    // check if account changed
    if (nextProps.currentAccount) {
      if (
        currentAccount &&
        currentAccount.uuid !== nextProps.currentAccount.uuid
      ) {
        fetchJobs(currentTab);
      }
      if (!currentAccount) {
        fetchJobs(currentTab);
      }
    }

    // check if jobs changed
    if (!isEqual(jobs, nextProps.jobs)) {
      this.setState({
        activeJobs: nextProps.jobs[jobsTabs.ACTIVE],
        favoritesJobs: nextProps.jobs[jobsTabs.FAVORITES],
        archivedJobs: nextProps.jobs[jobsTabs.ARCHIVED],
      });

      // update list of jobs if at least one is in progress
      if (this.isUnfinishedJobExist(nextProps.jobs[currentTab])) {
        if (!this.interval) {
          this.interval = setInterval(this.handleFetchJobs, API_CALL_INTERVAL);
        }
      } else {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = undefined;
        }
      }
    }

    // check if jobs list need update
    if (nextProps.needUpdateTab) {
      setJobsTabUpdate();
      this.handleChangeTab(nextProps.needUpdateTab);
    }
  };

  componentWillUnmount = () => {
    this.props.clearJobs();
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  render() {
    let {
      currentTab,
      activeJobs,
      favoritesJobs,
      archivedJobs,
      currentJob,
      isRerunDialogOpen,
      isCloneDialogOpen,
      isRemoveDialogOpen,
      isArchiveDialogOpen,
    } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="tools-export-header">
          <h1 className="tools-export-title">{t('export:exportJobs')}</h1>
          <RaisedButton
            label={t('export:createNewExportJob')}
            onClick={this.createNewJob}
            icon={<FontAwesome name="plus" style={theme.slateViewActionIconStyle}/>}
          />
        </div>

        <div className="tools-export-body">
          <Tabs
            value={currentTab}
            onChange={this.handleChangeTab}
            tabItemContainerStyle={styles.tabsItemsStyle}
            inkBarStyle={styles.tabsInkStyle}
          >
            <Tab
              label={t('export:active')}
              icon={<FontAwesome name="bolt" />}
              value={jobsTabs.ACTIVE}
            >
              <div className="tools-export-tab">
                {activeJobs ? (
                  <JobsList
                    jobs={activeJobs}
                    tab={jobsTabs.ACTIVE}
                    onRerunClick={this.handleOpenRerunDialog}
                    onCloneClick={this.handleOpenCloneDialog}
                    onFavoriteClick={this.handleToggleFavorite}
                    onRemoveClick={this.handleOpenArchiveDialog}
                  />
                ) : (
                  <Preloader style={styles.preloader} />
                )}
              </div>
            </Tab>

            <Tab
              label={t('export:favorites')}
              icon={<FontAwesome name="star-o" />}
              value={jobsTabs.FAVORITES}
            >
              <div className="tools-export-tab">
                {favoritesJobs ? (
                  <JobsList
                    jobs={favoritesJobs}
                    tab={jobsTabs.FAVORITES}
                    onRerunClick={this.handleOpenRerunDialog}
                    onCloneClick={this.handleOpenCloneDialog}
                    onFavoriteClick={this.handleToggleFavorite}
                    onRemoveClick={this.handleOpenArchiveDialog}
                  />
                ) : (
                  <Preloader style={styles.preloader} />
                )}
              </div>
            </Tab>

            <Tab
              label={t('export:archived')}
              icon={<FontAwesome name="history" />}
              value={jobsTabs.ARCHIVED}
            >
              <div className="tools-export-tab">
                {archivedJobs ? (
                  <JobsList
                    jobs={archivedJobs}
                    tab={jobsTabs.ARCHIVED}
                    onRerunClick={this.handleOpenRerunDialog}
                    onCloneClick={this.handleOpenCloneDialog}
                    onFavoriteClick={this.handleToggleFavorite}
                    onRemoveClick={this.handleOpenRemoveDialog}
                  />
                ) : (
                  <Preloader style={styles.preloader} />
                )}
              </div>
            </Tab>
          </Tabs>
        </div>

        <BasicDialog
          title={t('export:rerunExportJob')}
          open={isRerunDialogOpen}
          onClose={this.handleCloseDialog}
          actions={[
            <RaisedButton
              key="cancel"
              label={t('export:cancel')}
              className="jobs-item-btn-text"
              onClick={this.handleCloseDialog}
            />,
            <RaisedButton
              key="rerunJobNow"
              label={t('export:rerunJobNow')}
              primary={true}
              onClick={() => this.handleRerunJob(currentJob)}
            />,
          ]}
        >
          <div className="tools-export-dialog-body">
            {t('export:rerunTheExportJob')}{' '}
            <span className="tools-export-bold">
              {currentJob && currentJob.name}
            </span>
            ?
          </div>

          <div style={formStyles.warmingContainer}>
            <FormSlateViewTimeZone/>
          </div>

        </BasicDialog>

        <BasicDialog
          title={t('export:copyToNewExportJob')}
          open={isCloneDialogOpen}
          onClose={this.handleCloseDialog}
          actions={[
            <RaisedButton
              key="cancel"
              label={t('export:cancel')}
              className="jobs-item-btn-text"
              onClick={this.handleCloseDialog}
            />,
            <RaisedButton
              key="copyToNewJob"
              label={t('export:copyToNewJob')}
              primary={true}
              onClick={() => this.handleCloneJob(currentJob)}
            />,
          ]}
        >
          <div className="tools-export-dialog-body">
            {t('export:copyExportJob')}{' '}
            <span className="tools-export-bold">
              {currentJob && currentJob.name}
            </span>{' '}
            {t('export:youWillBeAbleToCustomize')}
          </div>
        </BasicDialog>

        <BasicDialog
          title={`${t('export:archiveExportJob')}?`}
          open={isArchiveDialogOpen}
          onClose={this.handleCloseDialog}
          actions={[
            <RaisedButton
              key="cancel"
              label={t('export:cancel')}
              className="jobs-item-btn-text"
              onClick={this.handleCloseDialog}
            />,
            <RaisedButton
              key="archiveExportJob"
              label={t('export:archiveExportJob')}
              backgroundColor={palette.error}
              labelColor={palette.white}
              onClick={() => this.handleArchiveJob(currentJob)}
            />,
          ]}
        >
          <div className="tools-export-dialog-body">
            {t('export:archiveTheExportJob')}{' '}
            <span className="tools-export-bold">
              {currentJob && currentJob.name}
            </span>{' '}
            {t('export:fromRecentAndFavorites')}
          </div>
        </BasicDialog>

        <BasicDialog
          title={`${t('export:deleteExportJob')}?`}
          open={isRemoveDialogOpen}
          onClose={this.handleCloseDialog}
          actions={[
            <RaisedButton
              key="cancel"
              label={t('export:cancel')}
              className="jobs-item-btn-text"
              onClick={this.handleCloseDialog}
            />,
            <RaisedButton
              key="deleteExportJob"
              label={t('export:deleteExportJob')}
              backgroundColor={palette.error}
              labelColor={palette.white}
              onClick={() => this.handleRemoveJob(currentJob)}
            />,
          ]}
        >
          <div className="tools-export-dialog-body">
            {t('export:permanentlyDeleteExportJob')}{' '}
            <span className="tools-export-bold">
              {currentJob && currentJob.name}
            </span>
            ? {t('export:thisCannotBeUndone')}
          </div>
        </BasicDialog>
      </div>
    );
  }

  isUnfinishedJobExist = (jobs) => {
    if (jobs) {
      const unfinished = jobs.filter(
        (job) => job.status !== jobsStatuses.FINISHED,
      );
      return !!unfinished.length;
    }
  };

  handleFetchJobs = () => {
    this.props.fetchJobs(this.state.currentTab);
  };

  handleChangeTab = (value) => {
    this.props.clearJobs();
    this.setState(
      {
        currentTab: value,
      },
      () => {
        this.props.fetchJobs(value);
      },
    );
  };

  handleOpenRerunDialog = (job) => {
    this.setState({
      isRerunDialogOpen: true,
      currentJob: job,
    });
  };

  handleOpenCloneDialog = (job) => {
    this.setState({
      isCloneDialogOpen: true,
      currentJob: job,
    });
  };

  handleOpenRemoveDialog = (job) => {
    this.setState({
      isRemoveDialogOpen: true,
      currentJob: job,
    });
  };

  handleOpenArchiveDialog = (job) => {
    this.setState({
      isArchiveDialogOpen: true,
      currentJob: job,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      isRerunDialogOpen: false,
      isCloneDialogOpen: false,
      isRemoveDialogOpen: false,
      isArchiveDialogOpen: false,
      currentJob: undefined,
    });
  };

  createNewJob = () => {
    this.props.clearJobSettings();
    this.props.history.push({
      pathname: route.EXPORT_NEW_JOB,
    });
  };

  handleRerunJob = (job) => {
    this.props.rerunJob(job.uuid, job.name);
    this.handleCloseDialog();
  };

  handleCloneJob = (job) => {
    this.props.cloneJob(job.uuid);
  };

  handleToggleFavorite = (job) => {
    const { activeJobs, favoritesJobs, archivedJobs, currentTab } = this.state;
    const { updateJob } = this.props;
    let currentJob;
    let jobs;

    switch (currentTab) {
      case jobsTabs.ACTIVE: {
        jobs = cloneDeep(activeJobs);
        currentJob = find(jobs, (item) => item.uuid === job.uuid);
        currentJob.isFavorite = !currentJob.isFavorite;
        this.setState({ activeJobs: jobs });
        break;
      }
      case jobsTabs.FAVORITES: {
        jobs = cloneDeep(favoritesJobs);
        currentJob = find(jobs, (item) => item.uuid === job.uuid);
        currentJob.isFavorite = !currentJob.isFavorite;
        jobs = jobs.filter((item) => item.uuid !== job.uuid);
        this.setState({ favoritesJobs: jobs });
        break;
      }
      case jobsTabs.ARCHIVED: {
        jobs = cloneDeep(archivedJobs);
        currentJob = find(jobs, (item) => item.uuid === job.uuid);
        currentJob.isFavorite = !currentJob.isFavorite;
        jobs = jobs.filter((item) => item.uuid !== job.uuid);
        this.setState({ archivedJobs: jobs });
        break;
      }
      default: {
        break;
      }
    }

    const data = {};
    data.patches = [createReplacePatch(`/isFavorite`, currentJob.isFavorite)];
    updateJob(data, currentJob.uuid);
  };

  handleArchiveJob = (job) => {
    const { activeJobs, favoritesJobs, currentTab } = this.state;
    const { updateJob } = this.props;
    let jobs;

    switch (currentTab) {
      case jobsTabs.ACTIVE: {
        jobs = cloneDeep(activeJobs);
        jobs = jobs.filter((item) => item.uuid !== job.uuid);
        this.setState({ activeJobs: jobs });
        break;
      }
      case jobsTabs.FAVORITES: {
        jobs = cloneDeep(favoritesJobs);
        jobs = jobs.filter((item) => item.uuid !== job.uuid);
        this.setState({ favoritesJobs: jobs });
        break;
      }
      default: {
        break;
      }
    }

    const data = {};
    data.patches = [createReplacePatch(`/isArchived`, true)];
    updateJob(data, job.uuid);

    this.handleCloseDialog();
  };

  handleRemoveJob = (job) => {
    this.handleCloseDialog();
    this.props.removeJob(job.uuid, job.name);
  };
}

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  jobs: state.jobs.list,
  needUpdateTab: state.jobs.needUpdateTab,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchJobs,
    clearJobs,
    updateJob,
    cloneJob,
    rerunJob,
    removeJob,
    setJobsTabUpdate,
    clearJobSettings,
  })(ExportJobs),
);
