import uuidv4 from 'uuid/v4';
import { fromByteArray } from 'base64-js';

export default function generateUUID() {
  const buffer = new Array(16);
  return fromByteArray(uuidv4(null, buffer, 0))
    .substr(0, 22)
    .replace(new RegExp('/', 'g'), '_')
    .replace(/\+/g, '-');
}
