import React, {useEffect, useRef, useState} from "react";
import {
  TransformWrapper,
  TransformComponent,
} from "react-zoom-pan-pinch";
import {createPortal} from "react-dom";
import Preloader from "../Preloader/Preloader";
import IconButton from "material-ui/IconButton";
import NavigationClose from "material-ui/svg-icons/navigation/close";

const ZoomPanPinch = ({imageSrc, isOpen, onClose}) => {
  const transformComponentRef = useRef();
  const [imageScale, setImageScale] = useState(null);
  const [initPosition, setInitPosition] = useState({x: 0, y: 0});

  useEffect(() => {
    if (isOpen) {
      const img = new Image();
      img.onload = function () {
        let scale = 1;
        const height = window.innerHeight;
        const width = window.innerWidth;
        const imageWidth = this.width;
        const imageHeight = this.height;
        if (imageHeight > height) {
          scale = height / imageHeight;
        }
        if (imageWidth > width) {
          const wScale = width / imageWidth;
          scale = wScale < scale ? wScale : scale;
        }

        setInitPosition({
          x: (width - imageWidth * scale) / 2,
          y: (height - imageHeight * scale) / 2,
        })

        setImageScale(scale);
      }
      img.src = imageSrc;
    }
  }, [isOpen]);

  function doubleClick() {
    if (transformComponentRef.current.instance.transformState.scale > imageScale) {
      transformComponentRef.current.resetTransform();
    } else {
      transformComponentRef.current.zoomIn(1)
    }
  }

  return (isOpen ? <PortalComponent doubleClick={doubleClick} onClose={onClose}>
      {imageScale ? <TransformWrapper
        initialScale={imageScale}
        minScale={imageScale / 2}
        initialPositionX={initPosition.x}
        initialPositionY={initPosition.y}
        limitToBounds={true}
        ref={transformComponentRef}
        doubleClick={{disabled: true}}
      >
        {() => (
          <React.Fragment>
            <TransformComponent
              wrapperStyle={wrapperStyle}
              contentStyle={contentStyle}
            >
              <img alt={'slate image'} src={imageSrc}/>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper> : <Preloader isGray className="field-image-preloader"/>}
      <div
        style={closeButtonStyle}
      >
        <IconButton
          iconStyle={iconStyle}
          onClick={onClose}
        >
          <NavigationClose/>
        </IconButton>
      </div>
    </PortalComponent> : <div/>
  );
};

export default ZoomPanPinch;

const PortalComponent = ({children, doubleClick, onClose}) => {
  let clicks = [];
  let timeout;
  const [touchStart, setTouchStart] = useState(null);
  const [time, setTime] = useState(null);
  const minSwipeDistance = 150;

  const onTouchStart = (e) => {
    setTime(Date.now());
    setTouchStart(e.nativeEvent.touches[0].pageY);
  }

  const onTouchEnd = async (e) => {
    let touchEnd = e.changedTouches[0].clientY;
    if (!touchStart || !touchEnd) return;
    if (touchEnd - touchStart > minSwipeDistance && (Date.now() - time < 250)) {
      await new Promise(r => setTimeout(r, 0));
      onClose(e);
    }
    setTime(null);
  }


  function onClick(event) {
    event.preventDefault();
    event.stopPropagation();
    clicks.push(new Date().getTime());
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      if (clicks.length > 1 && clicks[clicks.length - 1] - clicks[clicks.length - 2] < 250) {
        // menage doubleCLick event
        doubleClick(event.target);
      }
    }, 250);
  }

  return createPortal(
    <div className="modal" style={modalStyle} onClick={onClick} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
      {children}
    </div>,
    document.getElementById("root")
  );
};
const modalStyle = {
  width: "100%",
  height: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  position: "absolute",
  top: 0,
  backgroundColor: "rgba(98,98,98,0.9)",
  textAlign: "center",
  zIndex: 10000,
};
const wrapperStyle = {overflow: 'inherit', width: '100%', height: '100%'};
const contentStyle = {overflow: 'inherit'};
const closeButtonStyle = {
  position: 'absolute',
  right: 20,
  top: 20,
};
const iconStyle = {
  color: 'white'
};