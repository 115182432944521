import React, { Component } from 'react';
import AutoComplete from 'material-ui/AutoComplete';

import CreateViewButton from 'components/SlateViews/CreateViewButton';
import SlateViewsList from './SlateViewsList';
import { slateViewsTypes } from 'config/constants';
import './style.css';
import theme from './theme';

class SlateViewsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listViews: props.listViews,
      mapViews: props.mapViews,
    };
  }

  render() {
    const { listViews, mapViews } = this.state;
    const { onSlateViewClick } = this.props;

    return (
      <div className="slate-view-selector">
        <div className="slate-view-create-button">
          <CreateViewButton />
        </div>

        <AutoComplete
          hintText={`Search my views ...`}
          dataSource={[].concat(listViews, mapViews)}
          onUpdateInput={this.handleSearch}
          fullWidth={true}
          textFieldStyle={theme.autoCompleteSearch.textField}
          underlineStyle={theme.autoCompleteSearch.underline}
          inputStyle={theme.autoCompleteSearch.input}
        />

        <div className="slate-view-selector-container">
          <SlateViewsList
            views={listViews}
            type={slateViewsTypes.LIST}
            onSlateViewClick={onSlateViewClick}
          />
          <SlateViewsList
            views={mapViews}
            type={slateViewsTypes.MAP}
            onSlateViewClick={onSlateViewClick}
          />
        </div>
      </div>
    );
  }

  handleSearch = (query) => {
    const { listViews, mapViews } = this.props;
    let listResults = [];
    let mapResults = [];

    if (query.length === 0) {
      listResults = listViews;
      mapResults = mapViews;
    } else {
      listViews.forEach((item) => {
        let tmp = item.name.toLowerCase().search(query.toLowerCase());
        if (tmp !== -1) {
          listResults.push(item);
        }
      });

      mapViews.forEach((item) => {
        let tmp = item.name.toLowerCase().search(query.toLowerCase());
        if (tmp !== -1) {
          mapResults.push(item);
        }
      });
    }
    this.setState({
      listViews: listResults,
      mapViews: mapResults,
    });
  };
}

export default SlateViewsSelector;
