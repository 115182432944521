export const styles = {
  listItem: {
    width: '100%',
  },
  selectField: {
    style: {
      width: 80,
      height: 24,
      margin: '0 5px',
      textAlign: 'center',
      backgroundColor: '#0486dc',
      borderRadius: 4,
    },
    labelStyle: {
      padding: 0,
      color: '#fff',
      height: 24,
      lineHeight: '24px',
      top: 0,
      maxWidth: 'calc(100% - 28px)',
      fontSize: '12px',
    },
    iconStyle: {
      width: 36,
      height: 16,
      fill: '#fff',
      padding: 0,
    },
    selectedMenuItemStyle: {
      backgroundColor: '#0486dc',
      color: '#fff',
    },
    listStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    underlineStyle: {
      display: 'none',
    },
  },
  menuItem: {
    innerDivStyle: {
      height: 32,
      fontSize: '12px',
      lineHeight: '32px',
      paddingLeft: '10px',
    },
  },
  innerDivStyle: {
    margin: 0,
    padding: 0,
  },
  leftIcon: {
    position: 'relative',
    margin: 0,
    height: 'auto',
    width: 24,
  },
  isDragging: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
    border: 0,
  },
  openIcon: {
    cursor: 'pointer',
  },
  parentStyle: {
    fontSize: 10,
    lineHeight: '12px',
  },
  fieldBody: {
    marginTop: 0,
    height: '25px',
    lineHeight: '24px',
    overflow: 'inherit',
  },
  dragging: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
    border: 0,
  },
  listItemStyle: {
    padding: '12px 10px 12px 15px',
    width: '100%'
  },
  selectedDroppable: {
    minHeight: '100%',
  },
  removeButton: {
    color: '#87302c',
  },
};
