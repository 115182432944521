import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BasicDialog from "../Dialogs/BasicDialog";
import RaisedButton from 'material-ui/RaisedButton';

const useStyles = makeStyles(() => ({
  diffBox: {
    padding: '20px 24px',
  },
  littleText: {
    fontSize: 12,
    display: "flex",
  },
  pinSquare: {
    width: 10,
    height: 10,
    marginRight: 15,
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {
    closePopup,
    newOption,
    openPopup,
    selectedFields: {
      diffColumns = [],
      diffRecords = [],
      diffRules = [],
      diffPinColors = [],
    },
    agreeAndContinueAction,
    t} = props

  return (
    <BasicDialog
      title={t('settings:lossOfConfiguredOptions')}
      open={openPopup}
      actions={[
        <RaisedButton
          key="agree"
          primary={true}
          label={t('btn:acceptAndContinue')}
          onClick={agreeAndContinueAction}
        />,
        <RaisedButton
          key="cancel"
          className="jobs-item-btn-text"
          label={t('btn:cancel')}
          onClick={closePopup}
        />,
      ]}
    >
      <div className="tools-export-dialog-body">
        {t('settings:popupMessageStart')}{' '}
        <span className="tools-export-bold">
          {newOption}
          </span>{' '}
        {t('settings:popupMessageEnd')}
      </div>
      {!!diffRecords.length &&
        <div className={classes.diffBox}>
          {t('settings:dialogRecordsListTitle')}
          {diffRecords.map(item => <div className={classes.littleText} key={item}>{item}</div>)}
        </div>
      }
      {!!diffRules.length &&
        <div className={classes.diffBox}>
          {t('settings:dialogRulesListTitle')}
          {diffRules.map(item => <div className={classes.littleText} key={item.title}>
            {item.title}
            {item.operation && ` - ${t(`settings:${item.operation}`)}`}
            {item.value && ` - ${item.value}`}
          </div>)}
        </div>
      }
      {!!diffPinColors.length &&
        <div className={classes.diffBox}>
          {diffPinColors.map(item => <div className={classes.littleText} key={item.title}>
            <div className={classes.pinSquare} style={{backgroundColor: item.color}}  />
            {item.title}
            {item.operation && ` - ${t(`settings:${item.operation}`)}`}
            {item.value && ` - ${item.value}`}
          </div>)}
        </div>
      }
      {!!diffColumns.length &&
        <div className={classes.diffBox}>
          {t('settings:dialogColumnsListTitle')}
          {diffColumns.map(item => <div className={classes.littleText} key={item.title}>{item.title} {item.fieldAttribute && ` - ${item.fieldAttribute}`}</div>)}
        </div>
      }
    </BasicDialog>
  );
}