import muiTheme from 'theme/muiTheme';

export const zendeskOffsets = {
  HIDE: 1000,
  TOP: 7,
  RIGHT: 20,
};

export const setupZendesk = () => {
  window.zESettings = {
    webWidget: {
      position: {
        horizontal: 'right',
        vertical: 'top',
      },
      offset: {
        horizontal: `${70 - zendeskOffsets.HIDE}px`,
        vertical: '-2px',
        mobile: {
          horizontal: `${70 - zendeskOffsets.HIDE}px`,
          vertical: '-2px',
        },
      },
      zIndex: 1900,
      color: {
        theme: muiTheme.palette.primary1Color,
        launcherText: muiTheme.palette.alternateTextColor,
      },
    },
  };
};
