import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormAccountSettingsApiKey from '../../components/Forms/FormsAccountSettings/FormAccountSettingsApiKey';

function mapStateToProps(state) {
  return {
    currentApiKey: state.accounts.currentApiKey,
  };
}
export default withTranslation()(
  connect(mapStateToProps, null)(FormAccountSettingsApiKey),
);
