import p from '../../theme/palette';

const theme = {
  accountDialogRight: {
    borderLeft: `1px solid ${p.dialogBorder}`,
  },
  addAccountText: {
    color: p.addAccountText,
  },
  accountItem: {
    borderBottom: `1px solid ${p.dialogBorder}`,
    cursor: 'pointer',
  },
  accountItemImage: {
    backgroundColor: p.avatarDefaultBg,
    color: p.avatarDefaultColor,
    border: `1px solid ${p.avatarDefaultBorder}`,
  },
  accountItemLabel: {
    display: 'inline-block',
    width: 'calc(100% - 70px)',
    lineHeight: '18px',
  },
  accountButton: {
    height: '100%',
    textAlign: 'left',
  },
  autoCompleteSearch: {
    textField: {
      textIndent: 27,
    },
    underline: {
      bottom: 1,
      borderColor: p.grey200,
    },
  },
};

export default theme;
