import React, { Component } from 'react';
import FormWrapper from '../../containers/Forms/FormWrapper';
import FormUserProfile from '../../containers/Forms/FormUserProfile';
import { entityNames } from '../../config/constants';
import styles from './theme';

class CreateProfile extends Component {
  componentDidMount() {
    let el = document.getElementsByClassName('content')[0];
    el.classList.add('content-create-profile');
  }

  componentWillUnmount() {
    let el = document.getElementsByClassName('content')[0];
    el.classList.remove('content-create-profile');
  }

  render() {
    return (
      <div>
        <h1>Create Profile</h1>

        <FormWrapper
          entityName={entityNames.CREATE_USER}
          entity={[
            'name',
            'displayName',
            'contactInfo',
            'password',
            'imageUUID',
          ]}
          onSubmit={this.onCreateUser}
        >
          <FormUserProfile />
        </FormWrapper>

        <div style={styles.disclaimer}>
          {this.props.t('userProfile:createProfileDisclaimer')}
        </div>
      </div>
    );
  }

  onCreateUser = (data, entityName) => {
    this.props.createUser(data, entityName);
  };
}

export default CreateProfile;
