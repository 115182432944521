import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { setCurrentSlateView } from 'store/actions/slateViews';
import routes from 'config/routes';
import { route } from 'config/constants';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { defaultPath: route.SLATE_VIEWS };
  }

  UNSAFE_componentWillMount = () => {
    this.defineDefaultRoute();
  };

  componentDidMount = () => {
    // upload user avatar after the profile is created
    const {
      setCurrentSlateView,
      currentAccount,
      currentSlateView,
    } = this.props;
    // set a current slate view to a default value
    setCurrentSlateView();

    if(currentAccount || currentSlateView) {
      this.setState({
        defaultPath: `/accounts/${currentAccount && currentAccount.uuid}/views/${currentSlateView && currentSlateView.uuid}`,
      });
    }
  };

  defineDefaultRoute = () => {
    let defaultRoutes = routes
      .filter((route) => !!route.defaultPathPriority)
      .sort((a, b) => (a.defaultPathPriority > b.defaultPathPriority ? 1 : -1));
    const { dashboardSettings } = this.props;
    const defaultRoute = defaultRoutes.find((route) => {
      return (
        !route.allowBySettingKey ||
        (route.allowBySettingKey && dashboardSettings[route.allowBySettingKey])
      );
    });

    if (defaultRoute) {
      this.setState({ defaultPath: defaultRoute.path });
    }
  };

  render() {
    const { defaultPath } = this.state;
    return defaultPath ? <Redirect to={defaultPath} /> : '';
  }
}

const mapStateToProps = (state) => ({
  attachment: state.images.attachment,
  dashboardSettings: state.currentUser.dashboardSettings,
  currentAccount: state.accounts.currentAccount,
  currentSlateView: state.slateViews.currentSlateView,
});

export default connect(mapStateToProps, {
  setCurrentSlateView,
})(Home);
