import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import CollapsibleField from '../CollapsibleField/CollapsibleField';
import Search from '../../Shared/Search/Search';
import { searchAvailableFields } from 'helpers/searchHelper';
import './style.css';
import {filteringFieldsForRecordListView, filteringFieldsForRecordListViewOtherFields} from "../../../config/constants";


const RuleFields = ({ fields, onFieldClick, isRecordListView, allFields, recordsFieldUniquenessIdentifier }) => {

  const availableItemsList = () => {
      if (isRecordListView){
        const availableWithoutRecordsFields = allFields.filter(field => {
          return (fields.findIndex(_field =>
            (_field.suuid === field.suuid)
          ) === -1) && (field.suuid !== recordsFieldUniquenessIdentifier)
        });
        return [
          ...filteringFieldsForRecordListView,
          ...fields,
          ...filteringFieldsForRecordListViewOtherFields,
          ...availableWithoutRecordsFields,
        ]
      }
      return fields
    };

  const [availableLocalList, setAvailableLocalList] = useState(availableItemsList());


  useEffect(()=>{
    setAvailableLocalList(availableItemsList())
  }, [fields, isRecordListView])


  const handleSearch = (text = '') => {
    const available = cloneDeep(availableItemsList());
    text = text.trim().toLowerCase();

    if (text.length) {
      searchAvailableFields(available, text);
    }
    setAvailableLocalList(available)
  };
  return (
    <div>
      <Search onSearch={handleSearch} autoFocus />
      <div className="available-rule-fields">
        {availableLocalList.map((field) =>
          field.parent ? null : (
            field.suuid !== recordsFieldUniquenessIdentifier &&
            <CollapsibleField
              field={field}
              key={field.suuid || field.recordEntryAttribute}
              onFieldClick={onFieldClick}
              recordsFieldUniquenessIdentifier={isRecordListView ? recordsFieldUniquenessIdentifier : ''}
            />
          ),
        )}
      </div>
    </div>
  );
};

RuleFields.propTypes = {
  fields: PropTypes.array.isRequired,
  onFieldClick: PropTypes.func.isRequired,
};

export default RuleFields;
