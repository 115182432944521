import { connect } from 'react-redux';

import MapView from 'components/MapView/MapView';
import { updateUser } from 'store/actions/user';

const mapStateToProps = (state) => {
  return {
    dashboardSettings: state.currentUser.dashboardSettings,
  };
};

export default connect(mapStateToProps, {
  updateUser,
})(MapView);
