import cloneDeep from 'lodash/cloneDeep';
import * as type from 'store/actions/types';

const initialState = {};

const zendeskIdentify = (info) => {
  if (window.zE) {
    window.zE(() => {
      window.zE.identify(info);
    });
  }
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_USER:
      const { user } = action.payload;
      zendeskIdentify({
        name: user.displayName || user.name,
        email: user.contactInfo.email,
      });
      return Object.assign({}, user, { error: '' });

    case type.REMOVE_USER:
      zendeskIdentify({
        name: '',
        email: '', // Actually can't be cleared for some reason
      });

      if (action.payload.force) {
        return {
          previousSessionData: {
            email: state.contactInfo.email,
            phone: state.contactInfo.phone,
          },
        };
      } else {
        return {};
      }

    case type.USER_ETAG_CHANGED:
      let newState = cloneDeep(state);
      newState.etagChanged = true;
      return newState;

    case type.OPEN_LOGIN_DIALOG:
      let clonedState = cloneDeep(state);
      clonedState.isOpenLoginDialog = true;
      return clonedState;

    case type.CLOSE_LOGIN_DIALOG: {
      let clonedState = cloneDeep(state);
      clonedState.isOpenLoginDialog = false;
      clonedState.login2faDialogData = {
        isOpen: false,
      };
      return clonedState;
    }

    case type.SET_LOGIN_2FA_CODE_DATA: {
      let clonedState = cloneDeep(state);
      clonedState.login2faDialogData = action.payload;
      return clonedState;
    }

    default:
      return state;
  }
};

export default currentUser;
