import React from 'react';
import FontAwesome from 'react-fontawesome';
import TextField from 'material-ui/TextField';
import extend from 'lodash/extend';
import isBoolean from 'lodash/isBoolean';
import tryMe from '../../helpers/tryMe';
import p from '../../theme/palette';
import styles from './styles';
import './styles.css';

const FormTextField = (props) => (
  <span
    style={extend(
      {},
      { position: props.icon ? 'relative' : 'inherit' },
      props.containerStyle,
    )}
  >
    {props.icon && (
      <FontAwesome
        name={props.icon}
        style={extend({}, styles.iconInInput, props.iconStyle)}
        className={`${props.iconOnlyMediumScreen ? 'medium-screen-only' : ''}`}
      />
    )}
    <TextField
      hintText={props.hintText}
      floatingLabelText={props.floatingLabelText}
      fullWidth={props.fullWidth}
      type={props.type}
      name={props.name}
      value={props.value}
      autoFocus={props.autoFocus}
      autoComplete={props.autocomplete}
      errorText={tryMe(props.state.dataErrors)[props.name] || ''}
      onChange={(e) => props.actions.handleChange(e)}
      onBlur={(e) => props.actions.handleBlur(e)}
      errorStyle={{ bottom: 10, fontSize: 12, color: p.error, ...props.errorStyle }}
      floatingLabelFocusStyle={{ color: p.defaultText }}
      floatingLabelShrinkStyle={
        props.center
          ? { transform: 'scale(0.75) translate(-50px, -20px)' }
          : { transform: 'scale(0.75) translate(0px, -20px)' }
      }
      floatingLabelStyle={extend(
        {},
        props.center
          ? { width: '100%', textAlign: 'center', top: 26 }
          : { top: 26 },
        props.floatingLabelStyle,
      )}
      hintStyle={extend(
        {},
        props.center
          ? { width: '100%', textAlign: 'center', bottom: 8 }
          : { bottom: 8 },
        props.hintStyle,
      )}
      inputStyle={extend(
        {},
        props.center ? { textAlign: 'center', marginTop: 8 } : { marginTop: 8 },
        props.inputStyle,
      )}
      className={`${
        props.icon && props.iconOnlyMediumScreen
          ? 'icon-medium-screen-correction'
          : ''
      } ${props.icon && 'icon-all-screens-correction'} ${
        props.className || ''
      }`}
      style={extend(
        {},
        { fontSize: 14,
//---- global font setting ----
          fontWeight: 400,
          height: 56 },
        props.style,
      )}
      underlineShow={
        isBoolean(props.underlineShow) ? props.underlineShow : true
      }
      underlineFocusStyle={{ borderColor: p.defaultText }}
      underlineDisabledStyle={{}}
      underlineStyle={extend({}, { borderWidth: 1 }, props.underlineStyle)}
      disabled={props.disabled || false}
    />
    <div style={styles.formFieldHint}>{props.hint}</div>
  </span>
);

export default FormTextField;
