import Cookies from 'js-cookie';

const EXPIRES = 7;

export const cookiesTypes = {
  USER_COOKIE: 'sl8_user',
  RESET_PASSWORD_COOKIE: 'sl8_reset',
};

export const getCookies = (type) => {
  if (type) {
    return Cookies.get(type);
  }
};

export const setCookies = (type, data) => {
  if (type && data) {
    removeCookies(type);
    Cookies.set(type, JSON.stringify(data), { expires: EXPIRES });
  }
};

export const removeCookies = (type) => {
  if (type && Cookies.get(type)) {
    Cookies.remove(type);
  }
};

export const options = {
  withCredentials: true,
  headers: {
    'Authorization-SetAsCookie': true,
    'Content-Type': 'application/json',
    'Continuation-Token': '',
    'api-revision': process.env.REACT_APP_API_VERSION,
  },
};

export const INCLUDE_RESOURCE = 'include-resource-in-response=true';
