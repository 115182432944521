import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import SlateViewSearchResult from 'components/SlateViewSearchResult/SlateViewSearchResult';
import { updateSlatePane } from 'store/actions/slateViews';
import { fetchSlatesForSearch } from 'store/actions/slateViews';

const mapStateToProps = (state) => {
  return {
    slates: state.slateViews.searchResultSlates,
    searchResultRowCount: state.slateViews.searchResultRowCount,
    searchString: state.slateViews.searchString,
    currentAccount: state.accounts.currentAccount,
    isPaneOpen: state.slateViews.isPaneOpen,
    isPaneHidden: state.slateViews.isPaneHidden,
    isPaneDocked: state.slateViews.isPaneDocked,
    slateInSlatePaneUUID: state.slateViews.slateInSlatePaneUUID,
    currentUser: state.currentUser,
  };
};

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, {
      updateSlatePane,
      fetchSlatesForSearch,
    })(SlateViewSearchResult),
  ),
);
