import React from 'react';
import {connect} from 'react-redux';
import {useTranslation, withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import './style.css';
import BasicDialog from "../Dialogs/BasicDialog";
import Login from "../../containers/Login";
import ResetPassword from "../../containers/ResetPassword";
import CreateProfile from "../../containers/CreateProfile";
import {Box, Tab, Tabs, Typography} from "@material-ui/core";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const LoginModal = ({
                      open,
                      onClose,
                      isOpenLogin2faDialog,
                    }) => {
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <BasicDialog
      title={isOpenLogin2faDialog ? t('login:verificationCode') : t('login:signIn')}
      open={open}
      onClose={onClose}
    >
      <div className={'layout-sign'}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={activeTab}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="basic tabs example"
                TabIndicatorProps={{style: {background: '#f15a29'}}}
          >
            <Tab label={t('login:signIn')} {...a11yProps(0)}/>
            <Tab label={t('login:recoverPassword')} {...a11yProps(1)}/>
            <Tab label={t('login:register')} {...a11yProps(2)}/>
          </Tabs>
        </Box>
        <div style={{
          display: 'flex', alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{
            padding: 32, width: 480,
          }}>
            <TabPanel value={activeTab} index={0}>
              <Login isModal/>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <ResetPassword
                onChange={handleChange}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <CreateProfile/>
            </TabPanel>
          </div>
        </div>
      </div>
    </BasicDialog>
  );
};

const mapStateToProps = (state) => ({
  isOpenLogin2faDialog: state.currentUser.login2faDialogData && state.currentUser.login2faDialogData.isOpen,
});

const mapDispatchToProps = {};


export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(LoginModal),
  ),
);
