import axios from 'axios';

import * as type from 'store/actions/types';
import { cookiesTypes, removeCookies } from 'helpers/cookieHelper';

export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use(
      function (response) {
        if (process.env.REACT_APP_SL8_ENV === 'development') {
          if (response.headers.UserAccountsUpdated) {
            console.log('UserAccountsUpdated header');
          }
          // console.log('interceptor response', response);
        }
        return response;
      },
      function (error) {
        if (process.env.REACT_APP_SL8_ENV === 'development') {
          console.log('interceptor error', error.response);
        }

        if (error.response) {
          switch (error.response.status) {
            case 401:
              removeCookies(cookiesTypes.USER_COOKIE);
              store.dispatch({
                type: type.REMOVE_USER,
                payload: {
                  force: false,
                },
              });

              store.dispatch({
                type: type.SET_APP_LAYOUT,
                payload: 'sign',
              });
              break;

            case 500:
              store.dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: error.response.data.correlationUUID || null,
              });
              break;
            default:
              break;
          }
        }
        return Promise.reject(error);
      },
    );
  },
};
