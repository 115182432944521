import * as type from 'store/actions/types';

const initialState = { verificationMethod: null };

const verification = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_VERIFICATION_METHOD:
      return { verificationMethod: action.verificationMethod };
    default:
      return state;
  }
};

export default verification;
