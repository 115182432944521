import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import theme from './theme';

export const SidebarHeader = ({ onToggle }) => (
  <div className="sidebar-header" style={theme.sidebarHeader}>
    <div className="sidebar-logo" />
    <div className={'collapse-icon'} onClick={onToggle}>
      <FontAwesome name="thumb-tack" />
    </div>
  </div>
);

SidebarHeader.propTypes = {
  onToggle: PropTypes.func.isRequired,
};
