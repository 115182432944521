import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { Card } from 'material-ui/Card';
import palette from '../theme/palette';

const style = {
  card: {
    width: '100%',
    margin: '30px auto',
    padding: '0px 30px 30px 30px',
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 5px 0px',
  },
  cardWithoutTitle: {
    paddingTop: 30,
  },
  cardTitle: {
    color: palette.cardTitle,
    borderBottom: `1px solid ${palette.cardTitleBorder}`,
    margin: '0px -30px',
    padding: '20px 30px 15px 30px',
    marginBottom: 20,
    fontSize: 16,
  },
  cardSubtitle: {
    fontSize: 10,
    paddingTop: 5,
  },
};

const CustomCard = (props) => (
  <Card
    style={
      props.title ? style.card : merge({}, style.card, style.cardWithoutTitle)
    }
    className="card"
  >
    {props.title && (
      <div style={style.cardTitle}>
        {props.title}
        {props.subtitle && (
          <div style={style.cardSubtitle}>{props.subtitle}</div>
        )}
      </div>
    )}
    {props.content}
  </Card>
);

CustomCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
};

export default CustomCard;
