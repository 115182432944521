import React, {Component} from 'react';
import isEqual from 'lodash/isEqual';
import RaisedButton from 'material-ui/RaisedButton';

import tryMe from '../../../helpers/tryMe';
import AvatarUploader from '../../AvatarUploader/AvatarUploader';
import FormSubmit from '../../../containers/Forms/FormSubmit';
import FormTextField from '../FormTextField';
import FormTelField from '../FormTelField';
import {Verified} from '../../Badges/Badges';
import BasicDialog from 'components/Dialogs/BasicDialog';
import Verification from '../../../containers/Verification/Verification';
import {entityNames, avatarShapes} from '../../../config/constants';
import styles from '../styles';

export const VERIFICATION_DIALOG_TYPE = {
  enable2FA: '2FA',
  disable2FA: 'disable2FA',
  email: 'email',
  phone: 'phone',
}

export default class FormUserProfileDetails extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getUserEtag();

    const {currentUser} = this.props;
    let edit = this.props.type === 'edit';

    this.props.actions.setFormState({
      name: edit ? currentUser.name : '',
      displayName: edit ? currentUser.displayName : '',
      contactInfo: {
        phone: edit ? currentUser.contactInfo.phone : '',
        email: edit ? currentUser.contactInfo.email : '',
      },
      imageUUID: edit ? currentUser.imageUUID : '',
      attachments: [],
      dataErrors: {},
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!isEqual(props.currentUser, this.props.currentUser)) {
      this.props.actions.setFormState({
        name: props.currentUser.name,
        displayName: props.currentUser.displayName,
        contactInfo: {
          phone: props.currentUser.contactInfo.phone,
          email: props.currentUser.contactInfo.email,
        },
        imageUUID: props.currentUser.imageUUID,
        attachments: [],
        dataErrors: {},
      });
    }

    if (props.entities) {
      let verifyEntity =
        props.entities[entityNames.VERIFY_PHONE_VERIFICATION_CODE];
      if (verifyEntity && verifyEntity.isUpdated) {
        this.setState({open: false});
      }
    }
  }

  showContactInfoError = () =>
    tryMe(this.props.state.dataErrors).contactInfo &&
    !this.props.state.contactInfo.email &&
    !this.props.state.contactInfo.phone;

  handleDialogOpen = (type) =>
    this.setState({open: true, type: type}, () =>
      document.body.classList.add('dialog-open'),
    );

  handleDialogClose = () =>
    this.setState({open: false, type: null}, () =>
      document.body.classList.remove('dialog-open'),
    );

  render() {
    const {actions, state, t, currentUser, type, errorAction} = this.props;

    return (
      <form onKeyPress={actions.handleKeyPress}>
        {currentUser.error && (
          <div style={styles.formError}>{currentUser.error}</div>
        )}

        <AvatarUploader
          onAddAttachment={actions.handleAddAttachment}
          onRemoveAttachment={actions.handleRemoveProfileImage}
          onRestoreAttachment={actions.handleRestoreProfileImage}
          imageUUID={state.imageUUID}
          type={type}
          t={t}
          shape={avatarShapes.CIRCLE}
        />

        <BasicDialog
          title={
            this.state.type === VERIFICATION_DIALOG_TYPE.email
              ? 'Verify Email'
              : this.state.type === VERIFICATION_DIALOG_TYPE.enable2FA
              ? 'Enable Two Factor Authentication'
              : this.state.type === VERIFICATION_DIALOG_TYPE.disable2FA
                ? 'Disable Two Factor Authentication'
                : 'Verify Phone'
          }
          open={this.state.open}
          onClose={this.handleDialogClose}
        >
          <Verification
            type={this.state.type}
            entities={this.props.entities}
            onRequestClose={this.handleDialogClose}
            actions={actions}
          />
        </BasicDialog>

        <div
          style={
            this.showContactInfoError()
              ? Object.assign({}, styles.multiField, styles.multiFieldError)
              : styles.multiField
          }
        >
          <div className="field-to-verify">
            <FormTextField
              name="contactInfo.email"
              type="email"
              autocomplete="email"
              hintText={t('userProfile:email')}
              floatingLabelText={t('userProfile:email')}
              fullWidth={true}
              actions={actions}
              state={state}
              value={tryMe(state.contactInfo).email || ''}
            />
            {currentUser.contactInfo.email && (
              <div>
                {currentUser.contactInfo.isEmailVerified ? (
                  <Verified/>
                ) : (
                  <RaisedButton
                    label="Verify Email"
                    primary={true}
                    className="btn btn-verify"
                    onClick={() => this.handleDialogOpen('email')}
                  />
                )}
                {
                  currentUser.contactInfo.email2FAEnabled
                    ? <div>
                      <Verified customText={'2FA Enabled'} customClass={'badge badge-verified-second'}/>
                      <RaisedButton
                        label="Disable 2FA"
                        primary={true}
                        className="btn btn-disable-2FA"
                        onClick={() => this.handleDialogOpen('disable2FA')}
                      />
                    </div>
                    : <RaisedButton
                      label="Enable 2FA"
                      primary={true}
                      className="btn btn-verify-2FA"
                      onClick={() => this.handleDialogOpen('2FA')}
                    />
                }
              </div>
            )}
          </div>

          <div className="field-to-verify">
            <FormTelField
              name="contactInfo.phone"
              type="tel"
              autocomplete="tel-nation"
              hintText={t('userProfile:phone')}
              floatingLabelText={t('userProfile:phone')}
              fullWidth={true}
              actions={actions}
              state={state}
              value={tryMe(state.contactInfo).phone || ''}
              hint="For non-US numbers, include country code"
            />
            {currentUser.contactInfo.phone && (
              <div>
                {currentUser.contactInfo.isPhoneVerified ? (
                  <Verified/>
                ) : (
                  <RaisedButton
                    label="Verify Phone"
                    primary={true}
                    className="btn btn-verify"
                    onClick={() => this.handleDialogOpen('phone')}
                  />
                )}
              </div>
            )}
          </div>

          {this.showContactInfoError() && (
            <div style={styles.multiFieldErrorText}>
              {state.dataErrors.contactInfo}
            </div>
          )}
        </div>

        <FormTextField
          name="name"
          type="text"
          autocomplete="name"
          hintText={t('userProfile:name')}
          floatingLabelText={t('userProfile:name')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).name || ''}
        />

        <FormTextField
          name="displayName"
          type="text"
          hintText={t('userProfile:displayName')}
          floatingLabelText={t('userProfile:displayName')}
          fullWidth={true}
          actions={actions}
          state={state}
          value={tryMe(state).displayName || ''}
          hint={t('userProfile:displayNameHint')}
        />

        {errorAction && (
          <div className="form-error form-error-action">{errorAction}</div>
        )}

        <FormSubmit
          title={t('userProfile:updateProfile')}
          handleSubmit={actions.handleSubmit}
          handleMouseDown={actions.handleMouseDown}
          entityName={state.entityName}
        />
      </form>
    );
  }
}
