import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { logout } from 'store/actions/user';
import Topbar from 'components/Topbar/Topbar';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    imagesLinks: state.images.links,
  };
}

Topbar.propTypes = {
  handleSidebarToggle: PropTypes.func.isRequired,
};

export default withTranslation()(connect(mapStateToProps, { logout })(Topbar));
