import React, { Component } from 'react';
import FormWrapper from '../containers/Forms/FormWrapper';
import FormSearch from '../containers/Forms/FormSearch';
import { entityNames, minSearchLength } from '../config/constants';
import { withTranslation } from 'react-i18next';

class SlatesSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };
  }

  onSumbit = (values) => {
    if (!values.search || values.search.length < minSearchLength) {
      this.setState({
        errors: {
          search: this.props.t('search:minLengthError', {
            length: minSearchLength,
          }),
        },
      });
      return;
    }
    this.setState({
      errors: {},
    });
    document.querySelectorAll('.search-input-wrapper input')[0].blur();
    this.props.fetchSlatesForSearch(values);
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <FormWrapper
          errors={this.state.errors}
          noValidation={true}
          form={FormSearch}
          entityName={entityNames.GET_SLATES}
          entity={['search']}
          onSubmit={this.onSumbit}
        >
          <FormSearch />
        </FormWrapper>
      </div>
    );
  }
}
export default withTranslation()(SlatesSearch);
