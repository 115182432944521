import React from 'react';
import FormWrapper from '../../../containers/Forms/FormWrapper';
import FormVerficationCode from '../../../containers/Forms/FormVerficationCode';
import { entityNames } from '../../../config/constants';

const Stage2 = (props) => (
  <div>
    <h1>{props.t('resetPassword:enterVerificationCode')}</h1>
    <FormWrapper
      entityName={entityNames.VERIFY_PASSWORD_RESET_CODE}
      entity={['phone', 'code']}
      onSubmit={props.verifyPasswordResetCode}
    >
      <FormVerficationCode />
    </FormWrapper>
  </div>
);

export default Stage2;
