import Notifications from 'react-notification-system-redux';

export function showNotification(type, message) {
  return (dispatch) => {
    dispatch(
      Notifications[type]({
        message,
        position: 'tr',
        autoDismiss: 5,
      }),
    );
  };
}
