import { connect } from 'react-redux';

import Map from 'components/Shared/Map/Map';
import { updateMapType } from 'store/actions/map';

const mapStateToProps = (state) => {
  return {
    mapType: state.app.mapType,
  };
};

export default connect(mapStateToProps, { updateMapType })(Map);
