import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormSubmit } from '../../components/Forms/FormSubmit';

function mapStateToProps(state) {
  return {
    entities: state.entities,
  };
}

FormSubmit.propTypes = {
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  entityName: PropTypes.string,
};

export default connect(mapStateToProps)(FormSubmit);
