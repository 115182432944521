import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import theme from './theme';

export class IssueID extends Component {
  constructor(props) {
    super();
    this.state = {
      value: '',
      copied: false,
    };
  }

  onCopy = () => this.setState({ copied: true });

  componentDidMount() {
    this.setState({ value: this.props.uuid });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="dialog-error-issue">
        <CopyToClipboard text={this.state.value} onCopy={this.onCopy}>
          <div style={theme.dialogErrorIssue}>{this.props.uuid}</div>
        </CopyToClipboard>
        <div
          className="dialog-error-issue-hint"
          style={theme.dialogErrorIssueHint}
        >
          {t('errorDialog:issueIdToClipboard')}
        </div>
      </div>
    );
  }
}
