import React from 'react';
import FormSubmit from '../../containers/Forms/FormSubmit';
import {entityNames} from '../../config/constants';
import styles from "../Forms/styles";
import {VERIFICATION_DIALOG_TYPE} from "../Forms/FormUserProfile/FormUserProfileDetails";

const VerificationStep1 = (props) => {
  const isEmail = props.type === VERIFICATION_DIALOG_TYPE.email;
  const isEnable2fa = props.type === VERIFICATION_DIALOG_TYPE.enable2FA;
  const isDisable2fa = props.type === VERIFICATION_DIALOG_TYPE.disable2FA;

  return isEmail ? (
    <div className="verification-dialog-body">
      <h3>{props.t('verification:sendVerificationEmail')}</h3>
      <div className="verification-dialog-target">
        {props.t('verification:weSendYouLink')}
        <span style={{fontWeight: 700}}>{props.user.contactInfo.email}</span>
      </div>

      <div className="verification-dialog-submit-button">
        <FormSubmit
          title={props.t('verification:sendVerificationEmail')}
          handleSubmit={() =>
            props.onSendVerificationCode(entityNames.VERIFICATION_EMAIL)
          }
          entityName={entityNames.VERIFICATION_EMAIL}
        />
      </div>
    </div>
  ) : (isEnable2fa || isDisable2fa) ? (
    <div className="verification-dialog-body">
      <h3>{props.t('verification:emailTwoFactorAuthentication', {
        enabled: isEnable2fa ? 'Enable' : 'Disable'
      })}</h3>
      <div>{props.t('verification:youWillBeSentCode', {
        enabled: isEnable2fa ? 'enabled' : 'disabled'
      })}</div>
      <div className="verification-dialog-target">
        {props.t('verification:codeWillSentTo')}
        <span style={{fontWeight: 700}}>{props.user.contactInfo.email}</span>
      </div>

      {props.errors && (
        <div style={{marginTop: 12}}>
          <div style={styles.formError} className="form-error">
            {props.errors}
          </div>
        </div>
      )}
      <div className="verification-dialog-submit-button">
        <FormSubmit
          title={props.t('verification:sendCode')}
          handleSubmit={() =>
            props.onSendVerificationCode(
              isEnable2fa
                ? entityNames.VERIFICATION_ENABLE_2FA_CODE
                : entityNames.VERIFICATION_DISABLE_2FA_CODE,
              isDisable2fa,
            )
          }
          entityName={
            isEnable2fa
              ? entityNames.VERIFICATION_ENABLE_2FA_CODE
              : entityNames.VERIFICATION_DISABLE_2FA_CODE
          }
          disabled={props.errors}
        />
      </div>
    </div>
  ) : (
    <div className="verification-dialog-body">
      <h3>{props.t('verification:sendVerificationCode')}</h3>
      <div>{props.t('verification:youWillBeSentCode')}</div>
      <div className="verification-dialog-target">
        {props.t('verification:weSendYouCode')}
        <span style={{fontWeight: 700}}>{props.user.contactInfo.phone}</span>
      </div>

      <div className="verification-dialog-actions">
        <FormSubmit
          title={props.t('verification:sendCodeViaSms')}
          handleSubmit={() =>
            props.onSendVerificationCode('sms', entityNames.VERIFICATION_SMS)
          }
          entityName={entityNames.VERIFICATION_SMS}
        />
        <FormSubmit
          title={props.t('verification:sendCodeViaCall')}
          handleSubmit={() =>
            props.onSendVerificationCode('call', entityNames.VERIFICATION_CALL)
          }
          entityName={entityNames.VERIFICATION_CALL}
        />
      </div>
    </div>
  );
};

export default VerificationStep1;
