import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  confirmFieldsSource,
  fetchSettingsFields,
  revertFieldsSource,
  updateFieldsSource
} from 'store/actions/slateViews';
import { updateJob } from 'store/actions/jobs';
import FormWrapper from 'containers/Forms/FormWrapper';
import FormSlateViewSettings from 'components/Forms/FormSlateViewSettings/FormSlateViewSettings';
import { entityNames, resourceTypes } from 'config/constants';
import generateUUID from 'helpers/generateUUID';
import Select from "../Shared/Select";
import {defaultFieldsSource, getFieldsSourceOptions} from "../FieldSelector/FieldsSourceOptions";
import SlateViewSettingPopup from "../SlateViewsSettings/SlateViewSettingPopup";
import {cleanFieldsSource} from "../../store/actions/slateViews";

class ExportJobSettings extends Component {
  UNSAFE_componentWillMount = () => {
    const { updateFieldsSource, cleanFieldsSource } = this.props;
      cleanFieldsSource();
      updateFieldsSource('all')
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { currentAccount, fetchSettingsFields } = this.props;

    // handle account changing (after reloading the page)
    if (nextProps.currentAccount) {
      if (
        (currentAccount &&
          currentAccount.uuid !== nextProps.currentAccount.uuid) ||
        !currentAccount
      ) {
        fetchSettingsFields();
      }
    }
  };

  handleChangeFieldsSource = (value) => {
    this.props.updateFieldsSource(value)
  };

  render() {
    const { t, settingsFieldsFilters } = this.props;
    return (
      <div className="inner-page slate-view-settings">
        <div className="inner-page-header">
          <h1 className="settings-header">{t('export:newExportJob')}</h1>
          <div className="settings-source-selector settings-header">
            <div className="settings-source-title">
              {t('settings:sourceTitle')}
              <Select
                defaultValue={defaultFieldsSource}
                options={getFieldsSourceOptions(t)}
                onChange={this.handleChangeFieldsSource}
                stylesPresetName="blackBordered"
                settingsFieldsFilters={settingsFieldsFilters}
              />
            </div>
            <div className="settings-source-title settings-source-subtitle">
              {t('settings:sourceSubtitle')}
            </div>
          </div>
        </div>

        <FormWrapper
          noValidation={true}
          entityName={entityNames.UPDATE_EXPORT_JOB}
          entity={[
            'name',
            'columns',
            'ruleSet',
            'exportJobType',
            'recordsFieldUniquenessIdentifier',
            'includeUrl',
            'includeUUID',
            'includeHeaderRow',
          ]}
          jsonPatchResource={resourceTypes.JOB}
          onSubmit={this.onSave}
        >
          <FormSlateViewSettings resource={resourceTypes.JOB} />
        </FormWrapper>
        <SlateViewSettingPopup
          closePopup={this.props.revertFieldsSource}
          agreeAndContinueAction={this.props.confirmFieldsSource}
          {...this.props.lastStateBeforeUpdateSourceField}
          t={t}
        />
      </div>
    );
  }

  onSave = (data) => {
    const { updateJob } = this.props;
    let name = '';

    // find new name
    if (data.patches) {
      data.patches.forEach((patch) => {
        if (patch.path === '/name') {
          name = patch.value;
        }
      });
    }
    const jobUUID = generateUUID();

    updateJob(data, jobUUID, name, true);
  };
}

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  settingsFieldsFilters: state.slateViews.settingsFieldsFilters.include,
  lastStateBeforeUpdateSourceField: state.slateViews.snapshotOfTheCurrentStateForBeAbleToRevert,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchSettingsFields,
    updateJob,
    updateFieldsSource,
    revertFieldsSource,
    confirmFieldsSource,
    cleanFieldsSource,
  })(
    ExportJobSettings,
  ),
);
