import { route } from '../../config/constants';

export const contents = [
  {
    title: 'Dashboard',
    icon: 'tachometer',
    text: {
      __html:
        'Log in to the Slate Pages Dashboard here to access all your assets',
    },
    className: 'sign-item-dashboard',
  },
  {
    title: 'New User',
    icon: 'user-plus',
    text: {
      __html: `Are you new to Slate Pages and looking to get access 
             to an organization's Slates? Create your user profile 
             <a href="${route.CREATE_PROFILE}">here</a>.`,
    },
    className: 'sign-item-new-user',
  },
  {
    title: 'New Organization Account',
    icon: 'university',
    text: {
      __html: `Looking to create an account for your organization? 
             Fill out our <a href="${route.CREATE_PROFILE}">Getting Started form here</a>
             and we'll get you up and running.`,
    },
    className: 'sign-item-organization',
  },
  {
    title: 'More Info',
    icon: 'question-circle',
    text: {
      __html: `Want to learn more about Slate Pages? 
             Visit <a href="http://slatepages.com">slatepages.com</a> 
             or <a href="#">contact us</a>.`,
    },
    className: 'sign-item-more',
  },
];
