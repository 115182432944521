import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FormSearch from '../../components/Forms/FormSearch/FormSearch';

function mapStateToProps(state) {
  return {
    currentAccount: state.accounts.currentAccount,
  };
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, {})(FormSearch)),
);
