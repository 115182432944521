import React, { Component } from 'react';
import style from './style';

import ImageLoader from '../Shared/ImageLoader/ImageLoader';
import { avatarShapes, imageSizes } from '../../config/constants';
import { getImageLink } from '../../helpers/imageHelper';

class ImagePreview extends Component {
  render() {
    const {
      imageUUID,
      image,
      disabled,
      shape,
      type,
      imagesLinks,
      handleDialogOpen,
      t,
    } = this.props;

    let shapeStyle = {};
    let disabledStyle = disabled ? style.uploadButtonDisabled : {};

    if (shape) {
      switch (shape) {
        case avatarShapes.CIRCLE:
          shapeStyle = style.circle;
          break;
        case avatarShapes.ROUNDED_CORNERS:
          shapeStyle = style.roundedCorners;
          break;
        default:
          break;
      }
    }

    let link = getImageLink(
      imageUUID,
      imagesLinks,
      imageSizes.PROFILE_SETTINGS,
    );

    if (image) {
      return (
        <div
          onClick={handleDialogOpen}
          style={Object.assign(
            {},
            style.previewButton,
            style.border,
            shapeStyle,
            {
              backgroundImage: `url(${image})`,
            },
          )}
        />
      );
    } else {
      return (
        <div
          onClick={handleDialogOpen}
          style={Object.assign(
            {},
            style.uploadButton,
            shapeStyle,
            disabledStyle,
          )}
        >
          {!(type === 'edit' && link) && (
            <div
              style={Object.assign(
                {},
                style.border,
                shapeStyle,
                style.photoLabel,
              )}
            >
              {t('common:photo')}
            </div>
          )}

          {link && (
            <ImageLoader
              url={link}
              spinnerWidth={imageSizes.PROFILE_SETTINGS.width / 2}
              containerStyle={shapeStyle}
              imageStyle={Object.assign({}, shapeStyle, style.border)}
              preloaderStyle={Object.assign({}, shapeStyle, style.border)}
            />
          )}
        </div>
      );
    }
  }
}

export default ImagePreview;
