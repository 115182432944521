import p from '../../theme/palette';

const theme = {
  sidebar: {
    backgroundColor: p.sidebarContent,
  },
  sidebarContent: {
    backgroundColor: p.sidebarContent,
  },
  sidebarContentForLoggedOut: {
    padding: 20,
  },
  sidebarHeader: {
    backgroundColor: p.sidebarHeader,
  },
  sidebarLink: {
    color: p.sidebarLink,
  },
  sidebarActiveLink: {
    color: p.white,
  },
  sidebarAccount: {
    backgroundColor: p.sidebarAccountBg,
  },
  sidebarAccountImage: {
    backgroundColor: p.sidebarAccountImageBg,
    border: `1px solid ${p.white}`,
  },
  sidebarAccountCog: {
    color: p.white,
    fontSize: 20,
  },
};

export default theme;
