import React from 'react';
import FontAwesome from 'react-fontawesome';
import find from 'lodash/find';
import { SortableHandle } from 'react-sortable-hoc';

import { theme } from '../theme';

const DragHandle = SortableHandle(() => (
  <div style={theme.sortableIcon}>::</div>
));

const HeaderCell = ({
  onSortData,
  onFilterDialogOpen,
  column,
  sortColumnIndex,
  sortIcon,
  filters,
  width,
  colIndex,
  withOrdering,
  withFiltration,
  withSorting,
}) => {
  const currentFilter = find(filters, (item) => item.index === column.index);
  const paddingLeft = colIndex === 0 ? 8 : 0;

  return (
    <div
      className={`header-cell ${!withOrdering ? 'without-ordering' : ''}`}
      style={{ width, paddingLeft }}
    >
      {withOrdering && <DragHandle />}
      <div
        className="header-cell-title"
        onClick={() => {
          if (withSorting) {
            onSortData(column.index);
          }
        }}
        style={
          column.index === sortColumnIndex ? theme.active : theme.headerTitle
        }
      >
        {column.name}
      </div>
      <div className="header-cell-actions">
        {withFiltration && (
          <div
            className="header-cell-filter"
            onClick={(e) => {
              if (e) {
                e.stopPropagation();
              }
              onFilterDialogOpen(column);
            }}
          >
            <FontAwesome
              style={currentFilter ? theme.active : theme.filter}
              name="filter"
            />
          </div>
        )}

        {withSorting && column.index === sortColumnIndex && (
          <div
            className="header-cell-sort"
            style={theme.active}
            onClick={() => onSortData(column.index)}
          >
            {sortIcon && <FontAwesome name={sortIcon} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderCell;
