import React from 'react';
import IconButton from 'material-ui/IconButton';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import ModeEdit from 'material-ui/svg-icons/editor/mode-edit';
import { history } from '../../store/createStore';
import { route, apiKeyRoles } from '../../config/constants';
import styles from './styles';
import './style.css';

const ApiKeyItem = (props) => (
  <div className="apikey-item">
    <div>
      <div className="apikey-item-name">{props.apiKey.name}</div>
      <div className="apikey-item-options">
        {renderOptions(props.apiKey, props.t)}
      </div>
    </div>
    <div className="apikey-item-actions">
      <IconButton
        style={styles.button}
        iconStyle={styles.editIcon}
        onClick={() => {
          history.push({
            pathname: route.API_KEY,
            state: { uuid: props.apiKey.uuid },
          });
        }}
      >
        <ModeEdit />
      </IconButton>

      <IconButton
        style={styles.button}
        iconStyle={styles.removeIcon}
        onClick={(e) => props.onRemoveApiKey(props.apiKey.uuid)}
      >
        <NavigationClose />
      </IconButton>
    </div>
  </div>
);

function renderOptions(apiKey, t) {
  let result = [];
  if (apiKey.roles.includes(apiKeyRoles.SLATE_IMPORT)) {
    result.push(t('accountSettings:importSlates'));
  }
  if (apiKey.roles.includes(apiKeyRoles.SLATE_UPDATE)) {
    result.push(t('accountSettings:updateSlates'));
  }
  return result.join(', ');
}

export default ApiKeyItem;
