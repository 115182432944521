import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import RaisedButton from 'material-ui/RaisedButton';
import FontAwesome from 'react-fontawesome';

import Card from 'components/Card';
import SlateNewData from './SlateNewData';
import generateUUID from 'helpers/generateUUID';
import InfoPanel from '../Shared/InfoPanel/InfoPanel';
import { infoPanelTypes } from 'config/constants';
import './styles.css';

class SlateGenerator extends Component {
  state = {
    uuid: generateUUID(),
  };

  render() {
    const { t } = this.props;
    const { uuid } = this.state;

    return (
      <div className="inner-page">
        <div className="inner-page-header">
          <h1 className="slate-generator-title">
            {t('slateGenerator:slateGenerator')}
          </h1>
        </div>

        <Card
          title={t('slateGenerator:hereIsANewSlate')}
          content={
            <div>
              <div className="slate-generator-warning-wrapper">
                <InfoPanel type={infoPanelTypes.WARNING}>
                  {t('slateGenerator:warning')}{' '}
                </InfoPanel>
              </div>

              <SlateNewData uuid={uuid} t={t} />

              <RaisedButton
                label={t('slateGenerator:generateNewSlate')}
                primary={true}
                onClick={this.generateNewSlate}
                icon={
                  <FontAwesome name={'cogs'} style={{ color: '#ffffff' }} />
                }
              />
            </div>
          }
        />
      </div>
    );
  }

  generateNewSlate = () => {
    this.setState({
      uuid: generateUUID(),
    });
  };
}

export default withTranslation()(SlateGenerator);
