// Please, add new constants into new files in /src/constants/ folder
// TODO Split one enormous file to some small, group constants by meaning.

const API_ROOT_URI = `https://${process.env.REACT_APP_API_BASE_URL}/v2.0`;

export const api = {
  AUTH_USER: `${API_ROOT_URI}/auth/tokens`,
  AUTH_WITH_2FA_CODE_USER: `${API_ROOT_URI}/auth/tokens/2fa/verify`,
  AUTH_WITH_2FA_CODE_RESEND_USER: `${API_ROOT_URI}/auth/tokens/2fa/resend`,
  GET_USER: `${API_ROOT_URI}/user`,
  CREATE_USER: `${API_ROOT_URI}/users`,
  GET_USER_ETAG: `${API_ROOT_URI}/user/etag`,
  UPDATE_RESOURCE: `${API_ROOT_URI}/resources`,
  SEND_PASSWORD_RESET_CODE: `${API_ROOT_URI}/user/password/reset/codes`,
  SEND_EMAIL_VERIFICATION_LINK: `${API_ROOT_URI}/user/email/verification/email`,
  SEND_PHONE_VERIFICATION_CODE: `${API_ROOT_URI}/user/phone/verification/codes`,
  SEND_2FA_VERIFICATION_CODE: `${API_ROOT_URI}/user/email/2fa/enable`,
  SEND_DISABLED_2FA_VERIFICATION_CODE: `${API_ROOT_URI}/user/email/2fa/disable`,
  RESET_PASSWORD: `${API_ROOT_URI}/user/password/reset`,
  UPDATE_PASSWORD: `${API_ROOT_URI}/user/password`,
  VERIFY_CODE: `${API_ROOT_URI}/user/phone/verification/codes`,
  VERIFY_2FA_CODE: `${API_ROOT_URI}/user/email/2fa/enable/verify`,
  VERIFY_DISABLED_2FA_CODE: `${API_ROOT_URI}/user/email/2fa/disable/verify`,
  UPLOAD_DOCUMENT: `${API_ROOT_URI}/documents`,
  GET_ACCOUNT: `${API_ROOT_URI}/accounts`,
  ADD_ACCOUNT: `${API_ROOT_URI}/user/accounts`,
  REMOVE_ACCOUNT: `${API_ROOT_URI}/user/accounts`,
  CREATE_ACCOUNT: `${API_ROOT_URI}/accounts`,
  GET_SLATE: `${API_ROOT_URI}/slates`,
  GET_SLATE_VIEWS_LIST: `${API_ROOT_URI}/accounts`,
  GET_SLATES: `${API_ROOT_URI}/accounts`,
  GET_IMAGE: `${API_ROOT_URI}/documents/images`,
  GET_SLATE_VIEW_SETTINGS: `${API_ROOT_URI}/accounts`,
  GET_JOBS: `${API_ROOT_URI}/accounts`,
  CREATE_JOB: `${API_ROOT_URI}/accounts`,
  USER_METRICS_SETTINGS: `${API_ROOT_URI}/user/metrics/settings`,
  USER_METRICS: `${API_ROOT_URI}/user/metrics`,
  USER_METRICS_WIDGET: `${API_ROOT_URI}/user/metrics/widgets/`,
  GET_NOTIFICATIONS: `${API_ROOT_URI}/accounts/`,
  CREATE_NOTIFICATION: `${API_ROOT_URI}/resources/notification/`,
  DELETE_NOTIFICATION: `${API_ROOT_URI}/accounts/`,
  GET_TIME_ZONES: `${API_ROOT_URI}/timezones/iana/`,
};

export const layouts = {
  SIGN: 'sign',
  DASHBOARD: 'dashboard',
  SLATE: 'slate',
};

export const route = {
  // Public
  START: '/start',
  LOGIN: '/login',
  CREATE_PROFILE: '/create-profile',
  RESET_PASSWORD: '/reset-password',
  SUPPORT_IOS: '/.well-known/apple-app-site-association',
  // SUPPORT_ANDROID: '/.well-known/assetlinks.json',

  // Private
  ROOT: '/',
  PROFILE: '/profile',
  ACCOUNT: '/account',
  API_KEY: '/account/apikey',

  // Private // Slate Views
  SLATE_VIEWS: '/slate-views/', // it uses for redirects only
  SLATE_VIEW: '/accounts/:accounts/views/:views?',
  SLATE_VIEW_SETTINGS: '/settings/accounts/:accounts/views/:views',
  SLATE_VIEW_NEW: '/views/new',

  SLATE_VIEW_SEARCH_REDIRECT: '/search/',
  SLATE_VIEW_SEARCH: '/search/:searchString',

  // Private // Tools
  SLATE_GENERATOR: '/slate-generator',
  EXPORT: '/export',
  EXPORT_NEW_JOB: '/export/new',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_EDIT: '/notifications/:notificationUUID',
  NOTIFICATIONS_NEW: '/notifications/new',

  // Private // Slate
  SLATE: '/slates/:slateUUID',

  // Private // Metrics
  METRICS: '/metrics',
};

export const routeTitles = {
  HOME: 'home',
  PROFILE_SETTINGS: 'profileSettings',
  ACCOUNT_SETTINGS: 'accountSettings',
  API_KEY: 'apiKey',
  SLATE_VIEWS: 'slateViews',
  SLATE_VIEW_SETTINGS: 'slateViewSettings',
  SLATE_VIEW_NEW: 'slateViewNew',
  SLATE_VIEW_SEARCH: 'searchResults',
  SLATE_GENERATOR: 'slateGenerator',
  EXPORT: 'export',
  EXPORT_NEW_JOB: 'newExportJob',
  SLATE: 'slate',
  METRICS: 'metrics',
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_NEW: 'notificationNew',
  NOTIFICATION_EDIT: 'notificationEdit',
};

export const entityNames = {
  CREATE_AUTH_TOKEN: 'CreateAuthTokenCommand',
  LOGIN_2FA_CODE_VERIFICATION: 'loginWith2faCodeVerification',
  GET_USER: 'user:get', // ToDo
  CREATE_USER: 'CreateUserCommand',
  UPDATE_USER: 'User',
  GET_USER_ETAG: 'user:etag', // ToDo
  UPDATE_PASSWORD: 'UpdatePasswordCommand',
  SEND_PASSWORD_RESET_CODE: 'SendPasswordResetCodeCommand',
  VERIFY_PASSWORD_RESET_CODE: 'VerifyPasswordResetCodeCommand',
  RESET_PASSWORD: 'ResetPasswordCommand',
  VERIFY_PHONE_VERIFICATION_CODE: 'VerifyPhoneVerificationCodeCommand',
  VERIFICATION_ENABLE_2FA_CODE: 'verification:enable2faCode', // ToDo
  VERIFICATION_DISABLE_2FA_CODE: 'verification:disable2faCode', // ToDo
  VERIFICATION_ENABLE_2FA_CODE_BACK: 'verificationEnable2faCodeBack', // ToDo
  VERIFICATION_DISABLE_2FA_CODE_BACK: 'verificationDisable2faCodeBack', // ToDo
  VERIFICATION_EMAIL: 'verification:email', // ToDo
  VERIFICATION_SMS: 'verification:sms', // ToDo
  VERIFICATION_CALL: 'verification:call', // ToDo
  DASHBOARD_SETTINGS: 'DashboardSettings',
  UPDATE_ACCOUNT_TIMESTAMP: 'UpdateAccountTimestamp',
  ADD_ACCOUNT: 'AddAccountToUserProfileCommand',
  REMOVE_ACCOUNT: 'accounts:remove', // ToDo
  GET_ACCOUNT: 'account:get', // ToDo
  UPDATE_ACCOUNT: 'Account',
  GET_API_KEYS: 'apiKeys:get',
  CREATE_API_KEY: 'CreateAPIKeyCommand',
  REMOVE_API_KEY: 'DeleteAPIKeyCommand',
  UPDATE_API_KEY: 'APIKey',
  GET_SLATE: 'slate:get',
  UPDATE_SLATE: 'Slate',
  UPLOAD_DOCUMENT: 'document:upload',
  GET_SLATE_VIEWS_LIST: 'slateViewsList:get',
  GET_SLATE_VIEW: 'slateView:get',
  GET_SLATES: 'slates:get',
  SEARCH: 'Search',
  GET_SLATE_VIEW_SETTINGS: 'GetSlateViewSettings',
  UPDATE_LIST_VIEW: 'ListView',
  UPDATE_MAP_VIEW: 'MapView',
  DELETE_VIEW: 'DeleteViewCommand',
  CHANGE_TIME_ZONE: 'ChangeTimeZone',
  GET_SETTINGS_FIELDS: 'GetSettingsFields',
  GET_SETTINGS_OPERATORS: 'GetSettingsOperators',
  GET_JOBS: 'GetJobs',
  UPDATE_EXPORT_JOB: 'ExportJob',
  UPDATE_NOTIFICATION: 'UpdateNotification',
  CREATE_JOB: 'CreateJob',
  CLONE_JOB: 'CloneJob',
  RERUN_JOB: 'RerunJob',
  REMOVE_JOB: 'RemoveJob',
  GET_JOURNAL_ENTRIES: 'GetJournalEntries',
  GET_RECORD_ENTRIES: 'GetRecordEntries',
  GET_RECORD_SLATE: 'GetRecordSlate',
  GET_HISTORY_ENTRIES: 'GetHistoryEntries',
  UPDATE_RECORD_ENTRY: 'UpdateRecordEntry',
};

export const resourceTypes = {
  USER: 'user',
  ACCOUNT: 'account',
  API_KEY: 'apikey',
  SLATE: 'slate',
  LIST_VIEW: 'listview',
  MAP_VIEW: 'mapview',
  JOB: 'exportjob',
};

export const fieldsTypes = {
  TEXT: 'text',
  BOOLEAN: 'boolean',
  SELECTOR: 'selector',
  NUMBER: 'number',
  LOCATION: 'location',
  EMAIL: 'email',
  URL: 'url',
  PHONE: 'phone',
  DATETIME: 'dateTime',
  PHOTO: 'photo',
  GROUP: 'group',
  JOURNAL: 'journal',
  RECORDS: 'records',
  IMAGE: 'image',
  DESCRIPTION: 'description',
  ACTIONS: 'actions',
};

export const entryDetailsTypes = {
  USER_DISPLAY_NAME: 'userDisplayName',
  TIMESTAMP: 'timestamp',
};

export const entryDetails = {
  [entryDetailsTypes.USER_DISPLAY_NAME]: {
    isShown: true,
    recordEntryAttribute: entryDetailsTypes.USER_DISPLAY_NAME,
    title: "User Display Name",
    type: "",
    mainType: 'Entry_Details',
  },
  [entryDetailsTypes.TIMESTAMP]: {
    isShown: true,
    recordEntryAttribute: entryDetailsTypes.TIMESTAMP,
    title: "Timestamp",
    type: "",
    mainType: 'Entry_Details',
  },
};

export const filteringFieldsForRecordListView = [
  {
    isShown: true,
    suuid: "Entry_Details",
    title: "Entry Details",
    type: "",
    disable: true,
  },
  entryDetails.userDisplayName,
  entryDetails.timestamp,
  {
    isShown: true,
    suuid: "Entry_Fields",
    title: "Entry Fields",
    type: "",
    disable: true,
  },
]

export const filteringFieldsForRecordListViewOtherFields = [
  {
    isShown: true,
    suuid: "Other_Fields",
    title: "Other Fields",
    type: "",
    disable: true,
  },
]

export const settingsOperatorsEntryDetails = {
  timestamp: {
    allowedOperators: [
      "equals",
      "doesntEqual",
      "lessThan",
      "lessThanEqualTo",
      "greaterThan",
      "greaterThanEqualTo",
    ]
  },
  userDisplayName: {
    allowedOperators: [
      "equals",
      "doesntEqual",
      "contains",
      "doesntContain",
    ]
  },
};

export const apiKeyRoles = {
  SLATE_UPDATE: 'slateupdate',
  SLATE_IMPORT: 'slateimport',
};

export const infoPanelTypes = {
  WARNING: 'warning',
};

export const booleanDisplayTypes = {
  RED_GREEN: 'redGreen',
  TRUE_FALSE: 'trueFalse',
  YES_NO: 'yesNo',
  ON_OFF: 'onOff',
};

export const dateTimeDisplayTypes = {
  DATE: 'date',
  DATE_TIME: 'dateTime',
};

export const dateTimeFormats = {
  DATE: 'MM/DD/YYYY',
  DATE_TIME: 'MM/DD/YYYY h:mm a',
};

export const avatarShapes = {
  CIRCLE: 'CIRCLE',
  ROUNDED_CORNERS: 'ROUNDED_CORNERS',
};

export const sortTypes = {
  ASC: 'asc',
  DESC: 'desc',
};

export const imageSizes = {
  USER_TOPBAR: {
    width: 40,
    height: 40,
  },
  PROFILE_SETTINGS: {
    width: 100,
    height: 100,
  },
  ACCOUNT_SIDEBAR: {
    width: 80,
    height: 80,
  },
  ACCOUNT_LIST: {
    width: 44,
    height: 44,
  },
  SLATE_ACCOUNT: {
    width: 40,
    height: 40,
  },
  SLATE_HEADER: {
    height: 400,
  },
  SLATE_IMAGE: {
    height: 180,
  },
  MAP_VIEW: {
    width: 40,
    height: 40,
  },
  SEARCH_ITEM: {
    width: 50,
    height: 50,
  },
  HISTORY_ITEM: {
    width: 120,
    height: 120,
  },
};

export const slateViewsTypes = {
  LIST: 'list',
  MAP: 'map',
};

export const imageLoadingStatuses = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};

export const logicOperatorTypes = {
  AND: 'and',
  OR: 'or',
};

export const ruleParts = {
  RECORD_ENTRY_ATTRIBUTE: 'recordEntryAttribute',
  FIELD_SUUID: 'fieldSUUID',
  OPERATOR: 'operation',
  PATTERN: 'value',
};

export const selectorOperators = {
  EQUALS: 'equals',
  DOESNT_EQUAL: 'doesntEqual',
  EXIST_ON_SLATE: 'existsOnSlate',
  DOESNT_EXIST_ON_SLATE: 'doesntExistOnSlate',
  HAS_VALUE: 'hasValue',
  DOESNT_HAVE_VALUE: 'doesntHaveValue',
};

export const notificationTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const fieldsDnDId = {
  AVAILABLE: 'available',
  SELECTED: 'selected',
};

export const jobsTabs = {
  ACTIVE: 'active',
  FAVORITES: 'favorites',
  ARCHIVED: 'archived',
};

export const jobsStatuses = {
  FINISHED: 'finished',
  STARTING: 'starting',
};

export const columnTypes = {
  FIELD: 'field',
  METADATA: 'metadata',
};

export const fieldAttributeOptions = ['value', 'user', 'timestamp'];

export const dynamicDate = 'todayNow';

export const minSearchLength = 1;

export const localStorageProps = {
  MAP_TYPE: 'mapType',
  CURRENT_ACCOUNT: 'currentAccount',
};

export const mapTypes = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  HYBRID: 'hybrid',
  TERRAIN: 'terrain',
};

export const metricsModes = {
  FILTERS: 'filters',
  GALLERY: 'gallery',
};

export const patchOptions = {
  REPLACE: 'replace',
  ADD: 'add',
};

export const presetNames = {
  mediumIfPhoto: 'medium-if-photo',
  medium: 'medium',
};

export const listViewTypes = {
  slates: 'slates',
  records: 'recordEntries',
};

export const slateTypes = {
  slate: 'slate',
  record: 'recordEntry',
};
