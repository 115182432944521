import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, TextField} from '@material-ui/core';
import palette from "theme/palette";

const useStyles = makeStyles(() => ({
  root: {
    width: 365,
    color: '#000000',
    fontWeight: 500,
    textAlign: 'center',
    fontSize: `22px !important`,

    "&$focused $notchedOutline": {
      borderColor: palette.brandOrangeD
    },
  },
  focused: {},
  notchedOutline: {},
  resize: {
    fontSize: `20px !important`,
    fontWeight: 500,
    textAlign: 'center',
  },
  floatingLabelFocusStyle: {
    "&.Mui-focused": {
      color: palette.brandOrangeD,
    },
  }
}));

const TextInput = ({shareUrl, multiline = false}) => {
  const classes = useStyles();
  return (
    <TextField
      value={shareUrl}
      id="outlined-basic"
      label="LINK"
      variant="outlined"
      multiline={multiline}
      color={palette.brandOrangeD}
      InputProps={{
        classes: {
          input: classes.resize,
          root: classes.root,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline
        }
      }}
      InputLabelProps={{
        className: classes.floatingLabelFocusStyle,
      }}
      onClick={(event) =>
        event.target && event.target.select && event.target.select()
      }
    />
  );
};

TextInput.propTypes = {
  shareUrl: PropTypes.string.isRequired,
};

export default TextInput;
