import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {fieldsTypes, notificationTypes, route} from "../../config/constants";
import {useTranslation, withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import FontAwesome from "react-fontawesome";
import RaisedButton from "material-ui/RaisedButton";
import { AutoSizer } from "react-virtualized";
import ScrollableTable from "../ScrollableTable/ScrollableTable";
import IconButton from "material-ui/IconButton";
import { ActionDelete } from "material-ui/svg-icons";
import Preloader from "../Preloader/Preloader";
import {getListOfNotifications, setActiveNotifications} from "store/actions/notifications";
import './style.css';
import DeleteNotificationPopup from "./Components/DeleteNotificationPopup";
import theme from "../SlateViews/theme";
import {FETCHING_STATUS, FETCHING_TYPE} from "../../store/reducers/notifications";
import { showNotification } from 'store/actions/notification';

const Notifications = ({
                         history,
                         getListOfNotifications,
                         setActiveNotifications,
                         notificationsList: notificationsListProps,
                         currentAccount,
                         isFetching,
                         showNotification,
                 }) => {
  const { t } = useTranslation();
  const columns = [
    {
      index: 0,
      name: t('notificationsPage:tableTitles:name'),
      type: fieldsTypes.TEXT,
      width: 300,
    },
    {
      index: 1,
      name: t('notificationsPage:tableTitles:triggerType'),
      type: fieldsTypes.TEXT,
      width: 300,
    },
    {
      index: 2,
      name: t('notificationsPage:tableTitles:deliveryType'),
      type: fieldsTypes.TEXT,
    },
    // {
    //   index: 3,
    //   name: t('notificationsPage:tableTitles:actions'),
    //   type: fieldsTypes.ACTIONS,
    // },
  ]
  const [notificationsList, setNotificationsList] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const actionButtons = (data) => <div className="apikey-item-actions">
    <IconButton
      data-id={data}
      onClick={(e) => {
        e.stopPropagation();
        setActiveNotifications(data);
        setOpenPopup(true);
      }}
    >
      <ActionDelete />
    </IconButton>
  </div>

  useEffect(() => {
    if(!notificationsListProps && currentAccount) getListOfNotifications();
  }, []);

  const editNotification = (e, UUID) => {
    const notification = notificationsList.find(element => element.uuid === UUID);
    if (notification.columns[1].value === 'dateField') {
      setActiveNotifications(UUID);
      history.push({
        pathname: route.NOTIFICATIONS + '/' + UUID,
      });
    } else {
      showNotification(
        notificationTypes.WARNING,
        t('notifications:notificationMustBeConfiguredBySlatePages'),
      );
    }
  }

  useEffect(() => {
    if(currentAccount) getListOfNotifications();
  }, [currentAccount && currentAccount.uuid]);

  useEffect(() => {
    if(!notificationsListProps) return;
    const newNotificationsList = notificationsListProps.map( notification => {
      return {
        ...notification,
        columns: [...notification.columns, {value: actionButtons(notification.uuid), index: notification.columns.length}]
      }
    })

    setNotificationsList(newNotificationsList)
  }, [notificationsListProps]);

  const createNewNotification = () => {
    history.push({
      pathname: route.NOTIFICATIONS_NEW,
    });
  }

  const closePopup = () => {
    setActiveNotifications('');
    setOpenPopup(false);
  }

  const handleRefreshClick = () => {
    getListOfNotifications();
  };

  const getRowHeight = ({ index }) => {
    return (notificationsList[index].columns[3].value.length || 1) * 36
  }

  return (
    notificationsList ?
    <div className="notifications-container">
      <div className="tools-export-header">
        <h1 className="tools-export-title">{t('notificationsPage:title')}</h1>
        <div>
          <RaisedButton
            className="slate-view-actions-refresh"
            icon={<FontAwesome name="refresh" />}
            style={theme.slateViewActionStyle}
            onClick={handleRefreshClick}
          />

          <RaisedButton
            label={t('notificationsPage:addNotificationButton')}
            onClick={createNewNotification}
            icon={<FontAwesome name="plus" />}
          />
        </div>
      </div>
      <div className="notifications-table">
        {
          (
            isFetching.status === FETCHING_STATUS.inProgress &&
            isFetching.type === FETCHING_TYPE.gettingNotificationsList
          ) ?
            <Preloader /> :
            <AutoSizer>
              {({ width, height }) => (
                <ScrollableTable
                  onRowClickParentHandler={editNotification}
                  columns={columns}
                  data={notificationsList}
                  isExpandable={false}
                  fixedHeader={true}
                  fixedHeaderHeight={36}
                  useDragHandle={true}
                  withOrdering={false}
                  withFiltration={false}
                  withSorting={true}
                  uniqueField="uuid"
                  widthStretch={true}
                  width={width}
                  height={height}
                  fetchData={() => {}}
                  rowHeightPreset={getRowHeight}
                />
              )}
            </AutoSizer>
        }
      </div>
      <DeleteNotificationPopup
        open={openPopup}
        closePopup={closePopup}
        currentAccountUUID={currentAccount.uuid}
      />
    </div> :
      <Preloader />
  );
};

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.currentAccount,
  notificationsList: state.notifications_page.listOfNotifications,
  isFetching: state.notifications_page.isFetching,
});

const mapDispatchToProps = {
  getListOfNotifications,
  setActiveNotifications,
  showNotification,
};


export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(Notifications),
  ),
);
