import React from 'react';
import FontAwesome from 'react-fontawesome';

import Legal from '../Legal/Legal';
import { contents } from './contents';
import { history } from 'store/createStore';
import { route } from 'config/constants';

const handleLogoLink = () => history.push(route.ROOT);

export const LeftPane = () => {
  return (
    <div className="left-pane">
      <div className="sign-logo" onClick={handleLogoLink} />
      <div className="sign-content">
        <div className="sign-items">
          {contents.map((item, index) => {
            return (
              <div key={index} className={`sign-item ${item.className}`}>
                <div className="sign-item-icon">
                  <FontAwesome name={item.icon} />
                </div>
                <div className="sign-item-desc">
                  <div className="sign-item-title">{item.title}</div>
                  <div
                    className="sign-item-text"
                    dangerouslySetInnerHTML={item.text}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Legal />
    </div>
  );
};
