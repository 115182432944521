import axios from 'axios';
import {push} from 'connected-react-router';
import Notifications from 'react-notification-system-redux';
import i18next from 'i18next';

import * as type from './types';
import {
  route,
  api,
  entityNames,
  resourceTypes,
  localStorageProps,
  mapTypes,
} from 'config/constants';
import {
  cookiesTypes,
  setCookies,
  removeCookies,
  options,
  INCLUDE_RESOURCE,
} from 'helpers/cookieHelper';
import {getCurrentAccount} from 'helpers/accountHelper';
import {getFromLocalStorage} from 'helpers/localStorageHelper';
import {handleVersion} from 'helpers/versionsHelper';
import {handleSuccessResetPassword} from "./resetPassword";

const handleUserResponse = (res, dispatch, entityName, state) => {
  const {user, accounts} = res.data.data;
  const currentAccount = state().accounts.currentAccount
    ? state().accounts.currentAccount
    : getCurrentAccount(user.uuid, accounts);
  const mapType =
    getFromLocalStorage(user.uuid, localStorageProps.MAP_TYPE) ||
    mapTypes.ROADMAP;

  setCookies(cookiesTypes.USER_COOKIE, user);

  dispatch({
    type: type.SET_USER,
    payload: {
      user,
    },
  });

  if (currentAccount && currentAccount.uuid) {
    getNotificationIsShowing({uuid: currentAccount.uuid, dispatch}).then(() => {
    })
  }

  dispatch({
    type: type.SET_ACCOUNTS,
    payload: {
      userUUID: user.uuid,
      accounts,
      currentAccount,
    },
  });

  dispatch({
    type: type.UPDATE_MAP_TYPE,
    payload: {
      userUUID: user.uuid,
      mapType,
    },
  });

  dispatch({
    type: type.UPDATE_SLATE_PANE_EXPANDED,
    payload: {
      slatePaneExpanded: user.dashboardSettings.slatePaneExpanded,
    },
  });

  if (entityName) {
    dispatch({
      type: type.FETCH_SUCCESS,
      entityName: entityName,
    });

    switch (entityName) {
      case entityNames.CREATE_AUTH_TOKEN:
        removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);
        const {pathname} = state().router.location;

        if (pathname.includes('/accounts/') && pathname.includes('/views/')) {
          this.props.handleLoginDialogOpen();
        } else {
          dispatch(push(route.ROOT));
        }
        break;

      case entityNames.CREATE_USER:
        dispatch(push(route.ROOT));
        dispatch(
          Notifications.success({
            message: i18next.t('notifications:successfullyCreateUser'),
            position: 'tr',
            autoDismiss: 3,
          }),
        );
        break;

      default:
        break;
    }
  }
};

export const handleSuccessLogin = (res, dispatch, entityName, state) => {
  handleVersion(res);
  handleUserResponse(res, dispatch, entityName, state);
}

export function close2faLoginPage() {
  return (dispatch) => {
    removeCookies(cookiesTypes.RESET_PASSWORD_COOKIE);
    dispatch({
      entityName: entityNames.RESET_PASSWORD,
      type: type.FETCH_RESET,
    });
    dispatch({
      entityName: entityNames.CREATE_AUTH_TOKEN,
      type: type.FETCH_RESET,
    });
    dispatch({
      type: type.SET_LOGIN_2FA_CODE_DATA,
      payload: {
        isOpen: false,
      }
    });
  };
}

export function login(data, entityName) {
  return (dispatch, state) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .post(api.AUTH_USER, data, options)
      .then((res) => {
        if (res.status === 202) {
          dispatch({
            type: type.FETCH_STOP,
            entityName: entityName,
          });
          dispatch({
            type: type.SET_LOGIN_2FA_CODE_DATA,
            payload: {
              isOpen: true,
              parentEntityName: entityName
            }
          });
        } else {
          handleSuccessLogin(res, dispatch, entityName, state);
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              if (err.response && err.response.data && err.response.data.errorCode) {
                switch (err.response.data.errorCode) {
                  case 105:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:invalidCredentials')},
                    });
                    break
                  case 203:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:tooMany2faCodeRequests')},
                    });
                    break
                  default:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:userNotFound')},
                    });
                    break;
                }
              } else {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {error400: i18next.t('messages:userNotFound')},
                });
              }
              return;
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              return;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export function resend2faLink(data, entityName) {
  return (dispatch) => {
    return axios
      .post(api.AUTH_WITH_2FA_CODE_RESEND_USER, data, options)
      .then(() => {})
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              if (err.response && err.response.data && err.response.data.errorCode) {
                switch (err.response.data.errorCode) {
                  case 105:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:invalidCredentials')},
                    });
                    break
                  case 203:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:tooMany2faCodeRequests')},
                    });
                    break
                  default:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:userNotFound')},
                    });
                    break;
                }
              } else {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {error400: i18next.t('messages:userNotFound')},
                });
              }
              return;
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              return;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

const _handlerSuccess2faByParentEntityName = {
  [entityNames.CREATE_AUTH_TOKEN]: handleSuccessLogin,
  [entityNames.RESET_PASSWORD]: handleSuccessResetPassword,
}

export function loginWith2faCode(data, entityName) {
  return (dispatch, state) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });
    return axios
      .post(api.AUTH_WITH_2FA_CODE_USER, {code: data.code}, options)
      .then((res) => {
        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityNames.LOGIN_2FA_CODE_VERIFICATION,
        });
        const login2faDialogData = state().currentUser.login2faDialogData;
        const parentEntityName = login2faDialogData ? login2faDialogData.parentEntityName : null;
        if (parentEntityName && _handlerSuccess2faByParentEntityName[parentEntityName]) {
          const currentMethod = _handlerSuccess2faByParentEntityName[parentEntityName];
          currentMethod(res, dispatch, parentEntityName, state)
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              if (err.response && err.response.data && err.response.data.errorCode) {
                switch (err.response.data.errorCode) {
                  case 201:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: err.response.data.errorDebugDescription || i18next.t('messages:userDoesntExistCode'),
                    });
                    return;
                  case 203:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: err.response.data.errorDebugDescription || i18next.t('messages:tooManyCodesSent'),
                    });
                    return;
                  default:
                    dispatch({
                      type: type.FETCH_ERROR,
                      entityName: entityName,
                      hasErrors: {error400: i18next.t('messages:userNotFound')},
                    });
                    return;
                }
              } else {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {error400: i18next.t('messages:userNotFound')},
                });
              }
              return;
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              return;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export function getNotificationIsShowing({uuid, dispatch}) {
  return axios
    .get(`${api.GET_ACCOUNT}/${uuid}`, options)
    .then((res) => {
      handleVersion(res);
      const currentAccount = res.data.data;
      if (currentAccount) {
        dispatch({
          type: type.SET_IS_SHOWING_NOTIFICATION,
          uuid,
          currentAccount,
        })
      }
    })
    .catch(() => {
    });
}

export function getUser() {
  return (dispatch, state) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.GET_USER,
    });
    return axios
      .get(api.GET_USER, options)
      .then((res) => {
        handleVersion(res);
        handleUserResponse(res, dispatch, entityNames.GET_USER, state);
      })
      .catch((err) => {
        if (err.response && err.response.status === 500) {
          dispatch({
            type: type.FETCH_ERROR,
            entityName: entityNames.GET_USER,
            hasErrors: err.response.data.correlationUUID || null,
          });
        }
      });
  };
}

export function createUser(data, entityName) {
  return (dispatch, state) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .put(api.CREATE_USER, data, options)
      .then((res) => {
        handleVersion(res);
        handleUserResponse(res, dispatch, entityName, state);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: {error400: i18next.t('messages:userAlreadyExists')},
            });
            break;

          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;

          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
        }
      });
  };
}

export function updateUser(data, entityName) {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .patch(
        `${api.UPDATE_RESOURCE}/${resourceTypes.USER}/${
          getState().currentUser.uuid
        }?${INCLUDE_RESOURCE}`,
        data.patches,
        options,
      )
      .then((res) => {
        handleVersion(res);
        let user = res.data.data;
        if (user) {
          setCookies(cookiesTypes.USER_COOKIE, user);

          // if (user.etag !== getState().currentUser.etag) {
          //   dispatch({
          //     type: type.USER_ETAG_CHANGED
          //   });
          // }

          dispatch({
            type: type.SET_USER,
            payload: {
              user,
            },
          });

          // need for calling getUser() and fetching new accounts list
          dispatch({
            type: type.USER_ETAG_CHANGED,
          });
        }

        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });

        if (
          entityName !== entityNames.DASHBOARD_SETTINGS &&
          entityName !== entityNames.UPDATE_ACCOUNT_TIMESTAMP
        ) {
          dispatch(
            Notifications.success({
              message: i18next.t('notifications:successfullyUpdateUser'),
              position: 'tr',
              autoDismiss: 3,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {error400: i18next.t('messages:userNotFound')},
              });
              break;

            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export function getUserEtag() {
  return (dispatch, getState) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityNames.GET_USER_ETAG,
    });

    return axios
      .get(api.GET_USER_ETAG, options)
      .then((res) => {
        handleVersion(res);
        if (res.data.data !== getState().currentUser.etag) {
          dispatch({
            type: type.USER_ETAG_CHANGED,
          });
        }

        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityNames.GET_USER_ETAG,
        });
      })
      .catch(() => {
      });
  };
}

export function updatePassword(data, entityName) {
  return (dispatch) => {
    dispatch({
      type: type.FETCH_START,
      entityName: entityName,
    });

    return axios
      .post(api.UPDATE_PASSWORD, data, options)
      .then((res) => {
        handleVersion(res);
        dispatch({
          type: type.FETCH_SUCCESS,
          entityName: entityName,
        });

        dispatch({
          type: type.ENTITY_UPDATED,
          entityName: entityName,
        });

        dispatch(
          Notifications.success({
            message: i18next.t('notifications:successfullyUpdateUser'),
            position: 'tr',
            autoDismiss: 3,
          }),
        );
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export function logout() {
  return (dispatch) => {
    removeCookies(cookiesTypes.USER_COOKIE);
    dispatch({
      type: type.REMOVE_USER,
      payload: {
        force: false,
      },
    });

    dispatch({
      type: type.CLEAR_SLATE_VIEWS,
      payload: {},
    });

    dispatch({
      type: type.SET_APP_LAYOUT,
      payload: 'sign',
    });

    dispatch(
      Notifications.success({
        message: i18next.t('notifications:successfullyLoggedOut'),
        position: 'tr',
        autoDismiss: 3,
      }),
    );
  };
}

export function handleLoginDialogClose() {
  return (dispatch) => {
    dispatch({
      type: type.CLOSE_LOGIN_DIALOG,
    });
  };
}

export function handleLoginDialogOpen() {
  return (dispatch) => {
    dispatch({
      type: type.OPEN_LOGIN_DIALOG,
    });
  };
}
