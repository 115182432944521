import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ApiKeySettings from 'components/ApiKey/ApiKeySettings';
import {
  setCurrentApiKey,
  clearCurrentApiKey,
  updateApiKey,
  createApiKey,
  clearCreatedApiKey,
} from 'store/actions/account';

function mapStateToProps(state) {
  return {
    currentApiKey: state.accounts.currentApiKey,
    createdApiKey: state.accounts.createdApiKey,
  };
}

export default withTranslation()(
  connect(mapStateToProps, {
    setCurrentApiKey,
    clearCurrentApiKey,
    updateApiKey,
    createApiKey,
    clearCreatedApiKey,
  })(ApiKeySettings),
);
