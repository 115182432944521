import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  verifyPasswordResetCode,
  sendPasswordResetCode,
} from 'store/actions/resetPassword';
import FormVerificationCode from 'components/Forms/FormVerficationCode/FormVerficationCode';
import {fetchReset} from "../../store/actions/entities";

function mapStateToProps(state) {
  return {
    app: state.app,
    verification: state.verification,
  };
}

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, {
      verifyPasswordResetCode,
      sendPasswordResetCode,
      fetchReset,
    })(FormVerificationCode),
  ),
);
