import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FormLogin from '../../components/Forms/FormLogin/FormLogin';

function mapStateToProps(state) {
  return {
    previousSessionData: state.currentUser.previousSessionData,
    isOpenLoginDialog: state.currentUser.isOpenLoginDialog,
  };
}

export default withTranslation()(connect(mapStateToProps)(FormLogin));
