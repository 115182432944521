import React, { Component } from 'react';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import RaisedButton from 'material-ui/RaisedButton';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';

import { setNewSlateViewType } from 'store/actions/slateViews';
import { history } from 'store/createStore';
import { slateViewsTypes, route } from 'config/constants';
import theme from './theme';

class CreateViewButton extends Component {
  state = {
    isOpenPopover: false,
    anchorEl: null,
  };

  render() {
    const { isOpenPopover, anchorEl } = this.state;
    return (
      <div>
        <RaisedButton
          label="Create View"
          labelPosition="before"
          primary={true}
          onClick={this.handlePopoverOpen}
          icon={
            <FontAwesome
              name={isOpenPopover ? 'chevron-up' : 'chevron-down'}
              style={theme.slateViewSelectorIcon}
            />
          }
          style={theme.slateViewSelector}
          buttonStyle={theme.slateViewCreateButton}
          labelStyle={{...theme.slateViewSelectorLabel, ...theme.labelForSelector}}
        />

        <Popover
          animation={PopoverAnimationVertical}
          style={theme.popoverStyle}
          open={isOpenPopover}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handlePopoverClose}
        >
          <Menu autoWidth={false}>
            <MenuItem
              primaryText={'Create New List View'}
              leftIcon={<FontAwesome name="list-ul" style={theme.listIcon} />}
              innerDivStyle={theme.menuItemInnerStyle}
              onClick={(e) => this.createView(slateViewsTypes.LIST)}
            />
            <MenuItem
              primaryText={'Create New Map View'}
              leftIcon={<FontAwesome name="map-marker" style={theme.mapIcon} />}
              innerDivStyle={theme.menuItemInnerStyle}
              onClick={() => this.createView(slateViewsTypes.MAP)}
            />
          </Menu>
        </Popover>
      </div>
    );
  }

  handlePopoverOpen = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isOpenPopover: true,
      anchorEl: e.currentTarget,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      isOpenPopover: false,
    });
  };

  createView = (viewType) => {
    this.props.setNewSlateViewType(viewType);
    history.push({
      pathname: `${route.SLATE_VIEW_NEW}`,
    });
  };
}

export default connect(null, { setNewSlateViewType })(CreateViewButton);
