import axios from 'axios';
import i18next from 'i18next';
import Notifications from 'react-notification-system-redux';
import cloneDeep from 'lodash/cloneDeep';
import isBoolean from 'lodash/isBoolean';
import { push } from 'connected-react-router';

import { route } from 'config/constants';
import { api, entityNames, resourceTypes } from 'config/constants';
import { options, INCLUDE_RESOURCE } from 'helpers/cookieHelper';
import { adoptSettingsColumns } from 'helpers/fieldsHelper';
import { handleVersion, checkVersion } from 'helpers/versionsHelper';
import {createReplacePatch, getValidFormRulesList} from 'helpers/patchHelper';
import { updateUser } from './user';
import * as type from './types';
import {SET_RECORD_SLATE} from "./types";
import {isLoggedOut} from "../../helpers/accountHelper";

// Slate Views
export function fetchSlateView({newSlateView}) {
  return (dispatch) => {
    dispatch({
      type: type.SET_CURRENT_SLATE_VIEW_WITHOUT_VIEW_LIST,
      payload: {
        newSlateView: newSlateView,
      },
    });
  };
}

export function fetchSlateViewsList(accountUUID) {
  return (dispatch, getState) => {
    if (accountUUID) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_SLATE_VIEWS_LIST,
      });

      return axios
        .get(`${api.GET_SLATE_VIEWS_LIST}/${accountUUID}/views`, options)
        .then((res) => {
          handleVersion(res);
          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_SLATE_VIEWS_LIST,
          });

          const views = res.data.data;
          if (views) {
            dispatch({
              type: type.SET_SLATE_VIEWS_LIST,
              payload: {
                listViews: views.listViews,
                mapViews: views.mapViews,
                currentSlateView: getState().slateViews.currentSlateView,
              },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_SLATE_VIEWS_LIST,
          });
          dispatch({
            type: type.SET_SLATE_VIEWS_LIST,
            payload: {
              listViews: [],
              mapViews: [],
              currentSlateView: getState().slateViews.currentSlateView,
            },
          });
        });
    }
  };
}

/**
 *
 * @param {string} viewUUID
 * @param {Object: {isNewSet: boolean, filters: Array, sort: string, isChangedSlateView: boolean}} params
 */
export function fetchSlates(viewUUID, params) {
  return (dispatch, getState) => {
    if (viewUUID) {
      const { isNewSet, filters, sort, isChangedSlateView, _accountUUID } = params;
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_SLATES,
      });

      // if user is not login we add _accountUUID from url
      const accountUUID = !!_accountUUID ? _accountUUID : getState().accounts.currentAccount.uuid;
      const token = getState().slateViews.slatesToken;
      let slateViewOptions = cloneDeep(options);

      if (isNewSet) {
        dispatch({
          type: type.CLEAR_SLATES,
        });
      }

      if (!isNewSet && token) {
        slateViewOptions.headers['Continuation-Token'] = token;
      }
      if (filters && filters.length) {
        slateViewOptions.headers['query-filters'] = JSON.stringify(filters);
      }
      if (sort) {
        slateViewOptions.headers['query-sort'] = sort;
      }
      return axios
        .get(
          `${api.GET_SLATES}/${accountUUID}/views/${viewUUID}/slates`,
          slateViewOptions,
        )
        .then((res) => {
          handleVersion(res);
          if (isChangedSlateView) {
            checkVersion();
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_SLATES,
          });

          const { data, continuationToken, rowCount, autoRefresh } = res.data.data;

          if (data) {
            dispatch({
              type: type.SET_SLATES,
              payload: {
                slates: data,
                slatesToken: continuationToken,
                rowCount,
                isNewSet,
                autoRefresh,
              },
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_SLATES,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_SLATES,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export const deleteSlateView = (data, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName,
  });

  const accountUUID = getState().accounts.currentAccount.uuid;
  const viewUUID = getState().slateViews.currentSlateView.uuid;
  let verifyOptions = cloneDeep(options);
  verifyOptions.data = data;

  return axios
    .delete(
      `${api.GET_SLATE_VIEW_SETTINGS}/${accountUUID}/views/${viewUUID}`,
      verifyOptions,
    )
    .then((res) => {
      handleVersion(res);

      const newListViews = getState().slateViews.listViews.filter(view => view.uuid !== viewUUID);
      const newMapViews = getState().slateViews.mapViews.filter(view => view.uuid !== viewUUID);

      dispatch({
        type: type.SET_SLATE_VIEWS_LIST,
        payload: {
          listViews: newListViews,
          mapViews: newMapViews,
          currentSlateView: getState().slateViews.currentSlateView,
        },
      });

      dispatch({
        type: type.SET_CURRENT_SLATE_VIEW,
        payload: {
          uuid: null,
        },
      });

      dispatch({
        type: type.FETCH_SUCCESS,
        entityName,
      });

      dispatch(
        Notifications.success({
          message: i18next.t('notifications:successfullyRemoveSlateView'),
          position: 'tr',
          autoDismiss: 3,
        }),
      );

      dispatch(push(route.SLATE_VIEWS));
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;
          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
            break;
        }
      }
    });
};

export function clearSlates() {
  return (dispatch) => {
    dispatch({
      type: type.CLEAR_SLATES,
    });
  };
}

export function setCurrentSlateView(uuid) {
  return (dispatch) => {
    dispatch({
      type: type.SET_CURRENT_SLATE_VIEW,
      payload: {
        uuid,
      },
    });
  };
}

export function updateSlatePane(stateAttributes) {
  return (dispatch) => {
    if (isBoolean(stateAttributes.isPaneDocked)) {
      const data = {};
      data.patches = [
        createReplacePatch(
          `/dashboardSettings/slatePaneExpanded`,
          !!stateAttributes.isPaneDocked,
        ),
      ];
      dispatch(updateUser(data, entityNames.DASHBOARD_SETTINGS));
    }
    dispatch({
      type: type.UPDATE_SLATE_PANE_STATE,
      payload: stateAttributes,
    });
  };
}

export function updateSlateViewFilters(filters) {
  return (dispatch) => {
    dispatch({
      type: type.UPDATE_SLATE_VIEW_FILTERS,
      payload: filters,
    });
  };
}

export function setNewSlateViewType(slateViewType) {
  return (dispatch) => {
    dispatch({
      type: type.SET_NEW_SLATE_VIEW_TYPE,
      payload: slateViewType,
    });
  };
}

// Current Slate
export function fetchCurrentSlate(slateUUID, isRecord) {
  return (dispatch) => {
    let entityName = entityNames.GET_SLATE;

    if (isRecord) {
      entityName = entityNames.GET_RECORD_SLATE;
    }

    dispatch({
      type: type.FETCH_START,
      entityName,
    });

    const newOption = {...options};
    newOption.headers = {...newOption.headers,
      'api-revision': 3,
    }

    return axios
      .get(`${api.GET_SLATE}/${slateUUID}`, newOption)
      .then((res) => {
        handleVersion(res);
        const currentSlate = res.data.data;

        if(currentSlate.parentSlateInfo) {
          axios
            .get(`${api.GET_SLATE}/${currentSlate.parentSlateInfo.uuid}`, options)
            .then((res) => {
              handleVersion(res);
              const parentSlate = res.data.data;

              currentSlate.allowEntryEdits = parentSlate
                .fields[currentSlate.parentSlateInfo.fieldUUID].allowEntryEdits;
            })
        }

        dispatch({
          type: type.FETCH_SUCCESS,
          entityName,
        });

        if (currentSlate) {
          dispatch({
            type: isRecord ? type.SET_RECORD_SLATE : type.SET_CURRENT_SLATE,
            payload: {
              currentSlate,
            },
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (err.response) {
          switch (err.response.status) {
            case 400:
              dispatch({
                type: type.FETCH_ERROR,
                entityName,
                hasErrors: { error400: i18next.t('messages:userNotFound') },
              });
              break;

            case 422:
              if (err.response.data.validationErrors.dataErrors) {
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName,
                  hasErrors: {
                    dataErrors: err.response.data.validationErrors.dataErrors,
                  },
                });
              } else {
                dispatch({
                  type: type.ERROR_DIALOG_OPEN,
                  uuid: err.response.data.correlationUUID || null,
                });
              }
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export const updateSlate = (patches, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityName,
  });

  const slateUUID = getState().slateViews.currentSlate.uuid;
  const accountUUID = getState().accounts.currentAccount.uuid;

  const newOption = {...options};
  newOption.headers = {...newOption.headers,
    'api-revision': 3,
  }

  return axios
    .patch(
      `${api.UPDATE_RESOURCE}/${resourceTypes.SLATE}/${slateUUID}?account-uuid=${accountUUID}&${INCLUDE_RESOURCE}`,
      patches,
      newOption,
    )
    .then((res) => {
      handleVersion(res);
      let currentSlate = res.data.data;
      if(currentSlate.parentSlateInfo) {
        axios
          .get(`${api.GET_SLATE}/${currentSlate.parentSlateInfo.uuid}`, newOption)
          .then((res) => {
            handleVersion(res);
            const parentSlate = res.data.data;

            currentSlate.allowEntryEdits = parentSlate
              .fields[currentSlate.parentSlateInfo.fieldUUID].allowEntryEdits;
          })
      }
      if (currentSlate) {
        dispatch({
          type: type.SET_CURRENT_SLATE,
          payload: {
            currentSlate,
          },
        });
      }

      dispatch({
        type: type.ENTITY_UPDATED,
        entityName: entityName,
      });

      dispatch(
        Notifications.success({
          message: i18next.t('notifications:successfullyUpdateAccount'),
          position: 'tr',
          autoDismiss: 3,
        }),
      );
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: { error400: i18next.t('messages:userNotFound') },
            });
            break;

          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;

          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
        }
      }
    });
};

export const updateRecordEntry = (patches, entityName) => (dispatch, getState) => {
  dispatch({
    type: type.FETCH_START,
    entityName: entityName,
  });

  const slateUUID = getState().slateViews.recordSlate.uuid;
  const accountUUID = getState().accounts.currentAccount.uuid;

  const newOption = {...options};
  newOption.headers = {...newOption.headers,
    'api-revision': 3,
  }

  return axios
    .patch(
      `${api.UPDATE_RESOURCE}/${resourceTypes.SLATE}/${slateUUID}?account-uuid=${accountUUID}&${INCLUDE_RESOURCE}`,
      patches,
      options,
    )
    .then((res) => {
      handleVersion(res);
      let currentSlate = res.data.data;
      if(currentSlate.parentSlateInfo) {
        axios
          .get(`${api.GET_SLATE}/${currentSlate.parentSlateInfo.uuid}`, newOption)
          .then((res) => {
            handleVersion(res);
            const parentSlate = res.data.data;

            currentSlate.allowEntryEdits = parentSlate
              .fields[currentSlate.parentSlateInfo.fieldUUID].allowEntryEdits;
          })
      }
      if (currentSlate) {
        dispatch({
          type: SET_RECORD_SLATE,
          payload: {
            currentSlate,
          },
        });
      }

      dispatch({
        type: type.ENTITY_UPDATED,
        entityName: entityName,
      });
    })
    .catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: { error400: i18next.t('messages:userNotFound') },
            });
            break;

          case 422:
            if (err.response.data.validationErrors.dataErrors) {
              dispatch({
                type: type.FETCH_ERROR,
                entityName: entityName,
                hasErrors: {
                  dataErrors: err.response.data.validationErrors.dataErrors,
                },
              });
            } else {
              dispatch({
                type: type.ERROR_DIALOG_OPEN,
                uuid: err.response.data.correlationUUID || null,
              });
            }
            break;

          default:
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityName,
              hasErrors: err.response.data.correlationUUID || null,
            });
        }
      }
    });
};

export function fetchJournalEntries(fieldUUID, accountUuid) {
  return (dispatch, getState) => {
    if (fieldUUID && accountUuid) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_JOURNAL_ENTRIES,
      });

      const { continuationToken } = getState().slateViews.journalEntries;
      let slateViewOptions = cloneDeep(options);

      if (continuationToken) {
        slateViewOptions.headers['Continuation-Token'] = continuationToken;
      }

      return axios
        .get(
          `${api.GET_SLATES}/${accountUuid}/slate/fields/${fieldUUID}/journal-entries`,
          slateViewOptions,
        )
        .then((res) => {
          handleVersion(res);
          const { entries, continuationToken } = res.data.data;
          if (entries) {
            dispatch({
              type: type.SET_JOURNAL_ENTRIES,
              payload: {
                entries,
                continuationToken,
              },
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_JOURNAL_ENTRIES,
          });
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityNames.GET_JOURNAL_ENTRIES,
              hasErrors: err.response.data.correlationUUID || null,
            });
          }
        });
    }
  };
}

export function clearRecordSlate() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_RECORD_SLATE });
  };
}

export function clearJournalEntries() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_JOURNAL_ENTRIES });
  };
}

export function fetchRecordEntries(fieldUUID, accountUuid) {
  return (dispatch, getState) => {
    if (fieldUUID && accountUuid) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_RECORD_ENTRIES,
      });

      const { continuationToken } = getState().slateViews.recordEntries;
      let slateViewOptions = cloneDeep(options);

      if (continuationToken) {
        slateViewOptions.headers['Continuation-Token'] = continuationToken;
      }

      return axios
        .get(
          `${api.GET_SLATES}/${accountUuid}/slate/fields/${fieldUUID}/record-entries`,
          slateViewOptions,
        )
        .then((res) => {
          handleVersion(res);
          let { entries, continuationToken } = res.data.data;

          if (entries) {
            entries = entries.map((entry) => {
              return {
                columns: [
                  { index: 0, value: entry.lastChanged.displayName },
                  { index: 1, value: entry.lastChanged.timestamp },
                ],
                ...entry,
              };
            });
            dispatch({
              type: type.SET_RECORD_ENTRIES,
              payload: {
                entries,
                continuationToken,
              },
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_RECORD_ENTRIES,
          });
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityNames.GET_RECORD_ENTRIES,
              hasErrors: err.response.data.correlationUUID || null,
            });
          }
        });
    }
  };
}

export function clearRecordEntries() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_RECORD_ENTRIES });
  };
}

// Slate View Settings
export function fetchSlateViewSettings(viewUUID) {
  return (dispatch, getState) => {
    const { currentAccount } = getState().accounts;

    if (viewUUID && currentAccount) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_SLATE_VIEW_SETTINGS,
      });

      let url = `${api.GET_SLATE_VIEW_SETTINGS}/${currentAccount.uuid}/views/${viewUUID}`;

      if(isLoggedOut(getState().currentUser)) {
        url += '/info'
      }

      return axios
        .get(
          url,
          options,
        )
        .then((res) => {
          handleVersion(res);
          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_SLATE_VIEW_SETTINGS,
          });

          const settings = adoptSettingsColumns(res.data.data);

          if (settings) {
            dispatch({
              type: type.SET_SLATE_VIEW_SETTINGS,
              payload: {
                settings,
              },
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_SLATE_VIEW_SETTINGS,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function clearSlateViewSettings() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_SLATE_VIEW_SETTINGS });
  };
}

export function updateSlateViewSettings(
  data,
  entityName,
  viewUUID,
  resourceType,
  name,
  newSortAndFilters,
) {
  return (dispatch, getState) => {
    const { patches: _patches } = data;
    const patches = getValidFormRulesList(_patches);
    if (patches.length) {
      const accountUUID = getState().accounts.currentAccount.uuid;

      dispatch({
        type: type.FETCH_START,
        entityName,
      });
      return axios
        .patch(
          `${api.UPDATE_RESOURCE}/${resourceType}/${viewUUID}?account-uuid=${accountUUID}&${INCLUDE_RESOURCE}`,
          patches,
          options,
        )
        .then((res) => {
          handleVersion(res);
          dispatch({
            type: type.FETCH_SUCCESS,
            entityName,
          });

          if (newSortAndFilters) {
            dispatch({
              type: type.UPDATE_SLATE_VIEW_FILTERS,
              payload: newSortAndFilters,
            });
          } else {
            dispatch({
              type: type.CLEAR_SLATE_VIEW_FILTERS,
            });
          }
          dispatch({
            type: type.CLEAN_SLATE_VIEWS_AFTER_UPDATE_SETTINGS,
            payload: {
              uuid: viewUUID,
            },
          });

          dispatch({
            type: type.SET_CURRENT_SLATE_VIEW,
            payload: {
              uuid: viewUUID,
            },
          });

          const settings = res.data.data;
          if (settings) {
            dispatch({
              type: type.SET_SLATE_VIEW_SETTINGS,
              payload: {
                settings,
              },
            });
          }

          dispatch(push(`/accounts/${accountUUID}/views/${viewUUID}`));

          dispatch(
            Notifications.success({
              message: `${name} ${i18next.t(
                'notifications:successfullySavedSlateViewSettings',
              )}`,
              position: 'tr',
              autoDismiss: 5,
            }),
          );
        })
        .catch((err) => {
          if (err.response) {
            switch (err.response.status) {
              case 400:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: { error400: i18next.t('messages:userNotFound') },
                });
                break;

              case 422:
                if (err.response.data.validationErrors.dataErrors) {
                  dispatch({
                    type: type.FETCH_ERROR,
                    entityName: entityName,
                    hasErrors: {
                      dataErrors: err.response.data.validationErrors.dataErrors,
                    },
                  });
                } else {
                  dispatch({
                    type: type.ERROR_DIALOG_OPEN,
                    uuid: err.response.data.correlationUUID || null,
                  });
                }
                break;

              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityName,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    } else {
      // nothing to update
      dispatch(push(route.SLATE_VIEWS));
    }
  };
}

export function updateFieldsSource(fieldType) {
  return (dispatch, getState) => {
    dispatch({
      type: type.UPDATE_SETTINGS_FIELDS_FILTERS,
      payload: {
        include: fieldType,
      },
    });
    fetchSettingsFields()(dispatch, getState);
  };
}

export function cleanFieldsSource() {
  return (dispatch) => {
    dispatch({
      type: type.CLEAN_SETTINGS_FIELDS_FILTERS,
    });
  };
}

export function revertFieldsSource() {
  return (dispatch) => {
    dispatch({
      type: type.REVERT_SETTINGS_OPERATORS,
    });
  };
}
export function confirmFieldsSource() {
  return (dispatch) => {
    dispatch({
      type: type.CONFIRM_SETTINGS_OPERATORS,
    });
  };
}

export function openWarningSettingDialog(data) {
  return (dispatch) => {
    dispatch({
      type: type.OPEN_WARNING_SETTING_DIALOG,
      payload: data
    });
  };
}

export function cleanSnapshotPreviousVersion() {
  return (dispatch) => {
    dispatch({
      type: type.CLEAN_SNAPSHOT_PREVIOUS_VERSION,
    });
  };
}

const makeGetSettingsFieldsPath = (currentAccount, settingsFieldsFilters) => {
  let path = `${api.GET_SLATE_VIEW_SETTINGS}/${currentAccount.uuid}/fields`;
  if (settingsFieldsFilters.include) {
    path = path.concat(`?include=${settingsFieldsFilters.include}`);
  }
  return path;
};

export function fetchSettingsFields() {
  return (dispatch, getState) => {
    const { currentAccount } = getState().accounts;
    const { settingsFieldsFilters } = getState().slateViews;
    if (currentAccount) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_SETTINGS_FIELDS,
      });

      const path = makeGetSettingsFieldsPath(
        currentAccount,
        settingsFieldsFilters,
      );

      return axios
        .get(path, options)
        .then((res) => {
          handleVersion(res);
          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_SETTINGS_FIELDS,
          });

          const settingsFields = res.data.data;

          if (settingsFields) {
            dispatch({
              type: type.SET_SETTINGS_FIELDS,
              payload: [
                // Maybe some day metaAttributes will be retrieved from server so let it be here
                {
                  isShown: true,
                  title: 'Slate Title',
                  parents: [],
                  suuid: 'title',
                  type: 'text',
                  meta: true,
                },
                {
                  isShown: true,
                  title: 'UUID',
                  parents: [],
                  suuid: 'uuid',
                  type: 'text',
                  meta: true,
                },
                {
                  isShown: true,
                  title: 'URL',
                  parents: [],
                  suuid: 'url',
                  type: 'text',
                  meta: true,
                },
                {
                  isShown: true,
                  title: 'Slate Photo',
                  parents: [],
                  suuid: 'photo',
                  type: 'photo',
                  meta: true,
                },
              ].concat(settingsFields),
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_SETTINGS_FIELDS,
                  hasErrors: err.response.data.correlationUUID || null,
                });
            }
          }
        });
    }
  };
}

export function clearSettingsFields() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_SETTINGS_FIELDS });
  };
}

export function fetchSettingsOperators(suuid, isCounted) {
  return (dispatch, getState) => {
    const { currentAccount } = getState().accounts;

    if (suuid && currentAccount) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_SETTINGS_OPERATORS,
        isCounted,
      });

      return axios
        .get(
          `${api.GET_SLATE_VIEW_SETTINGS}/${currentAccount.uuid}/fields/${suuid}/operators`,
          options,
        )
        .then((res) => {
          handleVersion(res);
          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_SETTINGS_OPERATORS,
            isCounted,
          });

          const settingsOperators = res.data.data;

          if (settingsOperators) {
            dispatch({
              type: type.SET_SETTINGS_OPERATORS,
              payload: {
                suuid,
                settingsOperators,
              },
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            switch (err.response.status) {
              default:
                dispatch({
                  type: type.FETCH_ERROR,
                  entityName: entityNames.GET_SETTINGS_OPERATORS,
                  hasErrors: err.response.data.correlationUUID || null,
                  isCounted,
                });
            }
          }
        });
    }
  };
}

export function clearSettingsOperators(suuid) {
  return (dispatch) => {
    dispatch({
      type: type.CLEAR_SETTINGS_OPERATORS,
      payload: { suuid },
    });
  };
}

// Search
export function fetchSlatesForSearch(values) {
  return (dispatch, getState) => {
    const entityName = entityNames.GET_SLATES;
    dispatch({
      type: type.FETCH_START,
      entityName,
    });

    const accountUUID = getState().accounts.currentAccount.uuid;
    let slateViewOptions = cloneDeep(options);

    return axios
      .get(
        `${api.GET_SLATES}/${accountUUID}/slates?query=${values.search}`,
        slateViewOptions,
      )
      .then((res) => {
        handleVersion(res);
        dispatch(push(route.SLATE_VIEW_SEARCH_REDIRECT + values.search));
        dispatch({
          type: type.FETCH_SUCCESS,
          entityName,
        });
        const slates = res.data.data;
        const autoRefresh = res.data.autoRefresh;
        const rowCount = res.data.data.length;
        if (slates) {
          dispatch({
            type: type.SET_SLATES_SEARCH_RESULTS,
            payload: {
              slates,
              rowCount,
              autoRefresh,
              searchString: values.search,
            },
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (err.response) {
          switch (err.response.status) {
            case 400:
              dispatch({
                type: type.FETCH_ERROR,
                entityName,
                hasErrors: { error400: i18next.t('messages:userNotFound') },
              });
              break;

            default:
              dispatch({
                type: type.FETCH_ERROR,
                entityName,
                hasErrors: err.response.data.correlationUUID || null,
              });
          }
        }
      });
  };
}

export function fetchHistoryEntries(fieldUUID) {
  return (dispatch, getState) => {
    const { currentAccount } = getState().accounts;
    const { currentSlate } = getState().slateViews;

    if (fieldUUID && currentAccount && currentSlate) {
      dispatch({
        type: type.FETCH_START,
        entityName: entityNames.GET_HISTORY_ENTRIES,
      });

      const { continuationToken } = getState().slateViews.historyEntries;
      let slateViewOptions = cloneDeep(options);

      if (continuationToken) {
        slateViewOptions.headers['Continuation-Token'] = continuationToken;
      }

      return axios
        .get(
          `${api.GET_SLATES}/${currentAccount.uuid}/slates/${currentSlate.uuid}/fields/${fieldUUID}/history`,
          slateViewOptions,
        )
        .then((res) => {
          handleVersion(res);
          let { entities, continuationToken } = res.data.data;

          if (entities) {
            entities = entities.map((entity, index) => {
              return {
                uuid: `slate-field-history-${index + 1}`,
                columns: [
                  { index: 0, value: entity.timestamp },
                  { index: 1, value: entity.displayName },
                  { index: 2, value: entity.value },
                ],
              };
            });
            dispatch({
              type: type.SET_HISTORY_ENTRIES,
              payload: {
                entries: entities,
                continuationToken,
              },
            });
          }

          dispatch({
            type: type.FETCH_SUCCESS,
            entityName: entityNames.GET_HISTORY_ENTRIES,
          });
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response) {
            dispatch({
              type: type.FETCH_ERROR,
              entityName: entityNames.GET_HISTORY_ENTRIES,
              hasErrors: err.response.data.correlationUUID || null,
            });
          }
        });
    }
  };
}

export function clearHistoryEntries() {
  return (dispatch) => {
    dispatch({ type: type.CLEAR_HISTORY_ENTRIES });
  };
}

export function updateColumns(columns) {
  return (dispatch) => {
    dispatch({
      type: type.UPDATE_COLUMNS,
      payload: columns,
    });
  };
}
