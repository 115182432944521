import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FormUserProfile from '../../components/Forms/FormUserProfile/FormUserProfile';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}
export default withTranslation()(connect(mapStateToProps)(FormUserProfile));
