import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import p from 'theme/palette';

const useStyles = makeStyles(() => ({
  chip: {
    fontSize: 10,
    textTransform: 'lowercase',
    color: p.grey400,
    fontWeight: 500,
  },
}));

const SlateViewChip = ({ type }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Chip label={t(`settings:${type}`)} className={classes.chip} size="small" />
  );
};

SlateViewChip.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SlateViewChip;
