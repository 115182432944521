import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import * as type from 'store/actions/types';
import { metricsModes } from 'config/constants';

const initialState = {
  isReady: false,
  settings: {
    gridWidth: null, // keeps ONLY data from server.
    widgets: {},
    settingsLoading: false,
    widgetsLoading: true,
    generatedByGrid: false,
    widgetAttentions: [],
  },
  mode: metricsModes.FILTERS,
  filters: {
    startDate: moment(new Date()).add(-1, 'M').unix(),
    endDate: moment().unix(),
    accountUuid: null,
    userUuid: null,
    isUpdating: false,
  },
  widgets: {},
  gridUiParams: {
    actualGridWidth: null,
    maxItemsPerLine: null,
    useMobileMetrics: false,
  },
};

const metrics = (state = initialState, action) => {
  switch (action.type) {
    // SETTINGS
    case type.GET_METRICS_SETTINGS_START: {
      let newState = cloneDeep(state);
      newState.settings.settingsLoading = true;
      return newState;
    }
    case type.INIT_METRICS_SETTINGS: {
      let newState = cloneDeep(state);
      const { payload } = action;
      newState.settings.gridWidth = payload.gridWidth;
      newState.settings.widgets = payload.widgets;
      newState.settings.settingsLoading = false;
      newState.settings.generatedByGrid = payload.generatedByGrid || false;
      newState.isReady = true;
      return newState;
    }
    case type.SET_METRICS_SETTINGS: {
      let newState = cloneDeep(state);
      const { payload } = action;
      newState.settings.gridWidth = payload.gridWidth;
      newState.settings.widgets = payload.widgets;
      // newState.settings.settingsLoading = false;
      newState.settings.generatedByGrid = payload.generatedByGrid || false;
      newState.gridUiParams.actualGridWidth = payload.gridWidth;

      return newState;
    }

    // Ui Params

    case type.SET_METRICS_ACTUAL_GRID_WIDTH: {
      let newState = cloneDeep(state);
      const { payload } = action;
      if (payload.actualGridWidth) {
        newState.gridUiParams.actualGridWidth = payload.actualGridWidth;
      }
      newState.gridUiParams.maxItemsPerLine = payload.maxItemsPerLine;
      return newState;
    }

    // WIDGETS
    case type.GET_METRICS_WIDGETS_START: {
      let newState = cloneDeep(state);
      // newState.settings.widgetsLoading = true;
      return newState;
    }
    case type.SET_METRICS_WIDGETS: {
      let newState = cloneDeep(state);
      const { payload } = action;
      newState.widgets = payload.widgets;
      newState.settings.widgetsLoading = false;
      newState.filters.isUpdating = false;
      return newState;
    }

    // FILTERS

    case type.SET_METRICS_FILTERS: {
      let newState = cloneDeep(state);
      newState.filters = {
        ...newState.filters,
        ...action.payload.filters,
        isUpdating: true,
      };
      return newState;
    }

    // WIDGET
    case type.GET_WIDGET_START: {
      let newState = cloneDeep(state);

      newState.widgets[action.payload.widget] = { loading: true };
      return newState;
    }
    case type.ADD_WIDGET_TO_METRICS: {
      let newState = cloneDeep(state);
      const { payload } = action;
      newState.widgets = { ...newState.widgets, ...payload.widget };
      return newState;
    }
    case type.DELETE_WIDGET_FROM_METRICS: {
      let newState = cloneDeep(state);
      const { payload } = action;
      delete newState.widgets[payload.widgetName];
      delete newState.settings.widgets[payload.widgetName];
      newState.settings.generatedByGrid = false;
      return newState;
    }

    // UTILS
    case type.UPDATE_METRICS_GRID_WIDTH: {
      let newState = cloneDeep(state);
      newState.settings.gridWidth = action.payload.gridWidth;
      return newState;
    }

    case type.CHANGE_METRICS_MODE: {
      let newState = cloneDeep(state);
      newState.mode = action.payload.mode;
      return newState;
    }

    case type.ATTRACT_ATTENTION_TO_WIDGET: {
      let newState = cloneDeep(state);
      newState.settings.widgetAttentions.push(action.payload.widgetName);
      return newState;
    }

    case type.HIDE_ATTENTION_FROM_WIDGET: {
      let newState = cloneDeep(state);
      newState.settings.widgetAttentions = newState.settings.widgetAttentions.filter(
        (widgetName) => widgetName !== action.payload.widgetName,
      );
      return newState;
    }

    case type.START_METRICS_MODE_CHANGING: {
      let newState = cloneDeep(state);
      newState.settings.isModeChanging = true;
      return newState;
    }

    case type.FINISH_METRICS_MODE_CHANGING: {
      let newState = cloneDeep(state);
      delete newState.settings.isModeChanging;
      return newState;
    }

    default:
      break;
  }
  return state;
};

export default metrics;
