import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';

import Rule from 'components/SlateViewsSettings/Rule';
import generateUUID from 'helpers/generateUUID';
import { checkPatternExist } from 'helpers/fieldsHelper';
import { logicOperatorTypes, ruleParts } from 'config/constants';
import { styles } from './styles';

const RULE_TEMPLATE = {
  [ruleParts.FIELD_SUUID]: undefined,
  [ruleParts.OPERATOR]: undefined,
  [ruleParts.PATTERN]: undefined,
};

const SettingsRules = ({
  value,
  value: { rules, andOr: logicOperator },
  fields,
  label,
  onChange,
  fetchSettingsOperators,
  recordsFieldUniquenessIdentifier,
  isRecordListView,
}) => {
  const { t } = useTranslation();
  const [activeRule, setActiveRule] = useState('')
  // exclude meta fields for slate view and filter fields for record view
  const filteredFields = useMemo(() => {
    if (recordsFieldUniquenessIdentifier) {
      const allRecordChildren = fields.filter(
        (field) =>
          field.parents &&
          field.parents.find(
            (parent) => parent.suuid === recordsFieldUniquenessIdentifier,
          ),
      );
      // for proper rendering in RuleFields component
      return allRecordChildren.map((recordChild) =>
        recordChild.parent.suuid === recordsFieldUniquenessIdentifier
          ? {
              ...recordChild,
              parent: undefined,
              parents: undefined,
            }
          : recordChild,
      );
    } else {
      return fields.filter((field) => !field.meta);
    }
  }, [fields, recordsFieldUniquenessIdentifier]);

  const addRule = () => {
    onChange({
      ...value,
      rules: [
        ...value.rules,
        {
          ...RULE_TEMPLATE,
          uuid: generateUUID(),
        },
      ],
    });
  };

  const removeRule = (uuid) => {
    onChange({
      ...value,
      rules: filter(value.rules, (rule) => rule.uuid !== uuid),
    });
  };

  const changeRule = (params) => {
    onChange({
      ...value,
      rules: value.rules.map((rule) => {
        if (rule.uuid === params.uuid) {
          const newRule = { ...rule, title: params.title };
          newRule[params.name] = params.value;

          // clear operator and pattern if field changed
          if ((params.name === ruleParts.FIELD_SUUID) ||
            (params.name === ruleParts.RECORD_ENTRY_ATTRIBUTE)
          ) {
            newRule[ruleParts.OPERATOR] = '';
            newRule[ruleParts.PATTERN] = '';

            setActiveRule(params.uuid)
            // fetch new operators
            if(params.name !== ruleParts.RECORD_ENTRY_ATTRIBUTE) {
              (newRule[ruleParts.RECORD_ENTRY_ATTRIBUTE]) && (delete newRule[ruleParts.RECORD_ENTRY_ATTRIBUTE]);
              fetchSettingsOperators(params.value);
            } else {
              (newRule[ruleParts.FIELD_SUUID]) && (delete newRule[ruleParts.FIELD_SUUID]);
            }
          }

          // clear pattern if operator shouldn't have it
          if (
            params.name === ruleParts.OPERATOR &&
            !checkPatternExist(params.value)
          ) {
            newRule[ruleParts.PATTERN] = '';
          }
          return newRule;
        }
        return rule;
      }),
    });
  };

  const onLogicOperatorChange = (event, index, andOr) => {
    onChange({ ...value, andOr });
  };

  return (
    <div className="settings-rules">
      <div className="settings-rules-operator">
        {label || (
          <span className="settings-rules-operator-text">
            {t('settings:match')}
          </span>
        )}
        <SelectField
          floatingLabelText=""
          value={logicOperator}
          style={styles.selectField.style}
          labelStyle={styles.selectField.labelStyle}
          iconStyle={styles.selectField.iconStyle}
          selectedMenuItemStyle={styles.selectField.selectedMenuItemStyle}
          listStyle={styles.selectField.listStyle}
          underlineStyle={styles.selectField.underlineStyle}
          onChange={onLogicOperatorChange}
        >
          <MenuItem
            value={logicOperatorTypes.AND}
            primaryText={t('settings:all')}
            innerDivStyle={styles.menuItem.innerDivStyle}
          />
          <MenuItem
            value={logicOperatorTypes.OR}
            primaryText={t('settings:any')}
            innerDivStyle={styles.menuItem.innerDivStyle}
          />
        </SelectField>
        <span className="settings-rules-operator-text">
          {t('settings:ofTheFollowingRules')}
        </span>
      </div>

      {map(rules, (rule) => (
        <Rule
          key={rule.uuid}
          rule={rule}
          fields={filteredFields}
          allFields={fields}
          recordsFieldUniquenessIdentifier={recordsFieldUniquenessIdentifier}
          onRuleChange={changeRule}
          onRemoveRule={removeRule}
          activeRule={activeRule}
          isRecordListView={isRecordListView}
        />
      ))}

      <RaisedButton
        className="settings-rules-add"
        label={t('btn:addRule')}
        labelStyle={styles.addRule}
        backgroundColor="#aaaaaa"
        onClick={addRule}
      />
    </div>
  );
};

SettingsRules.propTypes = {
  value: PropTypes.object,
  fields: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  fetchSettingsOperators: PropTypes.func.isRequired,
  label: PropTypes.node,
  recordsFieldUniquenessIdentifier: PropTypes.string,
};

export default SettingsRules;
