import p from '../../theme/palette';
const styles = {
  steps: {
    display: 'flex',
    position: 'relative',
    margin: '30px 0px',
  },
  item: {
    width: 34,
    height: 34,
    lineHeight: '34px',
    textAlign: 'center',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: p.grey300,
    color: p.grey300,
    margin: '0px auto',
    backgroundColor: p.white,
    position: 'relative',
    zIndex: 1,
  },
  itemActive: {
    backgroundColor: p.grey600,
    color: p.white,
  },
  border: {
    borderBottom: '1px solid',
    borderColor: p.grey300,
    height: 1,
    position: 'absolute',
    top: 17,
  },
};

export default styles;
